import { MixpanelDashboardBookmark } from './chart.type';

// IF YOU DONT USE THEM DELETE THEM

export type MixpanelDashboardCard = { bookmark: MixpanelDashboardBookmark };
export type MixpanelDashboardDataResponse = Record<string, unknown> & {
	id: number;
	dashboard_cards: { bookmark: MixpanelDashboardBookmark }[];
	bookmarks: MixpanelDashboardBookmark[];
};

export type MixpanelDashboardMetadataResponse = {
	allow_staff_override: boolean;
	can_share: boolean;
	can_update_basic: boolean;
	can_view: boolean;
	creator: string;
	creator_email: string;
	creator_id: number;
	creator_name: string;
	description: string | null;
	filters: unknown[];
	length: number;
	id: number;
	is_boards_ui_enabled: boolean;
	is_favorited: boolean;
	is_private: boolean;
	is_restricted: boolean;
	is_superadmin: boolean;
	modified: string | null; //yyyy-mm-dd
	pinned_date: string | null; //yyyy-mm-dd
	time_filter: Record<string, unknown>;
	title: string;
};
