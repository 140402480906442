import { TutorialInterface } from './tutorial.interface';

export interface PushNotificationsHubDataInterface {
	hub: PushNotificationsHubInterface;
}

export interface PushNotificationsHubInterface {
	callback: string;
	topic: string;
	mode: PushNotificationsModeEnum;
	verify: PushNotificationsVerifyEnum;
	verify_token?: string;
	secret?: string;
	lease_seconds?: number;
}

export enum PushNotificationsModeEnum {
	SUBSCRIBE = 'subscribe',
	UNSUBSCRIBE = 'unsubscribe',
}

export enum PushNotificationsVerifyEnum {
	ASYNC = 'async',
	SYNC = 'sync',
}

export interface PushNotificationsSubscriptionInterface {
	mode: PushNotificationsModeEnum;
}

export type TutorialPushNotificationSubscriptionBody = PushNotificationsSubscriptionInterface;

export type TutorialPushNotificationCallbackResponse = TutorialInterface;
