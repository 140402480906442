import { DOCUMENT } from '@angular/common';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	inject,
	OnInit,
	Renderer2,
} from '@angular/core';
import { Keyboard, KeyboardStyle } from '@capacitor/keyboard';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Theme, UserPreferences } from '@reach/interfaces';
import { filter } from 'rxjs/operators';
import { ReachTranslateService } from '~app-client-item/core/services/translate.service';
import { ClientService, SettingsService } from '~app-client/core/services';
import { ClientPlatform } from '~app-client/core/types';

@UntilDestroy()
@Component({
	selector: 'settings-reactor',
	template: '',
	styles: [':host { display: none; } '],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
})
export class SettingsReactorComponent implements OnInit {
	private readonly translateService = inject(ReachTranslateService);
	private readonly client = inject(ClientService);
	private readonly settingsService = inject(SettingsService);
	private readonly renderer = inject(Renderer2);
	private readonly document = inject(DOCUMENT);
	private readonly cdRef = inject(ChangeDetectorRef);

	constructor() {
		this.cdRef.detach();
	}

	ngOnInit(): void {
		this.settingsService.settings$.pipe(untilDestroyed(this)).subscribe((settings) => {
			this.handleSettings(settings);
		});

		this.setBrowserTheme();

		this.client.appFocused$
			.pipe(
				filter((active) => !!active),
				untilDestroyed(this)
			)
			.subscribe(() => {
				this.setBrowserTheme();
			});
	}

	private handleSettings(settings: UserPreferences): void {
		this.changeTheme(settings.general.theme);
		this.translateService.setLanguage(settings.general.language);
	}

	private changeTheme(theme: Theme): void {
		// set the settings theme in the body
		const allValues = Object.values(Theme);
		const body = this.document.body;

		const themeClass = (t: Theme) => `theme-${t}`.toLowerCase();

		allValues.forEach((t) => {
			if (t !== theme) {
				this.renderer.removeClass(body, themeClass(t));
			}
		});

		this.renderer.addClass(body, themeClass(theme));

		// set the prefered browser theme
		const nonAutoTheme = theme === Theme.AUTO ? this.client.browserTheme : theme;
		const meta = this.document.head.querySelector('meta[name=theme-color]') as HTMLMetaElement;
		const color = nonAutoTheme === Theme.LIGHT ? '#FFFFFF' : '#121213';
		this.renderer.setAttribute(meta, 'content', color);

		// set the keyboard theme
		if (this.client.platform === ClientPlatform.IOS) {
			const keyboardStyle =
				nonAutoTheme === Theme.DARK ? KeyboardStyle.Dark : KeyboardStyle.Light;
			Keyboard?.setStyle?.({ style: keyboardStyle });
		}
	}

	private setBrowserTheme(): void {
		const prefersDarkMode = this.client.browserTheme === Theme.DARK;
		if (prefersDarkMode) {
			this.renderer.addClass(this.document.body, 'device-dark-theme');
		} else {
			this.renderer.removeClass(this.document.body, 'device-dark-theme');
		}
	}
}
