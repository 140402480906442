import { JobMetadataInterface, NodeGroupActionsCountMap } from '../jobs';
import { ReachIntegration } from './integration-type.enum';

export interface IntegrationExecutionInterface {
	id: string;
	type: ReachIntegration;
	createdAt: Date;
	metadata: JobMetadataInterface;
	cooldownUntil: Date | null;
	finishedAt: Date | null;
	totalJobs: number;
	successfulJobs: number;
	failedJobs: number;
	nodeActions: NodeGroupActionsCountMap;
}
