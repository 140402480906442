import { JobInterface, JobStatusEnum, JobTypeEnum } from '../jobs';
import { Nullable } from 'ts-toolbelt/out/Union/Nullable';
import { PipelineInterface } from './pipeline.interface';

export interface ListPipelinesResponseInterface {
	pipelines: PipelineInterface[];
	count: number;
}

export interface GetPipelineDependenciesResponseInterface {
	pipeline: PipelineInterface;
	jobDependencies: { [KEY in JobTypeEnum]?: JobTypeEnum[] };
	jobStates: {
		[KEY in JobTypeEnum]?: {
			id: string;
			jobStatus: JobStatusEnum;
			createdAt: Date;
			startedAt: Nullable<Date>;
			finishedAt: Nullable<Date>;
		}[];
	};
}

export interface GetPipelineJobsResponseInterface {
	jobs: JobInterface[];
}
