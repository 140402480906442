import { CustomError } from 'ts-custom-error';
import { AppErrorParams } from './app-error-params.type';
import { StatusCodes } from 'http-status-codes';
import { v4 as randomUUID } from 'uuid';
import { ErrorCodes } from '@reach/interfaces';

export class AppError extends CustomError implements AppErrorParams {
	public id: string;
	public status: StatusCodes;
	public code: ErrorCodes;
	public title: string;
	public detail: string;
	public meta: Record<string, unknown>;

	constructor({
		id,
		status,
		code,
		title,
		detail,
		meta,
	}: Partial<AppErrorParams> & Required<Pick<AppErrorParams, 'code'>>) {
		super(title);
		this.id = id ?? randomUUID();
		this.status = status ?? StatusCodes.INTERNAL_SERVER_ERROR;
		this.code = code ?? ErrorCodes.MISC_INTERNAL_SERVER_ERROR;
		this.title = title ?? 'An error has occurred.';
		this.detail = detail ?? 'Please contact support.';
		this.meta = meta ?? {};
	}

	public toPayload(): AppErrorParams {
		const { id, status, code, title, detail, meta } = this;
		return { id, status, code, title, detail, meta };
	}
}
