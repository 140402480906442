import { DebugLine } from '../../debug';
import { ConfigAccumulator } from '../../types';

export enum CssUnit {
	PX = 'px',
	EM = 'em',
	REM = 'REM',
}

export type StyleFn = (value: string, _debugLine?: DebugLine) => ConfigAccumulator;
