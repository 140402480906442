import { ClientEnvironment } from '~app-client/core/types';
import { AdTrackEvent } from './ad-tracking.service';

type GoogleAdsTrackFn = (
	event: 'event',
	type: string,
	properties?: Record<string, unknown>
) => void;

type GoogleAdsObj = GoogleAdsTrackFn;
declare const gtag: GoogleAdsObj;

export class GoogleAds {
	private eventToId: Record<AdTrackEvent, string> = {
		[AdTrackEvent.SUBMIT_APPLICATION]: '_ba1CISHmb4DEICv3M0o',
		[AdTrackEvent.COMPLETE_REGISTRATION]: 'sfhHCIeHmb4DEICv3M0o',
	};

	constructor(private readonly environment: ClientEnvironment) {}

	track(eventName: AdTrackEvent): Promise<void> {
		return new Promise<void>((res) => {
			try {
				if (this.environment?.googleAdsId) {
					gtag?.('event', 'conversion', {
						send_to: `${this.environment.googleAdsId}/${this.eventToId[eventName]}`,
						event_callback: () => {
							res();
						},
					});
				}
				if (this.environment?.googleAnalyticsId) {
					gtag?.('event', eventName); //
				}
			} catch (e) {
				console.error(e);
				res();
			}
		});
	}
}
