import { CustomPropertyCreateRequest } from '../../custom-property';
import { NodeSubtypeId } from '../../node-subtypes';

export class ZapierCreateAlbumActionInterface {
	name?: string;
	itemBundleUrl?: string;
	workspaceIds!: string[];
	nodeSubtypeId?: NodeSubtypeId;
	customProperties!: CustomPropertyCreateRequest[];
	comments!: string[];
	callbackUrl!: string;
}
