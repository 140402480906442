import {
	ContainAllOperatorGroup,
	EqualOperatorGroup,
	HasPropertyOperatorGroup,
	SearchOperator,
} from './search-operator.type';

export enum SearchAttribute {
	NAME = 'NAME',
	COMMENT = 'COMMENT',
	THUMBNAIL = 'THUMBNAIL',
}

export const searchAttributeToOperator: { [type in SearchAttribute]: SearchOperator[] } = {
	[SearchAttribute.NAME]: [...EqualOperatorGroup, ...ContainAllOperatorGroup],
	[SearchAttribute.COMMENT]: [...ContainAllOperatorGroup, ...HasPropertyOperatorGroup],
	[SearchAttribute.THUMBNAIL]: [...HasPropertyOperatorGroup],
};

export interface SearchAttributeFilter {
	id: SearchAttribute;
	operator: SearchOperator;
	value?: string;
}
