import { ReachSlateElementType } from '@reach/interfaces';
import { ConfigAccumulator } from '../types';

export const FORMATS_FROM_QUILL_CLASSES: Record<string, ConfigAccumulator> = {
	'ql-bg-reach-imp-1': {
		[ReachSlateElementType.TEXT]: {
			backgroundColor: 'imp-1',
		},
	},
	'ql-bg-reach-imp-2': {
		[ReachSlateElementType.TEXT]: {
			backgroundColor: 'imp-2',
		},
	},
	'ql-bg-reach-imp-3': {
		[ReachSlateElementType.TEXT]: {
			backgroundColor: 'imp-3',
		},
	},
	'ql-bg-reach-imp-4': {
		[ReachSlateElementType.TEXT]: {
			backgroundColor: 'imp-4',
		},
	},
	'ql-bg-reach-imp-5': {
		[ReachSlateElementType.TEXT]: {
			backgroundColor: 'imp-5',
		},
	},
	'ql-bg-reach-imp-6': {
		[ReachSlateElementType.TEXT]: {
			backgroundColor: 'imp-6',
		},
	},
	'ql-bg-reach-imp-7': {
		[ReachSlateElementType.TEXT]: {
			backgroundColor: 'imp-7',
		},
	},
	'ql-bg-reach-imp-8': {
		[ReachSlateElementType.TEXT]: {
			backgroundColor: 'imp-8',
		},
	},
	'ql-bg-reach-imp-9': {
		[ReachSlateElementType.TEXT]: {
			backgroundColor: 'imp-9',
		},
	},
	'ql-bg-reach-imp-10': {
		[ReachSlateElementType.TEXT]: {
			backgroundColor: 'imp-10',
		},
	},
	'ql-color-reach-imp-1': {
		[ReachSlateElementType.TEXT]: {
			fontColor: 'imp-1',
		},
	},
	'ql-color-reach-imp-2': {
		[ReachSlateElementType.TEXT]: {
			fontColor: 'imp-2',
		},
	},
	'ql-color-reach-imp-3': {
		[ReachSlateElementType.TEXT]: {
			fontColor: 'imp-3',
		},
	},
	'ql-color-reach-imp-4': {
		[ReachSlateElementType.TEXT]: {
			fontColor: 'imp-4',
		},
	},
	'ql-color-reach-imp-5': {
		[ReachSlateElementType.TEXT]: {
			fontColor: 'imp-5',
		},
	},
	'ql-color-reach-imp-6': {
		[ReachSlateElementType.TEXT]: {
			fontColor: 'imp-6',
		},
	},
	'ql-color-reach-imp-7': {
		[ReachSlateElementType.TEXT]: {
			fontColor: 'imp-7',
		},
	},
	'ql-color-reach-imp-8': {
		[ReachSlateElementType.TEXT]: {
			fontColor: 'imp-8',
		},
	},
	'ql-color-reach-imp-9': {
		[ReachSlateElementType.TEXT]: {
			fontColor: 'imp-9',
		},
	},
	'ql-color-reach-imp-10': {
		[ReachSlateElementType.TEXT]: {
			fontColor: 'imp-10',
		},
	},
	'ql-size-reach-s': {
		[ReachSlateElementType.TEXT]: {
			fontSize: 's',
		},
	},
	'ql-size-reach-m': {
		[ReachSlateElementType.TEXT]: {
			fontSize: 'm',
		},
	},
	'ql-size-reach-l': {
		[ReachSlateElementType.TEXT]: {
			fontSize: 'l',
		},
	},
	'ql-size-reach-xl': {
		[ReachSlateElementType.TEXT]: {
			fontSize: 'xl',
		},
	},
	'ql-font-reach-serif': {
		[ReachSlateElementType.TEXT]: {
			fontFamily: 'Serif',
		},
	},
	'ql-font-reach-monospace': {
		[ReachSlateElementType.TEXT]: {
			fontFamily: 'Mono',
		},
	},
	'ql-align-left': {
		[ReachSlateElementType.PARAGRAPH]: {
			alignment: 'left',
		},
	},
	'ql-align-center': {
		[ReachSlateElementType.PARAGRAPH]: {
			alignment: 'center',
		},
	},
	'ql-align-right': {
		[ReachSlateElementType.PARAGRAPH]: {
			alignment: 'right',
		},
	},
	'ql-align-justify': {
		[ReachSlateElementType.PARAGRAPH]: {
			alignment: 'justify',
		},
	},
	'ql-indent-1': {
		[ReachSlateElementType.PARAGRAPH]: {
			indent: 1,
		},
	},
	'ql-indent-2': {
		[ReachSlateElementType.PARAGRAPH]: {
			indent: 2,
		},
	},
	'ql-indent-3': {
		[ReachSlateElementType.PARAGRAPH]: {
			indent: 3,
		},
	},
	'ql-indent-4': {
		[ReachSlateElementType.PARAGRAPH]: {
			indent: 4,
		},
	},
	'ql-indent-5': {
		[ReachSlateElementType.PARAGRAPH]: {
			indent: 5,
		},
	},
	'ql-indent-6': {
		[ReachSlateElementType.PARAGRAPH]: {
			indent: 6,
		},
	},
	'ql-indent-7': {
		[ReachSlateElementType.PARAGRAPH]: {
			indent: 7,
		},
	},
	'ql-indent-8': {
		[ReachSlateElementType.PARAGRAPH]: {
			indent: 8,
		},
	},
};
