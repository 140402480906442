import { ReachColor } from './types';

export const REACH_COLOR_TO_VALUE: Record<ReachColor, string> = {
	[ReachColor.IMP_1]: 'imp-1',
	[ReachColor.IMP_2]: 'imp-2',
	[ReachColor.IMP_3]: 'imp-3',
	[ReachColor.IMP_4]: 'imp-4',
	[ReachColor.IMP_5]: 'imp-5',
	[ReachColor.IMP_6]: 'imp-6',
	[ReachColor.IMP_7]: 'imp-7',
	[ReachColor.IMP_8]: 'imp-8',
	[ReachColor.IMP_9]: 'imp-9',
	[ReachColor.IMP_10]: 'imp-10',
};
