import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import {
	HeaderReachAchievementsLeftToClaimInterface,
	HeaderReachBeforeAfterValue,
	HeaderReachCreditsInterface,
	HeaderReachExpInterface,
	HeaderReachLevelInterface,
	HeaderReachRankInterface,
	ReachHeaderEnum,
} from '@reach/interfaces';
import { decodeHeaderPayload } from '@reach/utils';
import { Observable, tap } from 'rxjs';
import { SessionService } from '~app-client/core/services';
import { CLIENT_ENVIRONMENT } from '~app-client/core/tokens';
import { ClientUser } from '~app-client/core/types';

@Injectable({ providedIn: 'root' })
export class HandleHeadersInterceptor implements HttpInterceptor {
	private readonly sessionService = inject(SessionService);
	private readonly environment = inject(CLIENT_ENVIRONMENT);

	intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		if (!req.url.startsWith(this.environment.apiBaseUrl)) {
			return next.handle(req);
		}

		return next.handle(req);
	}

	private setUserParameter<KEY extends keyof ClientUser>(
		value: ClientUser[KEY] | null,
		userParam: KEY
	): void {
		try {
			if (value !== null && value !== undefined) {
				this.sessionService.addData({ [userParam]: value });
			}
		} catch (error) {
			console.error(error);
		}
	}

	private setBeforeAfterUserParameter<KEY extends keyof ClientUser>(
		value: HeaderReachBeforeAfterValue<ClientUser[KEY]> | null,
		userParam: KEY
	): void {
		this.setUserParameter(value?.after, userParam);
	}

	private parseHeader<T>(headers: HttpHeaders, header: ReachHeaderEnum): T | null {
		try {
			return decodeHeaderPayload(headers.get(header));
		} catch (error) {
			return null;
		}
	}
}
