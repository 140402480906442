import * as cheerio from 'cheerio';
import {  ReachHtmlNode } from './html-types';

export const replaceMediaHash = (html: string, hashMap: Record<string, string>) => {
	const traverse = (node: ReachHtmlNode) => {
		const currentAttachmentId = node?.attribs?.['data-attachment-id'] || '';
		const nextAttachmentId = hashMap[currentAttachmentId] || '';
		if (!!currentAttachmentId && !!nextAttachmentId) {
			node.attribs['data-attachment-id'] = nextAttachmentId;
		}

		((node.childNodes || []) as ReachHtmlNode[]).forEach(traverse);
	};

	const $ = cheerio.load(html, null, false);
	const parsedHTML = $.parseHTML(html) as ReachHtmlNode[];

	parsedHTML.forEach(traverse);

	return cheerio.load(parsedHTML).html();
};

