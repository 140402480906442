import {
	AnalyticsEventAchievement,
	AnalyticsEventAlbumNodeAddItems,
	AnalyticsEventAlbumNodeDeleteItems,
	AnalyticsEventApiRequest,
	AnalyticsEventCardViewFinish,
	AnalyticsEventCardViewStart,
	AnalyticsEventCodepushUpdateSuccess,
	AnalyticsEventDeleteAccountFeedback,
	AnalyticsEventEmailSubscriptionChange,
	AnalyticsEventFriendInviteSuccess,
	AnalyticsEventInfoModalClosed,
	AnalyticsEventInfoModalOpened,
	AnalyticsEventIntegrationDeleted,
	AnalyticsEventIntegrationExecuted,
	AnalyticsEventIntegrationPhoneContactsSuccess,
	AnalyticsEventNewReleasesClosed,
	AnalyticsEventNewReleasesNavigatedToBlog,
	AnalyticsEventNewReleasesOpened,
	AnalyticsEventNodeCreationAlreadyExists,
	AnalyticsEventNodeCreationOnTheFly,
	AnalyticsEventNodeCreationSuccess,
	AnalyticsEventNodeCreationWithSize,
	AnalyticsEventNodeRelationshipCreationSuccess,
	AnalyticsEventNodeRestored,
	AnalyticsEventNodeSentToTrash,
	AnalyticsEventNodeShareCancel,
	AnalyticsEventNodeShareStart,
	AnalyticsEventNodeShareSuccess,
	AnalyticsEventNodeSubtypeCreated,
	AnalyticsEventNodeSubtypeNameChanged,
	AnalyticsEventNodeSubtypePropertyChanged,
	AnalyticsEventNodeSubtypeSmartFeatureChanged,
	AnalyticsEventNodeUpdated,
	AnalyticsEventNodeWorkspaceChanged,
	AnalyticsEventOnboardingSuccess,
	AnalyticsEventOpenMobileMenuCanceled,
	AnalyticsEventOpenMobileMenuShown,
	AnalyticsEventOpenMobileMenuSuccess,
	AnalyticsEventPasswordChangeRequest,
	AnalyticsEventPropertyAdded,
	AnalyticsEventPropertyCreated,
	AnalyticsEventPropertyDeleted,
	AnalyticsEventPropertyOptionsUpdated,
	AnalyticsEventPropertyRemovedFromNode,
	AnalyticsEventPropertyRenamed,
	AnalyticsEventPropertyValueUpdated,
	AnalyticsEventRank,
	AnalyticsEventRecommendationsRejected,
	AnalyticsEventRecommendationsSuccess,
	AnalyticsEventRegisterCancel,
	AnalyticsEventRegisterStart,
	AnalyticsEventRegisterSuccess,
	AnalyticsEventReminderAdded,
	AnalyticsEventReminderDeleted,
	AnalyticsEventSearch,
	AnalyticsEventSearchCurrentWorkspaceMenuShown,
	AnalyticsEventSettingsChanged,
	AnalyticsEventShareReceivedFails,
	AnalyticsEventShareReceivedStart,
	AnalyticsEventShareReceivedSuccess,
	AnalyticsEventsNodeSubtypeIconChanged,
	AnalyticsEventSpotlightFinished,
	AnalyticsEventSwipeActionExecuted,
	AnalyticsEventTagTypePageInfoViewed,
	AnalyticsEventTutorialTagSelected,
	AnalyticsEventTutorialVideoClosed,
	AnalyticsEventTutorialVideoOpened,
	AnalyticsEventUserProfileEdited,
	AnalyticsEventWorkspaceMenuShown,
	AnalyticsEventWorkspaceOpened,
	AnalyticsEventWorkspacesLoaded,
	AnalyticsEventWorkspacesSelectionChanged,
} from './data';
import { AnalyticsEvent } from './keys';

export interface AnalyticsEventData {
	[AnalyticsEvent.REGISTER_START]: AnalyticsEventRegisterStart;
	[AnalyticsEvent.REGISTER_SUCCESS]: AnalyticsEventRegisterSuccess;
	[AnalyticsEvent.REGISTER_CANCEL]: AnalyticsEventRegisterCancel;

	[AnalyticsEvent.SWIPE_ACTION_EXECUTED]: AnalyticsEventSwipeActionExecuted;

	[AnalyticsEvent.NODE_CREATION_SUCCESS]: AnalyticsEventNodeCreationSuccess;
	[AnalyticsEvent.NODE_CREATION_WITH_SIZE]: AnalyticsEventNodeCreationWithSize;
	[AnalyticsEvent.NODE_CREATION_ON_THE_FLY]: AnalyticsEventNodeCreationOnTheFly;
	[AnalyticsEvent.NODE_CREATION_ALREADY_EXISTS]: AnalyticsEventNodeCreationAlreadyExists;

	[AnalyticsEvent.CARD_VIEW_START]: AnalyticsEventCardViewStart;
	[AnalyticsEvent.CARD_VIEW_FINISH]: AnalyticsEventCardViewFinish;

	[AnalyticsEvent.NODE_UPDATED]: AnalyticsEventNodeUpdated;
	[AnalyticsEvent.NODE_SENT_TO_TRASH]: AnalyticsEventNodeSentToTrash;
	[AnalyticsEvent.NODE_RESTORED]: AnalyticsEventNodeRestored;
	[AnalyticsEvent.NODE_WORKSPACE_CHANGED]: AnalyticsEventNodeWorkspaceChanged;

	[AnalyticsEvent.SEARCH]: AnalyticsEventSearch;
	[AnalyticsEvent.SEARCH_CURRENT_WORKSPACE_MENU_SHOWN]: AnalyticsEventSearchCurrentWorkspaceMenuShown;

	[AnalyticsEvent.WORKSPACES_LOADED]: AnalyticsEventWorkspacesLoaded;
	[AnalyticsEvent.WORKSPACE_MENU_SHOWN]: AnalyticsEventWorkspaceMenuShown;
	[AnalyticsEvent.WORKSPACE_OPENED]: AnalyticsEventWorkspaceOpened;
	[AnalyticsEvent.WORKSPACES_SELECTION_CHANGED]: AnalyticsEventWorkspacesSelectionChanged;

	[AnalyticsEvent.PROPERTY_ADDED]: AnalyticsEventPropertyAdded;
	[AnalyticsEvent.PROPERTY_CREATED]: AnalyticsEventPropertyCreated;
	[AnalyticsEvent.PROPERTY_RENAMED]: AnalyticsEventPropertyRenamed;
	[AnalyticsEvent.PROPERTY_OPTIONS_UPDATED]: AnalyticsEventPropertyOptionsUpdated;
	[AnalyticsEvent.PROPERTY_VALUE_UPDATED]: AnalyticsEventPropertyValueUpdated;
	[AnalyticsEvent.PROPERTY_REMOVED_FROM_NODE]: AnalyticsEventPropertyRemovedFromNode;
	[AnalyticsEvent.PROPERTY_DELETED]: AnalyticsEventPropertyDeleted;

	[AnalyticsEvent.REMINDER_ADDED]: AnalyticsEventReminderAdded;
	[AnalyticsEvent.REMINDER_DELETED]: AnalyticsEventReminderDeleted;

	[AnalyticsEvent.NODE_RELATIONSHIP_CREATION_SUCCESS]: AnalyticsEventNodeRelationshipCreationSuccess;

	[AnalyticsEvent.SETTINGS_CHANGED]: AnalyticsEventSettingsChanged;

	[AnalyticsEvent.USER_PROFILE_EDITED]: AnalyticsEventUserProfileEdited;

	[AnalyticsEvent.FRIEND_INVITE_SUCCESS]: AnalyticsEventFriendInviteSuccess;

	[AnalyticsEvent.NODE_SHARE_START]: AnalyticsEventNodeShareStart;
	[AnalyticsEvent.NODE_SHARE_SUCCESS]: AnalyticsEventNodeShareSuccess;
	[AnalyticsEvent.NODE_SHARE_CANCEL]: AnalyticsEventNodeShareCancel;

	[AnalyticsEvent.TUTORIAL_TAG_SELECTED]: AnalyticsEventTutorialTagSelected;
	[AnalyticsEvent.TUTORIAL_VIDEO_OPENED]: AnalyticsEventTutorialVideoOpened;
	[AnalyticsEvent.TUTORIAL_VIDEO_CLOSED]: AnalyticsEventTutorialVideoClosed;

	[AnalyticsEvent.SHARE_RECEIVED_START]: AnalyticsEventShareReceivedStart;
	[AnalyticsEvent.SHARE_RECEIVED_SUCCESS]: AnalyticsEventShareReceivedSuccess;
	[AnalyticsEvent.SHARE_RECEIVED_FAILS]: AnalyticsEventShareReceivedFails;

	[AnalyticsEvent.ONBOARDING_SUCCESS]: AnalyticsEventOnboardingSuccess;

	[AnalyticsEvent.PASSWORD_CHANGE_REQUEST]: AnalyticsEventPasswordChangeRequest;

	[AnalyticsEvent.RECOMMENDATIONS_SUCCESS]: AnalyticsEventRecommendationsSuccess;
	[AnalyticsEvent.RECOMMENDATIONS_REJECTED]: AnalyticsEventRecommendationsRejected;

	[AnalyticsEvent.EMAIL_SUBSCRIPTION_CHANGE]: AnalyticsEventEmailSubscriptionChange;

	[AnalyticsEvent.SPOTLIGHT_FINISHED]: AnalyticsEventSpotlightFinished;

	[AnalyticsEvent.INFO_MODAL_OPENED]: AnalyticsEventInfoModalOpened;
	[AnalyticsEvent.INFO_MODAL_CLOSED]: AnalyticsEventInfoModalClosed;

	[AnalyticsEvent.NODE_SUB_TYPE_PAGE_INFO_VIEWED]: AnalyticsEventTagTypePageInfoViewed;
	[AnalyticsEvent.NODE_SUB_TYPE_CREATED]: AnalyticsEventNodeSubtypeCreated;
	[AnalyticsEvent.NODE_SUB_TYPE_NAME_CHANGED]: AnalyticsEventNodeSubtypeNameChanged;
	[AnalyticsEvent.NODE_SUB_TYPE_ICON_CHANGED]: AnalyticsEventsNodeSubtypeIconChanged;
	[AnalyticsEvent.NODE_SUB_TYPE_PROPERTY_CHANGED]: AnalyticsEventNodeSubtypePropertyChanged;
	[AnalyticsEvent.NODE_SUB_TYPE_SMART_FEATURE_CHANGED]: AnalyticsEventNodeSubtypeSmartFeatureChanged;

	[AnalyticsEvent.INTEGRATION_EXECUTED]: AnalyticsEventIntegrationExecuted;
	[AnalyticsEvent.INTEGRATION_DELETED]: AnalyticsEventIntegrationDeleted;
	[AnalyticsEvent.INTEGRATION_PHONE_CONTACTS_SUCCESS]: AnalyticsEventIntegrationPhoneContactsSuccess;

	[AnalyticsEvent.NEW_RELEASES_OPENED]: AnalyticsEventNewReleasesOpened;
	[AnalyticsEvent.NEW_RELEASES_CLOSED]: AnalyticsEventNewReleasesClosed;
	[AnalyticsEvent.NEW_RELEASES_NAVIGATED_TO_BLOG]: AnalyticsEventNewReleasesNavigatedToBlog;

	[AnalyticsEvent.CODEPUSH_UPDATE_SUCCESS]: AnalyticsEventCodepushUpdateSuccess;

	[AnalyticsEvent.DELETE_ACCOUNT_FEEDBACK]: AnalyticsEventDeleteAccountFeedback;

	[AnalyticsEvent.OPEN_MOBILE_MENU_SHOWN]: AnalyticsEventOpenMobileMenuShown;
	[AnalyticsEvent.OPEN_MOBILE_MENU_SUCCESS]: AnalyticsEventOpenMobileMenuSuccess;
	[AnalyticsEvent.OPEN_MOBILE_MENU_CANCELED]: AnalyticsEventOpenMobileMenuCanceled;

	[AnalyticsEvent.ALBUM_NODE_DELETE_ITEMS]: AnalyticsEventAlbumNodeDeleteItems;
	[AnalyticsEvent.ALBUM_NODE_ADD_ITEMS]: AnalyticsEventAlbumNodeAddItems;

	[AnalyticsEvent.API_REQUEST]: AnalyticsEventApiRequest;
}
