import { inject, Injectable } from '@angular/core';
import { CLIENT_ENVIRONMENT } from '~app-client/core/tokens';
import { FacebookPixel } from './facebook-pixel.class';
import { GoogleAds } from './google-ads.class';

export enum AdTrackEvent {
	SUBMIT_APPLICATION = 'SUBMIT_APPLICATION',
	COMPLETE_REGISTRATION = 'COMPLETE_REGISTRATION',
}

interface FacebookPixelEventProperties {
	[AdTrackEvent.SUBMIT_APPLICATION]: {
		authProvider: 'REACH' | 'GOOGLE' | 'APPLE';
	};
}

@Injectable({ providedIn: 'root' })
export class AdTrackingService {
	private readonly environment = inject(CLIENT_ENVIRONMENT);

	private readonly facebookPixel = new FacebookPixel();
	private readonly googleAds = new GoogleAds(this.environment);
	public track<EVENT extends AdTrackEvent>(
		eventName: EVENT,
		properties: EVENT extends keyof FacebookPixelEventProperties
			? FacebookPixelEventProperties[EVENT]
			: Record<string, never>
	): void {
		this.facebookPixel.track(eventName, properties);
		this.googleAds.track(eventName);
	}
}
