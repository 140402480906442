import { Require } from '../typescript';
import { CustomPropertyType, CustomPropertyValue } from './type.interface';
export interface CustomPropertyCreateInterface {
	name: string;
	type: CustomPropertyType;
	options?: string[];
}

export interface CustomPropertyUpdateNameInterface {
	name: string;
}

export interface CustomPropertyUpdateOptionsInterface {
	options: string[];
}

export type CustomPropertyCreateRequest = Omit<
	Require<CustomPropertyValue, 'id'>,
	'visible' | 'createdAt' | 'updatedAt' | 'lastModifiedBy' | 'reminders'
>;

export type CustomPropertyUpdateVisibilityInterface = Pick<CustomPropertyValue, 'id' | 'visible'>;

export type CustomPropertyGetInterface = {
	unassigned: boolean;
	workspaceIds: string[];
};
