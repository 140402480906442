export const defaultOpenModalOptions: OpenModalOptions = {
	closeOnOutsideClick: true,
	defaultHostStyle: true,
	defaultContainerStyle: true,
	fullScreen: false,
	templateIsHost: false,
	hostZIndex: 20,
};

export interface OpenModalOptions {
	/**
	 * If `true`: when the user clicks outside of the modal it is closed.
	 * If `false`: when the user clicks outside of the modal nothing happens.
	 *
	 * Default: `true`.
	 */
	closeOnOutsideClick: boolean;

	/**
	 * Default: `true`.
	 */
	defaultHostStyle: boolean;

	/**
	 * Default: `20`.
	 */
	hostZIndex?: number;

	/**
	 * default: `true`.
	 */
	defaultContainerStyle: boolean;

	/**
	 * default: `false`.
	 */
	fullScreen: boolean;

	/**
	 * default: `false`.
	 */
	templateIsHost: boolean;
}

export function cleanOpenModalOptions(options: Partial<OpenModalOptions>): OpenModalOptions {
	const validKeys = Object.keys(defaultOpenModalOptions);
	const givenKeys = Object.keys(options);
	const cleanPartialOptions: Partial<OpenModalOptions> = validKeys
		.filter((k) => givenKeys.includes(k))
		.reduce((acc, cur) => ({ ...acc, [cur]: options[cur] }), {});

	return {
		...defaultOpenModalOptions,
		...cleanPartialOptions,
	};
}
