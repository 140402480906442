import { Injectable } from '@angular/core';
import { CommentWithAuthor, NoteSlateItemInterface } from '@reach/interfaces';
import { BaseApiService } from '~app-client/api/_internal';
import { ReachNodeId } from '~app-client/core/types';

@Injectable({ providedIn: 'root' })
export class CommentsApiService extends BaseApiService {
	protected commonPath = ['nodes'];

	public getComments(nodeId: ReachNodeId): Promise<CommentWithAuthor[]> {
		return this.http.get<CommentWithAuthor[]>(this.url(nodeId, 'comments'));
	}

	public createComment(
		nodeId: ReachNodeId,
		content: NoteSlateItemInterface[]
	): Promise<CommentWithAuthor[]> {
		const body = { comment: content || [] };
		return this.http.post<CommentWithAuthor[]>(this.url(nodeId, 'comments'), body);
	}

	public editComment(
		nodeId: ReachNodeId,
		commentId: string,
		content: NoteSlateItemInterface[]
	): Promise<CommentWithAuthor> {
		const body = { comment: content || [] };
		return this.http.patch<CommentWithAuthor>(this.url(nodeId, 'comments', commentId), body);
	}

	public removeComment(nodeId: ReachNodeId, commentId: string): Promise<void> {
		return this.http.delete<void>(this.url(nodeId, 'comments', commentId));
	}
}
