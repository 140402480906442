import { WorkspaceType } from '@reach/interfaces';

export interface AnalyticsEventWorkspacesLoaded {
	workspacesCount: number;
	workspacesTotalNodesCount: number;
}

export interface AnalyticsEventWorkspaceMenuShown {
	workspaceMenuTarget: 'search' | 'node';
}

export interface AnalyticsEventWorkspaceOpened {
	workspaceType: WorkspaceType;
}

export interface AnalyticsEventWorkspacesSelectionChanged {
	workspacesSelectionCount: number;
	workspacesSelectionTypes: string[];
}
