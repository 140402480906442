export type Edge = EdgeProperties & EdgeMetadata;

export interface EdgeProperties {
	name: string;
	directed: boolean;
	type: EdgeType;
	hidden: boolean;
	hiddenBy?: string;
}

export interface EdgeMetadata {
	id: number;
	source: string;
	target: string;
}

export enum EdgeType {
	DOMAIN = 'DOMAIN',
	REFERENCE = 'REFERENCE',
	ALBUM = 'ALBUM',
	DEFAULT = 'DEFAULT',
	RECOMMENDATION = 'RECOMMENDATION',
}
