export interface ApiAdminInterface {
	cronsDeactivated: boolean;
	allowRequests: boolean;
	estimatedTime: number;
	status: ApiAdminStatus;
	message: string | null;
}

export const ApiAdminInterfaceKeys = ['cronsDeactivated', 'allowRequests', 'estimatedTime', 'status', 'message'] as const;
export enum ApiAdminStatus {
	UPDATING = 'UPDATING',
	MAINTENANCE = 'MAINTENANCE',
	UNKNOWN = 'UNKNOWN',
	OK = 'OK'
}

export type UpdateApiAdmin = Partial<ApiAdminInterface>;
