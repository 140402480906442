import { inject, Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Language } from '@reach/interfaces';
import { ReachTranslateLang } from '~app-client/core/types';
import { i18nMap } from '~app-client/core/utils';

@Injectable({ providedIn: 'root' })
export class ReachTranslateService {
	private readonly translate = inject(TranslocoService);

	public setLanguage(language: Language): void {
		const mappedValue = i18nMap[language] || ReachTranslateLang.ENGLISH_GB;
		const isValid = Object.values(ReachTranslateLang).includes(mappedValue);
		const translocoLang = isValid ? mappedValue : ReachTranslateLang.ENGLISH_GB;
		console.debug('setLanguage', `${language} -> ${translocoLang}`);
		this.translate.setActiveLang(translocoLang);
	}
}
