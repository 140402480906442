import { Injectable } from '@angular/core';
import {
	CustomPropertyInterface,
	ExtractedContentEnum,
	ExtractedContentOptionEnum,
	NodeSubtypeAdditionalSettingEnum,
	NodeSubtypeId,
	NodeSubtypeSettingsInterface,
	SmartFeatureEnum,
	SmartFeatureOptionEnum,
	TagCreationFormatOption,
} from '@reach/interfaces';
import { BehaviorSubject, merge } from 'rxjs';
import { map } from 'rxjs/operators';
import { NodeSubtypesApiService } from '~app-client/api/services';

/** Mark some properties which only the former including as optional and set the value to never */
type Without<T, U> = { [P in Exclude<keyof T, keyof U>]?: never };

/** get the XOR type which could make 2 types exclude each other */
export type XOR<T, U> = T | U extends object ? (Without<T, U> & U) | (Without<U, T> & T) : T | U;

export type AdditionalSettingsOptions = XOR<
	TagCreationFormatOption,
	never // @TODO: Substitute 'never' for the rest of the options enums when developed
>;

export const advancedSettingsIconsMap: {
	[key in NodeSubtypeAdditionalSettingEnum]: { [key in AdditionalSettingsOptions]: string };
} = {
	[NodeSubtypeAdditionalSettingEnum.TAG_CREATION_FORMAT]: {
		[TagCreationFormatOption.NAME]: 'icon-text',
		[TagCreationFormatOption.MAP_PICK]: 'icon-world-map',
	},
};

@Injectable({ providedIn: 'root' })
export class NodeSettingsService {
	private nodeSettings$$: BehaviorSubject<NodeSubtypeSettingsInterface[]> = new BehaviorSubject(
		[]
	);
	public nodeSettings$ = this.nodeSettings$$.asObservable();
	private readonly loadingCount$$ = new BehaviorSubject<number>(0);
	private readonly loading$$ = new BehaviorSubject<boolean>(false);
	public readonly loading$ = merge(
		this.loadingCount$$.asObservable().pipe(map((count) => count > 0)),
		this.loading$$
	);

	constructor(private readonly api: NodeSubtypesApiService) {}

	public async load(force = false): Promise<void> {
		if (force || this.loadingCount$$.value === 0) {
			try {
				this.loadingCount$$.next(this.loadingCount$$.value + 1);
				const settings = await this.api.loadSettings();
				this.nodeSettings$$.next(settings);
			} catch (error) {
				throw error;
			} finally {
				this.loadingCount$$.next(Math.max(0, this.loadingCount$$.value - 1));
			}
		}
	}

	public getAdditionalSettingValue(
		subtypeId: NodeSubtypeId,
		settingKey: NodeSubtypeAdditionalSettingEnum
	): AdditionalSettingsOptions | null {
		return (
			this.nodeSettings$$.value
				?.find((subtype) => subtype.subtypeId === subtypeId)
				?.additionalSettings?.find((setting) => setting.additionalSetting === settingKey)
				?.selectedOption || null
		);
	}

	public setNodeSubtypeSettings(
		subtypeId: NodeSubtypeId,
		value: NodeSubtypeSettingsInterface
	): void {
		this.nodeSettings$$.next(
			this.nodeSettings$$
				.getValue()
				.map((setting) =>
					setting.subtypeId === subtypeId ? { ...setting, value } : setting
				)
		);
	}

	public async toggleSmartFeature(
		subtypeId: NodeSubtypeId,
		feature: SmartFeatureEnum,
		enabled: boolean
	): Promise<void> {
		const settings = this.nodeSettings$$.getValue();
		const setting = settings.find((setting) => setting.subtypeId === subtypeId);
		try {
			this.loading$$.next(true);
			setting.smartFeatures = setting.smartFeatures.map((feat) =>
				feat.feature === feature
					? {
							...feat,
							selectedOption: enabled
								? SmartFeatureOptionEnum.ENABLED
								: SmartFeatureOptionEnum.DISABLED,
					  }
					: feat
			);
			await this.api.updateSettings(setting.id, { smartFeatures: setting.smartFeatures });

			this.nodeSettings$$.next([...settings]);
		} catch (e) {
			console.error('Could not update node settings smart feature: ', e);
		} finally {
			this.loading$$.next(false);
		}
	}

	public async toggleReadingTime(subtypeId: NodeSubtypeId, enabled: boolean): Promise<void> {
		const selectedOption = enabled
			? ExtractedContentOptionEnum.PROPERTY_VISIBLE
			: ExtractedContentOptionEnum.PROPERTY_HIDDEN;
		this.setExtractedContentValue(subtypeId, ExtractedContentEnum.READING_TIME, selectedOption);
	}

	public async setExtractedContentValue(
		subtypeId: NodeSubtypeId,
		key: ExtractedContentEnum,
		selectedOption: ExtractedContentOptionEnum
	): Promise<void> {
		const settings = this.nodeSettings$$.getValue();
		const setting = settings.find((setting) => setting.subtypeId === subtypeId);
		const extractedContent = setting.extractedContent.map((content) =>
			content.content === key ? { ...content, selectedOption } : content
		);
		console.log('¡key: ', key);
		console.log('¡selectedOption: ', selectedOption);
		console.log('extracted content', extractedContent);
		try {
			this.loading$$.next(true);
			await this.api.updateSettings(setting.id, { extractedContent });
			setting.extractedContent = extractedContent;
			this.nodeSettings$$.next([...settings]);
		} catch (e) {
			console.error('Could not update node settings extracted content: ', e);
		} finally {
			this.loading$$.next(false);
		}
	}

	public async setAdditionalSettingValue(
		subtypeId: NodeSubtypeId,
		key: NodeSubtypeAdditionalSettingEnum,
		selectedOption: AdditionalSettingsOptions
	): Promise<void> {
		const settings = this.nodeSettings$$.getValue();
		const setting = settings.find((setting) => setting.subtypeId === subtypeId);
		const additionalSettings = setting.additionalSettings.map((setting) =>
			setting.additionalSetting === key ? { ...setting, selectedOption } : setting
		);
		try {
			this.loading$$.next(true);
			await this.api.updateSettings(setting.id, { additionalSettings });
			setting.additionalSettings = additionalSettings;
			this.nodeSettings$$.next([...settings]);
		} catch (e) {
			console.error('Could not update node settings additional settings: ', e);
		} finally {
			this.loading$$.next(false);
		}
	}

	public async addSuggestedProperty(
		subtypeId: NodeSubtypeId,
		propertyId: CustomPropertyInterface['id']
	): Promise<void> {
		const settings = this.nodeSettings$$.getValue();
		const setting = settings.find((setting) => setting.subtypeId === subtypeId);
		try {
			this.loading$$.next(true);
			setting.suggestedPropertyIds = [
				...new Set(setting.suggestedPropertyIds.concat(propertyId)),
			];
			await this.api.updateSettings(setting.id, {
				suggestedPropertyIds: setting.suggestedPropertyIds,
			});

			this.nodeSettings$$.next([...settings]);
		} catch (e) {
			console.error('Could not update node settings property: ', e);
		} finally {
			this.loading$$.next(false);
		}
	}

	public async removeSuggestedProperty(subtypeId: NodeSubtypeId, propId: string): Promise<void> {
		const settings = this.nodeSettings$$.getValue();
		const setting = settings.find((setting) => setting.subtypeId === subtypeId);
		try {
			this.loading$$.next(true);
			setting.suggestedPropertyIds = setting.suggestedPropertyIds.filter(
				(id) => id !== propId
			);
			await this.api.updateSettings(setting.id, setting);
			this.nodeSettings$$.next([...settings]);
		} catch (e) {
			console.error('Could not update node settings property: ', e);
		} finally {
			this.loading$$.next(false);
		}
	}
}
