import { ReachIntegration } from '../integrations';
import { StandardQuotaTypeEnum } from './quota-type.enum';

export interface QuotaStatusItem {
	total: number;
	available: number;
	used: number;
	reserved: number;
	breakdown: Record<string, unknown>;
	integration?: ReachIntegration;
}

export type QuotaStatusResponse = {
	standardQuotas: StandardQuotaStatusResponse;
	integrationQuotas: IntegrationQuotaStatusResponse;
};

export type StandardQuotaStatusResponse = {
	[t in StandardQuotaTypeEnum]: QuotaStatusItem;
};

export type IntegrationQuotaStatusResponse = {
	[t in ReachIntegration]: QuotaStatusItem;
};
