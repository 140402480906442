import { IntegrationBaseInterface } from '../integration-base.interface';

export interface PocketIntegrationAccountInterface extends IntegrationBaseInterface {
	username: string;
	accessToken: string;
}

export type PocketIntegrationAccountWithoutSensitive = Omit<
	PocketIntegrationAccountInterface,
	'accessToken'
>;
