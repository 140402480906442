import { Injectable } from '@angular/core';
import { GraphDataFromSearch } from '@reach/interfaces';
import { BaseApiService } from '~app-client/api/_internal';
import { ReachNodeId } from '~app-client/core/types';
import { isValidNodeId } from '~app-client/core/utils';
import { unassignedWorkspaceId } from '~app-client/workspaces/utils';

@Injectable({ providedIn: 'root' })
export class GraphApiService extends BaseApiService {
	protected commonPath = ['graph'];

	public getGraph(ids: ReachNodeId[]): Promise<GraphDataFromSearch> {
		const workspaceIds = ids.filter((id) => isValidNodeId(id));
		const showUnassigned = ids.includes(unassignedWorkspaceId);
		const body = { workspaceIds, unassigned: showUnassigned };
		return this.http.post<GraphDataFromSearch>(this.url('get-graph'), body);
	}
}
