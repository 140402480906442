import { Mediastore } from '@agorapulse/capacitor-mediastore';
import { DOCUMENT } from '@angular/common';
import { inject, Injectable, RendererFactory2 } from '@angular/core';
import { Media } from '@capacitor-community/media';
import { Capacitor } from '@capacitor/core';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { LocalNotifications } from '@capacitor/local-notifications';
import { Share, ShareOptions } from '@capacitor/share';
import { FileInterface, NodeType } from '@reach/interfaces';
import { UserFilesApiService } from '~app-client/api/services';
import { AnalyticsEvent, AnalyticsService } from './analytics';
import { ClientService } from './client';

@Injectable({ providedIn: 'root' })
export class ReachBlobDownloaderService {
	private readonly renderer = inject(RendererFactory2).createRenderer(null, null);
	private readonly document = inject(DOCUMENT);
	private readonly analytics = inject(AnalyticsService);
	private readonly userFilesApi = inject(UserFilesApiService);
	private readonly clientService = inject(ClientService);

	public getBlobFromUrl(url: string): Promise<Blob> {
		return new Promise<Blob>((resolve, reject) => {
			const request = new XMLHttpRequest();
			request.open('GET', url, true);
			request.responseType = 'blob';
			request.onload = () => resolve(request.response);
			request.onerror = reject;
			request.send();
		});
	}

	public async share(shareOptions: ShareOptions, nodeType: NodeType): Promise<void> {
		const shareRet = await Share.share(shareOptions);

		this.analytics.addEvent(AnalyticsEvent.NODE_SHARE_SUCCESS, {
			nodeType,
			shareTargetAppId: shareRet.activityType,
		});
	}

	public async shareFile(
		file: Pick<FileInterface & { extension?: string }, 'fileUrl' | 'name' | 'id' | 'extension'>
	): Promise<void> {
		const fileUrl = file?.fileUrl;

		const { blob, extension } = await this.userFilesApi.downloadFile(fileUrl);

		const data = await this.blobToBase64(blob);
		const baseDirectoryPath = `reach/node_info`;
		const directoryPath = encodeURI(`${baseDirectoryPath}/${file?.id}`);
		const name: string = this.userFilesApi.getFilename(file.name, extension);
		const ext: string | undefined = file?.extension;
		const path = encodeURI(
			`${directoryPath}/${
				name.toLowerCase().endsWith(ext?.toLowerCase() || '') ? name : `${name}${ext}`
			}`
		);

		try {
			await Filesystem.rmdir({
				path: encodeURI(baseDirectoryPath),
				recursive: true,
				directory: Directory.Cache,
			});
		} catch (e) {
			console.error(e);
		}

		const savedFile = await Filesystem.writeFile({
			recursive: true,
			data,
			path,
			directory: Directory.Cache,
		});

		const fileUri = savedFile.uri;

		const shareOptions = {
			title: file?.name,
			url: fileUri,
			dialogTitle: 'Share file',
		};

		await this.share(shareOptions, NodeType.FILE);
	}

	public async downloadFile(
		node: Pick<FileInterface & { extension?: string }, 'name' | 'fileUrl' | 'extension'>
	): Promise<void> {
		const { blob, extension } = await this.userFilesApi.downloadFile(node?.fileUrl);
		this.analytics.addEvent(AnalyticsEvent.NODE_DOWNLOADED, {});
		await this.downloadBlob(blob, this.userFilesApi.getFilename(node.name, extension));
	}

	public downloadBlob(blob: Blob, filename: string): Promise<void> {
		if (Capacitor.isNativePlatform()) {
			return this.downloadNativeBlob(blob, filename);
		} else {
			return this.downloadWebBlob(blob, filename);
		}
	}

	private async downloadWebBlob(blob: Blob, filename: string): Promise<void> {
		const anchor = this.renderer.createElement('a') as HTMLAnchorElement;
		this.renderer.appendChild(this.document.body, anchor);
		this.renderer.setStyle(anchor, 'display', 'none');

		const url = window.URL.createObjectURL(blob);
		this.renderer.setAttribute(anchor, 'href', url);
		this.renderer.setAttribute(anchor, 'download', filename);
		anchor.click();
		window.URL.revokeObjectURL(url);

		this.renderer.removeChild(this.document.body, anchor);
		anchor.remove();
	}

	private async downloadNativeBlob(blob: Blob, filename: string): Promise<void> {
		const data = await this.blobToBase64(blob);
		const id = Number(`${Math.random()}`.substring(2));
		const threadIdentifier = 'Downloading native blob';

		LocalNotifications.schedule({
			notifications: [
				{
					id,
					title: 'Downloading',
					body: `Downloading ${filename}`,
					ongoing: false,
					threadIdentifier,
					group: threadIdentifier,
				},
			],
		});

		await Filesystem.writeFile({
			directory: Directory.Data,
			path: filename,
			data,
		});

		const fileUrl = await Filesystem.getUri({
			directory: Directory.Data,
			path: filename,
		});

		const filePath = fileUrl.uri;

		const mimetype = blob.type;

		try {
			// https://github.com/capacitor-community/media/issues/6#issuecomment-1184803048
			if (Capacitor.getPlatform() === 'ios') {
				if (mimetype.includes('image/')) {
					if (mimetype === 'image/gif') {
						await this.storeGif(filePath);
					} else {
						await this.storePhoto(filePath);
					}
				} else if ('video/') {
					await this.storeVideo(filePath);
				}
			} else {
				await Mediastore.saveToDownloads({ path: filePath, filename });
			}
			try {
				await LocalNotifications.requestPermissions();

				LocalNotifications.schedule({
					notifications: [
						{
							id,
							title: 'Downloaded',
							body: `${filename} downloaded`,
							ongoing: false,
							threadIdentifier,
							group: threadIdentifier,
							attachments: [
								{
									id: filename,
									url: fileUrl.uri,
								},
							],
						},
					],
				});
			} catch (error) {}
		} catch (error) {
			console.error(error);
		}
	}

	public blobToBase64(blob: Blob): Promise<string> {
		return new Promise<string>((resolve, reject) => {
			const reader = new FileReader();
			reader.onloadend = () => {
				const dataUrl = reader.result as string;
				resolve(dataUrl.split(',')[1]);
			};
			reader.onerror = (error) => reject(error);
			reader.readAsDataURL(blob);
		});
	}

	public async storePhoto(filePath: string): Promise<void> {
		const reachAlbum = await this.getAlbum();
		await Media.savePhoto({ path: filePath, albumIdentifier: reachAlbum });
	}

	public async storeVideo(filePath: string): Promise<void> {
		const reachAlbum = await this.getAlbum();
		await Media.saveVideo({ path: filePath, albumIdentifier: reachAlbum });
	}

	public async storeGif(filePath: string): Promise<void> {
		const reachAlbum = await this.getAlbum();
		await Media.saveGif({ path: filePath, albumIdentifier: reachAlbum });
	}

	private async getAlbum(throwIfNotFound = false): Promise<string | undefined> {
		const albumsResponse = await Media.getAlbums();
		const album = albumsResponse.albums.find((_album) => {
			return _album.name === 'myReach';
		});

		if (!album) {
			if (throwIfNotFound) {
				throw 'Album not found recursively';
			}

			await Media.createAlbum({ name: 'myReach' });
			return this.getAlbum(true);
		}

		return album.identifier;
	}
}
