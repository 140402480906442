import { Directive, inject, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UserFeatureFlags } from '@reach/interfaces';
import { BehaviorSubject, distinctUntilChanged, map, switchMap } from 'rxjs';
import { SessionService } from '~app-client/core/services';
import { AttributeString, attributeStringEnumParser } from '~app-ui-new/utils';

@UntilDestroy()
@Directive({ selector: '[ngIfFeatureFlag]', standalone: true })
export class NgIfFeatureFlagDirective {
	private readonly templateRef = inject(TemplateRef<void>);
	private readonly viewContainer = inject(ViewContainerRef);
	private readonly sessionService = inject(SessionService);

	private readonly featureFlag$$ = new BehaviorSubject<UserFeatureFlags | null>(null);
	private readonly featureFlag$ = this.featureFlag$$.asObservable();

	@Input()
	public set ngIfFeatureFlag(featureFlag: AttributeString<UserFeatureFlags> | null) {
		const value = attributeStringEnumParser(featureFlag, UserFeatureFlags, null);
		if (this.featureFlag$$.value === value) {
			return;
		}

		this.featureFlag$$.next(value);
	}

	public get ngIfFeatureFlag(): UserFeatureFlags | null {
		return this.featureFlag$$.value;
	}

	private rendered = false;

	ngOnInit(): void {
		this.sessionService.user$
			.pipe(
				map((user) => {
					return user?.featureFlags ?? [];
				}),
				switchMap((userFf) => {
					return this.featureFlag$.pipe(
						map((requiredFf) => {
							return userFf.includes(requiredFf);
						})
					);
				}),
				distinctUntilChanged(),
				untilDestroyed(this)
			)
			.subscribe((hasFeatureFlag) => {
				if (!hasFeatureFlag) {
					this.viewContainer.clear();
					this.rendered = false;
				} else if (!this.rendered) {
					this.viewContainer.createEmbeddedView(this.templateRef);
					this.rendered = true;
				}
			});
	}
}
