import { ErrorCodes } from '@reach/interfaces';
import { StatusCodes } from 'http-status-codes';
import { AppError } from './app-error';

export class NotImplementedError extends AppError {
	constructor(reason = 'Not implemented.') {
		super({
			status: StatusCodes.NOT_IMPLEMENTED,
			code: ErrorCodes.MISC_NOT_IMPLEMENTED,
			title: 'This operation is not implemented.',
			detail: reason,
		});
	}
}
