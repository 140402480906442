import { inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

export function getCurrentQueryParam(key: string) {
	return inject(ActivatedRoute).snapshot.queryParamMap.get(key);
}

export function getQueryParam$(key: string): Observable<string | null> {
	const activatedRoute = inject(ActivatedRoute);
	return activatedRoute.queryParamMap.pipe(
		startWith(activatedRoute.snapshot.queryParamMap),
		map((params) => {
			return params?.get(key);
		})
	);
}
