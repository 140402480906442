import { ClientMetadataSource } from '../client-metadata';

export interface TutorialSearchParams {
	ids?: string[];
	playlistId?: string;
	userId?: string;
	filter?: string;
	enabled?: boolean;
	clientSource?: ClientMetadataSource;
	sortProperty?: 'ALPHABETICAL' | 'DATE';
	sortDirection?: 'asc' | 'desc';
}
