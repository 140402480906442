import { StatusCodes } from 'http-status-codes';
import { ErrorCodes } from '@reach/interfaces';

/** @see {@link https://jsonapi.org/format/#error-objects} */
export interface AppErrorParams {
	/** A unique identifier for this particular occurrence of the problem */
	id: string;
	/** The HTTP status code applicable to this problem, expressed as a string value */
	status: StatusCodes;
	/** An application-specific error code, expressed as a string value */
	code: ErrorCodes;
	/** A short, human-readable summary of the problem that SHOULD NOT change from occurrence to occurrence of the problem */
	title: string;
	/** A human-readable explanation specific to this occurrence of the problem */
	detail: string;
	/** A meta object containing non-standard meta-information about the error */
	meta: Record<string, unknown>;
}
