import { IntegrationBaseInterface } from './integration-base.interface';
import { ResourceListInterface } from '../utils';
import { ReachIntegration } from './integration-type.enum';

interface IntegrationListMetaInterface {
	cooldowns: Record<ReachIntegration, Date | null>;
}

export type IntegrationListInterface = ResourceListInterface<
	IntegrationBaseInterface,
	IntegrationListMetaInterface
>;
