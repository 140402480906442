/**
 * https://gist.github.com/JonCatmull/ecdf9441aaa37336d9ae2c7f9cb7289a
 */
import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { FileSizeConfig, fileSizeTransform } from '~app-client/core/utils';

/*
 * Convert bytes into largest possible unit.
 * Takes an precision argument that can be a number or a map for each unit.
 * Usage:
 *   bytes | fileSize:precision
 * @example
 * // returns 1 KB
 * {{ 1500 | fileSize }}
 * @example
 * // returns 2.1 GB
 * {{ 2100000000 | fileSize }}
 * @example
 * // returns 1.46 KB
 * {{ 1500 | fileSize:{ precision: 2 } }}
 */
@Pipe({ name: 'fileSize' })
export class FileSizePipe implements PipeTransform {
	transform(bytes = 0, config?: FileSizeConfig): string {
		return fileSizeTransform(bytes, config);
	}
}

@NgModule({
	declarations: [FileSizePipe],
	providers: [FileSizePipe],
	exports: [FileSizePipe],
})
export class FileSizeModule {}
