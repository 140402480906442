export enum NodeType {
	TAG = 'TAG',
	FILE = 'FILE',
	WEBSITE = 'WEBSITE',
	WORKSPACE = 'WORKSPACE',
	NOTE = 'NOTE',
	ALBUM = 'ALBUM',
}

export const nodeTypeIcon: { [type in NodeType]: string } = {
	TAG: 'icon-tag',
	FILE: 'icon-document',
	WEBSITE: 'icon-website',
	WORKSPACE: 'icon-workspace',
	NOTE: 'icon-note',
	ALBUM: 'icon-album',
};

export const nodeTypeColor: { [type in NodeType]: string } = {
	TAG: 'var(--color-node-tag)',
	FILE: 'var(--color-node-file)',
	WEBSITE: 'var(--color-node-website)',
	WORKSPACE: 'var(--color-node-workspace)',
	NOTE: 'var(--color-node-note)',
	ALBUM: 'var(--color-node-album)',
};

export const nodeTypeColorRGB: { [type in NodeType]: string } = {
	TAG: 'var(--color-node-tag-rgb)',
	FILE: 'var(--color-node-file-rgb)',
	WEBSITE: 'var(--color-node-website-rgb)',
	WORKSPACE: 'var(--color-node-workspace-rgb)',
	NOTE: 'var(--color-node-note-rgb)',
	ALBUM: 'var(--color-node-album-rgb)',
};
