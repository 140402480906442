import { Injectable } from '@angular/core';
import { UserTutorial } from '@reach/interfaces';
import { BaseApiService } from '~app-client/api/_internal';

@Injectable({ providedIn: 'root' })
export class TutorialsApiService extends BaseApiService {
	protected commonPath = ['tutorials'];

	public getTutorials(query = ''): Promise<UserTutorial[]> {
		const params = { filter: query };
		return this.http.get<UserTutorial[]>(this.url(), params);
	}

	public getVideo(videoId: string): Promise<UserTutorial> {
		return this.http.get<UserTutorial>(this.url('videos', videoId));
	}

	public updateStats(videoId: string, secondsSeen: number): Promise<UserTutorial> {
		const body = { tutorialId: videoId, secondsSeen };
		return this.http.patch<UserTutorial>(this.url('userStats'), body);
	}
}
