export enum SpotlightKey {
	// CORE
	CORE__LOCATION = 'CORE__LOCATION',

	// HOME

	HOME__GET_STARTED = 'HOME__GET_STARTED',
	HOME__NAVBAR_CREATION = 'HOME__NAVBAR_CREATION',
	HOME__NAVBAR_SEARCH = 'HOME__NAVBAR_SEARCH',
	HOME__NAVBAR_CHAT = 'HOME__NAVBAR_CHAT',
	HOME__NAVBAR_INTEGRATIONS_DESKTOP = 'HOME__NAVBAR_INTEGRATIONS_DESKTOP',
	HOME__NAVBAR_PROFILE = 'HOME__NAVBAR_PROFILE',

	// CHAT

	CHAT__INITIAL_INFO = 'CHAT__INITIAL_INFO',
	CHAT__SHOW_ALL_CHATS_MOBILE = 'CHAT__SHOW_ALL_CHATS_MOBILE',
	CHAT__MESSAGE_SOURCE = 'CHAT__MESSAGE_SOURCE',
	CHAT__MESSAGE_OPEN_OPTIONS_MOBILE = 'CHAT__MESSAGE_OPEN_OPTIONS_MOBILE',
	CHAT__OPTIONS_VIEW_SOURCES = 'CHAT__OPTIONS_VIEW_SOURCES',
	CHAT__OPTIONS_CONVERT_TO_NOTE_DESKTOP = 'CHAT__OPTIONS_CONVERT_TO_NOTE_DESKTOP',

	// SEARCH

	SEARCH__CHANGE_VIEW_MOBILE = 'SEARCH__CHANGE_VIEW_MOBILE',
	SEARCH__CHANGE_VIEW_DESKTOP = 'SEARCH__CHANGE_VIEW_DESKTOP',
	SEARCH__CHANGE_WORKSPACE_MOBILE = 'SEARCH__CHANGE_WORKSPACE_MOBILE',
	SEARCH__SELECT_MULTIPLE_WORKSPACES_MOBILE = 'SEARCH__SELECT_MULTIPLE_WORKSPACES_MOBILE',
	SEARCH__SELECT_MULTIPLE_WORKSPACES_DESKTOP = 'SEARCH__SELECT_MULTIPLE_WORKSPACES_DESKTOP',
	SEARCH__SELECT_MULTIPLE_NODES_MOBILE = 'SEARCH__SELECT_MULTIPLE_NODES_MOBILE',
	SEARCH__SELECT_MULTIPLE_NODES_DESKTOP = 'SEARCH__SELECT_MULTIPLE_NODES_DESKTOP',
	SEARCH__SWIPE_CARDS = 'SEARCH__SWIPE_CARDS',
	SEARCH__OPEN_NODE = 'SEARCH__OPEN_NODE',
	SEARCH__OPEN_LATERAL_CHAT = 'SEARCH__OPEN_LATERAL_CHAT',

	// ADVANCED SEARCH

	SEARCH__ADVANCED__PROPERTIES__ADD = 'SEARCH__ADVANCED__PROPERTIES__ADD',
	SEARCH__ADVANCED__PROPERTIES__CHANGE_OPERATOR = 'SEARCH__ADVANCED__PROPERTIES__CHANGE_OPERATOR',

	SEARCH__ADVANCED__NODES__ADD = 'SEARCH__ADVANCED__NODES__ADD',
	SEARCH__ADVANCED__NODES__CHANGE_OPERATOR = 'SEARCH__ADVANCED__NODES__CHANGE_OPERATOR',

	SEARCH__ADVANCED__EXCLUDE__ADD = 'SEARCH__ADVANCED__EXCLUDE__ADD',

	// VISUALISER

	SEARCH__VIEWS__VISUALISER__SHOW_LABELS = 'SEARCH__VIEWS__VISUALISER__SHOW_LABELS',

	// BOARD

	SEARCH__VIEWS__BOARD__ZOOM = 'SEARCH__VIEWS__BOARD__ZOOM',

	// MAP

	SEARCH__VIEWS__MAP__SEARCH_PLACES = 'SEARCH__VIEWS__MAP__SEARCH_PLACES',
	SEARCH__VIEWS__MAP__FILTER_LOCATIONS = 'SEARCH__VIEWS__MAP__FILTER_LOCATIONS',
	SEARCH__VIEWS__MAP__LOCATION_PROPERTIES = 'SEARCH__VIEWS__MAP__LOCATION_PROPERTIES',
	SEARCH__VIEWS__MAP__OPTIONS_DETAILS = 'SEARCH__VIEWS__MAP__OPTIONS_DETAILS',
	SEARCH__VIEWS__MAP__CARD_RELATIONSHIPS = 'SEARCH__VIEWS__MAP__CARD_RELATIONSHIPS',
	SEARCH__VIEWS__MAP__CARD_PROPERTIES = 'SEARCH__VIEWS__MAP__CARD_PROPERTIES',

	// NODE INFO

	NODE_INFO__INITIAL = 'NODE_INFO__INITIAL',
	NODE_INFO__RELATIONSHIPS__ADD = 'NODE_INFO__RELATIONSHIPS__ADD',
	NODE_INFO__RELATIONSHIPS__RECOMMENDATIONS = 'NODE_INFO__RELATIONSHIPS__RECOMMENDATIONS',
	NODE_INFO__RELATIONSHIPS__EXTRACTED = 'NODE_INFO__RELATIONSHIPS__EXTRACTED',
	NODE_INFO__RELATIONSHIPS__CARDS__SWIPE = 'NODE_INFO__RELATIONSHIPS__CARDS__SWIPE',
	NODE_INFO__RELATIONSHIPS__CARDS__OTHER_WORKSPACES = 'NODE_INFO__RELATIONSHIPS__CARDS__OTHER_WORKSPACES',

	NODE_INFO__PROPERTIES__ADD = 'NODE_INFO__PROPERTIES__ADD',
	NODE_INFO__WORKSPACES = 'NODE_INFO__WORKSPACES',

	NODE_INFO__VISIT_WEBSITE = 'NODE_INFO__VISIT_WEBSITE',

	NODE_INFO__OPEN_CHAT = 'NODE_INFO__OPEN_CHAT',

	// NODE_CREATION

	NODE_CREATION__INTEGRATIONS = 'NODE_CREATION__INTEGRATIONS',
	NODE_CREATION__NODE_TYPE__NOTE = 'NODE_CREATION__NODE_TYPE__NOTE',
	NODE_CREATION__NODE_TYPE__TAG = 'NODE_CREATION__NODE_TYPE__TAG',
	NODE_CREATION__NODE_TYPE__ALBUM = 'NODE_CREATION__NODE_TYPE__ALBUM',
	NODE_CREATION__NODE_TYPE__FILE = 'NODE_CREATION__NODE_TYPE__FILE',
	NODE_CREATION__NODE_TYPE__WEBSITE = 'NODE_CREATION__NODE_TYPE__WEBSITE',
	NODE_CREATION__NODE_TYPE__WORKSPACE = 'NODE_CREATION__NODE_TYPE__WORKSPACE',

	// PROFILE

	PROFILE_ENIGMA = 'PROFILE_ENIGMA',
	PROFILE__TUTORIALS = 'PROFILE__TUTORIALS',
	PROFILE__RANKS = 'PROFILE__RANKS',
	PROFILE__RANKS_MODAL = 'PROFILE__RANKS_MODAL',
	PROFILE__ACHIEVEMENTS = 'PROFILE__ACHIEVEMENTS',
	PROFILE__ACHIEVEMENTS__ROTATE_CARD = 'PROFILE__ACHIEVEMENTS__ROTATE_CARD',
	PROFILE__INTEGRATIONS__ADD = 'PROFILE__INTEGRATIONS__ADD',

	// INTEGRATIONS
	INTEGRATIONS__FIRST_TIME = 'INTEGRATIONS__FIRST_TIME',

	// MISC

	CHAT_AI_LEARN_ALL = 'CHAT_AI_LEARN_ALL',
}

export enum SpotlightDateFilterEnum {
	NONE = 'NONE',
	GREATER = 'GREATER',
	LESS = 'LESS',
}
