/**
 * https://gist.github.com/JonCatmull/ecdf9441aaa37336d9ae2c7f9cb7289a
 */

type unit = 'B' | 'KB' | 'MB' | 'GB' | 'TB' | 'PB';
type unitPrecisionMap = {
	[u in unit]: number;
};

const defaultPrecisionMap: unitPrecisionMap = {
	B: 0,
	KB: 0,
	MB: 1,
	GB: 1,
	TB: 2,
	PB: 2,
};

const defaultMultiplier = 1000; // it can be either 1000 or 1024
const defaultSpacing = false;

const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'];

export interface FileSizeConfig {
	multiplier?: number;
	precision?: number | unitPrecisionMap;
	spacing?: boolean;
}

export function fileSizeTransform(bytes: number, config?: FileSizeConfig): string {
	const spacing: boolean = config?.spacing ?? defaultSpacing;
	const multiplier: number = config?.multiplier ?? defaultMultiplier;
	const precision: number | unitPrecisionMap = config?.precision ?? defaultPrecisionMap;

	if (isNaN(parseFloat(String(bytes))) || !isFinite(bytes)) return '?';

	let unitIndex = 0;

	while (bytes >= multiplier) {
		bytes /= multiplier;
		unitIndex++;
	}

	const unit = units[unitIndex];

	const spacingChar = spacing ? ` ` : ``;

	if (typeof precision === 'number') {
		return `${bytes.toLocaleString(undefined, {
			minimumFractionDigits: +precision,
			maximumFractionDigits: +precision,
		})}${spacingChar}${unit}`;
	}
	return `${bytes.toLocaleString(undefined, {
		minimumFractionDigits: precision[unit],
		maximumFractionDigits: precision[unit],
	})}${spacingChar}${unit}`;
}
