import { Injectable } from '@angular/core';
import {
	CustomPropertyValue,
	EdgeCreateBody,
	LocationLatLonWithPrecision,
	NodeCreationSource,
	NodeInterface,
	WebsiteInterface,
	WebsiteUpdateBody,
} from '@reach/interfaces';
import { BaseApiService } from '~app-client/api/_internal';
import { ReachNodeId } from '~app-client/core/types';

@Injectable({ providedIn: 'root' })
export class WebsitesApiService extends BaseApiService {
	protected commonPath = ['websites'];

	public checkExistence(
		url: string
	): Promise<{ website: WebsiteInterface; workspaceIds: ReachNodeId[] }> {
		const query = { url };

		return this.http.get<{ website: WebsiteInterface; workspaceIds: ReachNodeId[] }>(
			this.url('by-url'),
			query
		);
	}

	public create(
		url: string,
		workspaceIds: ReachNodeId[],
		source: NodeCreationSource,
		location?: LocationLatLonWithPrecision,
		edgesToCreate: Omit<EdgeCreateBody, 'source'>[] = []
	): Promise<WebsiteInterface> {
		const body = {
			url,
			workspaceIds,
			location: location,
			source,
			edgesToCreate,
		};

		return this.http.post<WebsiteInterface>(this.url(), body);
	}

	public analyze(url: string, workspaceId: number): Promise<WebsiteInterface> {
		const query = {
			url,
			workspaceId: `${workspaceId}`,
		};

		return this.http.post<WebsiteInterface>(this.url('analyze'), query);
	}

	public updateCustomProperties(
		websiteId: ReachNodeId,
		customProperties: CustomPropertyValue[],
		workspaceId: ReachNodeId
	): Promise<CustomPropertyValue[]> {
		const body = {
			customProperties,
			workspaceId,
		};
		return this.http
			.patch<NodeInterface>(this.url(websiteId), body)
			.then((node) => node?.customProperties || []);
	}

	public edit(websiteId: ReachNodeId, data: WebsiteUpdateBody): Promise<WebsiteInterface> {
		const body = data;
		return this.http.patch<WebsiteInterface>(this.url(websiteId), body);
	}

	public reprocessWebsite(websiteId: ReachNodeId): Promise<void> {
		return this.http.post<void>(this.url('re-extract'), { nodeIds: [websiteId] });
	}
}
