/* eslint-disable @typescript-eslint/no-namespace */
import { ErrorCodes } from '@reach/interfaces';
import { StatusCodes } from 'http-status-codes';
import { AppError } from './app-error';

export namespace MulterErrors {
	export class FileWasTooLarge extends AppError {
		constructor() {
			super({
				status: StatusCodes.REQUEST_TOO_LONG,
				code: ErrorCodes.MULTER_FILE_TOO_LARGE,
				title: 'The file was too large.',
			});
		}
	}

	export class FileUploadFailed extends AppError {
		constructor({ reason }: { reason: string }) {
			super({
				status: StatusCodes.BAD_REQUEST,
				code: ErrorCodes.MULTER_FILE_UPLOAD_FAILED,
				title: 'The file upload failed.',
				meta: {
					reason,
				},
			});
		}
	}

	export class WrongMimetype extends AppError {
		constructor({ mimetype }: { mimetype: string }) {
			super({
				status: StatusCodes.BAD_REQUEST,
				code: ErrorCodes.MULTER_WRONG_MIMETYPE,
				title: 'Wrong mimetype.',
				meta: {
					mimetype,
				},
			});
		}
	}

	export class TooManyItems extends AppError {
		constructor({ maxExpected }: { maxExpected: number }) {
			super({
				status: StatusCodes.BAD_REQUEST,
				code: ErrorCodes.MULTER_TOO_MANY_ITEMS,
				title: 'Too many files.',
				meta: {
					maxExpected,
				},
			});
		}
	}

	export class NoFileProvided extends AppError {
		constructor() {
			super({
				status: StatusCodes.BAD_REQUEST,
				code: ErrorCodes.MULTER_NO_FILE_PROVIDED,
				title: 'The file was not provided.',
			});
		}
	}
}
