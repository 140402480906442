/**
 * This type is usefull if we want to set a value as a string, so we
 * can use `prop="value"` instead of `[prop]="value"`.
 *
 * ---
 *
 * Example:
 *
 * Instead of
 * ```html
 * <button uiCdkButtonIconText [disabled]="true"></button>
 * ```
 *
 * We can use
 * ```html
 * <button uiCdkButtonIconText disabled="true"></button>
 * ```
 *
 * ---
 *
 * (I know that this is a silly thing, but whatever)
 */
export type AttributeString<T extends number | boolean | string> = `${T}` | T;

export function attributeStringBooleanParser(
	value: AttributeString<boolean>,
	defaultValue: boolean
): boolean {
	const cleanValue = `${value ?? ''}`.toLowerCase();
	return cleanValue === `${true}`.toLowerCase()
		? true
		: cleanValue === `${false}`.toLowerCase()
		? false
		: defaultValue;
}

export function attributeStringNumberParser(
	value: AttributeString<number>,
	defaultValue: number | null = null
): number | null {
	const num = Number(`${value ?? ''}`);
	return Number.isNaN(num) ? defaultValue : num;
}

export function attributeStringEnumParser<T extends string>(
	value: AttributeString<T>,
	allValues: Record<string, T>,
	defaultValue: T | null = null
): T | null {
	const keys = Object.values(allValues) as T[];
	return keys.includes(value as T) ? (value as T) : defaultValue;
}
