/**
 * Remove accents and special characters. Lowercase
 * @param name - Name to be normalized
 */
export const normalizeString = (name: string): string =>
	name
		.trim()
		.toLowerCase()
		.normalize('NFKD')
		.replace(/[\u0300-\u036f]/g, '');
