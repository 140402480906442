export enum QueryParamKey {
	OPEN_WORKSPACE_ID = 'openWorkspaceId',
	OPEN_NODE_ID = 'openNodeId',

	VISUALIZER_VIEW = 'visualizerView',

	SEARCH_VIEW = 'searchView',

	AUTH_COMPLETE_REGISTRATION_SLIDE = 'completeRegistrationSlide',
	AUTH_RESET_PASSWORD_VALUE = 'resetPasswordEmail',
	AUTH_RESET_PASSWORD_TOKEN = 'recoveryToken',
	AUTH_SEND_EMAIL_TOKEN = 'emailToken',

	CREATION_SHARE_NODE_TYPE = 'creationShareNodeType',

	EMAIL = 'email',

	AUTH_USER_CATEGORY = 'category',
	AUTH_REFERRAL_ID = 'referralId',
	AUTH_REFERRAL_NAME = 'name',

	ACTIVATION_TOKEN = 'activationToken',
}

export interface AllQueryParams {
	[QueryParamKey.OPEN_WORKSPACE_ID]: number;
	[QueryParamKey.OPEN_NODE_ID]: number;
	[QueryParamKey.VISUALIZER_VIEW]: string;
	[QueryParamKey.SEARCH_VIEW]: string;
	[QueryParamKey.AUTH_COMPLETE_REGISTRATION_SLIDE]: string;
	[QueryParamKey.AUTH_RESET_PASSWORD_VALUE]: string;
	[QueryParamKey.AUTH_RESET_PASSWORD_TOKEN]: string;
	[QueryParamKey.AUTH_SEND_EMAIL_TOKEN]: string;
	[QueryParamKey.CREATION_SHARE_NODE_TYPE]: string;
	[QueryParamKey.EMAIL]: string;
	[QueryParamKey.AUTH_USER_CATEGORY]: string;
	[QueryParamKey.AUTH_REFERRAL_ID]: string;
	[QueryParamKey.AUTH_REFERRAL_NAME]: string;
	[QueryParamKey.ACTIVATION_TOKEN]: string;
}

export type QueryParams = Partial<AllQueryParams>;
