class StorageI {
	public static readonly storage: Storage = localStorage;

	public static deleteOnStartupKeyBase = '__deleteOnStartup';

	public static persistentKeyBase = '__doNotWipe';

	public static asStartupKey(key: string): string {
		return `${StorageI.deleteOnStartupKeyBase}__${key}`;
	}

	public static asPersistentKey(key: string): string {
		return `${StorageI.persistentKeyBase}__${key}`;
	}

	public static removeStartupKeys(): void {
		StorageI.getKeys()
			.filter((k) => k.startsWith(StorageI.deleteOnStartupKeyBase))
			.forEach((k) => {
				StorageI.deleteItem(k);
			});
	}

	/**
	 * Stores an item given any key.
	 * @param key The key of the item to store.
	 * @param value The value of the item to store.
	 */
	public static setItem<T>(key: string, value: T): void {
		this.write(key, JSON.stringify(value));
	}

	/**
	 * @param key The key to the item to retrieve.
	 * @returns The value (or null if it is not stored) of the item with the given key.
	 */
	public static getItem<T = unknown>(key: string): T | null {
		const stored = this.read(key);
		try {
			return JSON.parse(stored as string);
		} catch (error) {
			return null;
		}
	}

	/**
	 * Removes the stored item with the given any key.
	 * @param key Key if the item to remove.
	 */
	public static deleteItem(key: string): void {
		this.delete(key);
	}

	public static getKeys(): string[] {
		const length = this.storage.length;
		return Array.from({ length }).map((_, idx) => this.storage.key(idx)) as string[];
	}

	/**
	 * Removes all the stored items.
	 */
	public static clear(): void {
		this.wipe();
	}

	private static read(key: string): string | null {
		return this.storage.getItem(key) ?? null;
	}

	private static write(key: string, value: string): void {
		this.storage.setItem(key, value);
	}

	private static delete(key: string): void {
		this.storage.removeItem(key);
	}

	private static wipe(): void {
		Array(this.storage.length)
			.map((_, idx) => this.storage.key(idx) as string)
			.filter((key) => {
				return !key.startsWith(StorageI.persistentKeyBase);
			})
			.forEach((key) => {
				this.storage.removeItem(key);
			});
	}
}

/**
 * Class that handles all the logic regarding Storage (localStorage).
 *
 * To use it, simply get the object's static methods like localStorage.
 */
export class ReachStorage extends StorageI {
	public static override readonly storage: Storage = localStorage;
}
