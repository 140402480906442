export enum CSSProperty {
	BACKGROUND_COLOR = 'background-color',
	TEXT_COLOR = 'color',
	BORDER_COLOR = 'border-color',
	BOLD = 'font-weight',
	STYLE = 'reach-style',
	ITALIC = 'font-style',
	SECTION_COLOR = 'section-color',
	WIDTH = 'width',
	THICKNESS = 'border-width',
	HEIGHT = 'height',
	EMAIL_COLOR = 'email-color',
	HORIZONTAL_PADDING = 'horizontal-padding',
	VERTICAL_PADDING = 'vertical-padding',
	FLEX_WIDTH = 'flex_width',
	REF = 'link',
	CAPTION = 'caption',
	FRAME = 'frame',
	TEXT_ALIGN = 'text-align',
	FONT_SIZE = 'font-size',
	BORDER = 'border',
	BORDER_TOP = 'border-top',
	BORDER_RIGHT = 'border-right',
	BORDER_BOTTOM = 'border-bottom',
	BORDER_LEFT = 'border-left',
	BORDER_RADIUS = 'border-radius',
	SOURCE = 'src',
	SOCIAL = 'social',
	LINE_HEIGHT = 'line-height',
	RESIZE = 'resize',
	ALIGN_ITEMS = 'align-items',
}

export interface CSSPropertyType {
	[CSSProperty.BACKGROUND_COLOR]: string;
	[CSSProperty.TEXT_COLOR]: string;
	[CSSProperty.BORDER_COLOR]: string;
	[CSSProperty.BOLD]: string;
	[CSSProperty.ITALIC]: string;
	[CSSProperty.SECTION_COLOR]: string;
	[CSSProperty.STYLE]: string;
	[CSSProperty.WIDTH]: number;
	[CSSProperty.THICKNESS]: number;
	[CSSProperty.HEIGHT]: number;
	[CSSProperty.EMAIL_COLOR]: string;
	[CSSProperty.HORIZONTAL_PADDING]: [number, number];
	[CSSProperty.VERTICAL_PADDING]: [number, number];
	[CSSProperty.FLEX_WIDTH]: number;
	[CSSProperty.CAPTION]: boolean;
	[CSSProperty.FRAME]: string;
	[CSSProperty.REF]: string;
	[CSSProperty.TEXT_ALIGN]: string;
	[CSSProperty.FONT_SIZE]: number;
	[CSSProperty.BORDER]: string;
	[CSSProperty.BORDER_TOP]: string;
	[CSSProperty.BORDER_RIGHT]: string;
	[CSSProperty.BORDER_BOTTOM]: string;
	[CSSProperty.BORDER_LEFT]: string;
	[CSSProperty.BORDER_RADIUS]: string;
	[CSSProperty.SOURCE]: string;
	[CSSProperty.SOCIAL]: string;
	[CSSProperty.LINE_HEIGHT]: string;
	[CSSProperty.ALIGN_ITEMS]: 'flex-end' | 'center' | 'flex-start';
}

export enum EmailElementType {
	EMAIL = 'EMAIL',
	TEXT = 'TEXT',
	BUTTON = 'BUTTON',
	MEDIA = 'MEDIA',
	STRUCTURE = 'STRUCTURE',
	SPACER = 'SPACER',
	DIVIDER = 'DIVIDER',
	FOOTER = 'FOOTER',
	PLACEHOLDER = 'PLACEHOLDER',
}

export interface EmailElementInterface<T extends EmailElementType = EmailElementType> {
	id: string;
	type: T;
	styles: Partial<CSSPropertyType>;
	data?: EmailDataMap<T>;
	children?: EmailElementInterface[];
}

export type EmailDataMap<T extends EmailElementType> = T extends EmailElementType.BUTTON
	? EmailButtonData
	: T extends EmailElementType.MEDIA
	? EmailMediaData
	: T extends EmailElementType.FOOTER
	? EmailFooterData
	: T extends EmailElementType.TEXT
	? EmailTextData
	: undefined;

export interface EmailButtonData {
	label?: string;
	link?: string;
}

export interface EmailMediaData {
	src?: string;
	ref?: string;
	caption?: string;
}

export interface EmailTextData {
	content?: string;
}

export interface EmailFooterData {
	social?: SocialLink[];
}

export interface SocialLink {
	alt: string;
	link: string;
	icon: string;
}
