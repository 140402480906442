export interface AnalyticsEventNewReleasesOpened {
	newReleasesTotalFeatureGroupsCount: number;
	newReleasesVisibleFeatureGroupsCount: number;
	newReleasesTotalSlidesCount: number;
	newReleasesVisibleSlidesCount: number;
}

export interface AnalyticsEventNewReleasesClosed {
	newReleasesCurrentSlideIndex: number;
	newReleasesCloseReason: string;
}

export interface AnalyticsEventNewReleasesNavigatedToBlog {
	newReleasesActiveSlideTitleOnNavigation: string;
}
