import { LocationLatLonWithPrecision } from '../location';
import { NodeSubtypeEnum } from '../node-subtypes';
import { Require, Nullable } from '../typescript';
import { TagInterface } from './tag.interface';

export interface DisambiguateTagsRequest {
	name: string;
	nodeSubtypeId?: string;
}

export type TagCreateBody = Require<
	Partial<Pick<TagInterface, 'name' | 'emoji' | 'customProperties'>>,
	'name'
> & {
	location?: LocationLatLonWithPrecision;
	typeId?: NodeSubtypeEnum | string;
};

export type TagUpdateBody = Partial<Pick<TagInterface, 'name'> & { emoji: Nullable<string> }>;
