import * as cheerio from 'cheerio';
import { CheerioOptions } from 'cheerio';
import { ElementType } from 'domelementtype';
import { HtmlTag, ReachHtmlNode } from './html-types';

type AccStr = { str: string };

function getStyles(node: ReachHtmlNode): Record<string, string> {
	return (node?.attribs?.style || '')
		.split(';')
		.map((style) => {
			const [rawKey, rawValue] = style.split(':');
			const key = (rawKey || '').trim();
			const value = (rawValue || '').trim();
			return { key, value };
		})
		.reduce((acc, style) => {
			if (style.key && style.value) {
				acc[style.key] = style.value;
			}
			return acc;
		}, {});
}

function fixCheckboxes(
	node: ReachHtmlNode,
	styles: Record<string, string>,
	_debug: string[]
): void {
	const todoValue = `${styles['--en-todo'] || ''}`.toLowerCase();
	if (todoValue === `true`) {
		_debug.push('(checkbox list)');
		node.attribs['data-checked'] = `${todoValue === 'true'}`;
	}

	const checkedValue = `${styles['--en-checked'] || ''}`.toLowerCase();
	if (checkedValue === `true` || checkedValue === `false`) {
		_debug.push(`(checkbox item)`);
		_debug.push(`[checked: ${checkedValue}]`);
		node.attribs['data-checked'] = `${checkedValue === 'true'}`;
	}
}

function fixPaddingInner(node: ReachHtmlNode, value: string, _debug: string[]): void {
	if (value.includes('px')) {
		const int = Number(value.replace('px', ''));

		if (int < 40) {
			return;
		}

		const newValueInt = (int / 40) * 30;
		const newValue = `${newValueInt}px`;
		_debug.push(`(padding-left: ${value} -> ${newValue})`);
		node[`padding-left`] = newValue;
	}
}

function fixPadding(node: ReachHtmlNode, styles: Record<string, string>, _debug: string[]): void {
	const paddingValue = `${styles.padding}`.trim();

	if (!paddingValue) {
		return;
	}

	const values = paddingValue.split(' ').filter((p) => `${p || ''}`.trim() !== '');

	if (values.length === 4) {
		// top | right | bottom | left
		return fixPaddingInner(node, values[3], _debug);
	} else if (values.length === 3) {
		// top | right and left | bottom
		return fixPaddingInner(node, values[1], _debug);
	} else if (values.length === 2) {
		// top and bottom | right and left
		return fixPaddingInner(node, values[1], _debug);
	} else if (values.length === 1) {
		// top, right, bottom and left
		return fixPaddingInner(node, values[0], _debug);
	}
}

function fixPaddingLeft(
	node: ReachHtmlNode,
	styles: Record<string, string>,
	_debug: string[]
): void {
	const paddingValue = `${styles['padding-left']}`.trim();

	if (!paddingValue) {
		return;
	}

	fixPaddingInner(node, paddingValue, _debug);
}

function fixCodeblocks(
	node: ReachHtmlNode,
	styles: Record<string, string>,
	_debug: string[]
): void {
	const isCodeblock = `${styles['--en-codeblock'] || ''}`.toLowerCase() === 'true';

	if (isCodeblock) {
		_debug.push('transformed to <img>');
		node.name = HtmlTag.PREFORMATTED;
	}
}

function fixMedia(node: ReachHtmlNode, _debug: string[]): void {
	const isMedia = (node.name as string) === 'en-media';

	if (isMedia) {
		_debug.push(`transformed to <img>`);
		node.name = HtmlTag.IMG;
		node.childNodes = [];
		const hash = node.attribs?.['hash'] || '';
		node.attribs = { 'data-attachment-id': hash };
	}
}

function parseChild(
	node: ReachHtmlNode,
	accStr: AccStr,
	_debugIdx: number,
	_debug: boolean
): ReachHtmlNode | null {
	const _debugLines = [`${'>'.repeat(_debugIdx + 1)} ${node.name || node.type}`];

	const _printDebug = () => {
		if (_debug) {
			console.log(_debugLines.join(' - '));
		}
	};

	if (node.type === ElementType.Text) {
		accStr.str += node.data;
		_debugLines.push(`(${node.data.substring(0, 8)})`.replace(/\n/gi, '').trim());
		_printDebug();
		return node;
	}

	const styles = getStyles(node);

	if (`${styles['--en-task-group'] || ''}`.toLowerCase() === `true`) {
		_debugLines.push('is task, remove');
		_printDebug();
		return null;
	}

	fixCheckboxes(node, styles, _debugLines);
	fixPadding(node, styles, _debugLines);
	fixPaddingLeft(node, styles, _debugLines);
	fixCodeblocks(node, styles, _debugLines);
	fixMedia(node, _debugLines);

	_printDebug();

	if (node.childNodes && node.childNodes.length > 0) {
		node.childNodes = parseChildren(
			node.childNodes as ReachHtmlNode[],
			accStr,
			_debugIdx + 1,
			_debug
		);
	}

	return node;
}

function parseChildren(
	nodes: ReachHtmlNode[],
	accStr: AccStr,
	_debugIdx: number,
	_debug: boolean
): ReachHtmlNode[] {
	return (nodes || [])
		.map((node) => parseChild(node, accStr, _debugIdx, _debug))
		.filter((node) => !!node) as ReachHtmlNode[];
}

export const evernoteToHtml = (note: string, _debug = false): { html: string; text: string } => {
	const $ = cheerio.load(
		note,
		{ xmlMode: true, selfClosingTags: false } as CheerioOptions,
		false
	);
	const cleanXhml = $('en-note').html() || '';

	const xhmlElements = $.parseHTML(cleanXhml) as ReachHtmlNode[];

	const accStr = { str: '' };
	const parsed = cheerio.load(parseChildren(xhmlElements, accStr, 0, _debug)).html();
	return { html: parsed, text: accStr.str.trim() };
};

export const replaceEvernoteMediaHash = (html: string, hashMap: Record<string, string>) => {
	const traverse = (node: ReachHtmlNode) => {
		const currentAttachmentId = node?.attribs?.['data-attachment-id'] || '';
		const nextAttachmentId = hashMap[currentAttachmentId] || '';
		if (!!currentAttachmentId && !!nextAttachmentId) {
			node.attribs['data-attachment-id'] = nextAttachmentId;
		}

		((node.childNodes || []) as ReachHtmlNode[]).forEach(traverse);
	};

	const $ = cheerio.load(html, null, false);
	const parsedHTML = $.parseHTML(html) as ReachHtmlNode[];

	parsedHTML.forEach(traverse);

	return cheerio.load(parsedHTML).html();
};

/**
 * this is just to easily test the script with:
 * `npx nodemon --exec npx ts-node ./src/parsers/xhml-to-html.ts`
 */
(() => {})();
