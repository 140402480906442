import { ClientMetadataOs, ClientMetadataSource } from '../client-metadata';
import { NodeCreationSource } from './node-creation-source.interface';

export enum SourceEnum {
	/**
	 * Nodes created by importing a workspace
	 */
	IMPORT = 'IMPORT',

	/**
	 * Nodes created from complete registration
	 */
	SAMPLE = 'SAMPLE',

	/**
	 * Nodes created automatically.
	 *
	 * For example, domains created when creating websites
	 */
	AUTO = 'AUTO',

	/**
	 * Nodes created from the plugin
	 */
	PLUGIN = 'PLUGIN',

	/**
	 * Nodes created by importing websites from pocket
	 */
	POCKET = 'POCKET',

	/**
	 * Nodes created by importing contacts from phone
	 */
	PHONE_CONTACTS = 'PHONE_CONTACTS',

	/**
	 * Nodes created by importing contacts from phone
	 */
	MAPS_PINS_LIST = 'MAPS_PINS_LIST',

	/**
	 * Nodes created by importing the user's bookmarks
	 */
	BOOKMARKS = 'BOOKMARKS',

	/**
	 * Nodes created from the attachment inside a note
	 */
	NOTE_ATTACHMENT = 'NOTE_ATTACHMENT',

	/**
	 * Nodes created by sharing nodes (currently not implemented)
	 */
	SHARE_NODES = 'SHARE_NODES',

	/**
	 * Requests that comes from a mobile phone source
	 */
	MOBILE = 'MOBILE',

	/**
	 * Requests that comes from a desktop source
	 */
	DESKTOP = 'DESKTOP',

	/**
	 * Requests that comes from an uknown source
	 */
	UNKNOWN = 'UNKNOWN',

	/**
	 * Nodes created from the node creation section in the platform.
	 */
	DEFAULT_CREATION = 'DEFAULT_CREATION',

	/**
	 * Nodes created quickly in the platform.
	 *
	 * For example, when trying to create an edge between two nodes.
	 * If you type the name of a tag which does not exist, you have the
	 * option to create the tag and link it id.
	 */
	FAST_CREATION = 'FAST_CREATION',

	/**
	 * Nodes created from a recommendation in the platform.
	 */
	RECCOMENDATION_CREATION = 'RECCOMENDATION_CREATION',

	/**
	 * Nodes created by sharing a file or website to the platform.
	 */
	SHARE_CREATION = 'SHARE_CREATION',

	/**
	 * Nodes created by Zapier actions
	 */
	ZAPIER_ACTION = 'ZAPIER_ACTION',

	/**
	 * Nodes created by importing a csv
	 */
	CSV = 'CSV',

	/**
	 * Nodes created by importing an evernote notebook
	 */
	IMPORT_EVERNOTE = 'IMPORT_EVERNOTE',

	/**
	 * Nodes created from the nodes to create extracted from node subtypes settings
	 */
	NODE_SUBTYPES_NODES_TO_CREATE = 'NODE_SUBTYPES_NODES_TO_CREATE',

	/**
	 * Nodes created from the info from an extracted candidate
	 */
	EXTRACTED_CANDIDATE = 'EXTRACTED_CANDIDATE',

	/**
	 * Nodes created from the info of a property value
	 */
	PROPERTY_VALUE = 'PROPERTY_VALUE',

	/**
	 * Nodes created by generating a note from the audio transcription of a file
	 */
	NOTE_FROM_TRANSCRIPTION = 'NOTE_FROM_TRANSCRIPTION',

	/**
	 * Nodes created by generating a note from the import of a docx word
	 */
	NOTE_FROM_WORD_DOCX = 'NOTE_FROM_WORD_DOCX',

	/**
	 * Nodes created by generating a note from the import of a markdown document
	 */
	NOTE_FROM_MARKDOWN = 'NOTE_FROM_MARKDOWN',

	/**
	 * Nodes created from importing a website and all its related websites
	 */

	WEBSITE_IMPORT = 'WEBSITE_IMPORT',
	/**
	 * Nodes created by integrating a google docs folder
	 */
	GOOGLE_DOCS = 'GOOGLE_DOCS',

	/**
	 * Nodes created by integrating google docs drive
	 */
	GOOGLE_DRIVE = 'GOOGLE_DRIVE',

	/**
	 * Nodes created by integrating a dropboxpaper folder
	 */
	DROPBOX_PAPER = 'DROPBOX_PAPER',

	/**
	 * Nodes created by integrating a quip file
	 */
	QUIP = 'QUIP',
	/**
	 * Node created from a chrome website
	 */
	CHROME = 'CHROME',

	/**
	 * Node created from a firefox website
	 */
	FIREFOX = 'FIREFOX',
}

export interface ClientCreationSourceInterface {
	clientOs: ClientMetadataOs;
	clientSource: ClientMetadataSource;
	creationSource: NodeCreationSource;
}

export const nodeCreationSourceToSource: { [key in NodeCreationSource]: SourceEnum } = {
	[NodeCreationSource.DEFAULT]: SourceEnum.DEFAULT_CREATION,
	[NodeCreationSource.FAST]: SourceEnum.FAST_CREATION,
	[NodeCreationSource.RECOMMENDATION]: SourceEnum.RECCOMENDATION_CREATION,
	[NodeCreationSource.SHARE]: SourceEnum.SHARE_CREATION,
	[NodeCreationSource.EXTRACTED_CANDIDATE]: SourceEnum.EXTRACTED_CANDIDATE,
	[NodeCreationSource.PROPERTY_VALUE]: SourceEnum.PROPERTY_VALUE,
};
