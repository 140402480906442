export enum ChatWidgetEventEnum {
	// Set if the user sends a message
	ASK = 'ASK',
	// Set if the ai replies to a message
	ANSWER_SUCCESSFUL = 'ANSWER_SUCCESSFUL',
	//Answer failed
	ANSWER_FAILED = 'ANSWER_FAILED',
	//Chat could not answer
	ANSWER_INCOMPLETE = 'ANSWER_INCOMPLETE',
	// Set if the user question is categorized
	CATEGORY = 'CATEGORY',
	// Set if the user reacts clicking the upvote button
	REACT_UPVOTE = 'REACT_UPVOTE',
	// Set if the user reacts clicking the downvote button
	REACT_DOWNVOTE = 'REACT_DOWNVOTE',
	// Set if the user reacts clicking the contact button
	REACT_CONTACT = 'REACT_CONTACT',
	// Set if the user opens a source
	REACT_SOURCE_OPEN = 'REACT_SOURCE_OPEN',
	// For when a user requests to speak to an agent
	ANSWER_REQUEST_SPEAK_TO_AGENT = 'ANSWER_REQUEST_SPEAK_TO_AGENT',
	// For when a user is interested in a category
	ANSWER_CATEGORY_QUESTION = 'ANSWER_CATEGORY_QUESTION',

	// Sources used by the chatbot in a conversation
	SOURCE_USED = 'SOURCE_USED',

	// System Message Triggered
	SYSTEM_MESSAGE = 'SYSTEM_MESSAGE',

	//Button Link Clicked
	BUTTON_LINK_CLICKED = 'BUTTON_LINK_CLICKED',

	//Email given
	EMAIL_GIVEN = 'EMAIL_GIVEN',
}
