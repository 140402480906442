import { JobStatusEnum, NodeGroupActionsCountMap } from '../jobs';
import { IntegrationExecutionInterface } from './integration-execution.interface';
import { IntegrationBaseInterface } from './integration-base.interface';

export interface IntegrationInterface extends IntegrationBaseInterface {
	nodesCount: NodeGroupActionsCountMap;
	status: JobStatusEnum;
	lastExecution: IntegrationExecutionInterface | null;
	isLocked: boolean;
	cooldownUntil: Date | null;
}
