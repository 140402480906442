import { XOR } from 'ts-essentials';
import { NodeType } from '../nodes';

export enum AITokenUsageTypeEnum {
	ASK = 'ASK',
}

export interface AITokenUsageInterface {
	id: string;

	type: AITokenUsageTypeEnum;

	tokenCount: number;

	usedAt: Date;

	userId: string;
}

export interface AskAITokenUsageInterface extends AITokenUsageInterface {
	messageId: string;
}

export interface SummarizeAndKeyphraseAITokenUsageInterface extends AITokenUsageInterface {
	nodeType: NodeType;

	nodeId: string;
}

export type AnyLearnAITokenUsageInterface = XOR<
	AskAITokenUsageInterface,
	SummarizeAndKeyphraseAITokenUsageInterface
>;

export interface AITokenUsageTotal {
	[AITokenUsageTypeEnum.ASK]: { total: number; count: number; avg: number };
	
}
