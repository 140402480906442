import { FileType } from '../files';
import { NodeSubtypeEnum } from '../node-subtypes';
import {
	CustomPropertyPermissions,
	CustomPropertyType,
	LocationPropertyValue,
	ProcessPropertyStatusEnum,
} from './type.interface';

export enum CustomPropertyKeys {
	// READONLY
	WEBPAGE_TYPE = 'WEBPAGE_TYPE',
	FILE_TYPE = 'FILE_TYPE',
	LAST_MODIFIED = 'LAST_MODIFIED',
	CREATION_DATE = 'CREATION_DATE',
	LAST_SEARCHED = 'LAST_SEARCHED',
	PAGE_NUMBER = 'PAGE_NUMBER',
	SIZE = 'SIZE',
	TOTAL_SIZE = 'TOTAL_SIZE',
	CONTAINED_FILES = 'CONTAINED_FILES',
	NODE_SUBTYPE = 'NODE_SUBTYPE',
	INTEGRATION_ID = 'INTEGRATION_ID',
	POCKET_RESOURCE_ID = 'POCKET_RESOURCE_ID',
	POCKET_ACCOUNT_USERNAME = 'POCKET_ACCOUNT_USERNAME',
	LAST_INTEGRATION_SYNC = 'LAST_INTEGRATION_SYNC',
	SOURCE = 'SOURCE',
	CREATED_BY = 'CREATED_BY',
	SHARED_NODE_BY = 'SHARED_NODE_BY',
	LEARNED = 'LEARNED',
	TAGS_PROCESSED = 'TAGS_PROCESSED',

	//SMART
	DESCRIPTION = 'DESCRIPTION',
	SUMMARY = 'SUMMARY',
	AUTHORS = 'AUTHORS',
	READING_TIME = 'READING_TIME',
	DURATION = 'DURATION',
	USER_LOCATION = 'USER_LOCATION',
	FILE_LOCATION = 'FILE_LOCATION',
	FILE_CREATION_DATE = 'FILE_CREATION_DATE',
	EMBEDDED_EMAILS = 'EMBEDDED_EMAILS',
	EMBEDDED_TELEPHONES = 'EMBEDDED_TELEPHONES',
	EMBEDDED_URLS = 'EMBEDDED_URLS',
	PUBLISHER = 'PUBLISHER',
	STARRED = 'STARRED',
	ARCHIVED = 'ARCHIVED',
	WIKIPEDIA_URL = 'WIKIPEDIA_URL',
	LANGUAGE = 'LANGUAGE',
	MODEL = 'MODEL',
	DOMAIN = 'DOMAIN',
	TAGS = 'TAGS',
	BIRTHDAY = 'BIRTHDAY',
	DUE_DATE = 'DUE_DATE',
	EVENT_DATE = 'EVENT_DATE',
	REMINDER = 'REMINDER',
	RATING = 'RATING',
	LOCATION = 'LOCATION',
	WEBSITE = 'WEBSITE',
	PHONE = 'PHONE',
	EVERNOTE_GUID = 'EVERNOTE_GUID',
	GOOGLE_DRIVE_GUID = 'GOOGLE_DRIVE_GUID',
	DETECTED_TAGS = 'DETECTED_TAGS',
	GOOGLE_DRIVE_VERSION = 'GOOGLE_DRIVE_VERSION',
	GOOGLE_DRIVE_URL = 'GOOGLE_DRIVE_URL',
	GOOGLE_DRIVE_NAME = 'GOOGLE_DRIVE_NAME',
}

export interface CustomPropertyKeyToTypeMap {
	[CustomPropertyKeys.WEBPAGE_TYPE]: string;
	[CustomPropertyKeys.FILE_TYPE]: string;
	[CustomPropertyKeys.DESCRIPTION]: string;
	[CustomPropertyKeys.SUMMARY]: string;
	[CustomPropertyKeys.PUBLISHER]: string;
	[CustomPropertyKeys.STARRED]: boolean;
	[CustomPropertyKeys.ARCHIVED]: boolean;

	[CustomPropertyKeys.SIZE]: number;
	[CustomPropertyKeys.TOTAL_SIZE]: number;
	[CustomPropertyKeys.CONTAINED_FILES]: number;
	[CustomPropertyKeys.LAST_MODIFIED]: number;
	[CustomPropertyKeys.CREATION_DATE]: number;
	[CustomPropertyKeys.LAST_SEARCHED]: number;
	[CustomPropertyKeys.PAGE_NUMBER]: number;
	[CustomPropertyKeys.READING_TIME]: number;
	[CustomPropertyKeys.DURATION]: number;
	[CustomPropertyKeys.INTEGRATION_ID]: string;
	[CustomPropertyKeys.POCKET_RESOURCE_ID]: string;
	[CustomPropertyKeys.POCKET_ACCOUNT_USERNAME]: string;
	[CustomPropertyKeys.LAST_INTEGRATION_SYNC]: number;
	[CustomPropertyKeys.EVERNOTE_GUID]: string;
	[CustomPropertyKeys.GOOGLE_DRIVE_GUID]: string[];
	[CustomPropertyKeys.GOOGLE_DRIVE_VERSION]: string;
	[CustomPropertyKeys.GOOGLE_DRIVE_URL]: string[];
	[CustomPropertyKeys.GOOGLE_DRIVE_NAME]: string[];

	[CustomPropertyKeys.USER_LOCATION]: LocationPropertyValue;
	[CustomPropertyKeys.FILE_LOCATION]: LocationPropertyValue;
	[CustomPropertyKeys.LOCATION]: LocationPropertyValue;
	[CustomPropertyKeys.FILE_CREATION_DATE]: number;
	[CustomPropertyKeys.CREATED_BY]: string[];
	[CustomPropertyKeys.SHARED_NODE_BY]: string[];
	[CustomPropertyKeys.LEARNED]: ProcessPropertyStatusEnum;
	[CustomPropertyKeys.TAGS_PROCESSED]: ProcessPropertyStatusEnum;

	[CustomPropertyKeys.AUTHORS]: string[];
	[CustomPropertyKeys.EMBEDDED_EMAILS]: string[];
	[CustomPropertyKeys.EMBEDDED_TELEPHONES]: string[];
	[CustomPropertyKeys.EMBEDDED_URLS]: string[];
	[CustomPropertyKeys.SOURCE]: string[];
	[CustomPropertyKeys.NODE_SUBTYPE]: string;
	[CustomPropertyKeys.DETECTED_TAGS]: string[];
	[CustomPropertyKeys.WIKIPEDIA_URL]: string;
	[CustomPropertyKeys.LANGUAGE]: string;
	[CustomPropertyKeys.MODEL]: string;
	[CustomPropertyKeys.DOMAIN]: string;
	[CustomPropertyKeys.TAGS]: string;

	[CustomPropertyKeys.BIRTHDAY]: number;
	[CustomPropertyKeys.DUE_DATE]: number;
	[CustomPropertyKeys.EVENT_DATE]: number;
	[CustomPropertyKeys.REMINDER]: number;
	[CustomPropertyKeys.RATING]: string;
	[CustomPropertyKeys.WEBSITE]: string[];
	[CustomPropertyKeys.PHONE]: string[];
}

export type CustomPropertyKeyType<KEY extends CustomPropertyKeys> = CustomPropertyKeyToTypeMap[KEY];

export const READONLY_KEYS = [
	CustomPropertyKeys.WEBPAGE_TYPE,
	CustomPropertyKeys.FILE_TYPE,
	CustomPropertyKeys.LAST_MODIFIED,
	CustomPropertyKeys.CREATION_DATE,
	CustomPropertyKeys.LAST_SEARCHED,
	CustomPropertyKeys.PAGE_NUMBER,
	CustomPropertyKeys.SIZE,
	CustomPropertyKeys.TOTAL_SIZE,
	CustomPropertyKeys.CONTAINED_FILES,
	CustomPropertyKeys.NODE_SUBTYPE,
	CustomPropertyKeys.INTEGRATION_ID,
	CustomPropertyKeys.POCKET_RESOURCE_ID,
	CustomPropertyKeys.POCKET_ACCOUNT_USERNAME,
	CustomPropertyKeys.LAST_INTEGRATION_SYNC,
	CustomPropertyKeys.SOURCE,
	CustomPropertyKeys.CREATED_BY,
	CustomPropertyKeys.SHARED_NODE_BY,
	CustomPropertyKeys.LEARNED,
	CustomPropertyKeys.TAGS_PROCESSED,
];

export const SMART_KEYS = [
	CustomPropertyKeys.DESCRIPTION,
	CustomPropertyKeys.SUMMARY,
	CustomPropertyKeys.AUTHORS,
	CustomPropertyKeys.READING_TIME,
	CustomPropertyKeys.DURATION,
	CustomPropertyKeys.USER_LOCATION,
	CustomPropertyKeys.FILE_LOCATION,
	CustomPropertyKeys.FILE_CREATION_DATE,
	CustomPropertyKeys.EMBEDDED_EMAILS,
	CustomPropertyKeys.EMBEDDED_TELEPHONES,
	CustomPropertyKeys.EMBEDDED_URLS,
	CustomPropertyKeys.PUBLISHER,
	CustomPropertyKeys.STARRED,
	CustomPropertyKeys.ARCHIVED,
	CustomPropertyKeys.DETECTED_TAGS,
	CustomPropertyKeys.WIKIPEDIA_URL,
	CustomPropertyKeys.LANGUAGE,
	CustomPropertyKeys.MODEL,
	CustomPropertyKeys.DOMAIN,
	CustomPropertyKeys.TAGS,
	CustomPropertyKeys.BIRTHDAY,
	CustomPropertyKeys.DUE_DATE,
	CustomPropertyKeys.EVENT_DATE,
	CustomPropertyKeys.RATING,
	CustomPropertyKeys.LOCATION,
	CustomPropertyKeys.WEBSITE,
	CustomPropertyKeys.PHONE,
];

export const ALL_KEYS = [...READONLY_KEYS, ...SMART_KEYS];

export const keyToPermissionsMapper = (key: string): CustomPropertyPermissions[] => {
	if (READONLY_KEYS.includes(key as CustomPropertyKeys)) {
		return [];
	} else if (SMART_KEYS.includes(key as CustomPropertyKeys)) {
		return [CustomPropertyPermissions.VALUE_ACCESS];
	} else {
		return [CustomPropertyPermissions.PROPERTY_ACCESS, CustomPropertyPermissions.VALUE_ACCESS];
	}
};

export const keyToName: { [key in CustomPropertyKeys]: string } = {
	// READONLY
	[CustomPropertyKeys.WEBPAGE_TYPE]: 'Webpage Type',
	[CustomPropertyKeys.FILE_TYPE]: 'File Type',
	[CustomPropertyKeys.LAST_MODIFIED]: 'Last Modified',
	[CustomPropertyKeys.CREATION_DATE]: 'Creation Date',
	[CustomPropertyKeys.LAST_SEARCHED]: 'Last Searched',
	[CustomPropertyKeys.PAGE_NUMBER]: 'Page Number',
	[CustomPropertyKeys.INTEGRATION_ID]: 'Integration ID',
	[CustomPropertyKeys.POCKET_RESOURCE_ID]: 'Pocket Resource ID',
	[CustomPropertyKeys.POCKET_ACCOUNT_USERNAME]: 'Pocket Account Username',
	[CustomPropertyKeys.LAST_INTEGRATION_SYNC]: 'Last Synchronization',
	[CustomPropertyKeys.SOURCE]: 'Source',
	[CustomPropertyKeys.NODE_SUBTYPE]: 'Node Subtype',
	[CustomPropertyKeys.SIZE]: 'Size',
	[CustomPropertyKeys.TOTAL_SIZE]: 'Total Size',
	[CustomPropertyKeys.CONTAINED_FILES]: 'Contained Files',
	[CustomPropertyKeys.EVERNOTE_GUID]: 'Evernote guid',
	[CustomPropertyKeys.GOOGLE_DRIVE_GUID]: 'Google Drive guid',
	[CustomPropertyKeys.GOOGLE_DRIVE_VERSION]: 'Google Drive version',
	[CustomPropertyKeys.GOOGLE_DRIVE_URL]: 'Google Drive URL',
	[CustomPropertyKeys.GOOGLE_DRIVE_NAME]: 'Google Drive Name',
	[CustomPropertyKeys.CREATED_BY]: 'Created By',
	[CustomPropertyKeys.SHARED_NODE_BY]: 'Shared By',
	[CustomPropertyKeys.LEARNED]: 'AI Status',
	[CustomPropertyKeys.TAGS_PROCESSED]: 'Tags Recommended',

	//SMART
	[CustomPropertyKeys.DESCRIPTION]: 'Description',
	[CustomPropertyKeys.SUMMARY]: 'Summary',
	[CustomPropertyKeys.AUTHORS]: 'Authors',
	[CustomPropertyKeys.READING_TIME]: 'Reading Time',
	[CustomPropertyKeys.DURATION]: 'Duration',
	[CustomPropertyKeys.USER_LOCATION]: 'Saved to myReach',
	[CustomPropertyKeys.FILE_LOCATION]: 'Extracted Location',
	[CustomPropertyKeys.FILE_CREATION_DATE]: 'File Creation Date',
	[CustomPropertyKeys.EMBEDDED_EMAILS]: 'Embedded Emails',
	[CustomPropertyKeys.EMBEDDED_TELEPHONES]: 'Embedded Telephones',
	[CustomPropertyKeys.EMBEDDED_URLS]: 'Embedded URLS',
	[CustomPropertyKeys.PUBLISHER]: 'Publisher',
	[CustomPropertyKeys.STARRED]: 'Starred',
	[CustomPropertyKeys.ARCHIVED]: 'Archived',
	[CustomPropertyKeys.DETECTED_TAGS]: 'Detected Tags',
	[CustomPropertyKeys.WIKIPEDIA_URL]: 'Wikipedia Page',
	[CustomPropertyKeys.LANGUAGE]: 'Language',
	[CustomPropertyKeys.MODEL]: 'Model',
	[CustomPropertyKeys.DOMAIN]: 'Domain',
	[CustomPropertyKeys.TAGS]: 'Tags',
	[CustomPropertyKeys.BIRTHDAY]: 'Birthday',
	[CustomPropertyKeys.DUE_DATE]: 'Due Date',
	[CustomPropertyKeys.EVENT_DATE]: 'Event Date',
	[CustomPropertyKeys.REMINDER]: 'Reminder',
	[CustomPropertyKeys.RATING]: 'Rating',
	[CustomPropertyKeys.LOCATION]: 'Address',
	[CustomPropertyKeys.WEBSITE]: 'Website',
	[CustomPropertyKeys.PHONE]: 'Phone',
};

export const keyToType: { [key in CustomPropertyKeys]: CustomPropertyType } = {
	// READONLY
	[CustomPropertyKeys.WEBPAGE_TYPE]: CustomPropertyType.PLAIN_TEXT,
	[CustomPropertyKeys.FILE_TYPE]: CustomPropertyType.SELECT_TAG,
	[CustomPropertyKeys.LAST_MODIFIED]: CustomPropertyType.DATE,
	[CustomPropertyKeys.CREATION_DATE]: CustomPropertyType.DATE,
	[CustomPropertyKeys.LAST_SEARCHED]: CustomPropertyType.DATE,
	[CustomPropertyKeys.PAGE_NUMBER]: CustomPropertyType.PLAIN_NUMBER,
	[CustomPropertyKeys.INTEGRATION_ID]: CustomPropertyType.PLAIN_TEXT,
	[CustomPropertyKeys.POCKET_RESOURCE_ID]: CustomPropertyType.PLAIN_TEXT,
	[CustomPropertyKeys.POCKET_ACCOUNT_USERNAME]: CustomPropertyType.PLAIN_TEXT,
	[CustomPropertyKeys.LAST_INTEGRATION_SYNC]: CustomPropertyType.DATE,
	[CustomPropertyKeys.SOURCE]: CustomPropertyType.MULTI_SELECT_SOURCE,
	[CustomPropertyKeys.NODE_SUBTYPE]: CustomPropertyType.SELECT_TAG,
	[CustomPropertyKeys.SIZE]: CustomPropertyType.PLAIN_NUMBER,
	[CustomPropertyKeys.TOTAL_SIZE]: CustomPropertyType.PLAIN_NUMBER,
	[CustomPropertyKeys.CONTAINED_FILES]: CustomPropertyType.PLAIN_NUMBER,
	[CustomPropertyKeys.EVERNOTE_GUID]: CustomPropertyType.PLAIN_TEXT,
	[CustomPropertyKeys.GOOGLE_DRIVE_GUID]: CustomPropertyType.NAME,
	[CustomPropertyKeys.GOOGLE_DRIVE_VERSION]: CustomPropertyType.PLAIN_NUMBER,
	[CustomPropertyKeys.GOOGLE_DRIVE_URL]: CustomPropertyType.URL,
	[CustomPropertyKeys.CREATED_BY]: CustomPropertyType.USER,
	[CustomPropertyKeys.SHARED_NODE_BY]: CustomPropertyType.USER,
	[CustomPropertyKeys.LEARNED]: CustomPropertyType.PROCESS,
	[CustomPropertyKeys.TAGS_PROCESSED]: CustomPropertyType.PROCESS,
	[CustomPropertyKeys.GOOGLE_DRIVE_NAME]: CustomPropertyType.NAME,

	//SMART
	[CustomPropertyKeys.DESCRIPTION]: CustomPropertyType.PLAIN_TEXT,
	[CustomPropertyKeys.SUMMARY]: CustomPropertyType.PLAIN_TEXT,
	[CustomPropertyKeys.AUTHORS]: CustomPropertyType.NAME,
	[CustomPropertyKeys.READING_TIME]: CustomPropertyType.TIME,
	[CustomPropertyKeys.DURATION]: CustomPropertyType.TIME,
	[CustomPropertyKeys.USER_LOCATION]: CustomPropertyType.LOCATION,
	[CustomPropertyKeys.FILE_LOCATION]: CustomPropertyType.LOCATION,
	[CustomPropertyKeys.FILE_CREATION_DATE]: CustomPropertyType.DATE,
	[CustomPropertyKeys.EMBEDDED_EMAILS]: CustomPropertyType.EMAIL,
	[CustomPropertyKeys.EMBEDDED_TELEPHONES]: CustomPropertyType.PHONE,
	[CustomPropertyKeys.EMBEDDED_URLS]: CustomPropertyType.URL,
	[CustomPropertyKeys.PUBLISHER]: CustomPropertyType.NAME,
	[CustomPropertyKeys.STARRED]: CustomPropertyType.BOOLEAN,
	[CustomPropertyKeys.ARCHIVED]: CustomPropertyType.BOOLEAN,
	[CustomPropertyKeys.DETECTED_TAGS]: CustomPropertyType.MULTI_SELECT_TAG,
	[CustomPropertyKeys.WIKIPEDIA_URL]: CustomPropertyType.URL,
	[CustomPropertyKeys.LANGUAGE]: CustomPropertyType.PLAIN_TEXT,
	[CustomPropertyKeys.MODEL]: CustomPropertyType.PLAIN_TEXT,
	[CustomPropertyKeys.DOMAIN]: CustomPropertyType.URL,
	[CustomPropertyKeys.TAGS]: CustomPropertyType.NAME,
	[CustomPropertyKeys.BIRTHDAY]: CustomPropertyType.DATE,
	[CustomPropertyKeys.DUE_DATE]: CustomPropertyType.DATE,
	[CustomPropertyKeys.EVENT_DATE]: CustomPropertyType.DATE,
	[CustomPropertyKeys.REMINDER]: CustomPropertyType.DATE,
	[CustomPropertyKeys.RATING]: CustomPropertyType.SELECT_STAR,
	[CustomPropertyKeys.LOCATION]: CustomPropertyType.LOCATION,
	[CustomPropertyKeys.WEBSITE]: CustomPropertyType.URL,
	[CustomPropertyKeys.PHONE]: CustomPropertyType.PHONE,
};

export const keyToCreationOptions: { [key in CustomPropertyKeys]: string[] | undefined } = {
	// READONLY
	[CustomPropertyKeys.WEBPAGE_TYPE]: undefined,
	[CustomPropertyKeys.FILE_TYPE]: Object.values(FileType),
	[CustomPropertyKeys.LAST_MODIFIED]: undefined,
	[CustomPropertyKeys.CREATION_DATE]: undefined,
	[CustomPropertyKeys.LAST_SEARCHED]: undefined,
	[CustomPropertyKeys.PAGE_NUMBER]: undefined,
	[CustomPropertyKeys.INTEGRATION_ID]: undefined,
	[CustomPropertyKeys.POCKET_RESOURCE_ID]: undefined,
	[CustomPropertyKeys.EVERNOTE_GUID]: undefined,
	[CustomPropertyKeys.GOOGLE_DRIVE_GUID]: undefined,
	[CustomPropertyKeys.GOOGLE_DRIVE_VERSION]: undefined,
	[CustomPropertyKeys.GOOGLE_DRIVE_URL]: undefined,
	[CustomPropertyKeys.GOOGLE_DRIVE_NAME]: undefined,
	[CustomPropertyKeys.POCKET_ACCOUNT_USERNAME]: undefined,
	[CustomPropertyKeys.LAST_INTEGRATION_SYNC]: undefined,
	[CustomPropertyKeys.SOURCE]: undefined,
	[CustomPropertyKeys.NODE_SUBTYPE]: Object.values(NodeSubtypeEnum),
	[CustomPropertyKeys.SIZE]: undefined,
	[CustomPropertyKeys.TOTAL_SIZE]: undefined,
	[CustomPropertyKeys.CONTAINED_FILES]: undefined,
	[CustomPropertyKeys.CREATED_BY]: undefined,
	[CustomPropertyKeys.SHARED_NODE_BY]: undefined,
	[CustomPropertyKeys.LEARNED]: undefined,
	[CustomPropertyKeys.TAGS_PROCESSED]: undefined,

	//SMART
	[CustomPropertyKeys.DESCRIPTION]: undefined,
	[CustomPropertyKeys.SUMMARY]: undefined,
	[CustomPropertyKeys.AUTHORS]: undefined,
	[CustomPropertyKeys.READING_TIME]: undefined,
	[CustomPropertyKeys.DURATION]: undefined,
	[CustomPropertyKeys.USER_LOCATION]: undefined,
	[CustomPropertyKeys.FILE_LOCATION]: undefined,
	[CustomPropertyKeys.LOCATION]: undefined,
	[CustomPropertyKeys.FILE_CREATION_DATE]: undefined,
	[CustomPropertyKeys.EMBEDDED_EMAILS]: undefined,
	[CustomPropertyKeys.EMBEDDED_TELEPHONES]: undefined,
	[CustomPropertyKeys.EMBEDDED_URLS]: undefined,
	[CustomPropertyKeys.PUBLISHER]: undefined,
	[CustomPropertyKeys.STARRED]: undefined,
	[CustomPropertyKeys.ARCHIVED]: undefined,
	[CustomPropertyKeys.DETECTED_TAGS]: [],
	[CustomPropertyKeys.WIKIPEDIA_URL]: undefined,
	[CustomPropertyKeys.LANGUAGE]: undefined,
	[CustomPropertyKeys.MODEL]: undefined,
	[CustomPropertyKeys.DOMAIN]: undefined,
	[CustomPropertyKeys.TAGS]: undefined,
	[CustomPropertyKeys.BIRTHDAY]: undefined,
	[CustomPropertyKeys.DUE_DATE]: undefined,
	[CustomPropertyKeys.EVENT_DATE]: undefined,
	[CustomPropertyKeys.REMINDER]: undefined,
	[CustomPropertyKeys.RATING]: undefined,
	[CustomPropertyKeys.WEBSITE]: undefined,
	[CustomPropertyKeys.PHONE]: undefined,
};

export const keysWithDefaultReminders = [
	CustomPropertyKeys.DUE_DATE,
	CustomPropertyKeys.EVENT_DATE,
	CustomPropertyKeys.BIRTHDAY,
	CustomPropertyKeys.REMINDER,
] as const;
export type KeysWithDefaultRemindersType = (typeof keysWithDefaultReminders)[number];
