import { NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({
	pure: true,
	name: 'jsonStringify',
})
export class JsonStringifyPipe implements PipeTransform {
	transform(item: unknown): string {
		return JSON.stringify(item, null, 4);
	}
}

@NgModule({
	declarations: [JsonStringifyPipe],
	providers: [JsonStringifyPipe],
	exports: [JsonStringifyPipe],
})
export class JsonStringifyModule {}
