import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
	selector: 'app-page-container',
	template: '',
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	host: {
		class: 'block w-100 h-100',
	},
})
export class EmptyPageComponent {}
