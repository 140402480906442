import { Nullable } from '../typescript';
import { LocationLatLonWithPrecision } from '../location';
import { NoteTemplate } from '../note-templates';
import { NoteInterface } from './note.interface';

export type NoteCreateBody = Pick<NoteInterface, 'name'> & {
	location?: LocationLatLonWithPrecision;
	noteTemplate: NoteTemplate | string;
};

export type NoteUpdateBody = Partial<
	Pick<NoteInterface, 'name' | 'noteContent'> & { emoji: Nullable<string> }
>;
