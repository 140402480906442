import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import '@codetrix-studio/capacitor-google-auth';
import 'hammerjs';
import { AppModule } from '~app-client/root/modules';
import { environment } from '~app-environment';

if (environment.production) {
	enableProdMode();
}

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.catch((err) => console.error(err));
