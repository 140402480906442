import { MultiXOR } from '../../typescript';
import { CustomPropertyCreateRequest } from '../../custom-property';
import { LocationLatLonWithPrecision } from '../../location';
import { NodeSubtypeId } from '../../node-subtypes';
import { NodeType } from '../../nodes';

// XOR<A, XOR<B, XOR<C, D>>> = exclusive OR of either A, B, C or D
export type AnyImportCsvNode = MultiXOR<
	[ImportCsvNodeTagInterface, ImportCsvNodeWebsiteInterface, ImportCsvNodeNoteInterface]
>;

export type AnyRelationshipToNodeInterface = MultiXOR<
	[
		RelationshipToNodeTagInterface,
		RelationshipToNodeWebsiteInterface,
		RelationshipToNodeNoteInterface
	]
>;

export type ImportCsvAllowedNodeTypes = NodeType.TAG | NodeType.WEBSITE | NodeType.NOTE;

export interface ImportCsvInterface {
	fileName: string;
	items: Array<AnyImportCsvNode>;
	location?: LocationLatLonWithPrecision;
}

export interface ImportCsvNodeInterface {
	name?: string;
	type: ImportCsvAllowedNodeTypes;
	comments: Array<string>;
	properties: Array<CustomPropertyCreateRequest>;
	relationships: Array<AnyRelationshipToNodeInterface>;
}

export interface RelationshipToNodeInterface {
	targetNodeType: ImportCsvAllowedNodeTypes;
	relationshipName?: string;
	nodeNames?: string[];
	nodeWebsiteUrls?: string[];
}

export interface RelationshipToNodeTagInterface extends RelationshipToNodeInterface {
	nodeNames: string[];
	targetNodeType: NodeType.TAG;
	tagType: NodeSubtypeId;
}

export interface RelationshipToNodeWebsiteInterface extends RelationshipToNodeInterface {
	targetNodeType: NodeType.WEBSITE;
	nodeWebsiteUrls: string[];
}

export interface RelationshipToNodeNoteInterface extends RelationshipToNodeInterface {
	targetNodeType: NodeType.NOTE;
	nodeNames: string[];
}

export interface ImportCsvNodeTagInterface extends ImportCsvNodeInterface {
	name: string;
	type: NodeType.TAG;
	tagType: NodeSubtypeId;
}

export interface ImportCsvNodeWebsiteInterface extends ImportCsvNodeInterface {
	type: NodeType.WEBSITE;
	websiteUrl: string;
}

export interface ImportCsvNodeNoteInterface extends ImportCsvNodeInterface {
	name: string;
	type: NodeType.NOTE;
	noteContent: string;
}
