import { ReminderTypeEnum } from '@reach/interfaces';

export interface AnalyticsEventReminderAdded {
	howMany: number;
	when: ReminderTypeEnum[];
}

export interface AnalyticsEventReminderDeleted {
	howMany: number;
}
