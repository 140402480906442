<div style="display: none">
	<settings-reactor></settings-reactor>
	<safe-area-reactor></safe-area-reactor>
	<user-reactor></user-reactor>
	<screen-reactor></screen-reactor>
	<app-url-open-reactor></app-url-open-reactor>
	<keyboard-reactor></keyboard-reactor>
	<fontsize-reactor></fontsize-reactor>
</div>

<!-- this is just so the core translations are loaded before the routes -->
<ng-container *transloco="let _">
	<router-outlet></router-outlet>
</ng-container>
