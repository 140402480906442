import { inject, Injectable } from '@angular/core';
import { Dict } from 'mixpanel-browser';
import { CLIENT_ENVIRONMENT } from '~app-client/core/tokens';
import { AsyncMixpanel } from './mixpanel.class';

@Injectable({ providedIn: 'root' })
export class MixpanelService {
	public readonly mixpanel = new AsyncMixpanel();
	private readonly environment = inject(CLIENT_ENVIRONMENT);
	private readonly useMixpanel = `${this.environment.mixpanelToken || ''}` !== '';

	private identity?: string;

	public init(): void {
		this.ifValid(() => {
			this.mixpanel.init(this.environment.mixpanelToken);
		}, true);
	}

	public reset(): void {
		this.identify = undefined;
		this.ifValid(() => {
			this.mixpanel.reset();
		}, true);
	}

	public addItems(items: Dict): void {
		this.ifValid(() => {
			try {
				this.mixpanel.people.set(items);
			} catch (error) {
				console.error(error);
			}
		});
	}

	public identify?(userId: string): void {
		this.identity = userId;
		this.ifValid(() => {
			try {
				this.mixpanel.identify(userId);
			} catch (error) {
				console.error(error);
			}
		});
	}

	public async track(actionId: string, actionProperties: Dict): Promise<void> {
		return this.ifValid(async () => {
			try {
				await this.mixpanel.track(actionId, actionProperties);
			} catch (error) {
				console.error(error);
			}
		});
	}

	public ifValid(cb: () => void | Promise<void>, skipIdentity = false): void {
		if (!this.useMixpanel || (!skipIdentity && !this.identity)) {
			return;
		}

		cb();
	}
}
