import { NodeType } from '../nodes';

export interface ScoredSuggestion {
	name: string;
	score: number;
}

export interface ScoredNode extends ScoredSuggestion {
	id: string;
	nodeType: NodeType;
}

export interface SuggestionResponse {
	keywords: ScoredSuggestion[];
	documents: ScoredNode[];
	tags: ScoredNode[];
}
