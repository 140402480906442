export enum CardType {
	SEARCH_SNIPPET = 'SEARCH_SNIPPET',
	SEARCH_LIST = 'SEARCH_LIST',
	SEARCH_CARD = 'SEARCH_CARD',
	SEARCH_EMPTY = 'SEARCH_EMPTY',
	GRAPH_NETWORK = 'GRAPH_NETWORK',
	GRAPH_LARGE_CARD = 'GRAPH_LARGE_CARD',
	GRAPH_SMALL_CARD = 'GRAPH_SMALL_CARD',
	GRAPH_CANVAS = 'GRAPH_CANVAS',
	CANVAS_CARD = 'CANVAS_CARD',
	BIG_IMAGE = 'BIG_IMAGE',
	TABLE_VIEW = 'TABLE_VIEW',
}

export type CardTypeGraph =
	| CardType.GRAPH_NETWORK
	| CardType.GRAPH_LARGE_CARD
	| CardType.GRAPH_SMALL_CARD
	| CardType.GRAPH_CANVAS
	| CardType.TABLE_VIEW;

export enum CardTypeIcon {
	SEARCH_SNIPPET = 'icon-search-view-snippet',
	SEARCH_LIST = 'icon-search-view-list',
	SEARCH_CARD = 'icon-search-view-card',
	SEARCH_EMPTY = 'icon-search-empty',
	GRAPH_NETWORK = 'icon-graph-view-network',
	GRAPH_LARGE_CARD = 'icon-graph-view-largecard',
	GRAPH_SMALL_CARD = 'icon-small-card-view-fullscreen',
	GRAPH_CANVAS = 'icon-board-view-fullscreen',
	TABLE_VIEW = 'icon-table-view-fullscreen',
}

export enum CardTypeIconStandalone {
	SEARCH_LIST = 'icon-search-list-standalone',
	BIG_IMAGE = 'icon-big-image-standalone',
	GRAPH_SMALL_CARD = 'icon-small-card-view-fullscreen',
	GRAPH_CANVAS = 'icon-board-view-fullscreen',
	TABLE_VIEW = 'icon-table-view-fullscreen',
}
