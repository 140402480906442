import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { catchError, firstValueFrom, Observable } from 'rxjs';
import { REACH_API_COMMON_HEADERS } from '~app-client/api/tokens';
import { throwAsApiError } from '../utils/error';

export type ApiParams =
	| HttpParams
	| {
			[param: string]: string | string[];
	  };

@Injectable({ providedIn: 'root' })
export class ApiHttpClientService {
	protected readonly http = inject(HttpClient);
	protected readonly commonHeaders = inject(REACH_API_COMMON_HEADERS);

	public getObs<T>(
		path: string,
		params?: ApiParams,
		headers?: { [header: string]: string },
		responseType?: string
	): Observable<T> {
		const options = {
			params,
			headers: {
				...this.commonHeaders,
				...headers,
			},
			withCredentials: true,
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			responseType: responseType as any,
		};
		return this.http.get<T>(path, options).pipe(catchError(throwAsApiError));
	}

	public get<T>(
		path: string,
		params?: ApiParams,
		headers?: { [header: string]: string },
		responseType?: string
	): Promise<T> {
		return firstValueFrom(this.getObs<T>(path, params, headers, responseType));
	}

	public getWithHeaders<T>(
		path: string,
		params?: ApiParams,
		headers?: { [header: string]: string }
	): Promise<HttpResponse<T>> {
		const responseType: 'json' = 'json' as const;
		const observe: 'response' = 'response' as const;

		const options = {
			params,
			headers: {
				...this.commonHeaders,
				...headers,
			},
			withCredentials: true,
			responseType,
			observe,
		};
		return firstValueFrom(this.http.get(path, options) as Observable<HttpResponse<T>>).catch(
			throwAsApiError
		);
	}

	public getBlobWithHeaders(
		path: string,
		params?: ApiParams,
		headers?: { [header: string]: string }
	): Promise<HttpResponse<Blob>> {
		const responseType: 'blob' = 'blob' as const;
		const observe: 'response' = 'response' as const;

		const options = {
			params,
			headers: {
				...this.commonHeaders,
				...headers,
			},
			withCredentials: true,
			responseType,
			observe,
		};
		return firstValueFrom(this.http.get(path, options) as Observable<HttpResponse<Blob>>).catch(
			throwAsApiError
		);
	}

	public post<T>(
		path: string,
		body: unknown = {},
		params?: ApiParams,
		headers?: { [header: string]: string },
		responseType?: string
	): Promise<T> {
		return firstValueFrom(
			this.http.post<T>(path, body, {
				params,
				headers: {
					...this.commonHeaders,
					...headers,
				},
				withCredentials: true,
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				responseType: responseType as any,
			})
		).catch(throwAsApiError);
	}

	public patch<T>(
		path: string,
		body: unknown = {},
		params?: ApiParams,
		headers?: { [header: string]: string }
	): Promise<T> {
		return firstValueFrom(
			this.http.patch<T>(path, body, {
				params,
				headers: {
					...this.commonHeaders,
					...headers,
				},
				withCredentials: true,
			})
		).catch(throwAsApiError);
	}

	public put<T>(
		path: string,
		body: unknown = {},
		params?: ApiParams,
		headers?: { [header: string]: string }
	): Promise<T> {
		return firstValueFrom(
			this.http.put<T>(path, body, {
				params,
				headers: {
					...this.commonHeaders,
					...headers,
				},
				withCredentials: true,
			})
		).catch(throwAsApiError);
	}

	public delete<T>(
		path: string,
		body: unknown = {},
		headers?: { [header: string]: string }
	): Promise<T> {
		return firstValueFrom(
			this.http.delete<T>(path, {
				body,
				headers: {
					...this.commonHeaders,
					...headers,
				},
				withCredentials: true,
			})
		).catch(throwAsApiError);
	}
}
