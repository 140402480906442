import { Injectable } from '@angular/core';
import { MarketingDevice } from '@reach/interfaces';
import { BaseApiService } from '~app-client/api/_internal';

@Injectable({ providedIn: 'root' })
export class DevicesApiService extends BaseApiService {
	protected commonPath = ['marketing'];

	public addDevice(device: MarketingDevice): Promise<void> {
		const body = device;
		return this.http.post(this.url('device'), body);
	}

	public removeDevice(deviceId: string): Promise<void> {
		return this.http.delete(this.url(deviceId));
	}

	public trackPush(body: {
		delivery_id: string;
		event: 'opened' | 'delivered' | 'converted';
		device_id: string;
		timestamp: number;
	}): Promise<void> {
		return this.http.post(this.url('track-push'), body);
	}
}
