import mixpanel, {
	ClearOptOutInOutOptions,
	Config,
	Dict,
	Group,
	HasOptedInOutOptions,
	InTrackingOptions,
	Mixpanel,
	People,
	Query,
	RequestOptions,
} from 'mixpanel-browser';

/**
 * {@link https://developer.mixpanel.com/docs/javascript-full-api-reference Reference}
 *
 * Asynchronous implementation of the mixpanel object.
 */
export class AsyncMixpanel implements Omit<Mixpanel, 'people' | 'track_pageview'> {
	// eslint-disable-next-line @typescript-eslint/no-use-before-define
	public people = new AsyncMixpanelPeople();

	/**
	 * {@link https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpaneladd_group Reference}
	 *
	 * Add a new group for this user.
	 *
	 * @example
	 *
	 * ```js
	 * mixpanel.add_group('company', 'mixpanel')
	 * ```
	 *
	 * @param groupKey Group key
	 * @param groupId A valid Mixpanel property type
	 */
	public add_group(groupKey: string, groupId: string): Promise<void> {
		return new Promise<void>((resolve) => {
			mixpanel.add_group(groupKey, groupId, () => resolve());
		});
	}

	/**
	 * {@link https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpanelalias Reference}
	 *
	 * The alias method creates an alias which Mixpanel will use to remap one id to another.
	 * Multiple aliases can point to the same identifier.
	 *
	 * The following is a valid use of alias:
	 *
	 * ```js
	 * mixpanel.alias('new_id', 'existing_id');
	 * // You can add multiple id aliases to the existing ID
	 * mixpanel.alias('newer_id', 'existing_id');
	 * ```
	 *
	 * Aliases can also be chained - the following is a valid example:
	 *
	 * ```js
	 * mixpanel.alias('new_id', 'existing_id');
	 * // chain newer_id - new_id - existing_id
	 * mixpanel.alias('newer_id', 'new_id');
	 * ```
	 *
	 * Aliases cannot point to multiple identifiers - the following example will not work:
	 *
	 * ```js
	 * mixpanel.alias('new_id', 'existing_id');
	 * // this is invalid as 'new_id' already points to 'existing_id'
	 * mixpanel.alias('new_id', 'newer_id');
	 * ```
	 *
	 * @remarks
	 *
	 * If your project does not hav
	 * {@link https://help.mixpanel.com/hc/en-us/articles/360039133851 ID Merge}
	 * enabled,
	 * the best practice is to call alias once when a unique ID is first
	 * created for a user (e.g., when a user first registers for an account).
	 * Do not use alias multiple times for a single user without ID Merge enabled.
	 *
	 * @param alias A unique identifier that you want to use for this user in the future.
	 * @param original The current identifier being used for this user.
	 */
	public alias(alias: string, original?: string): void {
		return mixpanel.alias(alias, original);
	}

	/**
	 * {@link https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpanelclear_opt_in_out_tracking Reference}
	 *
	 * Clear the user's opt in/out status of data tracking and cookies/localstorage
	 * for this Mixpanel instance.
	 *
	 * @param options A dictionary of config options to override
	 */
	public clear_opt_in_out_tracking(options?: Partial<ClearOptOutInOutOptions>): void {
		return mixpanel.clear_opt_in_out_tracking(options);
	}

	/**
	 * {@link https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpaneldisable Reference}
	 *
	 * Disable events on the Mixpanel object. If passed no arguments,
	 * this function disables tracking of any event. If passed an array of event names,
	 * those events will be disabled, but other events will continue to be tracked.
	 *
	 * @remarks
	 *
	 * this function does not stop other mixpanel functions from firing,
	 * such as `register()` or `people.set()`.
	 *
	 * @param events An array of event names to disable.
	 */
	public disable(events: string[]): void {
		return mixpanel.disable(events);
	}

	/**
	 * {@link https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpanelget_config Reference}
	 *
	 * Returns the current config object for the library.
	 *
	 * @param prop_name `optional` Property name to return.
	 */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public get_config(prop_name?: string): any {
		return mixpanel.get_config(prop_name);
	}

	/**
	 * {@link https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpanelget_distinct_id Reference}
	 *
	 * Returns the current distinct id of the user. This is either the id automatically generated by the library or the id that has been passed by a call to identify().
	 *
	 * @remarks
	 *
	 * `get_distinct_id()` can only be called after the Mixpanel library has finished loading. init() has a loaded function available to handle this automatically. For example:
	 *
	 * ```js
	 * // set distinct_id after the mixpanel library has loaded
	 * mixpanel.init('YOUR PROJECT TOKEN', {
	 *     loaded: function(mixpanel) {
	 *         distinct_id = mixpanel.get_distinct_id();
	 *     }
	 * });
	 * ```
	 */
	public get_distinct_id(): string {
		return mixpanel.get_distinct_id();
	}

	/**
	 * {@link https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpanelget_group Reference}
	 *
	 * Look up reference to a Mixpanel group
	 *
	 * @example
	 *
	 * ```js
	 * mixpanel.get_group(group_key, group_id)
	 * ```
	 *
	 * @param group_key Group key
	 * @param group_id A valid Mixpanel property type
	 */
	public get_group(group_key: string, group_id: string): Group {
		return mixpanel.get_group(group_key, group_id);
	}

	/**
	 * {@link https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpanelget_property Reference}
	 *
	 * Returns the value of the super property named property_name.
	 * If no such property is set, `get_property()` will return the undefined value.
	 *
	 * @remarks
	 *
	 * `get_property()` can only be called after the Mixpanel library has finished loading.
	 * `init()` has a loaded function available to handle this automatically. For example:
	 *
	 * @param property_name The name of the super property you want to retrieve
	 */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public get_property(property_name: string): any {
		return mixpanel.get_property(property_name);
	}

	/**
	 * {@link https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpanelhas_opted_in_tracking Reference}
	 *
	 * Check whether the user has opted in to data tracking and cookies/localstorage
	 * for this Mixpanel instance.
	 *
	 * @param options A dictionary of config options to override.
	 */
	public has_opted_in_tracking(options?: Partial<HasOptedInOutOptions>): boolean {
		return mixpanel.has_opted_in_tracking(options);
	}

	/**
	 * {@link https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpanelhas_opted_out_tracking Reference}
	 *
	 * @warning
	 *
	 * **This method will obey standard browser opt-out settings**.
	 *
	 * By default, `has_opted_out_tracking()`
	 * {@link https://github.com/mixpanel/mixpanel-js/blob/master/dist/mixpanel.amd.js#L3133-L3135 will check standard browser opt out settings}
	 * such as
	 * {@link https://developer.mozilla.org/en-US/docs/Web/API/Navigator/doNotTrack Navigator.doNotTrack}.
	 *
	 * You can change this behavior by specifying the `ignore_dnt`
	 * {@link https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpanelset_config config option}
	 * when initializing the Mixpanel instance.
	 *
	 * @warningend
	 *
	 * Check whether the user has opted out of data tracking and cookies/localStorage for this Mixpanel instance.
	 *
	 * @param options: `optional` A dictionary of config options to override.
	 */
	public has_opted_out_tracking(options?: Partial<HasOptedInOutOptions>): boolean {
		return mixpanel.has_opted_out_tracking(options);
	}

	/**
	 * {@link https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpanelidentify Reference}
	 *
	 * Identify a user with a unique ID to track user activity across devices,
	 * tie a user to their events, and create a user profile.
	 * If you never call this method, unique visitors are tracked using a UUID
	 * generated the first time they visit the site.
	 *
	 * Call identify when you know the identity of the current user,
	 * typically after login or signup. We recommend against using identify
	 * for anonymous visitors to your site.
	 *
	 * @remarks
	 *
	 * If your project has
	 * {@link https://help.mixpanel.com/hc/en-us/articles/360039133851 ID Merge}
	 * enabled, the identify method will connect pre- and post-authentication events
	 * when appropriate.
	 *
	 * If your project does not have ID Merge enabled, identify will
	 * change the user's local distinct_id to the unique ID you pass.
	 * Events tracked prior to authentication will not be connected
	 * to the same user identity. If ID Merge is disabled, alias can be
	 * used to connect pre- and post-registration events.
	 *
	 * @param unique_id
	 */
	public identify(unique_id?: string): void {
		return mixpanel.identify(unique_id);
	}

	/**
	 * {@link https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpanelinit Reference}
	 *
	 * This function initializes a new instance of the Mixpanel tracking object.
	 * All new instances are added to the main mixpanel object as sub properties
	 * (such as mixpanel.library_name) and also returned by this function.
	 * To define a second instance on the page, you would call:
	 *
	 * ```js
	 * mixpanel.init('new token', { your: 'config' }, 'library_name');
	 * ```
	 *
	 * and use it like so:
	 *
	 * ```js
	 * mixpanel.library_name.track(...);
	 * ```
	 *
	 * @param token Your Mixpanel API token.
	 * @param config `optional` A dictionary of config options to override.
	 * @param name `optional` The name for the new mixpanel instance that you want created.
	 * @returns
	 */
	init(token: string, config: Partial<Config>, name: string): Mixpanel;
	init(token: string, config?: Partial<Config>): undefined;
	public init(token: string, config?: Partial<Config>, name?: string): undefined | Mixpanel {
		if (!!name) {
			return mixpanel.init(token, config || {}, name);
		}

		return mixpanel.init(token, config);
	}

	/**
	 * {@link https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpanelopt_in_tracking Reference}
	 *
	 * Opt the user in to data tracking and cookies/localstorage for this Mixpanel instance
	 *
	 * @param options `optional` A dictionary of config options to override
	 */
	public opt_in_tracking(options?: Partial<InTrackingOptions>): void {
		return mixpanel.opt_in_tracking(options);
	}

	/**
	 * {@link https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpanelopt_out_tracking Reference}
	 *
	 * Opt the user out of data tracking and cookies/localstorage for this Mixpanel instance
	 *
	 * @param options `optional` A dictionary of config options to override
	 */
	public opt_out_tracking(options?: Partial<InTrackingOptions>): void {
		return mixpanel.opt_out_tracking(options);
	}

	/**
	 * {@link https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpanelpush Reference}
	 *
	 * `push()` keeps the standard async-array-push behavior around after the lib is loaded.
	 * This is only useful for external integrations that do not wish to rely on our
	 * convenience methods (created in the snippet).
	 *
	 * @example
	 *
	 * ```js
	 * ixpanel.push(['register', { a: 'b' }]);
	 * ```
	 */
	public push(): void {}

	/**
	 * {@link https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpanelregister Reference}
	 *
	 * Register a set of super properties, which are included with all events.
	 * This will overwrite previous super property values.
	 *
	 * @example
	 *
	 * ```js
	 * // register 'Gender' as a super property
	 * mixpanel.register({'Gender': 'Female'});
	 *
	 * // register several super properties when a user signs up
	 * mixpanel.register({
	 *    'Email': 'jdoe@example.com',
	 *    'Account Type': 'Free'
	 * });
	 * ```
	 *
	 * @param properties An associative array of properties to store about the user.
	 * @param days `optional` How many days since the user's last visit to store the super properties.
	 */
	public register(properties: Dict, days?: number): void {
		return mixpanel.register(properties, days);
	}

	/**
	 * {@link https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpanelregister_once Reference}
	 *
	 * Register a set of super properties only once.
	 * This will not overwrite previous super property values, unlike `register()`.
	 *
	 * @example
	 *
	 * ```js
	 * // register a super property for the first time only
	 * mixpanel.register_once({
	 *     'First Login Date': new Date().toISOString()
	 * });
	 * ```
	 *
	 * @remarks
	 *
	 * If default_value is specified, current super properties with that
	 * value will be overwritten.
	 *
	 * @param properties An associative array of properties to store about the user
	 * @param default_value `optional` Value to override if already set in super properties (ex: `'False'`) Default: `'None'`
	 * @param days `optional` How many days since the users last visit to store the super properties.
	 */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public register_once(properties: Dict, default_value?: any, days?: number): void {
		return mixpanel.register_once(properties, default_value, days);
	}

	/**
	 * {@link https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpanelremove_group Reference}
	 *
	 * Remove a group from this user.
	 *
	 * @example
	 *
	 * ```js
	 * mixpanel.remove_group('company', 'mixpanel');
	 * ```
	 *
	 * @param group_key Group key
	 * @param group_ids An array of group IDs, or a singular group ID
	 */
	public remove_group(
		group_key: string,
		group_ids: string | string[] | number | number[]
	): Promise<void> {
		return new Promise<void>((resolve) => {
			mixpanel.remove_group(group_key, group_ids, () => resolve());
		});
	}

	/**
	 * {@link https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpanelreset Reference}
	 *
	 * Clears super properties and generates a new random distinct_id for this instance.
	 * Useful for clearing data when a user logs out.
	 */
	public reset(): void {
		return mixpanel.reset();
	}

	/**
	 * {@link https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpanelset_config Reference}
	 *
	 * Update the configuration of a mixpanel library instance.
	 *
	 * @param config A dictionary of new configuration values to update
	 */
	public set_config(config: Partial<Config>): void {
		return mixpanel.set_config(config);
	}

	/**
	 * {@link https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpanelset_group Reference}
	 *
	 * Register the current user into one/many groups.
	 *
	 * @example
	 *
	 * ```js
	 * mixpanel.set_group('company', ['mixpanel', 'google']) // an array of IDs
	 * mixpanel.set_group('company', 'mixpanel')
	 * mixpanel.set_group('company', 128746312)
	 * ```
	 *
	 * @param group_key Group key
	 * @param group_ids An array of group IDs, or a singular group ID
	 * @returns
	 */
	public set_group(
		group_key: string,
		group_ids: string | number | string[] | number[]
	): Promise<void> {
		return new Promise<void>((resolve) => {
			mixpanel.set_group(group_key, group_ids, () => resolve());
		});
	}

	/**
	 * {@link https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpaneltime_event Reference}
	 *
	 * Time an event by including the time between this call and a later 'track'
	 * call for the same event in the properties sent with the event.
	 * Note that each call to time_event for the same event name restarts the start timer.
	 *
	 * @example
	 *
	 * ```js
	 * // time an event named 'Registered'
	 * mixpanel.time_event('Registered');
	 * mixpanel.track('Registered', {'Gender': 'Male', 'Age': 21});
	 * ```
	 *
	 * When called for a particular event name, the next track call for that event name
	 * will include the elapsed time between the `time_event` and `track` calls.
	 * This value is stored as seconds in the `$duration` property.
	 *
	 * @param event_name The name of the event.
	 */
	public time_event(event_name: string): void {
		return mixpanel.time_event(event_name);
	}

	/**
	 * {@link https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpaneltrack Reference}
	 *
	 * Track an event. This is the most important and frequently used Mixpanel function.
	 *
	 * @example
	 *
	 * ```js
	 * // track an event named 'Registered'
	 * mixpanel.track('Registered', {'Gender': 'Male', 'Age': 21});
	 *
	 * // track an event using navigator.sendBeacon
	 * mixpanel.track('Left page', {'duration_seconds': 35}, {transport: 'sendBeacon'});
	 * ```
	 *
	 * To track link clicks or form submissions, see
	 * {@link AsyncMixpanel.track_links track_links}
	 * or
	 * {@link AsyncMixpanel.track_forms track_forms}.
	 *
	 * @param event_name The name of the event. This can be anything the user does - 'Button Click', 'Sign Up', 'Item Purchased', etc.
	 * @param properties `optional` A set of properties to include with the event you're sending. These describe the user who did the event or details about the event itself.
	 * @param options `optional` Optional configuration for this track request.
	 */
	public track(event_name: string, properties?: Dict, options?: RequestOptions): Promise<void> {
		return new Promise<void>((resolve) => {
			mixpanel.track(event_name, properties, options, () => resolve());
		});
	}

	/**
	 * {@link https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpaneltrack_forms Reference}
	 *
	 * Track form submissions. Selector must be a valid query.
	 *
	 * @example
	 *
	 * ```js
	 * // track submission for form id 'register'
	 * mixpanel.track_forms('#register', 'Created Account');
	 * ```
	 *
	 * @remarks
	 *
	 * This function will wait up to 300 ms for the mixpanel servers to respond,
	 * if they have not responded by that time it will head to the link without
	 * ensuring that your event has been tracked.
	 * To configure this timeout please see the
	 * {@link AsyncMixpanel.set_config set_config}
	 *  documentation below.
	 *
	 * If you pass a function in as the properties argument,
	 * the function will receive the DOMElement that triggered the event as an argument.
	 * You are expected to return an object from the function;
	 * any properties defined on this object will be sent to mixpanel as event properties.
	 *
	 * @param query A valid DOM query, element or jQuery-esque list
	 * @param event_name The name of the event to track
	 * @param properties `optional` This can be a set of properties, or a function that returns a set of properties after being passed a DOMElement
	 */
	public track_forms(query: Query, event_name: string, properties?: Dict): void {
		return mixpanel.track_forms(query, event_name, properties);
	}

	/**
	 * {@link https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpaneltrack_links Reference}
	 *
	 * Track clicks on a set of document elements. Selector must be a valid query.
	 * Elements must exist on the page at the time track_links is called.
	 *
	 * @example
	 *
	 * ```js
	 * // track click for link id #nav
	 * mixpanel.track_links('#nav', 'Clicked Nav Link');
	 * ```
	 *
	 * @remarks
	 *
	 * This function will wait up to 300 ms for the Mixpanel servers to respond.
	 * If they have not responded by that time it will head to the link without
	 * ensuring that your event has been tracked.
	 * To configure this timeout please see the
	 * {@link AsyncMixpanel.set_config set_config}
	 *  documentation below.
	 *
	 * If you pass a function in as the properties argument,
	 * the function will receive the DOMElement that triggered the event as an argument.
	 * You are expected to return an object from the function;
	 * any properties defined on this object will be sent to mixpanel as event properties.
	 *
	 * @param query A valid DOM query, element or jQuery-esque list
	 * @param event_name The name of the event to track
	 * @param properties `optional` A properties object or function that returns a dictionary of properties when passed a DOMElement
	 */
	public track_links(query: Query, event_name: string, properties?: Dict): void {
		return mixpanel.track_links(query, event_name, properties);
	}

	/**
	 * {@link https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpaneltrack_with_groups Reference}
	 *
	 * Track an event with specific groups.
	 *
	 * @example
	 *
	 * ```js
	 * mixpanel.track_with_groups('purchase', {'product': 'iphone'}, {'University': ['UCB', 'UCLA']})
	 * ```
	 *
	 * @param event_name The name of the event (see {@link AsyncMixpanel.track mixpanel.track})
	 * @param properties A set of properties to include with the event you're sending (see {@link AsyncMixpanel.track mixpanel.track})
	 * @param groups An object mapping group name keys to one or more values
	 */
	public track_with_groups(event_name: string, properties: Dict, groups: Dict): Promise<void> {
		return new Promise<void>((resolve) => {
			mixpanel.track_with_groups(event_name, properties, groups, () => resolve());
		});
	}

	/**
	 * {@link https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpanelunregister Reference}
	 *
	 * Delete a super property stored with the current user.
	 *
	 * @param property The name of the super property to remove
	 */
	public unregister(property: string): void {
		return mixpanel.unregister(property);
	}
}

/**
 * {@link https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpanelpeople Reference}
 */
export class AsyncMixpanelPeople implements People {
	/**
	 * {@link https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpanelpeopleappend Reference}
	 *
	 * Append a value to a list-valued people analytics property.
	 *
	 * @example
	 *
	 * ```js
	 * // append a value to a list, creating it if needed
	 * mixpanel.people.append('pages_visited', 'homepage');
	 *
	 * // like mixpanel.people.set(), you can append multiple
	 * // properties at once:
	 * mixpanel.people.append({
	 *     list1: 'bob',
	 *     list2: 123
	 * });
	 * ```
	 *
	 * @param prop an associative array of names and values.
	 */
	public append(prop: Dict): Promise<void>;

	/**
	 * {@link https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpanelpeopleappend Reference}
	 *
	 * Append a value to a list-valued people analytics property.
	 *
	 * @example
	 *
	 * ```js
	 * // append a value to a list, creating it if needed
	 * mixpanel.people.append('pages_visited', 'homepage');
	 *
	 * // like mixpanel.people.set(), you can append multiple
	 * // properties at once:
	 * mixpanel.people.append({
	 *     list1: 'bob',
	 *     list2: 123
	 * });
	 *
	 * @param list_name the name of the property
	 * @param value `optional` An item to append to the list
	 * ```
	 */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public append(list_name: string, value: any): Promise<void>;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public append(prop: string | Dict, value?: any): Promise<void> {
		return new Promise<void>((resolve) => {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			mixpanel.people.append(prop as any, value, () => resolve());
		});
	}

	/**
	 * {@link https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpanelpeopleclear_charges Reference}
	 *
	 * Permanently clear all revenue report transactions
	 * from the current user's people analytics profile.
	 *
	 * @example
	 *
	 * ```js
	 * mixpanel.people.clear_charges();
	 * ```
	 */
	public clear_charges(): Promise<void> {
		return new Promise<void>((resolve) => {
			mixpanel.people.clear_charges(() => resolve());
		});
	}

	/**
	 * {@link https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpanelpeopledelete_user Reference}
	 *
	 * Permanently deletes the current people analytics profile from Mixpanel
	 * (using the current distinct_id).
	 *
	 * @example
	 *
	 * ```js
	 * // remove the all data you have stored about the current user
	 * mixpanel.people.delete_user();
	 * ```
	 */
	public delete_user(): void {
		return mixpanel.people.delete_user();
	}

	/**
	 * {@link https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpanelpeopleincrement Reference}
	 *
	 * Increment/decrement numeric people analytics properties.
	 *
	 * @example
	 *
	 * ```js
	 * mixpanel.people.increment('page_views', 1);
	 *
	 * // or, for convenience, if you're just incrementing a counter by
	 * // 1, you can simply do
	 * mixpanel.people.increment('page_views');
	 *
	 * // to decrement a counter, pass a negative number
	 * mixpanel.people.increment('credits_left', -1);
	 *
	 * // like mixpanel.people.set(), you can increment multiple
	 * // properties at once:
	 * mixpanel.people.increment({
	 *     counter1: 1,
	 *     counter2: 6
	 * });
	 * ```
	 *
	 * @param prop If a string, this is the name of the property. If an object, this is an associative array of names and numeric values.
	 */
	public increment(prop: string | Dict): Promise<void>;

	/**
	 * {@link https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpanelpeopleincrement Reference}
	 *
	 * Increment/decrement numeric people analytics properties.
	 *
	 * @example
	 *
	 * ```js
	 * mixpanel.people.increment('page_views', 1);
	 *
	 * // or, for convenience, if you're just incrementing a counter by
	 * // 1, you can simply do
	 * mixpanel.people.increment('page_views');
	 *
	 * // to decrement a counter, pass a negative number
	 * mixpanel.people.increment('credits_left', -1);
	 *
	 * // like mixpanel.people.set(), you can increment multiple
	 * // properties at once:
	 * mixpanel.people.increment({
	 *     counter1: 1,
	 *     counter2: 6
	 * });
	 * ```
	 *
	 * @param prop If a string, this is the name of the property. If an object, this is an associative array of names and numeric values.
	 * @param by `optional` An amount to increment the given property
	 */
	public increment(prop: string | Dict, by?: number): Promise<void> {
		return new Promise<void>((resolve) => {
			if (typeof prop === typeof 'string' && !Number.isNaN(by)) {
				return mixpanel.people.increment(prop as string, by as number, () => resolve());
			} else {
				return mixpanel.people.increment(prop, () => resolve());
			}
		});
	}

	/**
	 * {@link https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpanelpeopleremove Reference}
	 *
	 * Remove a value from a list-valued people analytics property.
	 *
	 * @example
	 *
	 * ```js
	 * mixpanel.people.remove('School', 'UCB');
	 * ```
	 *
	 * @param list_name the name of the property
	 * @param value A value to set on the given property name
	 */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public remove(list_name: string, value: any): Promise<void>;

	/**
	 * {@link https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpanelpeopleremove Reference}
	 *
	 * Remove a value from a list-valued people analytics property.
	 *
	 * @example
	 *
	 * ```js
	 * mixpanel.people.remove('School', 'UCB');
	 * ```
	 *
	 * @param prop an associative array of names and values.
	 */
	public remove(prop: Dict): Promise<void>;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public remove(prop: Dict | string, value?: any): Promise<void> {
		return new Promise<void>((resolve) => {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			mixpanel.people.remove(prop as any, value, () => resolve());
		});
	}

	/**
	 * {@link https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpanelpeopleset Reference}
	 *
	 * Set properties on a user record.
	 *
	 * @example
	 *
	 * ```js
	 * mixpanel.people.set('gender', 'm');
	 *
	 * // or set multiple properties at once
	 * mixpanel.people.set({
	 *     'Company': 'Acme',
	 *     'Plan': 'Premium',
	 *     'Upgrade date': new Date()
	 * });
	 * // properties can be strings, integers, dates, or lists
	 * ```
	 *
	 * @param prop the name of the property.
	 * @param to A value to set on the given property name
	 */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public set(prop: string, to: any): Promise<void>;

	/**
	 * {@link https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpanelpeopleset Reference}
	 *
	 * Set properties on a user record.
	 *
	 * @example
	 *
	 * ```js
	 * mixpanel.people.set('gender', 'm');
	 *
	 * // or set multiple properties at once
	 * mixpanel.people.set({
	 *     'Company': 'Acme',
	 *     'Plan': 'Premium',
	 *     'Upgrade date': new Date()
	 * });
	 * // properties can be strings, integers, dates, or lists
	 * ```
	 *
	 * @param prop an associative array of names and values.
	 */
	public set(prop: Dict): Promise<void>;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public set(prop: Dict | string, to?: any): Promise<void> {
		return new Promise<void>((resolve) => {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			mixpanel.people.set(prop as any, to, () => resolve());
		});
	}

	/**
	 * {@link https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpanelpeopleset_once Reference}
	 *
	 * Set properties on a user record, only if they do not yet exist.
	 * This will not overwrite previous people property values, unlike
	 * {@link AsyncMixpanelPeople.set people.set()}.
	 *
	 * @example
	 *
	 * ```js
	 * mixpanel.people.set_once('First Login Date', new Date());
	 *
	 * // or set multiple properties at once
	 * mixpanel.people.set_once({
	 *     'First Login Date': new Date(),
	 *     'Starting Plan': 'Premium'
	 * });
	 * // properties can be strings, integers or dates
	 * ```
	 *
	 * @param prop the name of the property.
	 * @param to A value to set on the given property name
	 */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public set_once(prop: string, to: any): Promise<void>;

	/**
	 * {@link https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpanelpeopleset_once Reference}
	 *
	 * Set properties on a user record, only if they do not yet exist.
	 * This will not overwrite previous people property values, unlike
	 * {@link AsyncMixpanelPeople.set people.set()}.
	 *
	 * @example
	 *
	 * ```js
	 * mixpanel.people.set_once('First Login Date', new Date());
	 *
	 * // or set multiple properties at once
	 * mixpanel.people.set_once({
	 *     'First Login Date': new Date(),
	 *     'Starting Plan': 'Premium'
	 * });
	 * // properties can be strings, integers or dates
	 * ```
	 *
	 * @param prop an associative array of names and values.
	 */
	public set_once(prop: Dict): Promise<void>;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public set_once(prop: Dict | string, to?: any): Promise<void> {
		return new Promise<void>((resolve) => {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			mixpanel.people.set_once(prop as any, to, () => resolve());
		});
	}

	/**
	 * {@link https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpanelpeopletrack_charge Reference}
	 *
	 * Record that you have charged the current user a certain amount of money.
	 * Charges recorded with track_charge() will appear in the Mixpanel revenue report.
	 *
	 * @example
	 *
	 * ```js
	 * // charge a user $50
	 * mixpanel.people.track_charge(50);
	 *
	 * // charge a user $30.50 on the 2nd of january
	 * mixpanel.people.track_charge(30.50, {
	 *     '$time': new Date('jan 1 2012')
	 * });
	 * ```
	 *
	 * @param amount The amount of money charged to the current user
	 * @param properties `optional` An associative array of properties associated with the charge
	 */
	public track_charge(amount: number, properties?: Dict): Promise<void> {
		return new Promise<void>((resolve) => {
			mixpanel.people.track_charge(amount, properties, () => resolve());
		});
	}

	/**
	 * {@link https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpanelpeopleunion Reference}
	 *
	 * Merge a given list with a list-valued people analytics property,
	 * excluding duplicate values.
	 *
	 * @example
	 *
	 * ```js
	 * // merge a value to a list, creating it if needed
	 * mixpanel.people.union('pages_visited', 'homepage');
	 *
	 * // like mixpanel.people.set(), you can append multiple
	 * // properties at once:
	 * mixpanel.people.union({
	 *     list1: 'bob',
	 *     list2: 123
	 * });
	 *
	 * // like mixpanel.people.append(), you can append multiple
	 * // values to the same list:
	 * mixpanel.people.union({
	 *     list1: ['bob', 'billy']
	 * });
	 * ```
	 *
	 * @param list_name the name of the property
	 * @param value Value / values to merge with the given property
	 */
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public union(list_name: string, value?: any): Promise<void>;

	/**
	 * {@link https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpanelpeopleunion Reference}
	 *
	 * Merge a given list with a list-valued people analytics property,
	 * excluding duplicate values.
	 *
	 * @example
	 *
	 * ```js
	 * // merge a value to a list, creating it if needed
	 * mixpanel.people.union('pages_visited', 'homepage');
	 *
	 * // like mixpanel.people.set(), you can append multiple
	 * // properties at once:
	 * mixpanel.people.union({
	 *     list1: 'bob',
	 *     list2: 123
	 * });
	 *
	 * // like mixpanel.people.append(), you can append multiple
	 * // values to the same list:
	 * mixpanel.people.union({
	 *     list1: ['bob', 'billy']
	 * });
	 * ```
	 *
	 * @param list_name an associative array of names and values.
	 */
	public union(prop: Dict): Promise<void>;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	public union(list_name: Dict | string, value?: any): Promise<void> {
		return new Promise<void>((resolve) => {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			mixpanel.people.union(list_name as any, value, () => resolve());
		});
	}

	/**
	 * {@link https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpanelpeopleunset Reference}
	 *
	 * Unset properties on a user record
	 * (permanently removes the properties and their values from a profile).
	 *
	 * @example
	 *
	 * ```js
	 * mixpanel.people.unset('gender');
	 *
	 * // or unset multiple properties at once
	 * mixpanel.people.unset(['gender', 'Company']);
	 * ```
	 *
	 * @param prop If a string, this is the name of the property. If an array, this is a list of property names.
	 */
	public unset(prop: string | string[]): Promise<void> {
		return new Promise<void>((resolve) => {
			mixpanel.people.unset(prop, () => resolve());
		});
	}
}
