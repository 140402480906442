export interface UpdateLogGroup {
	id: string;
	publicationDate: Date | null;
	url: string | null;
	name: string;
	version: string;
	published: boolean;
	slides: UpdateLogSlide[];
}

export interface UpdateLogSlide {
	id: string;
	index: number;
	title: string;
	text: string;
	imageUrl: string;
}
export type UpdateLogsCreateItem = Pick<UpdateLogSlide, 'title' | 'text'> & {
	imageIndex: number;
};

export type UpdateLogsCreateSlideBody = Pick<UpdateLogSlide, 'title' | 'text'>;

export type UpdateLogsCreateBody = {
	updateLogs: UpdateLogsCreateItem[];
	url?: string;
	name: string;
	version: string;
};

export type UpdateLogSlideUpdateBody = Partial<Pick<UpdateLogSlide, 'title' | 'text'>>;
export type UpdateLogGroupUpdateBody = Partial<
	Pick<UpdateLogGroup, 'name' | 'version' | 'published' | 'url'>
>;

export type UpdateLogsCreateResponse = UpdateLogGroup;
export type UpdateLogsUpdateResponse = UpdateLogGroup;

export type UpdateLogsGetResponse = UpdateLogGroup[];

export type UpdateLogsUserUpdateResponse = UpdateLogGroup | null;

export type UpdateLogsGetLastResponse = UpdateLogGroup | null;

export type UpdateLogDeleteSlideResponse = UpdateLogGroup;
export type UpdateLogDeleteGroupResponse = UpdateLogGroup[];
