import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map } from 'rxjs/operators';
import { MoveToRootDirective } from '~app-client/core/directives';
import { ClientService, SessionService } from '~app-client/core/services';

@UntilDestroy()
@Component({
	selector: 'user-reactor',
	template: `<div
		*ngIf="impersonating$ | async"
		moveToRoot
		class="pos-fixed pos-t-0 pos-l-0 w-100 h-100 border-box unclickable"
		style="border: 4px solid var(--color-warn); z-index: var(--z-index-max);"
	></div>`,
	styles: [':host { display: none; } '],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [CommonModule, MoveToRootDirective],
})
export class UserReactorComponent implements OnInit {
	private readonly clientService = inject(ClientService);
	private readonly sessionService = inject(SessionService);

	public readonly impersonating$ = this.sessionService.user$.pipe(
		map((user) => user?.impersonating)
	);

	ngOnInit(): void {
		this.clientService.appFocused$.pipe(untilDestroyed(this)).subscribe(async (focused) => {
			if (focused) {
				if (!!this.sessionService.user) {
					await this.sessionService.ping();
				}
			}
		});
	}
}
