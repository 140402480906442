import { Injectable } from '@angular/core';
import {
	CreateRemindersBody,
	CustomPropertyValue,
	ReminderInterface,
	UpdateReminderBody,
} from '@reach/interfaces';
import { BaseApiService } from '~app-client/api/_internal';

@Injectable({ providedIn: 'root' })
export class RemindersApiService extends BaseApiService {
	protected commonPath = ['reminders'];

	public getReminders(
		nodeId: string,
		propertyId: CustomPropertyValue['id']
	): Promise<ReminderInterface[]> {
		return this.http.get<ReminderInterface[]>(this.url(nodeId, propertyId));
	}

	public getReminderById(reminderId: string): Promise<ReminderInterface> {
		return this.http.get<ReminderInterface>(this.url('reminder', reminderId));
	}

	public createReminders(
		nodeId: string,
		customPropertyId: string,
		remindersBodies: CreateRemindersBody
	): Promise<ReminderInterface[]> {
		return this.http.post<ReminderInterface[]>(
			this.url(nodeId, customPropertyId),
			remindersBodies
		);
	}

	public updateReminder(
		reminderId: string,
		reminderBody: UpdateReminderBody
	): Promise<ReminderInterface> {
		return this.http.patch<ReminderInterface>(this.url(reminderId), reminderBody);
	}

	public deleteRemindersBulk(reminderIds: string[]): Promise<void> {
		return this.http.delete<void>(this.url('bulk'), { reminderIds: reminderIds });
	}
}
