import { RankKeyEnum } from '../ranks';

export interface LevelingSummaryInterface {
	/**
	 * The amount of experience points that the user has collected so far
	 */
	numExperiencePoints: number;
	level: {
		/**
		 * Current lvl of the user: starts at lvl 1
		 */
		current: number;
		/**
		 * How many experience points are required to complete this level
		 * e.g. the lvl 10 requires 100 exp points to be completed
		 */
		numExperiencePointsRequiredToNext: number;
		/**
		 * How many experience points you have completed for this level
		 * e.g. you have completed 80 exp points of lvl 10
		 */
		numExperiencePointsCompletedToNext: number;
		/**
		 * How many experience points remain to complete this level
		 * e.g. you only need 20 exp points to get to lvl 11
		 */
		numExperiencePointsRemainingToNext: number;
	};
	rank: {
		/**
		 * Current rank of the user: starts at rookie
		 */
		current: RankKeyEnum;
		/**
		 * How many experience points are required to complete this rank
		 * e.g. the rank "rookie" requires 1000 exp points to be completed
		 */
		numExperiencePointsRequiredToNext: number | null;
		/**
		 * How many experience points you have completed for this rank
		 * e.g. you have completed 800 exp points of the rank "rookie"
		 */
		numExperiencePointsCompletedToNext: number | null;
		/**
		 * How many experience points remain to complete this rank
		 * e.g. you only need 200 exp points to get to the "dreamer" rank
		 */
		numExperiencePointsRemainingToNext: number | null;
	};
}
