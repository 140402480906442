import { Injectable, NgModule } from '@angular/core';
import { provideTransloco, Translation, TranslocoLoader, TranslocoModule } from '@ngneat/transloco';
import { ReachTranslateLang } from '~app-client/core/types';
import { environment } from '~app-environment';

declare const BUILD_TIME: string;

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
	private readonly promiseMap: Record<string, Promise<Translation>> = {};
	private readonly valueMap: Record<string, Translation> = {};

	public async getTranslation(path: string): Promise<Translation> {
		const oldPathArr = path.split('/');
		const pathRaw = oldPathArr.slice(0, -1);
		// We check if it's the first time the app loads to load the general translation file or the specific one for the selected page.
		const objectPath = pathRaw.length === 0 ? ['general'] : pathRaw;
		const newPath = [...oldPathArr.slice(-1), ...objectPath].join('/');

		const storedValue = this.valueMap[newPath];
		if (!!storedValue) {
			// console.debug(`[i18n] ${newPath} (cached)`);
			return storedValue;
		}

		const storedPromise = this.promiseMap[newPath];
		if (!!storedPromise) {
			// console.debug(`[i18n] ${newPath} (repeated)`);
			const i18n = await storedPromise;
			return i18n;
		}

		// console.debug(`[i18n] ${path} -> ${newPath}`);

		// console.debug(`[i18n] ${newPath} (fetching)`);

		if (newPath === 'undefined/general') {
			return {};
		}

		const promise = fetch(`./assets/i18n/${newPath}.json?t=${BUILD_TIME}`);
		this.promiseMap[newPath] = new Promise<Translation>(async (resolve) => {
			try {
				const response = await promise;
				const i18n = (await response.json()) as Translation;
				this.valueMap[newPath] = i18n;
				delete this.promiseMap[newPath];
				resolve(i18n);
			} catch (error) {
				console.error(`[i18n] (${newPath})`, error);
				resolve({});
				// reject(error);
			}
		});

		// console.debug(`[i18n] ${newPath} (finished)`);

		const i18n = await this.promiseMap[newPath];
		return i18n;
	}
}

@NgModule({
	exports: [TranslocoModule],
	providers: [
		provideTransloco({
			config: {
				availableLangs: Object.values(ReachTranslateLang),
				defaultLang: ReachTranslateLang.ENGLISH_GB,
				fallbackLang: [ReachTranslateLang.ENGLISH_GB, ReachTranslateLang.UNIVERSAL],
				missingHandler: {
					logMissingKey: true,
					useFallbackTranslation: true,
					allowEmpty: true,
				},
				reRenderOnLangChange: true,
				prodMode: environment.production,
			},
			loader: TranslocoHttpLoader,
		}),
	],
})
export class TranslocoRootModule {}
