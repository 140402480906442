import {
	UserSummaryInfo,
	UserTimeInterval,
	UserTimeNumberInterval,
} from './users-summary.interface';

export interface UserTypesInterface {
	type: UserSummaryInfo;
	time: UserTimeInterval;
	interval?: UserTimeNumberInterval;
	truncate?: boolean;
}
