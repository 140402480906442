import { Nullable } from '../typescript';

export interface UserToken {
	value: string;
	useCase: JwtUses;
	expirationDate: Nullable<Date>;
}
export enum JwtUses {
	VERIFY_EMAIL = 'VERIFY_EMAIL',
	RECOVER_PASSWORD = 'RECOVER_PASSWORD',
	UNSUBSCRIBE = 'UNSUBSCRIBE',
	DELETE_USER = 'DELETE_USER',
	PUSH_IMAGE = 'PUSH_IMAGE',
}
