import { Injectable } from '@angular/core';
import { Edge, EdgeCreateBody } from '@reach/interfaces';
import { BaseApiService } from '~app-client/api/_internal';
import { ReachNodeId } from '~app-client/core/types';

@Injectable({ providedIn: 'root' })
export class EdgesApiService extends BaseApiService {
	protected commonPath = ['edges'];

	public create(edge: EdgeCreateBody): Promise<Edge> {
		return this.http.post<Edge>(this.url(), edge);
	}

	public createNew(sourceId: ReachNodeId, targetId: ReachNodeId): Promise<void> {
		const body = {
			jumps: 1,
			directed: false,
			source: sourceId,
			target: targetId,
		};
		return this.http.post<void>(this.url(), body);
	}

	public createManyRaw(edges: EdgeCreateBody[]): Promise<Edge[]> {
		const body = { edges };
		return this.http.post<Edge[]>(this.url('many'), body);
	}

	public createMany(
		sourceId: ReachNodeId,
		options?: Partial<{ directed: boolean; name?: string }>,
		...targetIds: ReachNodeId[]
	): Promise<Edge[]> {
		const body = {
			edges: targetIds.map((targetId) => ({
				source: sourceId,
				target: targetId,
				directed: options?.directed || false,
				name: options?.name || 'Linked',
			})),
		};
		return this.http.post<Edge[]>(this.url('many'), body);
	}

	public removeEdges(...edgeIds: number[]): Promise<void> {
		const body = { edgeIds: edgeIds || [] };
		return this.http.delete<void>(this.url('many'), body);
	}

	public rename(relationshipId: Edge['id'], relationshipName: string): Promise<Edge[]> {
		const body = { name: relationshipName };
		return this.http.patch<Edge[]>(this.url('one', relationshipId), body);
	}

	public removeName(relationshipId: Edge['id']): Promise<Edge[]> {
		return this.http.post<Edge[]>(this.url('one', relationshipId, 'delete-name'));
	}
}
