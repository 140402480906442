import { YoutubeThumbnailResolutions } from './youtube-thumbnail.interface';

export interface YoutubeInterface {
	id: string;
	channelId: string;

	title: string;
	description: string;
	creationDate: number | null;
	channelTitle: string;

	thumbnails: YoutubeThumbnailResolutions | null;
}
