import { CustomPropertyType } from '../../../custom-property';
import { ExcludedPropertyTypes } from './excluded-property-types.type';

export const propertyTypeTextOverrideMap: Record<
	Exclude<CustomPropertyType, ExcludedPropertyTypes>,
	string
> = {
	[CustomPropertyType.PLAIN_TEXT]: 'TEXT',
	[CustomPropertyType.PLAIN_NUMBER]: 'NUMBER',
	[CustomPropertyType.SELECT_TAG]: 'SELECT',
	[CustomPropertyType.DATE]: 'DATE',
	[CustomPropertyType.TIME]: 'TIME',
	[CustomPropertyType.MULTI_SELECT_TAG]: 'MULTI_SELECT',
	[CustomPropertyType.SELECT_STAR]: 'RATING',
	[CustomPropertyType.PHONE]: 'PHONE',
	[CustomPropertyType.EMAIL]: 'EMAIL',
	[CustomPropertyType.URL]: 'URL',
	[CustomPropertyType.NAME]: 'WORDS',
	[CustomPropertyType.CURRENCY]: 'CURRENCY',
	[CustomPropertyType.BOOLEAN]: 'CHECKBOX',
	[CustomPropertyType.MEASURE_WEIGHT]: 'WEIGHT',
	[CustomPropertyType.MEASURE_DISTANCE]: 'DISTANCE',
	[CustomPropertyType.SELECT_IMPORTANCE]: 'IMPORTANCE',
	[CustomPropertyType.PERCENTAGE]: 'PERCENTAGE',
	[CustomPropertyType.USER]: 'USER',
	[CustomPropertyType.PROCESS]: 'PROCESS',
};
