import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

export interface ClientErrorScreenMessage {
	title: string;
	description: string;
}

export const DEFAULT_MESSAGE: ClientErrorScreenMessage = {
	title: 'Something went wrong',
	description: 'We detected a problem while running the app.',
};

@Injectable({ providedIn: 'root' })
export class ClientErrorScreenService {
	private readonly router = inject(Router);

	private readonly message$$ = new BehaviorSubject<ClientErrorScreenMessage>(DEFAULT_MESSAGE);
	public readonly message$ = this.message$$.asObservable();

	private _somethingFailed = false;

	public get somethingFailed(): boolean {
		return this._somethingFailed;
	}

	public async show(message: ClientErrorScreenMessage): Promise<void> {
		this._somethingFailed = true;
		this.message$$.next(message);
		await this.router.navigate(['/', 'client-error'], {
			onSameUrlNavigation: 'reload',
		});
	}
}
