import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { NodeType } from '@reach/interfaces';

@Pipe({
	pure: true,
	name: 'nodeTypeTranslation',
})
export class NodeTypeTranslationPipe implements PipeTransform {
	transform(type: NodeType, plural = false): string {
		return `nodeType.${plural ? `${type}_PLURAL` : `${type}`}`;
	}
}

@NgModule({
	declarations: [NodeTypeTranslationPipe],
	providers: [NodeTypeTranslationPipe],
	exports: [NodeTypeTranslationPipe],
})
export class NodeTypeTranslationModule {}
