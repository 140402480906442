import { DOCUMENT } from '@angular/common';
import {
	ApplicationRef,
	ComponentRef,
	createComponent,
	EmbeddedViewRef,
	Inject,
	Injectable,
	Injector,
	Renderer2,
	RendererFactory2,
	TemplateRef,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ReachModalComponent } from '../components/public_api';
import { OpenModalOptions } from '../types/open-modal-options.type';
import { asImplicit, Implicit } from '../types/public_api';

@Injectable({ providedIn: 'root' })
export class ReachModalsService {
	private embeddedView?: EmbeddedViewRef<unknown>;
	private modalComponentRef?: ComponentRef<ReachModalComponent>;
	private modalDomElement?: HTMLElement;

	private modalClosed$$ = new Subject<boolean>();
	public modalClosed$ = this.modalClosed$$.asObservable();

	private renderer: Renderer2;

	constructor(
		@Inject(DOCUMENT) private document: Document,
		private appRef: ApplicationRef,
		rendererFactory: RendererFactory2
	) {
		this.renderer = rendererFactory.createRenderer(null, null);
	}

	public openModal<T>(
		template: TemplateRef<Implicit<T>>,
		context?: T,
		options?: Partial<OpenModalOptions>
	): void {
		this.closeModal(false);

		this.embeddedView = template.createEmbeddedView({
			...asImplicit(context),
			close: (doneButtonClicked?: boolean) => this.closeModal(doneButtonClicked || false),
		});

		this.appRef.attachView(this.embeddedView);

		this.modalComponentRef = createComponent(ReachModalComponent, {
			environmentInjector: this.appRef.injector,
			projectableNodes: [this.embeddedView.rootNodes],
		});

		this.modalComponentRef.instance.options = options;

		this.appRef.attachView(this.modalComponentRef.hostView);

		this.modalDomElement = (this.modalComponentRef.hostView as EmbeddedViewRef<HTMLElement>)
			.rootNodes[0] as HTMLElement;

		this.renderer.appendChild(this.document.body, this.modalDomElement);
		this.modalComponentRef.instance.close.pipe(takeUntil(this.modalClosed$)).subscribe(() => {
			this.closeModal(false);
		});
	}

	public closeModal(doneButtonClicked = false): void {
		try {
			if (!!this.modalComponentRef) {
				this.modalClosed$$.next(doneButtonClicked);
				this.modalComponentRef?.destroy();
			}
			this.modalComponentRef = undefined;
		} catch (e) {
			console.warn(e);
		}
	}
}
