// API

import { OccurrenceStatisticResponse } from '../statistics';
import { UptimeMonitorsData } from '../uptime-robot';
import {
	CountryStats,
	FirstActionCount,
	GeneralUseCount,
	NodeCount,
	OSStats,
	PlatformStats,
	StatisticAggregationItem,
	User2DSummary,
	UserSummaryInterface,
	UserTypeDailyCounts,
} from '../user';

export type BinaryBarData = UptimeMonitorsData[]; /* | ... */

export type StatisticsApiResponse =
	| UserSummaryInterface
	| StatisticAggregationItem[]
	| PlatformStats[]
	| CountryStats[]
	| OSStats[]
	| User2DSummary
	| NodeCount
	| FirstActionCount
	| GeneralUseCount
	| BinaryBarData
	| UserTypeDailyCounts
	| OccurrenceStatisticResponse
	| null; /* | ... */

export type ApiChart = StatisticsApiResponse;

// MIXPANEL
export interface MixPanelContinuousData {
	[isoDate: string]: number;
}
export interface MixPanelContinuousDataOneEntryMap {
	[eventName: string]: MixPanelContinuousData;
}
export interface MixPanelCategoricalData {
	all: number;
}
export type MixPanelSerie =
	| MixPanelContinuousDataOneEntryMap
	| MixPanelContinuousData
	| MixPanelCategoricalData;

export interface MixPanelSeriesMap<T = MixPanelSerie> {
	[serieName: string]: T;
}

export interface MixpanelInsightResponse<T = MixPanelSerie> {
	computed_at: string; // iso DateTime
	headers: string[];
	date_range: {
		from_date: string; // iso DateTime
		to_date: string; // iso DateTime
	};
	meta: {
		min_sampling_factor: number;
		report_sections: {
			group: unknown[];
			cohorts: unknown[];
			show: { metric_key: string }[];
		};
	};
	series: MixPanelSeriesMap<T>;
}

export type MixpanelDashboardBookmark = Record<string, unknown> & {
	id: number;
	name: string;
	params: Record<string, unknown> & {
		displayOptions?: Record<string, unknown> & { chartType: string };
		ttcVizType?: string;
	};
};

export type MixpanelChart = {
	insight?: MixpanelInsightResponse<MixPanelSerie>;
	bookmark: MixpanelDashboardBookmark;
};
