<div
	*ngIf="mockSafeArea$ | async"
	moveToRoot
	class="pos-fixed pos-t-0 pos-l-0 w-100 h-100 unclickable"
	style="z-index: var(--z-index-max)"
>
	<!-- top area -->
	<div
		class="pos-abs pos-t-0 pos-l-0 w-100 fl fl-ai-c fl-js-c"
		style="height: var(--safe-area-inset-top)"
	>
		<!-- hour -->
		<div class="fs-s fw-bold p-1-rl fl fl-ai-c fl-jc-fs" style="width: 2.5rem">
			{{ time$ | async }}
		</div>

		<!-- notch -->
		<div class="fl-adaptable fl-aself-fs fl fl-ai-fs fl-jc-c h-100">
			<div
				class="fl-aself-fs w-100"
				style="
					max-width: 300px;
					height: calc(100% - 8px);
					border-radius: 0 0 28px 28px;
					background-color: #222222;
				"
			></div>
		</div>

		<!-- activity icons -->
		<div class="p-1-rl fl fl-ai-c fl-jc-fe" style="width: 2.5rem">
			<i class="icon-reach fs-s"></i>
		</div>
	</div>

	<!-- bottom area -->
	<div
		class="pos-abs pos-b-0 pos-l-0 w-100 fl fl-ai-c fl-jc-c"
		style="height: var(--safe-area-inset-bottom)"
	>
		<!-- navigation bar -->
		<div
			style="width: 50%; height: 4px; border-radius: 2px; background: var(--color-text)"
		></div>
	</div>

	<!-- left area -->
	<div
		class="pos-abs h-100 pos-l-0 pos-t-0"
		style="width: var(--safe-area-inset-left)"
	></div>

	<!-- right area -->
	<div
		class="pos-abs h-100 pos-r-0"
		style="width: var(--safe-area-inset-right)"
	></div>
</div>
