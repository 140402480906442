export interface AnalyticsEventOnboardingSuccess {
	slide_welcome_menu_opened_count: number;
	slide_welcome_time_spent_ms: number;

	slide_save_menu_opened_count: number;
	slide_save_time_spent_ms: number;

	slide_view_menu_opened_count: number;
	slide_view_time_spent_ms: number;

	slide_find_menu_opened_count: number;
	slide_find_time_spent_ms: number;

	slide_start_menu_opened_count: number;
	slide_start_time_spent_ms: number;
}
