export interface EmailSubscription {
	id: number;
	email: string;
	subscribedAt: Date;
	ip: string;
}

export type EmailSubscriptionCreateInterface = Pick<EmailSubscription, 'email'>;

export type EmailSubscriptionDeleteInterface = Pick<EmailSubscription, 'email'>;

export type EmailSubscriptionGetResponse = EmailSubscription[];

export type EmailSubscriptionCreateBody = Pick<EmailSubscription, 'email'>;
export type EmailSubscriptionCreateResponse = EmailSubscription;
