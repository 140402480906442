import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { codePush, InstallMode } from '@dwimcore/capacitor-codepush';
import {
	DownloadProgress,
	ILocalPackage,
	IRemotePackage,
} from '@dwimcore/capacitor-codepush/dist/esm/package';
import { SyncStatus } from '@dwimcore/capacitor-codepush/dist/esm/syncStatus';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
import { auditTime, filter } from 'rxjs/operators';
import { ReachStorage } from '../utils/storage';
import { AnalyticsEvent, AnalyticsService } from './analytics';
import { ClientService } from './client';

export enum CodepushUpdateStatus {
	IDLE = 'IDLE',
	CHECKING = 'CHECKING',
	DOWNLOADING = 'DOWNLOADING',
	UPDATING = 'UPDATING',
	RESTARTING = 'RESTARTING',
}

export interface CodepushUpdate {
	status: CodepushUpdateStatus;
	progress?: number;
}

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class CodepushService {
	private static storageKey = ReachStorage.asPersistentKey('codepush_just_installed');
	private static storageTrueValue = `${true}`;

	private static get justInstalledUpdate(): boolean {
		const stored = ReachStorage.getItem(CodepushService.storageKey);
		return `${stored || ''}` === CodepushService.storageTrueValue;
	}

	/**
	 * @param remotePackage The package to install.
	 */
	public static async downloadRemote(
		remotePackage: IRemotePackage,
		progressCallback?: (progress?: DownloadProgress) => void
	): Promise<ILocalPackage> {
		return remotePackage.download(progressCallback);
	}

	/**
	 * @param pkg The poackage to install.
	 * @param installMode Used to specify the InstallMode used for the install operation.
	 */
	public static async installPackage(
		pkg: ILocalPackage,
		installMode: InstallMode
	): Promise<void> {
		await pkg.install({ installMode });
	}

	public get className(): string {
		return 'CodepushService';
	}

	private _updating = false;

	private readonly status$$ = new BehaviorSubject<CodepushUpdate>({
		status: CodepushUpdateStatus.IDLE,
	});
	public readonly status$ = this.status$$.asObservable();

	private readonly newVersionDetected$$ = new BehaviorSubject<boolean>(false);
	public readonly newVersionDetected$ = this.newVersionDetected$$.asObservable();

	public autoRedirect = true;

	private readonly clientService = inject(ClientService);
	private readonly analytics = inject(AnalyticsService);
	private readonly router = inject(Router);

	constructor() {
		this.clientService.appFocused$
			.pipe(
				filter((visible) => {
					return visible;
				}),
				auditTime(1000),
				untilDestroyed(this)
			)
			.subscribe(() => {
				try {
					this.sync();
				} catch (error) {}
			});
	}

	public async sync(): Promise<SyncStatus> {
		const auxStartTime = performance.now();

		// If we just installed an update, skip.
		if (CodepushService.justInstalledUpdate) {
			ReachStorage.deleteItem(CodepushService.storageKey);
			console.debug('Update just installed, skipping');
			await this.notifyApplicationReady();
			return SyncStatus.UP_TO_DATE;
		}

		// If we are already updating, throw error.
		if (this._updating) {
			throw 'Already updating';
		}

		// If we are on web, skip.
		if (!Capacitor.isNativePlatform()) {
			console.debug('not native, no update required.');
			return SyncStatus.UP_TO_DATE;
		}

		try {
			this._updating = true;

			await new Promise<void>((res) => window.setTimeout(res, 3000));

			console.debug('Check start');

			// Check new version.

			this.status$$.next({
				status: CodepushUpdateStatus.CHECKING,
			});
			const remote = await this.checkForUpdate();

			const auxCheckFinishTime = performance.now();

			console.debug('Check result', remote);

			if (!remote) {
				await this.notifyApplicationReady();
				this.status$$.next({
					status: CodepushUpdateStatus.IDLE,
				});
				return SyncStatus.UP_TO_DATE;
			}

			this.newVersionDetected$$.next(true);

			this.status$$.next({
				status: CodepushUpdateStatus.DOWNLOADING,
				progress: 0,
			});

			console.debug('Download start');

			if (remote.isMandatory && this.autoRedirect) {
				await this.router.navigate(['/', 'version-error', 'updating']);
			}

			// Download new package.

			const local = await CodepushService.downloadRemote(remote, (pg) => {
				console.debug('downloadRemote progress', pg);

				const { receivedBytes, totalBytes } = pg || { receivedBytes: 0, totalBytes: 1 };

				this.status$$.next({
					status: CodepushUpdateStatus.DOWNLOADING,
					progress: receivedBytes / totalBytes,
				});
			});

			const auxDownloadFinishTime = performance.now();

			console.debug('Download result', local);

			console.debug('Install start');

			this.status$$.next({
				status: CodepushUpdateStatus.UPDATING,
			});

			// Install new package.

			await CodepushService.installPackage(local, InstallMode.ON_NEXT_RESTART);

			this.analytics.addEvent(AnalyticsEvent.CODEPUSH_UPDATE_SUCCESS, {
				installedCodepushLabel: local.label,
				installedCodepushAppVersion: local.appVersion,
				codepushCheckDurationMiliseconds: auxCheckFinishTime - auxStartTime,
				codepushDownloadDurationMiliseconds: auxDownloadFinishTime - auxCheckFinishTime,
			});

			// Mark package as just installed.
			ReachStorage.setItem(CodepushService.storageKey, CodepushService.storageTrueValue);

			return SyncStatus.UPDATE_INSTALLED;
		} catch (error) {
			console.debug(error);
			this.status$$.next({
				status: CodepushUpdateStatus.IDLE,
			});
			this.analytics.addEvent(AnalyticsEvent.CODEPUSH_UPDATE_FAILED, {});
			return SyncStatus.ERROR;
		} finally {
			this._updating = false;
		}
	}

	/**
	 * Get the current package information
	 * @returns The currently deployed package information
	 */
	public async getCurrentPackage(): Promise<ILocalPackage> {
		return await codePush.getCurrentPackage();
	}

	/**
	 * Checks with the CodePush server if an update package is available for download.
	 *
	 * @param deploymentKey Optional deployment key that overrides
	 * the `capacitor.config.json` setting.
	 *
	 * @returns the package if there is a valid update.
	 * @returns `null` if the application is up to date.
	 */
	private checkForUpdate(deploymentKey?: string): Promise<IRemotePackage | undefined> {
		return new Promise<IRemotePackage | undefined>((resolve, reject) => {
			const timer = setTimeout(() => {
				reject('Timeout');
			}, 3000);
			codePush.checkForUpdate(
				(resData) => {
					clearTimeout(timer);
					resolve(resData);
				},
				(rejData) => {
					clearTimeout(timer);
					reject(rejData);
				},
				deploymentKey
			);
		});
	}

	/**
	 * Reloads the application.
	 *
	 * If there is a pending update package installed using `ON_NEXT_RESTART`
	 * or `ON_NEXT_RESUME` modes, the update will be immediately visible to the user.
	 * Otherwise, calling this function will simply reload the
	 * current version of the application.
	 */
	public async restartApplication(): Promise<void> {
		console.debug('restartApplication');
		if (Capacitor.isNativePlatform()) {
			try {
				const timerId = window.setTimeout(() => {
					location.reload();
				}, 2000);
				await codePush.restartApplication();
				window.clearTimeout(timerId);
			} catch (error) {
				location.reload();
			}
		} else {
			location.reload();
		}
	}

	/**
	 * Notifies the plugin that the update operation succeeded and
	 * that the application is ready.
	 *
	 * Calling this function is required on the first run after an update.
	 * On every subsequent application run, calling this function is a noop.
	 *
	 * If using sync API, calling this function is not required
	 * since sync calls it internally.
	 */
	public notifyApplicationReady(): Promise<void> {
		return codePush.notifyApplicationReady();
	}
}
