export interface YoutubeThumbnailResolutions {
	default: YoutubeThumbnail;
	/**
	 * The high quality image for this resource.
	 */
	high: YoutubeThumbnail;
	/**
	 * The maximum resolution quality image for this resource.
	 */
	maxres: YoutubeThumbnail;
	/**
	 * The medium quality image for this resource.
	 */
	medium: YoutubeThumbnail;
	/**
	 * The standard quality image for this resource.
	 */
	standard: YoutubeThumbnail;
}

export interface YoutubeThumbnail {
	url: string;
}
