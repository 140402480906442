export interface LocationLatLon {
	lat: number;
	lon: number;
}

export interface LocationLatLonWithPrecision {
	lat: number;
	lon: number;
	precision?: number;
}
