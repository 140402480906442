export type StringParserFn<T> = (str: string) => T | undefined;

export class StringParser {
	public static parsePositiveInt: StringParserFn<number> = (value) => {
		const valueAsNum = Number.parseFloat(value);
		const validNumber = (num: number) => Number.isInteger(num) && num >= 0;
		const result = validNumber(valueAsNum) ? valueAsNum : undefined;
		return result;
	};

	public static parseString: StringParserFn<string> = (value) => {
		return value || undefined;
	};
}
