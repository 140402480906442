import { ApiAdminInterface } from '@reach/interfaces';

export type ApiReachabilityStatus = Pick<
	ApiAdminInterface,
	'status' | 'message' | 'estimatedTime' | 'allowRequests'
>;

export function apiReachabilityStatusParser(apiData: ApiAdminInterface): ApiReachabilityStatus {
	return {
		status: apiData.status,
		message: apiData.message,
		estimatedTime: apiData.estimatedTime,
		allowRequests: apiData.allowRequests,
	};
}
