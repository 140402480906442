import { inject } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	CanActivateFn,
	Router,
	RouterStateSnapshot,
	UrlTree,
} from '@angular/router';

export class RedirectorGuard {
	public static canActivate(searchValue: string, replaceWith: string): CanActivateFn {
		return (_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): UrlTree => {
			const router = inject(Router);
			const newUrl = state.url.replace(searchValue, replaceWith);
			const urlTree = router.parseUrl(newUrl);
			return urlTree;
		};
	}
}
