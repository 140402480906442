import { ReachSlateElementType } from '@reach/interfaces';
import { DebugLine } from '../../debug';
import { ConfigAccumulator } from '../../types';
import { CssUnit } from './types';
import { getValueUnitAndValue } from './utils';

function fontSizeFromPixels(pixels: number): string {
	if (pixels <= 10) {
		return 'ql-size-reach-s';
	} else if (pixels <= 20) {
		return `ql-size-reach-m`;
	} else if (pixels <= 36) {
		return `ql-size-reach-l`;
	} else {
		return `ql-size-reach-xl`;
	}
}

export function fontSizeFn(value: string, _debugLine?: DebugLine): ConfigAccumulator {
	const config: ConfigAccumulator = {
		[ReachSlateElementType.TEXT]: {},
	};

	const getClosestFontSizeValue = (value: string): string | null => {
		const cssValue = getValueUnitAndValue(value);

		switch (cssValue?.unit) {
			case CssUnit.PX: {
				return fontSizeFromPixels(cssValue.value);
			}

			default: {
				return null;
			}
		}
	};

	const fontSize = getClosestFontSizeValue(value);

	if (!!fontSize) {
		_debugLine?.addToLine(`(fontSize: ${fontSize})`);
		config[ReachSlateElementType.TEXT] = { fontSize };
	}

	return config;
}

const TEXT_DECORATION_TO_CONFIG_MAP: Record<string, ConfigAccumulator> = {
	underline: {
		[ReachSlateElementType.TEXT]: {
			underlined: true,
		},
	},
	'line-through': {
		[ReachSlateElementType.TEXT]: {
			bold: true,
		},
	},
};

export function textDecorationFn(value: string, _debugLine?: DebugLine): ConfigAccumulator {
	const config = TEXT_DECORATION_TO_CONFIG_MAP[value];
	if (!!config) {
		const _debugKey = Object.keys(config?.[ReachSlateElementType.TEXT] || {})[0];
		_debugLine?.addToLine(
			`(decoration: ${_debugKey} - ${config?.[ReachSlateElementType.TEXT]?.[_debugKey]})`
		);
	}
	return config || {};
}

const TEXT_ALIGN_TO_CONFIG_MAP: Record<string, string> = {
	left: 'left',
	center: 'center',
	right: 'right',
	justify: 'justify',
};

export function textAlignFn(value: string, _debugLine?: DebugLine): ConfigAccumulator {
	const alignment = TEXT_ALIGN_TO_CONFIG_MAP[value] || null;
	if (alignment) {
		_debugLine?.addToLine(`(align: ${alignment})`);
		return {
			[ReachSlateElementType.PARAGRAPH]: {},
		};
	}
	return {};
}

enum ReachFontFamily {
	SERIF = 'Serif',
	MONO = 'Mono',
}

const FONT_FAMILY_MAP: Record<string, ReachFontFamily> = {
	georgia: ReachFontFamily.SERIF,
	'times new roman': ReachFontFamily.SERIF,
	serif: ReachFontFamily.SERIF,
	monaco: ReachFontFamily.MONO,
	'courier new': ReachFontFamily.MONO,
	monospace: ReachFontFamily.MONO,
};

export function fontFamilyFn(value: string, _debugLine?: DebugLine): ConfigAccumulator {
	const reachFont = value.split(',').reduce((acc, rawFont) => {
		if (!!acc) {
			return acc;
		}

		const font = `${rawFont}`.trim().toLowerCase();

		return Object.keys(FONT_FAMILY_MAP).reduce((innerAcc, fontFamily) => {
			if (!!innerAcc) {
				return innerAcc;
			}

			if (font.includes(fontFamily)) {
				return FONT_FAMILY_MAP[fontFamily];
			}

			return null;
		}, null as ReachFontFamily | null);
	}, null as ReachFontFamily | null);

	if (!!reachFont) {
		_debugLine?.addToLine(`(font: ${reachFont})`);
		return {
			[ReachSlateElementType.TEXT]: {
				fontFamily: reachFont,
			},
		};
	}

	return {};
}
