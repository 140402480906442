export function partition<T>(array: T[], isValid: (elem: T) => boolean): [T[], T[]] {
	return array.reduce(
		([pass, fail], elem) => {
			if (isValid(elem)) {
				pass.push(elem);
			} else {
				fail.push(elem);
			}
			return [pass, fail];
		},
		[[], []] as [T[], T[]]
	);
}
