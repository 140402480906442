export enum ThemeColor {
	BLUE = 'blue',
	PURPLE = 'purple',
	PINK = 'pink',
	RED = 'red',
	ORANGE = 'orange',
	YELLOW = 'yellow',
	GREEN = 'green',
	GREY = 'grey',
}
