export type RgbColor = [number, number, number];

export enum ReachColor {
	IMP_1 = 'IMP_1',
	IMP_2 = 'IMP_2',
	IMP_3 = 'IMP_3',
	IMP_4 = 'IMP_4',
	IMP_5 = 'IMP_5',
	IMP_6 = 'IMP_6',
	IMP_7 = 'IMP_7',
	IMP_8 = 'IMP_8',
	IMP_9 = 'IMP_9',
	IMP_10 = 'IMP_10',
}
