import { FrontendBookmarkItem } from '@reach/interfaces';

export enum BrowserExtensionRequestMessageKey {
	GET_BOOKMARKS = 'GET_BOOKMARKS',
	IS_INSTALLED_REQUEST = 'IS_INSTALLED_REQUEST',
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface BrowserExtensionRequestMessageDataMap {}

export enum BrowserExtensionResponseMessageKey {
	USER_BOOKMARKS = 'USER_BOOKMARKS',
	IS_INSTALLED_RESPONSE = 'IS_INSTALLED_RESPONSE',
}

// IMPORTANT: THIS MUST BE EXACTLY THE SAME AS `BrowserExtensionRequestToResponseKeyMap`
export const BrowserExtensionRequestToResponseKeyMapObj: Record<
	BrowserExtensionRequestMessageKey,
	BrowserExtensionResponseMessageKey
> = {
	[BrowserExtensionRequestMessageKey.GET_BOOKMARKS]:
		BrowserExtensionResponseMessageKey.USER_BOOKMARKS,
	[BrowserExtensionRequestMessageKey.IS_INSTALLED_REQUEST]:
		BrowserExtensionResponseMessageKey.IS_INSTALLED_RESPONSE,
} as const;

type BrowserExtensionRequestToResponseKeyMap_ = typeof BrowserExtensionRequestToResponseKeyMapObj;

// IMPORTANT: This is a workaround for the TS bug that prevents us from using
// `typeof BrowserExtensionRequestToResponseKeyMapObj` directly.
// IMPORTANT: THIS MUST BE EXACTLY THE SAME AS `BrowserExtensionRequestToResponseKeyMapObj`
export interface BrowserExtensionRequestToResponseKeyMap
	extends BrowserExtensionRequestToResponseKeyMap_ {
	[BrowserExtensionRequestMessageKey.GET_BOOKMARKS]: BrowserExtensionResponseMessageKey.USER_BOOKMARKS;
	[BrowserExtensionRequestMessageKey.IS_INSTALLED_REQUEST]: BrowserExtensionResponseMessageKey.IS_INSTALLED_RESPONSE;
}

export interface BrowserExtensionResponseMessageDataMap {
	[BrowserExtensionResponseMessageKey.USER_BOOKMARKS]: { bookmarks: FrontendBookmarkItem[] };
}
