import { ensureAssert } from '../assertions';
import { removeUndefinedProps } from './remove-undefined-props';

/**
 * Checks if object without undefined properties is empty
 * @param obj Object to check if object without undefined properties is empty
 * ```
 * 	test('is not empty', () => {
 * 		expect(isObjectWithoutUndefinedPropsEmpty({a: 2, b: undefined})).toEqual(boolean);
 * 	});
 *
 * test('is empty', () => {
 * 		expect(isObjectWithoutUndefinedPropsEmpty({b: undefined})).toEqual(true);
 * 	});
 * 	```
 */
export const isObjectWithoutUndefinedPropsEmpty = <T>(obj: T | undefined): boolean => {
	if (!ensureAssert(obj)) {
		return true;
	}

	return Object.keys(removeUndefinedProps(obj as Record<string, unknown>)).length === 0;
};
