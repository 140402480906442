import {
	CSSProperty,
	CSSPropertyType,
	EmailElementInterface,
	EmailElementType,
} from '@reach/interfaces';
import { ensure } from '../assertions';

const getEmailColor = (emailBody: EmailElementInterface<EmailElementType>) => {
	const emailBodyElementColor = emailBody?.styles?.[CSSProperty.EMAIL_COLOR];
	return `background-color: ${emailBodyElementColor || 'white'}`;
};

const getContentColor = (emailBody: EmailElementInterface<EmailElementType>) => {
	const emailBodyElementColor = emailBody?.styles?.[CSSProperty.BACKGROUND_COLOR];
	return `background-color: ${emailBodyElementColor || 'white'}`;
};

export const emailContentToHTML = (
	emailContent: EmailElementInterface<EmailElementType>
): string => {
	const initDocument = `<!DOCTYPE html>
	<html lang="en">
	<head>
		<meta charset="UTF-8">
		<meta http-equiv="X-UA-Compatible" content="IE=edge">
		<meta name="viewport" content="width=device-width, initial-scale=1.0">
		<title> Template </title>
	</head>
	<body>
	<table
	id="wrapper"
	style="
		width: 100%;
		text-align: center;
	"
>
	<tr>
		<td>
			<!--
				This table is just needed to center the email in the body.
			-->
			<table id="email" style="margin: 0 auto; text-align: center; ${getEmailColor(emailContent)};">
				<!--
					This row will have a table with the content of the email.
				-->
				<tr id="content-row">
					<td>
						<table
							style="border: 1px #DFDFDF solid;
								border-radius: 5px;
								-webkit-border-radius: 5px; 
								-moz-border-radius: 5px;
								min-width: 450px;
								max-width: 600px;
								${getContentColor(emailContent)};
							"
						>
							<tr>
								<td id="content-left-padding" style="width: 20px;"></td>
								<td>
									<table id="content" style="text-align: center;
									color: #4A4A4A;
									font-weight: 400;
									font-size: 16px;
									font-family: Helvetica Neue, Roboto, Arial, sans-serif;
									line-height: 30px;
									">

										<tr style="width: 100%; height: 50px;"><td></td></tr>`;

	const endDocument = `</table>
	</td>
	<td id="content-right-padding" style="width: 20px;"></td>
</tr>
</table>
</td>
</tr>

<!--
This row will have a table with the footer (burocracy)
-->
<tr id="footer-row">
<td>

<!--
This table needs the "width: 100%" in order to have the same
width as the one with the email content.
-->

<table
id="footer"
style="
	width: 100%;
	color: #a8b9c6;
	font-size: 10px;
	line-height: 16px;
	font-weight: 400;
	font-family: Helvetica Neue, Roboto, Arial, sans-serif;
	text-align: center;
	text-decoration:none;
"
>
<tr valign="top" style="vertical-align: top;">
	<!--
		This column should have the same content that
		the one at the right but with its content hidden (transparent).

		By doing so, the center column will be centered.
	-->
	<td style="width: 102px;"></td>
	<td>
		MYREACH SA
		<br>
		Route de la Glâne 107
		<br>
		1752 Villars-sur-Glâne 1
		Switzerland
		<br>
		<a
			href="{{snippets.BASE_URL}}/legal/privacy-policies"
			style="text-decoration:none; color: #439FF1;"
		>View Privacy Policy</a>
		|
		<a
			href="{{snippets.BASE_URL}}/legal/subscription?subscriptionToken={{customer.subscriptionToken}}"
			style="text-decoration:none; color: #439FF1;"
		>Unsubscribe</a>
	</td>
	<td style="text-align: right; width: 102px;">© 2022 myReach</td>
</tr>
</table>
</td>
</tr>
</table>
</td>
</tr>
</table>

</body>
</html>`;
	let emailAsHTML = initDocument;
	emailAsHTML = emailAsHTML.concat(childrensToHTML(ensure(emailContent.children), ''));
	emailAsHTML = emailAsHTML.concat(endDocument);
	return emailAsHTML;
};

const childrensToHTML = (
	elements: EmailElementInterface<EmailElementType>[],
	emailAsHTML: string
): string => {
	for (const element of elements) {
		const sectionColor: string = paintSection(element.styles);
		emailAsHTML = emailAsHTML.concat(
			'<tr style="width:100%;"><td align="center" style="padding-top:2px;padding-bottom:6px;word-break: break-word;-webkit-hyphens: none;-moz-hyphens: none;hyphens: none;border-collapse: collapse !important;' +
				sectionColor +
				'">'
		);
		emailAsHTML = emailAsHTML.concat(elementToHTMLElement(element));
		if (element.children != undefined) {
			emailAsHTML = emailAsHTML.concat(structureScript(element));
		}
		emailAsHTML = emailAsHTML.concat('</td></tr>');
	}
	return emailAsHTML;
};

const structureScript = (structure: EmailElementInterface<EmailElementType>) => {
	let structureHTML = '<table style="width:100%;table-layout:fixed;"><tr>';

	for (const child of ensure(structure.children)) {
		const childAlignment = getAlignment(structure, child);
		structureHTML = structureHTML.concat(
			`<td ${childAlignment}> ` + elementToHTMLElement(child) + '</td>'
		);
	}
	structureHTML = structureHTML.concat('</tr></table>');

	return structureHTML;
};

const getAlignment = (
	father: EmailElementInterface<EmailElementType>,
	child: EmailElementInterface<EmailElementType>
) => {
	let style = 'style="';
	for (const [key, value] of Object.entries(father.styles)) {
		if (key === 'align-items') {
			const alignment = alignmentMap(value);
			style = style.concat('vertical-align:' + alignment + ';');
		}
	}
	for (const [key, value] of Object.entries(child.styles)) {
		if (key === CSSProperty.FLEX_WIDTH) {
			style = style.concat('width: ' + value + '%;');
		}
	}
	style = style.concat('"');
	return style;
};

const alignmentMap = (og: unknown) => {
	switch (og) {
		case 'flex-end':
			return 'bottom';
		case 'flex-start':
			return 'top';
		default:
			return og;
	}
};

const paintSection = (styles: Partial<CSSPropertyType>) => {
	for (const [key, value] of Object.entries(styles)) {
		if (key === CSSProperty.SECTION_COLOR) {
			return 'background-color:' + value + ';';
		}
	}
	return '';
};

const elementToHTMLElement = (element: EmailElementInterface<EmailElementType>) => {
	switch (element.type) {
		case EmailElementType.BUTTON:
			const buttonEl = element as EmailElementInterface<EmailElementType.BUTTON>;
			return (
				'<a href="' +
				buttonEl.data?.link +
				'"' +
				objectToCSS(buttonEl.styles, EmailElementType.BUTTON) +
				'>' +
				buttonEl.data?.label +
				'</a>'
			);
			break;
		case EmailElementType.DIVIDER:
			return '<hr ' + objectToCSS(element.styles, element.type) + '/>';
			break;
		case EmailElementType.FOOTER:
			const footerEl = element as EmailElementInterface<EmailElementType.FOOTER>;
			let htmlToReturn = '<table style="width:50%;text-align:center;"><tr>';
			for (const social of ensure(footerEl.data?.social)) {
				htmlToReturn = htmlToReturn.concat(
					`<td><a href="${social.link}"><img src="${social.icon}" style="height:22px;width:22px"/></a></td>`
				);
			}
			htmlToReturn = htmlToReturn.concat('</tr></table>');
			return htmlToReturn;
			break;
		case EmailElementType.MEDIA:
			const mediaEl = element as EmailElementInterface<EmailElementType.MEDIA>;
			if (mediaEl.data?.caption != undefined) {
				return (
					`<table width="100%" border="0" cellspacing="0" cellpadding="0">
					<tr>
						<td align="center"><img ` +
					objectToCSS(mediaEl.styles) +
					' src="' +
					mediaEl.data.src +
					'"/><span style="color: #8b92a2;font-size: 12px;font-style: italic;">' +
					mediaEl.data.caption +
					`		</td>
						</tr>
					</table>`
				);
			} else {
				return (
					`<table width="100%" border="0" cellspacing="0" cellpadding="0">
					<tr>
						<td align="center"><img ` +
					objectToCSS(mediaEl.styles) +
					'src="' +
					mediaEl.data?.src +
					`		"/> </td>
						</tr>
					</table>`
				);
			}
			break;
		case EmailElementType.TEXT:
			return (
				'<div ' +
				objectToCSS(element.styles) +
				'>' +
				deltaToHTML(
					(element as EmailElementInterface<EmailElementType.TEXT>).data?.content
				) +
				'</div>'
			);
			break;
		case EmailElementType.SPACER:
			return '<div ' + objectToCSS(element.styles) + '/>';
			break;
		default:
			return '';
			break;
	}
};

const objectToCSS = (styles: Partial<CSSPropertyType>, elementType?: string) => {
	let stylesAsString = 'style="';
	stylesAsString = stylesAsString.concat('display:block;');
	if (elementType === EmailElementType.BUTTON)
		stylesAsString = stylesAsString.concat(
			'text-decoration: none; line-height:50px; margin: auto;'
		);
	else if (elementType === EmailElementType.DIVIDER)
		stylesAsString = stylesAsString.concat('margin: 8px auto 8px auto;');
	for (const [key, value] of Object.entries(styles)) {
		let propertyValue = value;
		if (key === CSSProperty.WIDTH) {
			propertyValue = propertyValue.toLocaleString().concat('%');
			stylesAsString = stylesAsString.concat(key + ':' + propertyValue + ';');
		} else if (key === CSSProperty.HEIGHT || key === CSSProperty.FONT_SIZE) {
			propertyValue = propertyValue.toLocaleString().concat('px');
			stylesAsString = stylesAsString.concat(key + ':' + propertyValue + ';');
		} else if (key === CSSProperty.FRAME)
			stylesAsString = stylesAsString.concat(
				'background-color:' +
					propertyValue +
					';padding:10px; box-shadow: rgb(17 17 26 / 20%) 0px 0px 8px;'
			);
		else if (key === CSSProperty.HORIZONTAL_PADDING)
			stylesAsString = stylesAsString.concat(
				'margin-left:' + propertyValue[0] + 'px; margin-right: ' + propertyValue[1] + 'px;'
			);
		else if (key === CSSProperty.VERTICAL_PADDING)
			stylesAsString = stylesAsString.concat(
				'margin-top:' + propertyValue[0] + 'px; margin-bottom: ' + propertyValue[1] + 'px;'
			);
		else if (key === CSSProperty.FLEX_WIDTH) {
		} else stylesAsString = stylesAsString.concat(key + ':' + propertyValue + ';');
	}
	stylesAsString = stylesAsString.concat('"');
	return stylesAsString;
};

const deltaToHTML = (html: string | undefined) => {
	const newHTML = html?.replace(
		new RegExp('<p>', 'g'),
		'<p style="padding:0px;margin:0px;word-break: break-word;-webkit-hyphens: none;-moz-hyphens: none;hyphens: none;border-collapse: collapse !important;">'
	);
	const newHTML2 = newHTML?.replace(
		new RegExp('www.mailtoreach.com', 'g'),
		'mailto:contact@rea.ch'
	);
	const newHTML3 = newHTML2?.replace(
		new RegExp('<h1 style="text-align:center">', 'g'),
		'<h1 style="text-align:center; padding-top: 5px; padding-bottom: 5px">'
	);
	const newHTML4 = newHTML3?.replace(
		new RegExp('<h2 style="text-align:center">', 'g'),
		'<h2 style="text-align:center; padding-top: 5px; padding-bottom: 5px">'
	);
	const newHTML5 = newHTML4?.replace(
		new RegExp('<h3 style="text-align:center">', 'g'),
		'<h3 style="text-align:center; padding-top: 5px; padding-bottom: 5px">'
	);
	const newHTML6 = newHTML5?.replace(
		new RegExp('<h1>', 'g'),
		'<h1 style="padding-top: 5px; padding-bottom: 5px">'
	);
	const newHTML7 = newHTML6?.replace(
		new RegExp('<h2>', 'g'),
		'<h2 style="padding-top: 5px; padding-bottom: 5px">'
	);
	const newHTML8 = newHTML7?.replace(
		new RegExp('<h3>', 'g'),
		'<h3 style="padding-top: 5px; padding-bottom: 5px">'
	);
	const newHTML9 = newHTML8?.replace(new RegExp('<ul>', 'g'), '<ul style="margin:0px;">');
	return newHTML9;
};
