import { ContentType, FileType } from '../files';
import { NodeInterface } from '../nodes';
import { ReachSlateElementType } from './element-type.type';
import {
	ToolbarAddBasicOptions,
	ToolbarAddMediaOptions,
	ToolbarFormatOptions,
} from './toolbar.type';

export interface NoteInterface extends NodeInterface {
	noteContent: NoteSlateItemInterface[];
	attachments: Array<NoteAttachmentInterface>;
}

export interface NotePatchInterface {
	note: NoteInterface;
	changedEdges: boolean;
}

export interface NoteAttachmentInterface {
	attachmentId: string;
	filename: string;
	size: number;
	contentType: ContentType;
	fileType: FileType;
	fileUrl: string;
	filePreviewUrl?: string;
	imageUrl?: string;
	thumbnailUrl?: string;
	mapThumbnailUrl?: string;
}

export interface NoteSlateItemInterface {
	type: ReachSlateElementType;
	baseConfig: unknown;
	children?: NoteSlateItemInterface[];
	text?: string;
}

export type BaseConfigType = {
	[ReachSlateElementType.ATTACHMENT]: {
		attachment: NoteAttachmentInterface;
	};
	[ReachSlateElementType.MENTION_CARD]: {
		id: string;
	};
	[ReachSlateElementType.MENTION_TAG]: {
		id: string;
	};
	[ReachSlateElementType.MENTION_ITEM]: {
		id: string;
	};
};

export const toolbarToBaseConfigMap = {
	[ToolbarAddBasicOptions.BULLET_LIST]: '',
	[ToolbarAddBasicOptions.CALL_OUT]: '',
	[ToolbarAddBasicOptions.CHECK_LIST]: '',
	[ToolbarAddBasicOptions.CODE]: '',
	[ToolbarAddBasicOptions.DIVIDER]: '',
	[ToolbarAddBasicOptions.EMOJI]: '',
	[ToolbarAddBasicOptions.NUMBERED_LIST]: '',
	[ToolbarAddBasicOptions.QUOTE]: '',
	[ToolbarAddMediaOptions.URL]: '',
	[ToolbarFormatOptions.HEADING]: '',
};
