import { ConfigAccumulator } from '../types';

export function mergeConfigs(
	prevConfig: ConfigAccumulator,
	nextConfig: ConfigAccumulator
): ConfigAccumulator {
	const config = {};

	Object.keys(prevConfig).forEach((configKey) => {
		config[configKey] = prevConfig[configKey];
	});

	Object.keys(nextConfig).forEach((configKey) => {
		config[configKey] = {
			...(config[configKey] || {}),
			...(nextConfig[configKey] || {}),
		};
	});

	return config;
}
