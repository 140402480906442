import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { ApiDownService } from '~app-client/api-down/services';

@Injectable({
	providedIn: 'root',
})
export class ApiDownGuard implements CanActivate {
	constructor(private apiDownService: ApiDownService, private router: Router) {}

	private createUrlTree(urls: string[]): UrlTree {
		return this.router.createUrlTree(urls, {
			queryParamsHandling: 'preserve',
		});
	}

	private guard(): Promise<boolean | UrlTree> {
		return new Promise<boolean | UrlTree>(async (resolve) => {
			const canNavigate = this.apiDownService.shouldNavigateToError;

			if (!canNavigate) {
				resolve(this.createUrlTree(['/']));
			} else {
				resolve(true);
			}
		});
	}

	canActivate(): Promise<boolean | UrlTree> {
		return this.guard();
	}
}
