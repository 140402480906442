import { SpotlightKey, SpotlightDateFilterEnum } from './spotlights.enum';

export interface Spotlight {
	id: string;

	date: Date;

	dateFilter: SpotlightDateFilterEnum;

	description: string;

	active: boolean;

	seenByCount: number;
}
export type SpotlightGetUnseenResponse = SpotlightKey[];

export type SpotlightGetResponse = Spotlight[];

type SpotlightCreationOmitProps = 'seenByCount';
export type SpotlightCreateBody = Omit<Spotlight, SpotlightCreationOmitProps>;

export type SpotlightCreateResponse = Spotlight;

type SpotlightUpdateOmitProps = 'id';
export type SpotlightUpdateBody = Partial<Omit<Spotlight, SpotlightUpdateOmitProps>>;

export type SpotlightUpdateResponse = Spotlight;

export type MarkSpotlightAsSeenBody = SpotlightIdsInterface;

export type MarkSpotlightAsSeenResponse = SpotlightKey[];

export type MarkSpotlightAsUnseenResponse = number;

export interface SpotlightIdsInterface {
	spotlightIds: string[];
}

export interface SpotlightIdInterface {
	spotlightId: string;
}
