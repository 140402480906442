export enum FileType {
	PDF = 'PDF',
	DOC = 'DOC',
	XLS = 'XLS',
	PPT = 'PPT',
	IMG = 'IMG',
	MP3 = 'MP3',
	MP4 = 'MP4',
	ZIP = 'ZIP',
	UNK = 'UNK',
	CODE = 'CODE',
	TXT = 'TXT',
}
