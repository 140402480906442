export interface PrivacyPolicyAcceptedTerms {
	ip: string;

	acceptanceDate: Date;

	privacyPolicy: PrivacyPolicyVersions;
}

export interface TermsAndConditionsAcceptedTerms {
	ip: string;

	acceptanceDate: Date;

	termsAndConditions: TermsAndConditionsVersions;
}

export interface TermsAndConditionsVersions {
	name: string;

	date: Date;
}
export interface PrivacyPolicyVersions {
	name: string;

	date: Date;
}
