import { AdminFeatureFlags } from '../user';

export enum DashboardsEnum {
	OVERVIEW = 0,
	REGISTRATION = 1,
	USER_DETAILS = 2,
	USAGE = 3,
	USAGE_INTEGRATIONS = 4,
	USAGE_QUOTA = 5,
	USAGE_NODE_DETAIL = 6,
	GAMIFICATION = 7,
	COMMUNICATION = 8,
	LEGAL = 9,
	LANDING = 10,
	BENCHMARKS = 11,
}

export interface PositioningOptions {
	x?: number;
	y?: number;
	order: number;
}

export interface InsightGroupConfiguration {
	dashboardId: DashboardsEnum;
	adminFeatureFlag: AdminFeatureFlags;
	positioningOptions: PositioningOptions;
}
