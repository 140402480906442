import { ReachSlateElementType } from '@reach/interfaces';
import { DebugLine } from '../../debug';
import { ConfigAccumulator } from '../../types';
import { CssUnit } from './types';
import { getValueUnitAndValue } from './utils';

function indentFromPadding(pixels: number): number {
	return Math.max(0, Math.min(8, Math.round(pixels / 30)));
}

export function paddingLeftFn(value: string, _debugLine?: DebugLine): ConfigAccumulator {
	const cssValue = getValueUnitAndValue(value);

	switch (cssValue?.unit) {
		case CssUnit.PX: {
			const indent = indentFromPadding(cssValue.value);
			_debugLine?.addToLine(`(indent: ${indent})`);
			return {
				[ReachSlateElementType.PARAGRAPH]: {
					indent: indent,
				},
			};
		}

		default: {
			return {};
		}
	}
}

export function paddingFn(value: string, _debugLine?: DebugLine): ConfigAccumulator {
	const values = value.split(' ').filter((str) => (str?.trim() || '') !== '');

	if (values.length === 4) {
		// top | right | bottom | left
		return paddingLeftFn(values[3], _debugLine);
	} else if (values.length === 3) {
		// top | right and left | bottom
		return paddingLeftFn(values[1], _debugLine);
	} else if (values.length === 2) {
		// top and bottom | right and left
		return paddingLeftFn(values[1], _debugLine);
	} else if (values.length === 1) {
		// top, right, bottom and left
		return paddingLeftFn(values[0], _debugLine);
	}

	return {};
}
