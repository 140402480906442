import { Injectable } from '@angular/core';
import { NodeImagesSetResponse, NodeImagesSetUrlResponse } from '@reach/interfaces';
import { BaseApiService } from '~app-client/api/_internal';

@Injectable({ providedIn: 'root' })
export class NodeImagesApiService extends BaseApiService {
	protected commonPath = ['node-pictures'];

	public setNodeImage(nodeId: number, image: File): Promise<NodeImagesSetResponse> {
		const formData = new FormData();
		formData.append('image', image);

		return this.http.post<NodeImagesSetResponse>(this.url(`${nodeId}`), formData);
	}

	public setNodeImageUrl(nodeId: string, url: string): Promise<NodeImagesSetUrlResponse> {
		return this.http.post<NodeImagesSetUrlResponse>(this.url(`${nodeId}`, 'url'), { url });
	}

	public deleteNodeImage(nodeId: number): Promise<void> {
		return this.http.delete<void>(this.url(`${nodeId}`));
	}
}
