import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, OnInit, Renderer2 } from '@angular/core';
import { TextZoom } from '@capacitor/text-zoom';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ClientService } from '~app-client/core/services';

@UntilDestroy()
@Component({
	selector: 'keyboard-reactor',
	template: ``,
	styles: [':host { display: none; } '],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [],
})
export class KeyboardReactorComponent implements OnInit {
	private readonly clientService = inject(ClientService);
	private readonly renderer = inject(Renderer2);
	private readonly document = inject(DOCUMENT);

	ngOnInit(): void {
		this.clientService.keyboardHeight$.pipe(untilDestroyed(this)).subscribe((height) => {
			this.updateKeyboardHeight(height);
		});
	}

	private updateKeyboardHeight(height: number): void {
		const kbHeightStyleId = 'kbHeightStyle';

		const queriedDoc = this.document.querySelector(`#${kbHeightStyleId}`);
		queriedDoc?.remove();

		if (height > 0) {
			const style: HTMLStyleElement = this.renderer.createElement('style');
			this.renderer.setProperty(style, 'id', kbHeightStyleId);
			style.innerText = `
			:root {
				--safe-area-inset-bottom: 0px !important;
			}
			`;
			this.renderer.appendChild(this.document.head, style);
		}
	}
}
