import { CustomPropertyCreateRequest } from '../../custom-property';
import { NodeSubtypeId } from '../../node-subtypes';

export class ZapierCreateWorkspaceActionInterface {
	name!: string;
	nodeSubtypeId?: NodeSubtypeId;
	customProperties!: CustomPropertyCreateRequest[];
	comments!: string[];
	callbackUrl!: string;
}
