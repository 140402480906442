export enum TutorialTypeEnum {
	ALL = 'all',
	INTRO = 'intro',
	SEARCH = 'search',
	VIEWS = 'views',
	INTEGRATIONS = 'integrations',
	PROFILE = 'profile',
	NODES = 'nodes',
	COLLABORATION = 'collaboration',
	EXTENSION = 'extension',
	AI = 'ai',
}
