import { YoutubeVideoInterface } from '../youtube';

export interface TutorialInterface {
	video: YoutubeVideoInterface;

	enabled: boolean;

	usersStats: UserTutorialStats[];
}

export interface UserTutorial {
	video: YoutubeVideoInterface;

	userStats: UserTutorialStats;
}

export interface UserTutorialStats {
	userId: string;
	seen: boolean;
	secondsSeen: number;
}

export interface TutorialIdsInterface {
	tutorialIds: string[];
}

export interface TutorialIdInterface {
	tutorialId: string;
}

export interface UserTutorialGetQuery {
	filter?: string;
}

export interface TutorialUserStatsUpdate extends TutorialIdInterface {
	secondsSeen: number;
}

export interface TutorialsEnabledUpdate extends TutorialIdsInterface {
	enabled: boolean;
}

export type TutorialGetResponse = TutorialInterface[];

export type TutorialGetByIdResponse = TutorialInterface;

export type TutorialGetByUserBody = UserTutorialGetQuery;

export type TutorialGetByUserResponse = UserTutorial[];

export type TutorialRetrieveResponse = TutorialInterface[];

export type TutorialDeleteBody = TutorialIdsInterface;

export type TutorialDeleteResponse = TutorialInterface[];

export type TutorialUpdateEnabledBody = TutorialsEnabledUpdate;

export type TutorialUpdateEnabledResponse = TutorialInterface[];

export type TutorialUpateUserStatsBody = TutorialUserStatsUpdate;

export type TutorialUpateUserStatsResponse = UserTutorial;
