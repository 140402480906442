import {
	ChatIntegrationBodyInterface,
	ChatIntegrationEnum,
	ChatIntegrationInterface,
} from './chat-integrations.types';

//  ██████╗██████╗ ███████╗ █████╗ ████████╗███████╗    ██╗███╗   ██╗████████╗███████╗ ██████╗ ██████╗  █████╗ ████████╗██╗ ██████╗ ███╗   ██╗
// ██╔════╝██╔══██╗██╔════╝██╔══██╗╚══██╔══╝██╔════╝    ██║████╗  ██║╚══██╔══╝██╔════╝██╔════╝ ██╔══██╗██╔══██╗╚══██╔══╝██║██╔═══██╗████╗  ██║
// ██║     ██████╔╝█████╗  ███████║   ██║   █████╗      ██║██╔██╗ ██║   ██║   █████╗  ██║  ███╗██████╔╝███████║   ██║   ██║██║   ██║██╔██╗ ██║
// ██║     ██╔══██╗██╔══╝  ██╔══██║   ██║   ██╔══╝      ██║██║╚██╗██║   ██║   ██╔══╝  ██║   ██║██╔══██╗██╔══██║   ██║   ██║██║   ██║██║╚██╗██║
// ╚██████╗██║  ██║███████╗██║  ██║   ██║   ███████╗    ██║██║ ╚████║   ██║   ███████╗╚██████╔╝██║  ██║██║  ██║   ██║   ██║╚██████╔╝██║ ╚████║
//  ╚═════╝╚═╝  ╚═╝╚══════╝╚═╝  ╚═╝   ╚═╝   ╚══════╝    ╚═╝╚═╝  ╚═══╝   ╚═╝   ╚══════╝ ╚═════╝ ╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚═╝ ╚═════╝ ╚═╝  ╚═══╝

export interface CreateChatIntegrationRequest<T extends ChatIntegrationEnum> {
	type: T;
	body: ChatIntegrationBodyInterface<T>;
}

export type CreateChatIntegrationResponse = ChatIntegrationInterface<ChatIntegrationEnum>;

//  ██████╗ ███████╗████████╗    ██╗███╗   ██╗████████╗███████╗ ██████╗ ██████╗  █████╗ ████████╗██╗ ██████╗ ███╗   ██╗
// ██╔════╝ ██╔════╝╚══██╔══╝    ██║████╗  ██║╚══██╔══╝██╔════╝██╔════╝ ██╔══██╗██╔══██╗╚══██╔══╝██║██╔═══██╗████╗  ██║
// ██║  ███╗█████╗     ██║       ██║██╔██╗ ██║   ██║   █████╗  ██║  ███╗██████╔╝███████║   ██║   ██║██║   ██║██╔██╗ ██║
// ██║   ██║██╔══╝     ██║       ██║██║╚██╗██║   ██║   ██╔══╝  ██║   ██║██╔══██╗██╔══██║   ██║   ██║██║   ██║██║╚██╗██║
// ╚██████╔╝███████╗   ██║       ██║██║ ╚████║   ██║   ███████╗╚██████╔╝██║  ██║██║  ██║   ██║   ██║╚██████╔╝██║ ╚████║
//  ╚═════╝ ╚══════╝   ╚═╝       ╚═╝╚═╝  ╚═══╝   ╚═╝   ╚══════╝ ╚═════╝ ╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚═╝ ╚═════╝ ╚═╝  ╚═══╝

export type GetChatIntegrationsResponse = ChatIntegrationInterface<ChatIntegrationEnum>[];

export type GetChatIntegrationByIdResponse = ChatIntegrationInterface<ChatIntegrationEnum>;

/*
    ██╗ ██╗    ██╗   ██╗██████╗ ██████╗  █████╗ ████████╗███████╗     ██████╗██╗  ██╗ █████╗ ████████╗    ██╗███╗   ██╗████████╗███████╗ ██████╗ ██████╗  █████╗ ████████╗██╗ ██████╗ ███╗   ██╗
   ██╔╝██╔╝    ██║   ██║██╔══██╗██╔══██╗██╔══██╗╚══██╔══╝██╔════╝    ██╔════╝██║  ██║██╔══██╗╚══██╔══╝    ██║████╗  ██║╚══██╔══╝██╔════╝██╔════╝ ██╔══██╗██╔══██╗╚══██╔══╝██║██╔═══██╗████╗  ██║
  ██╔╝██╔╝     ██║   ██║██████╔╝██║  ██║███████║   ██║   █████╗      ██║     ███████║███████║   ██║       ██║██╔██╗ ██║   ██║   █████╗  ██║  ███╗██████╔╝███████║   ██║   ██║██║   ██║██╔██╗ ██║
 ██╔╝██╔╝      ██║   ██║██╔═══╝ ██║  ██║██╔══██║   ██║   ██╔══╝      ██║     ██╔══██║██╔══██║   ██║       ██║██║╚██╗██║   ██║   ██╔══╝  ██║   ██║██╔══██╗██╔══██║   ██║   ██║██║   ██║██║╚██╗██║
██╔╝██╔╝       ╚██████╔╝██║     ██████╔╝██║  ██║   ██║   ███████╗    ╚██████╗██║  ██║██║  ██║   ██║       ██║██║ ╚████║   ██║   ███████╗╚██████╔╝██║  ██║██║  ██║   ██║   ██║╚██████╔╝██║ ╚████║
╚═╝ ╚═╝         ╚═════╝ ╚═╝     ╚═════╝ ╚═╝  ╚═╝   ╚═╝   ╚══════╝     ╚═════╝╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝       ╚═╝╚═╝  ╚═══╝   ╚═╝   ╚══════╝ ╚═════╝ ╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚═╝ ╚═════╝ ╚═╝  ╚═══╝

*/

export interface UpdateChatIntegrationRequest<T extends ChatIntegrationEnum> {
	type: T;
	body: ChatIntegrationBodyInterface<T>;
}

export type UpdateChatIntegrationResponse = ChatIntegrationInterface<ChatIntegrationEnum>;

//     ██╗ ██╗    ██████╗ ███████╗██╗     ███████╗████████╗███████╗     ██████╗██╗  ██╗ █████╗ ████████╗    ██╗███╗   ██╗████████╗███████╗ ██████╗ ██████╗  █████╗ ████████╗██╗ ██████╗ ███╗   ██╗
//    ██╔╝██╔╝    ██╔══██╗██╔════╝██║     ██╔════╝╚══██╔══╝██╔════╝    ██╔════╝██║  ██║██╔══██╗╚══██╔══╝    ██║████╗  ██║╚══██╔══╝██╔════╝██╔════╝ ██╔══██╗██╔══██╗╚══██╔══╝██║██╔═══██╗████╗  ██║
//   ██╔╝██╔╝     ██║  ██║█████╗  ██║     █████╗     ██║   █████╗      ██║     ███████║███████║   ██║       ██║██╔██╗ ██║   ██║   █████╗  ██║  ███╗██████╔╝███████║   ██║   ██║██║   ██║██╔██╗ ██║
//  ██╔╝██╔╝      ██║  ██║██╔══╝  ██║     ██╔══╝     ██║   ██╔══╝      ██║     ██╔══██║██╔══██║   ██║       ██║██║╚██╗██║   ██║   ██╔══╝  ██║   ██║██╔══██╗██╔══██║   ██║   ██║██║   ██║██║╚██╗██║
// ██╔╝██╔╝       ██████╔╝███████╗███████╗███████╗   ██║   ███████╗    ╚██████╗██║  ██║██║  ██║   ██║       ██║██║ ╚████║   ██║   ███████╗╚██████╔╝██║  ██║██║  ██║   ██║   ██║╚██████╔╝██║ ╚████║
// ╚═╝ ╚═╝        ╚═════╝ ╚══════╝╚══════╝╚══════╝   ╚═╝   ╚══════╝     ╚═════╝╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝       ╚═╝╚═╝  ╚═══╝   ╚═╝   ╚══════╝ ╚═════╝ ╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚═╝ ╚═════╝ ╚═╝  ╚═══╝

export type DeleteChatIntegrationResponse = void;
