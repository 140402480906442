import { JobMetadataInterface } from './job-metadata.interface';
import { JobStatusEnum } from './job-status.enum';
import { JobTypeEnum } from './job-type.enum';
import { NodeGroupActionsCountMap } from './node-group-actions-map.type';
import { Nullable } from '../typescript';

export interface JobInterface {
	id: string;
	pipelineId: string;
	type: JobTypeEnum;
	createdAt: Date;
	startedAt: Date | null;
	finishedAt: Date | null;
	status: JobStatusEnum;
	userId: string;
	metadata: JobMetadataInterface;
	errorCode: Nullable<string>;
}

export type JobInterfaceWithStats = JobInterface & {
	numSubJobs: number;
	nodeActions: NodeGroupActionsCountMap;
	progress: {
		numPending: number;
		numRunning: number;
		numFailures: number;
		numSuccesses: number;
	};
};
