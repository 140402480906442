import { DOCUMENT } from '@angular/common';
import { inject, Injectable, RendererFactory2 } from '@angular/core';
import * as confetti from 'canvas-confetti';

export type ConfettiOptions = confetti.Options & { delayMs?: number };

@Injectable({ providedIn: 'root' })
export class ConfettiService {
	private static randomInRange(min: number, max: number): number {
		return Math.random() * (max - min) + min;
	}

	public static get random(): ConfettiOptions {
		return {
			angle: ConfettiService.randomInRange(55, 125),
			spread: ConfettiService.randomInRange(50, 70),
			particleCount: ConfettiService.randomInRange(50, 100),
			origin: { y: ConfettiService.randomInRange(0.7, 1) },
		};
	}

	public static get wholeBottomScreen(): ConfettiOptions[] {
		return [
			{
				angle: 45,
				spread: 90,
				particleCount: 50,
				origin: { y: 1.1, x: 0 },
				startVelocity: ConfettiService.randomInRange(50, 70),
				decay: ConfettiService.randomInRange(0.8, 0.95),
				ticks: 30,
				gravity: 0.25,
			},
			{
				angle: 135,
				spread: 90,
				particleCount: 100,
				origin: { y: 1.1, x: 1 },
				startVelocity: ConfettiService.randomInRange(50, 70),
				decay: ConfettiService.randomInRange(0.8, 0.95),
				ticks: 30,
				gravity: 0.25,
			},
			{
				angle: 45,
				spread: 90,
				particleCount: 50,
				origin: { y: 1.1, x: 0 },
				startVelocity: ConfettiService.randomInRange(35, 50),
				decay: ConfettiService.randomInRange(0.8, 0.95),
				ticks: 60,
				gravity: 0.5,
				delayMs: 250,
			},
			{
				angle: 67.7,
				spread: 90,
				particleCount: 50,
				origin: { y: 1.1, x: 0.25 },
				startVelocity: ConfettiService.randomInRange(35, 50),
				decay: ConfettiService.randomInRange(0.8, 0.95),
				ticks: 60,
				gravity: 0.5,
				delayMs: 250,
			},
			{
				angle: 90,
				spread: 90,
				particleCount: 100,
				origin: { y: 1.1, x: 0.5 },
				startVelocity: ConfettiService.randomInRange(35, 50),
				decay: ConfettiService.randomInRange(0.8, 0.95),
				ticks: 60,
				gravity: 0.5,
				delayMs: 250,
			},
			{
				angle: 112.5,
				spread: 90,
				particleCount: 100,
				origin: { y: 1.1, x: 0.75 },
				startVelocity: ConfettiService.randomInRange(35, 50),
				decay: ConfettiService.randomInRange(0.8, 0.95),
				ticks: 60,
				gravity: 0.5,
				delayMs: 250,
			},
			{
				angle: 135,
				spread: 90,
				particleCount: 100,
				origin: { y: 1.1, x: 1 },
				startVelocity: ConfettiService.randomInRange(35, 50),
				decay: ConfettiService.randomInRange(0.8, 0.95),
				ticks: 60,
				gravity: 0.5,
				delayMs: 250,
			},
			{
				angle: 90,
				spread: 180,
				particleCount: 300,
				origin: { y: 1.1, x: 0.5 },
				startVelocity: ConfettiService.randomInRange(50, 80),
				decay: ConfettiService.randomInRange(0.8, 0.95),
				gravity: 1,
				delayMs: 1000,
			},
		];
	}

	private canvasCreated = false;
	private confettiCanvas?: confetti.CreateTypes;

	private readonly document = inject(DOCUMENT);

	private readonly renderer = inject(RendererFactory2).createRenderer(null, null);

	public fire(options?: ConfettiOptions | ConfettiOptions[]): Promise<void> {
		this.createCanvasIfNeeded();
		return this.execute(options);
	}

	private async execute(options?: ConfettiOptions | ConfettiOptions[]): Promise<void> {
		if (Array.isArray(options)) {
			await Promise.all(options.map((o) => this.execute(o))).then(() => {});
		} else {
			const delay = Number.isFinite(options?.delayMs)
				? Math.max(0, options?.delayMs || 0)
				: 0;
			if (delay > 0) {
				await new Promise<void>((res) => {
					window.setTimeout(async () => {
						await this.confettiCanvas?.(options || {});
						res();
					}, delay);
				});
			} else {
				await this.confettiCanvas?.(options || {});
			}
		}
	}

	private createCanvasIfNeeded(): void {
		if (!this.canvasCreated) {
			const canvas = this.renderer.createElement('canvas');
			this.renderer.setStyle(canvas, 'position', 'absolute');
			this.renderer.setStyle(canvas, 'top', '0px');
			this.renderer.setStyle(canvas, 'left', '0px');
			this.renderer.setStyle(canvas, 'width', '100%');
			this.renderer.setStyle(canvas, 'height', '100%');
			this.renderer.setStyle(canvas, 'z-index', '50');
			this.renderer.setStyle(canvas, 'pointer-events', 'none');
			this.renderer.appendChild(this.document.body, canvas);
			this.confettiCanvas = confetti.create(canvas, {
				resize: true,
				useWorker: true,
				disableForReducedMotion: true,
			});
			this.canvasCreated = true;
		}
	}
}
