import { IntegrationBaseInterface } from '../../integration-base.interface';

export interface EvernoteIntegrationInterface extends IntegrationBaseInterface {
	accessToken: string;
}

export enum EvernoteIntegrationExtraNodesEnum {
	PROPERTY = 'PROPERTY',
	NODE = 'NODE',
	NOTHING = 'NOTHING',
}
