export enum EmailTemplateEnum {
	ACTIVATE_ACCOUNT = 'activate-account',
	ACTIVATE_ACCOUNT_2 = 'activate-account2',
	ALMOST_READY = 'almost-ready',
	BIG_RELEASE_ALERT = 'big-release-alert',
	BIRTHDAY = 'birthday',
	BIRTHDAY_MONTH = 'birthday-month',
	BOOKMARKS_IMPORT_FINISHED = 'bookmarks-import-finished',
	CHRISTMAS = 'christmas',
	CUSTOMISE = 'customise',
	DOWNLOAD_APP_STORE = 'download-app-store',
	DELETE_ACCOUNT = 'delete-account',
	EMAIL_SUBSCRIPTION = 'email-subscription',
	EMAIL_SUBSCRIPTION_DAY5 = 'email-subscription-5',
	GIFT = 'gift',
	GOODBYE_PREMIUM = 'goodbye-premium',
	INVITE = 'invite',
	INVITED = 'invited',
	LEAVE = 'leave',
	LONG_TIME = 'long-time',
	MISS = 'miss',
	MOBILE = 'mobile',
	MONTHLY_WRAP_UP = 'monthly-wrap-up',
	ONE_FINAL_STEP = 'one-final-step',
	PASSWORD = 'password',
	PASSWORD_CHANGED = 'password-changed',
	PAYMENT_ERROR = 'payment_error',
	PENDING_ACTIVATION = 'pending-activation',
	PENDING_ACTIVATION_DAY5 = 'pending-activation-5',
	PLUGIN = 'plugin',
	POCKET_BOOKMARKS_IMPORT_FINISHED = 'pocket-bookmarks-import-finished',
	PHONE_CONTACTS_IMPORT_FINISHED = 'phone-contacts-import-finished',
	QUESTION = 'question',
	SEARCH = 'search',
	SEARCH_EMAIL_SUBSCRIPTION = 'search-email-subscription',
	SURVEY = 'survey',
	SURVEY2 = 'survey2',
	THANKS = 'thanks',
	TIPS = 'tips',
	TIPS2 = 'tips2',
	TWO_DAYS_LEFT = 'two-days-left',
	UPDATES1 = 'updates1',
	WELCOME = 'welcome',
}
