import { NodeType, WorkspaceType } from '@reach/interfaces';
import { ReachWorkspaceType } from '~app-client/workspaces/types';

export interface AnalyticsEventNodeCreationSuccess {
	nodeType: NodeType;
	workspaceTypes: WorkspaceType[];
}

export type AnalyticsEventNodeCreationOnTheFly = {
	nodeType: NodeType;
	workspaceTypes: ReachWorkspaceType[];
};

export interface AnalyticsEventNodeCreationAlreadyExists {
	nodeType: NodeType;
}

export interface AnalyticsEventNodeCreationWithSize {
	nodeType: NodeType;
	sizeInBytes: number;
	ellapsedTimeInMs: number;
	filesInChunk: number;

	nodeWasAlreadyCreated: boolean;
}
