import { ClientMetadataOs } from '@reach/interfaces';
import { ClientPlatform } from '~app-client/core/types';

export const platformTransformer = (raw: string): ClientPlatform => {
	switch (raw) {
		case 'ios':
			return ClientPlatform.IOS;
		case 'android':
			return ClientPlatform.ANDROID;
		default:
			return ClientPlatform.WEB;
	}
};

export class DetectOS {
	private static searchString(
		data: { string: string; subString: string; identity: ClientMetadataOs }[]
	): ClientMetadataOs {
		for (let i = 0; i < data.length; i++) {
			const dataString = data[i].string;
			if (dataString) {
				if (dataString.indexOf(data[i].subString) !== -1) {
					return data[i].identity;
				}
			}
		}

		return ClientMetadataOs.UNKNOWN;
	}

	private static dataOS = [
		{
			string: navigator.platform,
			subString: 'Win',
			identity: ClientMetadataOs.WINDOWS,
		},
		{
			string: navigator.platform,
			subString: 'Mac',
			identity: ClientMetadataOs.MAC,
		},
		{
			string: navigator.userAgent,
			subString: 'iPhone',
			identity: ClientMetadataOs.IOS,
		},
		{
			string: navigator.userAgent,
			subString: 'iPad',
			identity: ClientMetadataOs.IOS,
		},
		{
			string: navigator.userAgent,
			subString: 'iPod',
			identity: ClientMetadataOs.IOS,
		},
		{
			string: navigator.userAgent,
			subString: 'Android',
			identity: ClientMetadataOs.ANDROID,
		},
		{
			string: navigator.platform,
			subString: 'Linux',
			identity: ClientMetadataOs.LINUX,
		},
	];

	public readonly OS = DetectOS.searchString(DetectOS.dataOS);
}
