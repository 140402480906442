import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'cutArray', pure: true, standalone: true })
export class CutArrayPipe<T> implements PipeTransform {
	transform(array: T[], size: number | null): T[] {
		if (size === null) {
			return array;
		}

		return array.slice(0, size);
	}
}
