import { Injectable } from '@angular/core';
import { UnsplashApiResponse, UnsplashApiResponseResult } from '@reach/interfaces';
import { BaseApiService } from '~app-client/api/_internal';

@Injectable({ providedIn: 'root' })
export class UnsplashApiService extends BaseApiService {
	protected commonPath = ['unsplash'];

	/**
	 * **`page`** starts at 1.
	 */
	public search(query: string, size: number, page: number): Promise<UnsplashApiResponse> {
		const params = {
			query,
			page: `${page}`,
			per_page: `${size}`,
		};

		return this.http.get<UnsplashApiResponse>(this.url(), params);
	}

	/**
	 * This is used to comply with the Unsplash API Guidelines.
	 *
	 * The only thing it does is adds a +1 to the image download counter.
	 */
	public select(image: UnsplashApiResponseResult): Promise<void> {
		const body = image;
		return this.http.post<void>(this.url('select'), body);
	}
}
