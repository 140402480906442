import { Injectable } from '@angular/core';
import {
	CustomPropertyValue,
	LocationLatLonWithPrecision,
	NodeCreationSource,
	NodeInterface,
	NodeSubtypeId,
	TagCreateBody,
	TagInterface,
	TagUpdateBody,
} from '@reach/interfaces';
import { BaseApiService } from '~app-client/api/_internal';
import { ReachNodeId } from '~app-client/core/types';
import { isValidNodeId } from '~app-client/core/utils';

@Injectable({ providedIn: 'root' })
export class TagsApiService extends BaseApiService {
	protected commonPath = [];

	public checkExistence(
		name: string,
		nodeSubtypeId: NodeSubtypeId
	): Promise<{ tag: TagInterface; workspaceIds: ReachNodeId[] }[] | undefined> {
		const query = { name, nodeSubtypeId };

		return this.http.get<{ tag: TagInterface; workspaceIds: ReachNodeId[] }[] | undefined>(
			this.url('tags', 'disambiguation'),
			query
		);
	}

	public create(
		workspaceIds: ReachNodeId[],
		name: string,
		tagType: NodeSubtypeId,
		description: string,
		relatedIds: ReachNodeId[],
		source: NodeCreationSource,
		location?: LocationLatLonWithPrecision
	): Promise<TagInterface> {
		const body: TagCreateBody = {
			name,
			nodeSubtypeId: tagType,
			description,
			workspaceIds: workspaceIds.filter((id) => isValidNodeId(id)),
			edgesToCreate: relatedIds.map((id) => {
				return {
					target: id,
					directed: false,
				};
			}),
			location: location,
			source,
		} as TagCreateBody;
		return this.http.post<TagInterface>(this.url('tags'), body);
	}

	public updateCustomProperties(
		tagId: ReachNodeId,
		customProperties: CustomPropertyValue[],
		workspaceId: number
	): Promise<CustomPropertyValue[]> {
		const body = {
			customProperties,
			fatherWorkspaceId: workspaceId,
		};
		return this.http
			.patch<NodeInterface>(this.url('tags', tagId), body)
			.then((node) => node?.customProperties || []);
	}

	public edit(tagId: ReachNodeId, data: TagUpdateBody): Promise<TagInterface> {
		const body = data;
		return this.http.patch<TagInterface>(this.url('tags', tagId), body);
	}

	public changeTagType(tagId: ReachNodeId, nodeSubtypeId: NodeSubtypeId): Promise<void> {
		const body = {
			nodeIds: [tagId],
			nodeSubtypeId,
		};

		return this.http.patch<void>(this.url('nodes', 'node-subtype'), body);
	}
}
