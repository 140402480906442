import { NodeType } from '@reach/interfaces';

export interface AnalyticsEventNodeShareStart {
	nodeType: NodeType;
}

export interface AnalyticsEventNodeShareSuccess {
	nodeType: NodeType;
	shareTargetAppId: string | undefined;
}

export interface AnalyticsEventNodeShareCancel {
	nodeType: NodeType;
}
