import { DOCUMENT } from '@angular/common';
import { inject, Injectable, RendererFactory2 } from '@angular/core';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { CLIENT_ENVIRONMENT } from '~app-client/core/tokens';
import { KnownScript, KnownScriptState } from './types';
import { KNOWN_SCRIPT_DATA_MAP } from './utils';

@Injectable({ providedIn: 'root' })
export class ScriptsLoaderService {
	private readonly states$$ = new BehaviorSubject<Partial<Record<KnownScript, KnownScriptState>>>(
		{}
	);
	public readonly states$ = this.states$$.asObservable();

	private readonly document = inject(DOCUMENT);
	private readonly environment = inject(CLIENT_ENVIRONMENT);
	private readonly renderer = inject(RendererFactory2).createRenderer(null, null);

	public async loadScript(script: KnownScript): Promise<void> {
		const alreadyLoaded = this.states$$.value[script];
		if (alreadyLoaded) {
			await this.untilLoaded(script);
			return;
		}

		this.setState(script, KnownScriptState.LOADING);

		try {
			await this.createScript(script);
			this.setState(script, KnownScriptState.LOADED);
		} catch (error) {
			this.setState(script, KnownScriptState.FAILED);
			throw error;
		}
	}

	private async untilLoaded(script: KnownScript): Promise<void> {
		await firstValueFrom(
			this.states$.pipe(
				map((state) => {
					return [KnownScriptState.LOADED, KnownScriptState.FAILED].includes(
						state[script]
					);
				})
			)
		);
	}

	private async createScript(script: KnownScript): Promise<void> {
		return new Promise<void>((resolve, reject) => {
			const data = KNOWN_SCRIPT_DATA_MAP[script];

			const element: HTMLScriptElement = this.renderer.createElement('script');
			element.id = data.elementId;
			element.type = 'text/javascript';
			element.src = data.url(this.environment);
			element.onload = () => {
				resolve();
			};
			element.onerror = (error: unknown) => {
				console.error(error);
				reject(error);
			};
			// finally append the script tag in the DOM
			this.renderer.appendChild(this.document.head, element);
		});
	}

	private setState(script: KnownScript, state: KnownScriptState): void {
		this.states$$.next({
			...this.states$$.value,
			[script]: state,
		});
	}
}
