import {
	Directive,
	EmbeddedViewRef,
	inject,
	NgModule,
	OnInit,
	TemplateRef,
	ViewContainerRef,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ClientScreen, ClientService } from '../services/client';

interface ClientScreenContext {
	isDesktop: boolean;
	isMobile: boolean;
	screen: ClientScreen;
}

@UntilDestroy()
@Directive({
	selector: '[clientScreen]',
	standalone: true,
})
export class ClientScreenDirective implements OnInit {
	private readonly templateRef = inject(TemplateRef<ClientScreenContext>);
	private readonly viewContainer = inject(ViewContainerRef);
	private readonly clientService = inject(ClientService);

	constructor() {
		this.viewContainer.createEmbeddedView(this.templateRef, this.context);
	}

	ngOnInit(): void {
		this.clientService.screen$.pipe(untilDestroyed(this)).subscribe(() => {
			const viewRef = <EmbeddedViewRef<ClientScreenContext>>this.viewContainer.get(0);
			viewRef.context = this.context;
			viewRef.detectChanges();
		});
	}

	private get context(): ClientScreenContext {
		const screen = ClientService.screen;

		return {
			isDesktop: screen === ClientScreen.DESKTOP,
			isMobile: screen === ClientScreen.MOBILE,
			screen,
		};
	}
}

@UntilDestroy()
@Directive({
	selector: '[ifGivenScreen]',
	standalone: true,
})
export abstract class IfGivenScreenDirective {
	protected abstract givenScreen: ClientScreen;

	private readonly templateRef = inject(TemplateRef<ClientScreenContext>);
	private readonly viewContainer = inject(ViewContainerRef);
	private readonly clientService = inject(ClientService);

	private rendered = false;

	ngOnInit(): void {
		this.clientService.screen$.pipe(untilDestroyed(this)).subscribe(() => {
			this.afterScreenChanged();
		});
	}

	private afterScreenChanged(): void {
		const screen = ClientService.screen;
		const shouldBeVisible = screen === this.givenScreen;

		if (!shouldBeVisible) {
			this.viewContainer.clear();
			this.rendered = false;
		} else if (!this.rendered) {
			this.viewContainer.createEmbeddedView(this.templateRef);
			this.rendered = true;
		}
	}
}

@UntilDestroy()
@Directive({
	selector: '[ifDesktop]',
	standalone: true,
})
export class IfDesktopDirective extends IfGivenScreenDirective {
	protected givenScreen = ClientScreen.DESKTOP;
}

@UntilDestroy()
@Directive({
	selector: '[ifMobile]',
	standalone: true,
})
export class IfMobileDirective extends IfGivenScreenDirective {
	protected givenScreen = ClientScreen.MOBILE;
}

@NgModule({
	imports: [ClientScreenDirective, IfDesktopDirective, IfMobileDirective],
	exports: [ClientScreenDirective, IfDesktopDirective, IfMobileDirective],
})
export class ClientScreenDirectives {}
