/**
 * Geolocation interface for domain https://freegeoip.app/json/{IP}
 */
export interface GeolocationFromIpInterface {
	country_name: string;
	region_name: string;
	city: string;
}

export type GeolocationFromCoordsInterface = {
	results: Array<{ formatted_address: string; geometry: { location_type: string } }>;
};
