import { EnigmaQuestionCategoryEnum } from './enigma-question-category.enum';
import { EnigmaQuestionSubcategoriesEnum } from './enigma-question-subcategories.enum';

export interface EnigmaQuestionCreationInterface {
	category: EnigmaQuestionCategoryEnum;
	title: string;
	subcategory: EnigmaQuestionSubcategoriesEnum;
	difficulty: number;
	timeLimitMs: number;
	scatterAnswers: boolean;
	answers: EnigmaAnswerCreationInterface[];
}

export interface EnigmaAnswerCreationInterface {
	value: string;
	isCorrect: boolean;
}
