import { ErrorCodes } from '@reach/interfaces';
import { StatusCodes } from 'http-status-codes';
import { AppError } from './app-error';

export class InvalidWebhookSignatureError extends AppError {
	constructor() {
		super({
			status: StatusCodes.BAD_REQUEST,
			code: ErrorCodes.MISC_INVALID_WEBHOOK_SIGNATURE,
			title: 'The webhook signature does not match the expected one.',
			detail: 'Please check that your secret matches the provided secret.',
		});
	}
}
