import {
	ChatNodesMessageAIAnswerTypeEnum,
	ChatNodesMessageStatusEnum,
	ChatNodesMessageTypeEnum,
	ChatNodesStrategyEnum,
	ChatNodesToolEnum,
	ChatNodesToolUsageInterface,
} from '../chat-nodes';
import { Nullable } from '../typescript';
import { ChatWidgetSystemPayloadInterface } from './chat-widget-system-action.types';
import { ChatWidgetSystemTriggerEnum } from './chat-widget-system-trigger.enum';

export interface ChatWidgetBaseMessageInterface {
	id: string;
	type: ChatNodesMessageTypeEnum;
	timestamp: number;
	content: string;
	sessionId: string;
	widgetId: string;
}

export interface ChatWidgetAIMessageInterface extends ChatWidgetBaseMessageInterface {
	type: ChatNodesMessageTypeEnum.AI;
	status: ChatNodesMessageStatusEnum;
	rawContent: string;
	rating: Nullable<number>; // 0 or 1
	hasContactedSupport: boolean;
	toolsUsed: ChatNodesToolUsageInterface<ChatNodesToolEnum>[]; // Use generic tool usage interface
	systemMessage?: ChatWidgetSystemMessageInterface;
}

export interface ChatWidgetUserMessageInterface extends ChatWidgetBaseMessageInterface {
	type: ChatNodesMessageTypeEnum.USER;
	answerType?: ChatNodesMessageAIAnswerTypeEnum;
	strategy: ChatNodesStrategyEnum;
	categories: Nullable<string[]>;
	useMemory: boolean;
	originUrl: Nullable<string>;
	response: ChatWidgetAIMessageInterface;
}

export interface ChatWidgetSystemMessageInterface extends ChatWidgetBaseMessageInterface {
	type: ChatNodesMessageTypeEnum.SYSTEM;
	status: ChatNodesMessageStatusEnum;
	trigger?: ChatWidgetSystemTriggerEnum;
	category?: string;
	payload?: ChatWidgetSystemPayloadInterface;
}
