import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { SessionService } from '~app-client/core/services';

@Injectable({
	providedIn: 'root',
})
export class LogOutGuard implements CanActivate {
	private readonly sessionService = inject(SessionService);
	private readonly router = inject(Router);

	constructor() {}

	async canActivate(next: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
		let redirectUrl = '';

		try {
			redirectUrl = `${next.data?.redirectUrl}`.trim();
		} catch (error) {}

		console.debug('log-out', redirectUrl);

		try {
			await this.sessionService.logout();
		} catch (error) {}

		if (redirectUrl !== '') {
			const tree = this.router.createUrlTree([redirectUrl], {
				queryParams: next.queryParams,
				queryParamsHandling: 'merge',
			});

			return tree;
		}

		return true;
	}
}
