import { inject, Injectable, NgZone } from '@angular/core';
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import { Theme } from '@reach/interfaces';
import { BehaviorSubject, fromEvent, of } from 'rxjs';
import { distinctUntilChanged, map, startWith } from 'rxjs/operators';
import { CLIENT_ENVIRONMENT, REACH_COMMIT_HASH, REACH_VERSION } from '~app-client/core/tokens';
import { ClientScreen } from './types';
import { DetectOS, platformTransformer } from './utils';

@Injectable({ providedIn: 'root' })
export class ClientService {
	public static get screen(): ClientScreen {
		return window.visualViewport.width >= 720 ? ClientScreen.DESKTOP : ClientScreen.MOBILE;
	}

	public readonly os = new DetectOS().OS;
	public readonly isNative = Capacitor.isNativePlatform();
	public readonly platform = platformTransformer(Capacitor.getPlatform());

	private readonly ngZone = inject(NgZone);

	private readonly appFocused$$ = new BehaviorSubject(true);
	public readonly appFocused$ = this.appFocused$$.asObservable().pipe(distinctUntilChanged());

	private readonly nativeKeyboardVisible$$ = new BehaviorSubject(false);
	public readonly nativeKeyboardVisible$ = this.nativeKeyboardVisible$$.asObservable();

	private readonly keyboardHeight$$ = new BehaviorSubject(0);
	public readonly keyboardHeight$ = this.keyboardHeight$$.asObservable();

	private readonly environment = inject(CLIENT_ENVIRONMENT);
	private readonly version = inject(REACH_VERSION);
	private readonly commitHash = inject(REACH_COMMIT_HASH);

	public readonly version$ = of(this.version).pipe(
		map((version) => {
			if (this.environment.production) {
				return version;
			}

			return `${version} - ${this.commitHash}`;
		})
	);

	public readonly size$ = fromEvent(window.visualViewport, 'resize').pipe(
		startWith(''),
		map(() => {
			return window.visualViewport.width;
		})
	);

	public readonly screen$ = this.size$.pipe(
		map(() => {
			return ClientService.screen;
		}),
		distinctUntilChanged()
	);

	public readonly isDesktop$ = this.screen$.pipe(
		map((screen) => {
			return screen === ClientScreen.DESKTOP;
		})
	);

	public get browserTheme(): Theme.LIGHT | Theme.DARK {
		try {
			const prefersDarkTheme = window.matchMedia?.('(prefers-color-scheme: dark)').matches;
			return prefersDarkTheme ? Theme.DARK : Theme.LIGHT;
		} catch (error) {
			return Theme.LIGHT;
		}
	}

	constructor() {
		App.addListener('appStateChange', (state) => {
			this.ngZone.run(() => {
				this.appFocused$$.next(state.isActive);
			});
		});

		if (Capacitor.isNativePlatform()) {
			Keyboard.addListener('keyboardDidShow', (info) => {
				this.ngZone.run(() => {
					this.nativeKeyboardVisible$$.next(true);
					this.keyboardHeight$$.next(info.keyboardHeight);
				});
			});

			Keyboard.addListener('keyboardDidHide', () => {
				this.ngZone.run(() => {
					this.nativeKeyboardVisible$$.next(false);
					this.keyboardHeight$$.next(0);
				});
			});
		}
	}
}
