// https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types#types

export enum MimeTypeDiscreteType {
	APPLICATION = 'application',
	AUDIO = 'audio',
	FONT = 'font',
	IMAGE = 'image',
	MODEL = 'model',
	TEXT = 'text',
	VIDEO = 'video',
	EXAMPLE = 'example',
}

export enum MimeTypeMultipartType {
	MESSAGE = 'message',
	MULTIPART = 'multipart',
}

export type MimeTypeAllTypes =
	| keyof typeof MimeTypeDiscreteType
	| keyof typeof MimeTypeMultipartType
	| string;

export interface ContentType {
	raw: string;
	subtype: string;
	type: MimeTypeAllTypes;
}
