import { NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'enumPrettier',
	pure: true,
})
export class EnumPrettierPipe implements PipeTransform {
	transform(value: string): string {
		const indexOfLast_ = value.lastIndexOf('_');
		if (indexOfLast_ === -1) return value[0].toUpperCase() + value.substr(1).toLowerCase();

		let newString = value;

		if (!isNaN(Number(value.substring(indexOfLast_ + 1)))) {
			newString = value.substring(0, indexOfLast_);
		}
		const newString2 = newString.replace(new RegExp('_', 'g'), ' ');

		return newString2[0].toUpperCase() + newString2.substr(1).toLowerCase();
	}
}

@NgModule({
	declarations: [EnumPrettierPipe],
	exports: [EnumPrettierPipe],
})
export class EnumPrettierModule {}
