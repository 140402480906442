/**
 * Parses a environment variable and tries to transform to Integer.
 * @param key
 */
export const parseNumEnv = (key: string): number | undefined => {
	if (process.env[key]) {
		const value = parseInt(process.env[key] as string, 10);
		if (!isNaN(value)) {
			return value;
		}
		// console.error(`Key ${key} not found in enviroment. Using default.`);
	}
	// console.error(`Key ${key} not found in enviroment. Value: ${valueString}. Using default.`);
	return undefined;
};
