import { Injectable } from '@angular/core';
import {
	CreateNodeSubtypeInterface,
	CreateNodeSubtypeResponse,
	NodeSubtypeId,
	NodeSubtypeInterface,
	NodeSubtypeSettingsInterface,
	UpdateNodeSubtypeSettingsType,
} from '@reach/interfaces';
import { BaseApiService } from '~app-client/api/_internal';

@Injectable({ providedIn: 'root' })
export class NodeSubtypesApiService extends BaseApiService {
	protected commonPath = ['users', 'me'];
	protected subtypesPath = 'node-subtypes';
	protected settingsPath = 'node-subtypes-settings';

	public loadNodeSubtypes(): Promise<NodeSubtypeInterface[]> {
		return this.http.get<NodeSubtypeInterface[]>(this.url(this.subtypesPath));
	}

	public loadSettings(): Promise<NodeSubtypeSettingsInterface[]> {
		return this.http.get<NodeSubtypeSettingsInterface[]>(this.url(this.settingsPath));
	}

	public createNew(data: CreateNodeSubtypeInterface): Promise<CreateNodeSubtypeResponse> {
		return this.http.post(this.url(this.subtypesPath), data);
	}

	public updateSettings(
		id: NodeSubtypeSettingsInterface['id'],
		data: UpdateNodeSubtypeSettingsType
	): Promise<NodeSubtypeSettingsInterface> {
		return this.http.patch<NodeSubtypeSettingsInterface>(
			this.url(`${this.settingsPath}/${id}`),
			data
		);
	}

	public removeOne(id: NodeSubtypeId): Promise<void> {
		return this.http.delete<void>(this.url(`${this.subtypesPath}/${id}`));
	}
}
