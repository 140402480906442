import { Primitive } from 'ts-essentials';

function baseSortFn<T extends Primitive | Date>(
	a: T,
	b: T,
	orderBy: 'asc' | 'desc',
	compareFn: (a: T, b: T) => number
): number {
	return orderBy === 'asc' ? compareFn(a, b) : compareFn(b, a);
}

export function textualSortFn(a: string, b: string, orderBy: 'asc' | 'desc'): number {
	return baseSortFn(a, b, orderBy, (x, y) => x.localeCompare(y));
}

export function numericalSortFn(a: number, b: number, orderBy: 'asc' | 'desc'): number {
	return baseSortFn(a, b, orderBy, (x, y) => x - y);
}

export function dateSortFn(a: Date, b: Date, orderBy: 'asc' | 'desc'): number {
	return baseSortFn(a, b, orderBy, (x, y) => x.valueOf() - y.valueOf());
}
