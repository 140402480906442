import { CustomPropertyType, UnitOptionType } from '@reach/interfaces';

enum WeightExps {
	KILOGRAM = 1000,
	GRAM = 1,
	MILLIGRAM = 0.001,
}

enum DistanceExps {
	KILOMETER = 1000,
	METER = 1,
	CENTIMETER = 0.01,
	MILLIMETER = 0.001,
}
export const convertUnit = (
	type: CustomPropertyType,
	value: number,
	unitOrigin: UnitOptionType,
	unitTarget: UnitOptionType
): number => {
	let multsUnit;
	switch (type) {
		case CustomPropertyType.MEASURE_DISTANCE:
			multsUnit = DistanceExps;
			break;
		case CustomPropertyType.MEASURE_WEIGHT:
			multsUnit = WeightExps;
			break;
		case CustomPropertyType.CURRENCY:
			return value;
		default:
			throw new Error(`Unit cannot be converted for type ${type}.`);
	}
	const originMultiplier = multsUnit[unitOrigin];
	const targetMultiplier = multsUnit[unitTarget];
	return value * (originMultiplier / targetMultiplier);
};
