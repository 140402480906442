import { EdgeMetadata, EdgeProperties } from './edges.types';

export type EdgeCreateBody = Partial<Omit<EdgeProperties, 'hiddenBy' | 'type'>> &
	Omit<EdgeMetadata, 'id'>;

export interface EdgeCreateManyBody {
	edges: EdgeCreateBody[];
}

export type EdgeUpdateBody = Partial<Omit<EdgeProperties, 'hiddenBy' | 'type' | 'directed'>>;
export interface EdgeUpdateManyBody {
	edges: (EdgeUpdateBody & Pick<EdgeMetadata, 'id'>)[];
}
