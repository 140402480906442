export interface AnalyticsEventInfoModalOpened {
	infoModalKey: string;
	infoModalReason: 'tell-me-more' | 'click';
}

export interface AnalyticsEventInfoModalClosed {
	infoModalKey: string;
	infoModalCloseReason: 'done' | 'cross' | 'click-outside' | 'tutorial-clicked';
	infoModalSlidesSeenCount: number;
	infoModalSlidesSeenTotal: number;
}
