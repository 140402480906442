import { Injectable } from '@angular/core';
import { ImageUrl } from '@reach/interfaces';
import { BaseApiService } from '~app-client/api/_internal';
import { ReachNodeId } from '~app-client/core/types';

@Injectable({ providedIn: 'root' })
export class ThumbnailsApiService extends BaseApiService {
	protected commonPath = ['node-pictures'];

	public addThumbnail(nodeId: ReachNodeId, file: File): Promise<ImageUrl> {
		const formData = new FormData();
		formData.append('image', file);

		return this.http.post<ImageUrl>(this.url(nodeId), formData);
	}

	public addUrl(nodeId: ReachNodeId, url: string): Promise<ImageUrl> {
		const body = { url };
		return this.http.post<ImageUrl>(this.url(nodeId, 'url'), body);
	}

	public deleteThumbnail(nodeId: ReachNodeId): Promise<void> {
		return this.http.delete<void>(this.url(nodeId));
	}
}
