import { HttpErrorResponse } from '@angular/common/http';
import { MonoTypeOperatorFunction, Observable, of, throwError } from 'rxjs';
import { delay, mergeMap, retry, switchMap } from 'rxjs/operators';

export interface GenericRetryStrategyOptions {
	maxRetryAttempts: number;
	scalingDuration: number;
	includedStatusCodes: number[];
}

export const genericRetryStrategy = <T>(
	options?: Partial<GenericRetryStrategyOptions>
): MonoTypeOperatorFunction<T> => {
	const parsedOptions: GenericRetryStrategyOptions = {
		...{
			maxRetryAttempts: 5,
			scalingDuration: 300,
			includedStatusCodes: [0],
		},
		...options,
	};
	return (src: Observable<T>) => {
		let retries = 0;
		return src.pipe(
			retry({
				delay: (error) => {
					retries += 1;
					const delayTime = retries * parsedOptions.scalingDuration;
					return of(error).pipe(
						delay(delayTime),
						mergeMap((error) => {
							if (
								retries >= parsedOptions.maxRetryAttempts ||
								!parsedOptions.includedStatusCodes.includes(
									(error as HttpErrorResponse)?.status
								)
							) {
								return throwError(() => error);
							}
							return of(error);
						})
					);
				},
			})
		);
	};
};
