import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { normalizeString } from '@reach/utils';

@Pipe({ name: 'highlightText', pure: true })
export class HighlightTextPipe implements PipeTransform {
	transform(text: string, highlight: string): string {
		const cleanHighlight = `${highlight || ''}`.trim().toLowerCase();

		if (cleanHighlight === '') {
			return text;
		}

		const regexp = new RegExp(`(${cleanHighlight})`, 'gi');
		const ret = `${text}`.replace(regexp, '<mark>$&</mark>');

		return ret;
	}
}

@NgModule({
	declarations: [HighlightTextPipe],
	providers: [HighlightTextPipe],
	exports: [HighlightTextPipe],
})
export class HighlightTextModule {}

export interface HighlightPipeItem {
	match: boolean;
	name?: string;
}

@Pipe({
	name: 'highlight',
	pure: true,
	standalone: true,
})
export class HighlightPipe implements PipeTransform {
	public static highlight(str: string, query: string): HighlightPipeItem {
		const regex = new RegExp(query, 'gi');
		const match = regex.exec(normalizeString(str));

		if (!match || query.length === 0) {
			return { match: false, name: str };
		}

		return {
			match: true,
			name: `${str.substring(0, match.index)}<mark>${str.substring(
				match.index,
				regex.lastIndex
			)}</mark>${str.substring(regex.lastIndex)}`,
		};
	}

	transform(str: string, query: string): HighlightPipeItem {
		if (!query) {
			return { match: false, name: str };
		}

		return HighlightPipe.highlight(str, query);
	}
}
