export enum ClientMetadataSource {
	DESKTOP = 'DESKTOP',
	MOBILE = 'MOBILE',
	PLUGIN = 'PLUGIN',
}

/**
 * The source of the client (desktop, mobile, plugin, ...).
 */
export const CLIENT_METADATA_SOURCE_HEADER = 'x-client-metadata-source';

export enum ClientMetadataIsNative {
	TRUE = 'true',
	FALSE = 'false',
}

/**
 * Whether the client is native (installed on mobile) or not (web page).
 */
export const CLIENT_METADATA_IS_NATIVE_HEADER = 'x-client-metadata-is-native';

export enum ClientMetadataOs {
	UNKNOWN = 'unknown',
	IOS = 'ios',
	ANDROID = 'android',
	WINDOWS = 'windows',
	MAC = 'mac',
	LINUX = 'linux',
}

/**
 * The OS of the user.
 */
export const CLIENT_METADATA_OS_HEADER = 'x-client-metadata-os';

/**
 * The version of the client.
 */
export const CLIENT_METADATA_VERSION = 'x-client-metadata-version';
