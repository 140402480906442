import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HammerModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { HammerConfig } from '../hammer-config';
import { ReachPanDirective } from './directive';

@NgModule({
	declarations: [ReachPanDirective],
	imports: [CommonModule, HammerModule],
	providers: [
		{
			provide: HAMMER_GESTURE_CONFIG,
			useClass: HammerConfig,
		},
	],
	exports: [ReachPanDirective],
})
export class ReachPanModule {}
