import { NodeInterface } from '../nodes';
import { ContentType } from './content-type.types';

export interface FileInterface extends NodeInterface {
	// SAVED
	content?: string;
	contentType: ContentType;
	fileUrl: string;
	filePreviewUrl?: string;
}

export interface FileUpdateBody {
	name?: string;
	content?: string;
}
