import { ReachSlateElementType } from '@reach/interfaces';
import { ReachHtmlNode } from '../../html-types';
import { DebugLine } from '../debug';
import { ConfigAccumulator } from '../types';
import { FORMATS_FROM_QUILL_CLASSES } from './classes';
import { mergeConfigs } from './common';
import { getFormatFromStyles } from './styles/base';
import { FORMATS_FROM_ELEMENT_TAG } from './tags';

export function getAccConfig(currentType: ReachSlateElementType, accConfig: ConfigAccumulator) {
	return accConfig?.[currentType] || {};
}

export function getElementConfig(
	element: ReachHtmlNode,
	acc: ConfigAccumulator,
	_debugLine?: DebugLine
): ConfigAccumulator {
	let config: ConfigAccumulator = {
		...acc,
	};

	// config from html tag
	const fromTag = FORMATS_FROM_ELEMENT_TAG[element.name || '(no element name)'] || {};
	config = mergeConfigs(config, fromTag);

	// config from html class
	(element?.attribs?.class || '').split(' ').map((elementClass) => {
		const classConfig = FORMATS_FROM_QUILL_CLASSES[elementClass] || {};
		config = mergeConfigs(config, classConfig);
	});

	// config from html style
	const styleFormats = getFormatFromStyles(element, _debugLine);
	config = mergeConfigs(config, styleFormats);

	return config;
}
