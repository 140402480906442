import { Injectable } from '@angular/core';
import {
	MarkSpotlightAsSeenBody,
	SpotlightGetUnseenResponse,
	SpotlightKey,
} from '@reach/interfaces';
import { BaseApiService } from '~app-client/api/_internal';

@Injectable({ providedIn: 'root' })
export class SpotlightsApiService extends BaseApiService {
	protected commonPath = ['spotlights'];

	public getUnseenSpotlights(): Promise<SpotlightGetUnseenResponse> {
		const params = { t: this.paramTime };

		return this.http.get<SpotlightGetUnseenResponse>(this.url(''), params);
	}

	public markSpotlightsAsSeen(spotlightIds: SpotlightKey[]): Promise<void> {
		const dto: MarkSpotlightAsSeenBody = { spotlightIds };

		return this.http.post<void>(this.url(''), dto);
	}

	public markAllAsUnseenByUser(): Promise<SpotlightKey[]> {
		return this.http.post<SpotlightKey[]>(this.url('admin', 'all-unseen-by-user'));
	}
}
