import { RegisterMethod } from '../auth';
import { ClientMetadataIsNative, ClientMetadataOs, ClientMetadataSource } from '../client-metadata';
import { RankKeyEnum } from '../ranks';
import {
	AdminFeatureFlags,
	CountryCode,
	FirstAction,
	Gender,
	GeneralUse,
	Hearing,
	Interest,
	Profession,
} from '../user';

export interface StatisticTimeRangeInterface {
	from?: Date;
	to?: Date;
}

export interface StatisticNumberRangeInterface {
	min?: number;
	max?: number;
}

export interface OccurrenceStatisticsUserFilterInterface {
	countries?: CountryCode[];
	professions?: Profession[];
	cleanHearings?: Hearing[];
	interests?: Interest[];
	genders?: Gender[];
	ranks?: RankKeyEnum[];
	generalUses?: GeneralUse[];
	firstActions?: FirstAction[];
	registerMethods?: RegisterMethod[];
	registeredNatives?: ClientMetadataIsNative[];
	registeredOSs?: ClientMetadataOs[];
	registeredSources?: ClientMetadataSource[];
	adminFeatureFlags?: AdminFeatureFlags[];
	activatedAt?: StatisticTimeRangeInterface;
	registeredAt?: StatisticTimeRangeInterface;
	birthday?: StatisticTimeRangeInterface;
}
