import { ErrorCodes } from './error-codes.enum';

export interface ReachApiError {
	id: string;
	status: number;
	code: ErrorCodes;
	title: string;
	detail: string;
	meta: Record<string, unknown>;
}
