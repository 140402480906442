export enum ReachSlateElementType {
	ROOT = 'ROOT',
	TEXT = 'TEXT',
	BLOCK = 'BLOCK',
	PARAGRAPH = 'PARAGRAPH',
	HEADER = 'HEADER',
	IMAGE = 'IMAGE',
	URL = 'URL',
	EMOJI = 'EMOJI',
	DIVIDER = 'DIVIDER',
	LIST_ITEM = 'LIST_ITEM',
	LIST_CHECKBOX = 'LIST_CHECKBOX',
	LIST_ORDERED = 'LIST_ORDERED',
	LIST_UNORDERED = 'LIST_UNORDERED',
	CHECKBOX_ITEM = 'CHECKBOX_ITEM',
	QUOTE = 'QUOTE',
	CODE = 'CODE',
	CALL_OUT = 'CALL OUT',
	MENTION_CREATION = 'MENTION_CREATION',
	MENTION_ITEM = 'MENTION_ITEM',
	MENTION_TAG = 'MENTION_TAG',
	MENTION_CARD = 'MENTION_CARD',
	ATTACHMENT = 'ATTACHMENT',
	TABLE = 'TABLE',
	TABLE_ROW = 'TABLE_ROW',
	TABLE_CELL = 'TABLE_CELL',
	NODE_NAME = 'NODE_NAME',

}
