import { QuotaStatusResponse, QuotaTypeEnum, StandardQuotaTypeEnum } from '@reach/interfaces';
import { ReachQuotaStatus, ReachQuotaStatusItem } from './quota.type';

export function quotaCalculations(
	quota: Omit<ReachQuotaStatusItem, 'percentage' | 'showWarning'>
): Pick<ReachQuotaStatusItem, 'percentage' | 'showWarning'> {
	const percentage = (quota.used / quota.total) * 100;
	const showWarning = percentage >= 90;
	return {
		percentage,
		showWarning,
	};
}

export function parseResponse(response: QuotaStatusResponse): ReachQuotaStatus {
	const keys = Object.keys(response.standardQuotas) as QuotaTypeEnum[];
	const parsedResponse = keys.reduce((acc, key) => {
		const data = response.standardQuotas[key];
		const total = Number(data.total);
		const available = Number(data.available);
		const used = Number(data.used);

		const item: ReachQuotaStatusItem = {
			...data,
			total,
			available,
			used,
			percentage: 0,
			showWarning: false,
		};

		const { percentage, showWarning } = quotaCalculations(item);

		item.percentage = percentage;
		item.showWarning = showWarning;

		return {
			...acc,
			[key]: item,
		};
	}, {} as Record<StandardQuotaTypeEnum, ReachQuotaStatusItem>);
	const integrationQuotas = Object.keys(response.integrationQuotas).reduce((acc, key) => {
		const data = response.integrationQuotas[key];
		const total = Number(data.total);
		const available = Number(data.available);
		const used = Number(data.used);

		const percentage = (used / total) * 100;

		const item: ReachQuotaStatusItem = {
			...data,
			total,
			available,
			used,
			percentage,
			showWarning: percentage >= 90,
		};

		return {
			...acc,
			[key]: item,
		};
	}, {} as Record<string, ReachQuotaStatusItem>);
	return {
		standardQuotas: parsedResponse,
		integrationQuotas,
	};
}
