import { CustomPropertyCreateRequest } from '../../custom-property';
import { LocationLatLonWithPrecision } from '../../location/lat-lon.type';

export type PhoneContactType = {
	name: string;

	customProperties: CustomPropertyCreateRequest[];

	image?: string;

	companies: string[];
};

export type PhoneContactsRequestType = {
	contacts: PhoneContactType[];
	location?: LocationLatLonWithPrecision;
};
