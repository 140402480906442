import { Nullable } from '../typescript';
import { LocationLatLonWithPrecision } from '../location';
import { NodeType } from '../nodes';
import { WorkspaceInterface } from './workspace.interface';

export interface UserWorkspaces {
	trash: WorkspaceInterface;
	unassigned: { count: number };
	all: Array<WorkspaceInterface>;
}

export type AllWorkspacesResponse = UserWorkspaces;

export type WorkspaceCreatePartialBody = Pick<WorkspaceInterface, 'name'> &
	Partial<Pick<WorkspaceInterface, 'emoji'>> & {
		location?: LocationLatLonWithPrecision;
	};

export type WorkspaceCreateBody = WorkspaceCreatePartialBody;
export type WorkspaceUpdateBody = Partial<
	Pick<WorkspaceInterface, 'name'> & { emoji: Nullable<string> }
>;

export type WorkspaceStatistics = { [id in NodeType]: number };
