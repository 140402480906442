import {
	AdminFeatureFlags,
	CountryCode,
	Gender,
	Interest,
	Nullable,
	Profession,
	RankKeyEnum,
	RegisterMethod,
	UserFeatureFlags,
	UserRegisteredSource,
	UserType,
	UserWithoutSensitive,
} from '@reach/interfaces';

export interface ClientUser {
	_raw: UserWithoutSensitive;

	id: string;
	username: string;
	type: UserType;
	activatedAt: number;

	impersonating: boolean;
	registerMethod: RegisterMethod;

	featureFlags: UserFeatureFlags[];
	adminLevel: AdminFeatureFlags;

	thumbnailUrl: Nullable<string>;
	imageUrl: Nullable<string>;

	registeredSource: UserRegisteredSource;

	email: string;
	emailVerified: boolean;

	fullName: Nullable<string>;
	firstName: Nullable<string>;
	lastName: Nullable<string>;

	birthday: Nullable<Date>;
	profession: Nullable<Profession>;
	interests: Nullable<Interest[]>;
	country: Nullable<CountryCode>;
	gender: Nullable<Gender>;

	followingTeamLeader: boolean;
}
