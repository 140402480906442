import { inject, Injectable } from '@angular/core';
import { ActivatedRoute, NavigationExtras, ParamMap, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ReachStorage, StringParser, StringParserFn } from '~app-client/core/utils';
import { RoutesService } from '../routes.service';
import { QueryParamKey, QueryParams } from './query-params.types';

/**
 * Service that handles all the query parameters.
 */
@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class QueryParamsService {
	private static storageKey = '__query_params_data';

	private paramsMap$$ = new BehaviorSubject<QueryParams>({});
	public paramsMap$ = this.paramsMap$$.asObservable();

	public get className(): string {
		return 'QueryParamsService';
	}

	private paramsParserMap: { [key in QueryParamKey]: StringParserFn<QueryParams[key]> } = {
		[QueryParamKey.OPEN_WORKSPACE_ID]: StringParser.parsePositiveInt,
		[QueryParamKey.OPEN_NODE_ID]: StringParser.parsePositiveInt,
		[QueryParamKey.VISUALIZER_VIEW]: StringParser.parseString,
		[QueryParamKey.SEARCH_VIEW]: StringParser.parseString,
		[QueryParamKey.CREATION_SHARE_NODE_TYPE]: StringParser.parseString,
		[QueryParamKey.AUTH_COMPLETE_REGISTRATION_SLIDE]: StringParser.parseString,
		[QueryParamKey.AUTH_RESET_PASSWORD_VALUE]: StringParser.parseString,
		[QueryParamKey.AUTH_RESET_PASSWORD_TOKEN]: StringParser.parseString,
		[QueryParamKey.AUTH_SEND_EMAIL_TOKEN]: StringParser.parseString,
		[QueryParamKey.EMAIL]: StringParser.parseString,
		[QueryParamKey.AUTH_USER_CATEGORY]: StringParser.parseString,
		[QueryParamKey.AUTH_REFERRAL_ID]: StringParser.parseString,
		[QueryParamKey.AUTH_REFERRAL_NAME]: StringParser.parseString,
		[QueryParamKey.ACTIVATION_TOKEN]: StringParser.parseString,
	};

	private readonly router = inject(Router);
	private readonly routesService = inject(RoutesService);
	private readonly activatedRoute = inject(ActivatedRoute);

	async onStart(): Promise<void> {
		// Temporally removed because it messes up the initial routing.
		// e.g. stored foo=1 does this: localhost:4200/bar/ --> localhost:4200/?foo=1
		// const storedData = ReachStorage.getItem(StorageKey.QUERY_PARAMS_DATA);
		// await this.setInitialValues(storedData || {});

		this.activatedRoute.queryParamMap
			.pipe(
				map((params) => this.parseParams(params)),
				untilDestroyed(this)
			)
			.subscribe((params) => {
				this.paramsMap$$.next(params);
			});

		this.paramsMap$.subscribe((params) => {
			ReachStorage.setItem(QueryParamsService.storageKey, params);
		});
	}

	public getValue<T extends QueryParamKey>(key: T): Observable<QueryParams[T] | undefined> {
		return this.paramsMap$$.asObservable().pipe(
			map((params) => params[key]),
			untilDestroyed(this)
		);
	}

	public setValue<T extends QueryParamKey>(
		key: T,
		value: QueryParams[T] | null,
		options?: Omit<NavigationExtras, 'relativeTo' | 'queryParams'>
	): Promise<void> {
		return new Promise<void>(async (res) => {
			const before = this.getCurrentValue(key);

			if (before !== value) {
				await this.routesService.navigationFinished();

				await this.router.navigate([], {
					relativeTo: this.activatedRoute,
					queryParams: { [key]: value },
					queryParamsHandling: options?.queryParamsHandling || 'merge',
					...(options || {}),
				});
			}

			res();
		});
	}

	public deleteValue(key: QueryParamKey): Promise<void> {
		return this.setValue(key, null);
	}

	public getCurrentValue<T extends QueryParamKey>(key: T): QueryParams[T] | undefined {
		return this.paramsMap$$.value[key];
	}

	private parseParams(rawParams: ParamMap): QueryParams {
		return Object.values(QueryParamKey)
			.map((key: QueryParamKey) => {
				const raw = rawParams.get(key);
				const parser = this.paramsParserMap[key];
				const value = parser(raw);
				return { key, value };
			})
			.reduce((acc: QueryParams, cur) => ({ ...acc, [cur.key]: cur.value }), {});
	}

	private async setInitialValues(params: QueryParams): Promise<void> {
		const keys = Object.values(QueryParamKey);

		for (const key of keys) {
			const value = params[key];
			if (value !== undefined) {
				await this.setValue(key, value);
			}
		}
	}
}
