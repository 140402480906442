import { Injectable } from '@angular/core';
import { QuotaStatusResponse } from '@reach/interfaces';
import { BaseApiService } from '~app-client/api/_internal';

@Injectable({ providedIn: 'root' })
export class QuotaApiService extends BaseApiService {
	protected commonPath = ['quotas'];

	public getUserQuota(): Promise<QuotaStatusResponse> {
		const params = {
			t: `${this.cacheTimer}`,
		};
		return this.http.get<QuotaStatusResponse>(this.url('status'), params);
	}
}
