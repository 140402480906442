import { Injectable } from '@angular/core';
import { RestoreResponse } from '@reach/interfaces';
import { BaseApiService } from '~app-client/api/_internal';
import { ReachNodeId } from '~app-client/core/types';

@Injectable({ providedIn: 'root' })
export class TrashApiService extends BaseApiService {
	protected commonPath = ['trash'];

	public moveToTrash(ids: ReachNodeId[]): Promise<void> {
		const body = {
			nodeIds: ids || [],
		};
		return this.http.post(this.url(), body);
	}

	public restoreFromTrash(ids: ReachNodeId[]): Promise<RestoreResponse> {
		const body = {
			nodeIds: ids || [],
		};
		return this.http.post<RestoreResponse>(this.url('restore'), body);
	}

	public restoreAllFromTrash(): Promise<RestoreResponse> {
		return this.http.post<RestoreResponse>(this.url('restore-all'));
	}

	public permanentlyDeleteNodes(...ids: ReachNodeId[]): Promise<void> {
		const body = {
			nodeIds: ids || [],
		};
		return this.http.post(this.url('delete'), body);
	}

	public emptyTrash(): Promise<void> {
		return this.http.post(this.url('empty'));
	}
}
