export enum ColorDistance {
	EQUALS = 0,
	SUPER_CLOSE = 1,
	VERY_SIMILAR = 2,
	SIMILAR = 3,
	NOPE = 4,
}

export function getColorDistance(delta: number): ColorDistance {
	if (delta <= 1) {
		// no perceptible by human eyes
		return ColorDistance.EQUALS;
	} else if (delta <= 2) {
		// perceptible through close observation
		return ColorDistance.SUPER_CLOSE;
	} else if (delta <= 10) {
		// perceptible at a glance
		return ColorDistance.VERY_SIMILAR;
	} else if (delta <= 25) {
		// colors are more similar than opposite
		return ColorDistance.SIMILAR;
	}

	return ColorDistance.NOPE;
}
