export enum UserFeatureFlags {
	IMPORT_EXPORT = 'IMPORT_EXPORT',
	EXTENDED_SEARCH = 'EXTENDED_SEARCH',
	CREATE_AND_DELETE_OWN_NOTE_TEMPLATES = 'CREATE_AND_DELETE_OWN_NOTE_TEMPLATES',
	GOOGLE_DRIVE_INTEGRATION = 'GOOGLE_DRIVE_INTEGRATION',
	CHAT_WIDGET = 'CHAT_WIDGET',
	CHAT_BOT_AGENT_STRATEGY = 'CHAT_BOT_AGENT_STRATEGY', // TODO: Remove when the chat only uses agent strategy
	CHAT_INTEGRATIONS = 'CHAT_INTEGRATIONS',
	GENERATE_DOCUMENT_IMAGE_CHUNKS = 'GENERATE_DOCUMENT_IMAGE_CHUNKS',
}
