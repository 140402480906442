import { inject, Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DeepPartial, UserPreferences } from '@reach/interfaces';
import { BehaviorSubject, map, switchMap } from 'rxjs';
import { SettingsApiService } from '~app-client/api/services';
import { SessionService } from '../session';
import { SettingsUtils } from './settings.utils';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class SettingsService {
	private readonly overridenSettings$$ = new BehaviorSubject<DeepPartial<UserPreferences>>({});
	private readonly overridenSettings$ = this.overridenSettings$$.asObservable();

	private settings$$ = new BehaviorSubject<UserPreferences>(SettingsUtils.initialSettings);
	public settings$ = this.settings$$.asObservable().pipe(
		switchMap((settings) => {
			return this.overridenSettings$.pipe(
				map((overriden) => {
					return SettingsUtils.mergeWithUnknown(settings, overriden);
				})
			);
		})
	);
	public get settings(): UserPreferences {
		return this.settings$$.value;
	}

	private lastValidValues: UserPreferences = SettingsUtils.initialSettings;

	private settingsLoaded = false;

	private readonly session = inject(SessionService);
	private readonly settingsApi = inject(SettingsApiService);

	private readonly loading$$ = new BehaviorSubject<boolean>(false);
	public readonly loading$ = this.loading$$.asObservable();

	private lastLoadQueryId = 0;
	private lastLoadResponseId = 0;

	private lastChangeQueryId = 0;
	private lastChangeResponseId = 0;

	constructor() {
		this.session.loggedOut$.pipe(untilDestroyed(this)).subscribe(() => {
			this.reset();
		});
	}

	public async load(force = false): Promise<void> {
		try {
			if (force || !this.settingsLoaded) {
				const queryId = ++this.lastLoadQueryId;

				const response = await this.settingsApi.get();

				if (queryId < this.lastLoadResponseId) {
					throw 'Request responded after newer one';
				}

				this.lastLoadResponseId = queryId;

				this.setSettings(response, true);
			}
		} catch (error) {
			console.error(error);
			throw error;
		}
	}

	public overrideSettigns(settings: DeepPartial<UserPreferences>): void {
		this.overridenSettings$$.next(settings);
	}

	public async changeValue(changes: DeepPartial<UserPreferences>): Promise<UserPreferences> {
		try {
			const queryId = ++this.lastChangeQueryId;
			const beforeCallSettings = this.setSettings(changes, false);

			this.loading$$.next(true);

			const response = await this.settingsApi.update(changes);

			if (queryId < this.lastChangeQueryId) {
				return beforeCallSettings;
			}

			const settings = this.setSettings(response, true);
			return settings;
		} catch (error) {
			console.error(error);
			// revert to last
			this.setSettings(this.lastValidValues, false);
			throw error;
		} finally {
			this.loading$$.next(false);
		}
	}

	private reset(): void {
		this.settingsLoaded = false;
		const currentSettings = SettingsUtils.defaultSettings;
		this.lastValidValues = currentSettings;
		this.settings$$.next(currentSettings);
	}

	private setSettings(
		settings: DeepPartial<UserPreferences>,
		setAsValid: boolean
	): UserPreferences {
		const cleanSettings = SettingsUtils.mergeWithUnknown(this.settings, settings);
		this.settings$$.next(cleanSettings);
		if (setAsValid) {
			this.lastValidValues = cleanSettings;
			SettingsUtils.storeSettings(cleanSettings);
		}

		return cleanSettings;
	}
}
