import { GetNodeResponse, NodeInterface } from '../nodes';
import { NodeSubtypeEnum } from '../node-subtypes';
import { NodeType } from '../nodes';

export interface NodeRecommendationItem {
	nodeType: NodeType;
	nodeSubtype: NodeSubtypeEnum;
	text: string;
}

export interface NodesRecommendationResponse {
	existingNodes: NodeInterface[];
	newCreationTags: NodeRecommendationItem[];
}

export interface SimilarityRecommendationsResponse {
	similarNodes: SimilarityRecommendationNode[];
	recommendedNodes?: GetNodeResponse[];
}

export interface SimilarityRecommendationNode {
	node: NodeInterface;
	score: number;
}
