// move blocks out
// trim json
// add paragraphs to some items
// add href to empty text URLs

import { NoteSlateItemInterface, ReachSlateElementType } from '@reach/interfaces';

const BLOCK_TYPES: ReachSlateElementType[] = [
	ReachSlateElementType.ATTACHMENT,
	ReachSlateElementType.CALL_OUT,
	ReachSlateElementType.CODE,
	ReachSlateElementType.DIVIDER,
	ReachSlateElementType.HEADER,
	ReachSlateElementType.LIST_CHECKBOX,
	ReachSlateElementType.LIST_ORDERED,
	ReachSlateElementType.LIST_UNORDERED,
	ReachSlateElementType.PARAGRAPH,
	ReachSlateElementType.QUOTE,
	ReachSlateElementType.TABLE,
	ReachSlateElementType.TABLE_ROW,
	ReachSlateElementType.TABLE_CELL,
];

function isBlock(type: ReachSlateElementType): boolean {
	return BLOCK_TYPES.includes(type);
}

const ALLOWED_BLOCKS: Partial<Record<ReachSlateElementType, ReachSlateElementType[]>> = {
	[ReachSlateElementType.LIST_CHECKBOX]: [ReachSlateElementType.CHECKBOX_ITEM],
	[ReachSlateElementType.LIST_ORDERED]: [ReachSlateElementType.CHECKBOX_ITEM],
	[ReachSlateElementType.LIST_UNORDERED]: [ReachSlateElementType.CHECKBOX_ITEM],
	[ReachSlateElementType.TABLE]: [ReachSlateElementType.TABLE_ROW],
	[ReachSlateElementType.TABLE_ROW]: [ReachSlateElementType.TABLE_CELL],
	[ReachSlateElementType.TABLE_CELL]: [ReachSlateElementType.PARAGRAPH],
};
function canBeChild(parent: ReachSlateElementType, child: ReachSlateElementType): boolean {
	const childIsBlock = isBlock(child);
	if (!childIsBlock) {
		return true;
	}

	return ALLOWED_BLOCKS[parent]?.includes(child) || false;
}

const WRAP_CONTENT_IN: Partial<Record<ReachSlateElementType, ReachSlateElementType>> = {
	[ReachSlateElementType.TABLE_CELL]: ReachSlateElementType.PARAGRAPH,
};

const REMOVE_TEXT_FROM: ReachSlateElementType[] = [
	ReachSlateElementType.TABLE,
	ReachSlateElementType.TABLE_ROW,
];

/**
 * Normalizes block elements
 */
function normalizeBlocks(item: NoteSlateItemInterface): NoteSlateItemInterface[] {
	const children = (item.children || []).reduce((acc, child) => {
		acc.push(...normalizeBlocks(child));
		return acc;
	}, [] as NoteSlateItemInterface[]);

	if (children.length === 0) {
		return [item];
	}

	const itemWithoutChildren = () => ({
		type: item.type,
		baseConfig: item.baseConfig,
		children: [],
	});
	const ret: NoteSlateItemInterface[] = [];
	let addToPrev = false;
	for (const child of children) {
		const allowedChild = canBeChild(item.type, child.type);

		if (allowedChild) {
			if (!addToPrev) {
				ret.push(itemWithoutChildren());
			}

			ret[ret.length - 1].children?.push(child);
		} else {
			ret.push(child);
		}

		addToPrev = allowedChild;
	}

	return ret;
}

const CHECK_FOR_EMPTY_CHILDREN = [
	ReachSlateElementType.PARAGRAPH,
	ReachSlateElementType.TABLE,
	ReachSlateElementType.TABLE_ROW,
	ReachSlateElementType.TABLE_CELL,
];

function itemIsEmpty(item: NoteSlateItemInterface): boolean {
	if (CHECK_FOR_EMPTY_CHILDREN.includes(item.type)) {
		return (item.children || []).reduce((acc, child) => {
			return acc && itemIsEmpty(child);
		}, true);
	} else if (item.type === ReachSlateElementType.TEXT) {
		return (item.text || '').trim() === '';
	} else {
		return false;
	}
}

/**
 * Trims the note until it founds a non-empty item.
 */
function trimNote(note: NoteSlateItemInterface[]): NoteSlateItemInterface[] {
	// trim start
	let continueCheckingStart = true;
	while (continueCheckingStart) {
		const item = note[0];

		if (!item) {
			continueCheckingStart = false;
		} else {
			const isEmpty = itemIsEmpty(item);
			continueCheckingStart = isEmpty;
			if (isEmpty) {
				note.shift();
			}
		}
	}

	// trim end
	let continueCheckingEnd = true;
	while (continueCheckingEnd) {
		const item = note[note.length - 1] || null;

		if (!item) {
			continueCheckingEnd = false;
		} else {
			const isEmpty = itemIsEmpty(item);
			continueCheckingEnd = isEmpty;
			if (isEmpty) {
				note.pop();
			}
		}
	}

	return note;
}

/**
 * Trims texts
 */
function trimTexts(item: NoteSlateItemInterface): void {
	item.children?.forEach(trimTexts);

	if (item.type === ReachSlateElementType.TEXT) {
		const text = item.text || '';
		if (text.trim() === '' && !text.includes('\n') && !text.includes('\t')) {
			item.text = '';
		}
	} else {
		if (
			item.type !== ReachSlateElementType.CODE &&
			item.children?.length === 1 &&
			item.children[0].type === ReachSlateElementType.TEXT
		) {
			const text = item.children[0].text || '';
			item.children[0].text = text.trim();
			return;
		}

		const aux = (item.children || []).reduce(
			(acc, child) => {
				acc.allTexts = acc.allTexts && child.type === ReachSlateElementType.TEXT;
				acc.text += child.text || '';
				return acc;
			},
			{ allTexts: true, text: '' }
		);

		if (aux.allTexts && aux.text.trim() === '' && item.children && item.children.length > 0) {
			item.children = [item.children[0]];
		}
	}

	return;
}

function removeEmptyTables(note: NoteSlateItemInterface[]): NoteSlateItemInterface[] {
	return note.filter((child) => {
		if (child.type === ReachSlateElementType.TABLE) {
			return !itemIsEmpty(child);
		}
		return true;
	});
}

/**
 * If an URL doesn't have a text, adds the href as its text.
 */
function normalizeUrls(item: NoteSlateItemInterface): void {
	if (item.type === ReachSlateElementType.URL) {
		if ((item.children?.length || 0) === 0) {
			item.children = [
				{
					type: ReachSlateElementType.TEXT,
					baseConfig: {},
					text: (item.baseConfig as { href: string }).href,
				},
			];
		}
	}

	item.children?.forEach(normalizeUrls);
}

/**
 * Removes texts from items that do not support text items
 */
function removeTextIfNeccessary(item: NoteSlateItemInterface): void {
	const removeText = REMOVE_TEXT_FROM.includes(item.type);

	if (removeText) {
		item.children = (item.children || []).filter(
			({ type }) => type !== ReachSlateElementType.TEXT
		);
	}

	item.children?.forEach(removeTextIfNeccessary);
}

/**
 * Wraps children into an auxiliary item
 */
function wrapItemsIfNecessary(item: NoteSlateItemInterface): void {
	const wrapItemType = WRAP_CONTENT_IN[item.type];

	if (!!wrapItemType) {
		if (item.children?.length !== 1 && item.children?.[0]?.type !== wrapItemType) {
			item.children = [
				{
					type: wrapItemType,
					baseConfig: {},
					children: item.children || [],
				},
			];
		}
	}

	item.children?.forEach(wrapItemsIfNecessary);
}

/**
 * Removes empty text if the previous item also was an empty text
 */
function removeConsecutiveEmptyTexts(item: NoteSlateItemInterface): void {
	if (item.type === ReachSlateElementType.TEXT) {
		return;
	}

	const children = item.children || [];
	children.forEach(removeConsecutiveEmptyTexts);
	const newChildren: NoteSlateItemInterface[] = [];

	let prevWasEmptyText = false;
	for (const child of children) {
		const currentIsEmptyText =
			child.type === ReachSlateElementType.TEXT && `${child.text || ''}` === '';
		if (!(prevWasEmptyText && currentIsEmptyText)) {
			newChildren.push(child);
		}
		prevWasEmptyText = currentIsEmptyText;
	}

	item.children = newChildren;
}

export function normalizeNote(note: NoteSlateItemInterface[]): NoteSlateItemInterface[] {
	note.forEach(removeTextIfNeccessary);

	note.forEach(wrapItemsIfNecessary);

	note.forEach(trimTexts);

	note = note.reduce((acc, item) => {
		acc.push(...normalizeBlocks(item));
		return acc;
	}, [] as NoteSlateItemInterface[]);

	note.forEach(normalizeUrls);

	note.forEach(removeConsecutiveEmptyTexts);

	note = removeEmptyTables(note);

	note.forEach(trimTexts);

	note = trimNote(note);

	return note;
}
