export enum FirstAction {
	FILE = 'FILE',
	SCANNED_DOCUMENT = 'SCANNED_DOCUMENT',
	WEBSITE = 'WEBSITE',
	YOUTUBE_VIDEO = 'YOUTUBE_VIDEO',
	QUICK_NOTE = 'QUICK_NOTE',
	AUDIO = 'AUDIO',
	INTEGRATION_GOOGLE_DRIVE = 'INTEGRATION_GOOGLE_DRIVE',
	INTEGRATION_EVERNOTE = 'INTEGRATION_EVERNOTE',
}
