export interface AnalyticsEventOpenMobileMenuShown {
	openMobileMenuBrowserOption: string;
}

export interface AnalyticsEventOpenMobileMenuSuccess {
	openMobileMenuBrowserOption: string;
}

export interface AnalyticsEventOpenMobileMenuCanceled {
	openMobileMenuBrowserOption: string;
}
