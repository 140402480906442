import { NoteSlateItemInterface } from '../notes';
import { UserPublicInfo } from '../user';

export interface CommentBase {
	id: string;
	authorUserId: string;
	comment: string;
	structuredComment: NoteSlateItemInterface[];
	creationDate: number;
}

export type CommentWithAuthor = Omit<CommentBase, 'authorUserId'> & {
	author: UserPublicInfo;
	editable: boolean;
};

export type CommentCreateResponse = CommentWithAuthor[];
export type CommentGetResponse = CommentWithAuthor[];
export type CommentUpdateResponse = CommentWithAuthor;

export interface CommentContentBody {
	comment: NoteSlateItemInterface[];
}
