// https://pastebin.com/b6R3KQkG
// https://pastebin.com/bVtgPXnZ
/** https://api.wikimedia.org/wiki/Special:SiteMatrix */
export enum WikimediaLanguageCodeEnum {
	AB_ABKHAZIAN = 'ab',
	ACE_ACHINESE = 'ace',
	ADY_ADYGHE = 'ady',
	AF_AFRIKAANS = 'af',
	AK_AKAN = 'ak',
	ALS_ALEMANNISCH = 'als',
	ALT_SOUTHERN_ALTAI = 'alt',
	AMI_AMIS = 'ami',
	AM_AMHARIC = 'am',
	ANG_OLD_ENGLISH = 'ang',
	AN_ARAGONESE = 'an',
	ARC_ARAMAIC = 'arc',
	ARY_MOROCCAN_ARABIC = 'ary',
	ARZ_EGYPTIAN_ARABIC = 'arz',
	AR_ARABIC = 'ar',
	AST_ASTURIAN = 'ast',
	AS_ASSAMESE = 'as',
	ATJ_ATIKAMEKW = 'atj',
	AVK_KOTAVA = 'avk',
	AV_AVARIC = 'av',
	AWA_AWADHI = 'awa',
	AY_AYMARA = 'ay',
	AZB_SOUTH_AZERBAIJANI = 'azb',
	AZ_AZERBAIJANI = 'az',
	BAN_BALINESE = 'ban',
	BAR_BAVARIAN = 'bar',
	BAT_SMG_SAMOGITIAN = 'bat-smg',
	BA_BASHKIR = 'ba',
	BCL_CENTRAL_BIKOL = 'bcl',
	BE_BELARUSIAN = 'be',
	BE_TARASK_BELARUSIAN_TARASKIEVICA_ORTHOGRAPHY = 'be-tarask',
	BE_X_OLD_BELARUSIAN_TARASKIEVICA_ORTHOGRAPHY = 'be-x-old',
	BG_BULGARIAN = 'bg',
	BH_BHOJPURI = 'bh',
	BI_BISLAMA = 'bi',
	BJN_BANJAR = 'bjn',
	BLK_PAO = 'blk',
	BM_BAMBARA = 'bm',
	BN_BANGLA = 'bn',
	BO_TIBETAN = 'bo',
	BPY_BISHNUPRIYA = 'bpy',
	BR_BRETON = 'br',
	BS_BOSNIAN = 'bs',
	BUG_BUGINESE = 'bug',
	BXR_RUSSIA_BURIAT = 'bxr',
	CA_CATALAN = 'ca',
	CBK_ZAM_CHAVACANO = 'cbk-zam',
	CDO_MIN_DONG_CHINESE = 'cdo',
	CEB_CEBUANO = 'ceb',
	CE_CHECHEN = 'ce',
	CHR_CHEROKEE = 'chr',
	CHY_CHEYENNE = 'chy',
	CH_CHAMORRO = 'ch',
	CKB_CENTRAL_KURDISH = 'ckb',
	CO_CORSICAN = 'co',
	CRH_CRIMEAN_TATAR = 'crh',
	CR_CREE = 'cr',
	CSB_KASHUBIAN = 'csb',
	CS_CZECH = 'cs',
	CU_CHURCH_SLAVIC = 'cu',
	CV_CHUVASH = 'cv',
	CY_WELSH = 'cy',
	DAG_DAGBANI = 'dag',
	DA_DANISH = 'da',
	DE_GERMAN = 'de',
	DIN_DINKA = 'din',
	DIQ_ZAZAKI = 'diq',
	DSB_LOWER_SORBIAN = 'dsb',
	DTY_DOTELI = 'dty',
	DV_DIVEHI = 'dv',
	DZ_DZONGKHA = 'dz',
	EE_EWE = 'ee',
	EL_GREEK = 'el',
	EML_EMILIANO_ROMAGNOLO = 'eml',
	EN_ENGLISH = 'en',
	EO_ESPERANTO = 'eo',
	ES_SPANISH = 'es',
	ET_ESTONIAN = 'et',
	EU_BASQUE = 'eu',
	EXT_EXTREMADURAN = 'ext',
	FA_PERSIAN = 'fa',
	FF_FULAH = 'ff',
	FIU_VRO_VORO = 'fiu-vro',
	FI_FINNISH = 'fi',
	FJ_FIJIAN = 'fj',
	FO_FAROESE = 'fo',
	FRP_ARPITAN = 'frp',
	FRR_NORTHERN_FRISIAN = 'frr',
	FR_FRENCH = 'fr',
	FUR_FRIULIAN = 'fur',
	FY_WESTERN_FRISIAN = 'fy',
	GAG_GAGAUZ = 'gag',
	GAN_GAN_CHINESE = 'gan',
	GA_IRISH = 'ga',
	GCR_GUIANAN_CREOLE = 'gcr',
	GD_SCOTTISH_GAELIC = 'gd',
	GLK_GILAKI = 'glk',
	GL_GALICIAN = 'gl',
	GN_GUARANI = 'gn',
	GOM_GOAN_KONKANI = 'gom',
	GOR_GORONTALO = 'gor',
	GOT_GOTHIC = 'got',
	GUW_GUN = 'guw',
	GU_GUJARATI = 'gu',
	GV_MANX = 'gv',
	HAK_HAKKA_CHINESE = 'hak',
	HAW_HAWAIIAN = 'haw',
	HA_HAUSA = 'ha',
	HE_HEBREW = 'he',
	HIF_FIJI_HINDI = 'hif',
	HI_HINDI = 'hi',
	HR_CROATIAN = 'hr',
	HSB_UPPER_SORBIAN = 'hsb',
	HT_HAITIAN_CREOLE = 'ht',
	HU_HUNGARIAN = 'hu',
	HYW_WESTERN_ARMENIAN = 'hyw',
	HY_ARMENIAN = 'hy',
	IA_INTERLINGUA = 'ia',
	ID_INDONESIAN = 'id',
	IE_INTERLINGUE = 'ie',
	IG_IGBO = 'ig',
	IK_INUPIAQ = 'ik',
	ILO_ILOKO = 'ilo',
	INH_INGUSH = 'inh',
	IO_IDO = 'io',
	IS_ICELANDIC = 'is',
	IT_ITALIAN = 'it',
	IU_INUKTITUT = 'iu',
	JAM_JAMAICAN_CREOLE_ENGLISH = 'jam',
	JA_JAPANESE = 'ja',
	JBO_LOJBAN = 'jbo',
	JV_JAVANESE = 'jv',
	KAA_KARA_KALPAK = 'kaa',
	KAB_KABYLE = 'kab',
	KA_GEORGIAN = 'ka',
	KBD_KABARDIAN = 'kbd',
	KBP_KABIYE = 'kbp',
	KCG_TYAP = 'kcg',
	KG_KONGO = 'kg',
	KI_KIKUYU = 'ki',
	KK_KAZAKH = 'kk',
	KL_KALAALLISUT = 'kl',
	KM_KHMER = 'km',
	KN_KANNADA = 'kn',
	KOI_KOMI_PERMYAK = 'koi',
	KO_KOREAN = 'ko',
	KRC_KARACHAY_BALKAR = 'krc',
	KSH_COLOGNIAN = 'ksh',
	KS_KASHMIRI = 'ks',
	KU_KURDISH = 'ku',
	KV_KOMI = 'kv',
	KW_CORNISH = 'kw',
	KY_KYRGYZ = 'ky',
	LAD_LADINO = 'lad',
	LA_LATIN = 'la',
	LBE_LAK = 'lbe',
	LB_LUXEMBOURGISH = 'lb',
	LEZ_LEZGHIAN = 'lez',
	LFN_LINGUA_FRANCA_NOVA = 'lfn',
	LG_GANDA = 'lg',
	LIJ_LIGURIAN = 'lij',
	LI_LIMBURGISH = 'li',
	LLD_LADIN = 'lld',
	LMO_LOMBARD = 'lmo',
	LN_LINGALA = 'ln',
	LO_LAO = 'lo',
	LTG_LATGALIAN = 'ltg',
	LT_LITHUANIAN = 'lt',
	LV_LATVIAN = 'lv',
	MAD_MADURESE = 'mad',
	MAI_MAITHILI = 'mai',
	MAP_BMS_BASA_BANYUMASAN = 'map-bms',
	MDF_MOKSHA = 'mdf',
	MG_MALAGASY = 'mg',
	MHR_EASTERN_MARI = 'mhr',
	MIN_MINANGKABAU = 'min',
	MI_MAORI = 'mi',
	MK_MACEDONIAN = 'mk',
	ML_MALAYALAM = 'ml',
	MNI_MANIPURI = 'mni',
	MNW_MON = 'mnw',
	MN_MONGOLIAN = 'mn',
	MO_MOLDOVAN = 'mo',
	MRJ_WESTERN_MARI = 'mrj',
	MR_MARATHI = 'mr',
	MS_MALAY = 'ms',
	MT_MALTESE = 'mt',
	MWL_MIRANDESE = 'mwl',
	MYV_ERZYA = 'myv',
	MY_BURMESE = 'my',
	MZN_MAZANDERANI = 'mzn',
	NAH_NAHUATL = 'nah',
	NAP_NEAPOLITAN = 'nap',
	NA_NAURU = 'na',
	NDS_LOW_GERMAN = 'nds',
	NDS_NL_LOW_SAXON = 'nds-nl',
	NEW_NEWARI = 'new',
	NE_NEPALI = 'ne',
	NIA_NIAS = 'nia',
	NL_DUTCH = 'nl',
	NN_NORWEGIAN_NYNORSK = 'nn',
	NOV_NOVIAL = 'nov',
	NO_NORWEGIAN = 'no',
	NQO_NKO = 'nqo',
	NRM_NORMAN = 'nrm',
	NSO_NORTHERN_SOTHO = 'nso',
	NV_NAVAJO = 'nv',
	NY_NYANJA = 'ny',
	OC_OCCITAN = 'oc',
	OLO_LIVVI_KARELIAN = 'olo',
	OM_OROMO = 'om',
	OR_ODIA = 'or',
	OS_OSSETIC = 'os',
	PAG_PANGASINAN = 'pag',
	PAM_PAMPANGA = 'pam',
	PAP_PAPIAMENTO = 'pap',
	PA_PUNJABI = 'pa',
	PCD_PICARD = 'pcd',
	PCM_NIGERIAN_PIDGIN = 'pcm',
	PDC_PENNSYLVANIA_GERMAN = 'pdc',
	PFL_PALATINE_GERMAN = 'pfl',
	PIH_NORFUK_PITKERN = 'pih',
	PI_PALI = 'pi',
	PL_POLISH = 'pl',
	PMS_PIEDMONTESE = 'pms',
	PNB_WESTERN_PUNJABI = 'pnb',
	PNT_PONTIC = 'pnt',
	PS_PASHTO = 'ps',
	PT_PORTUGUESE = 'pt',
	PWN_PAIWAN = 'pwn',
	QU_QUECHUA = 'qu',
	RMY_VLAX_ROMANI = 'rmy',
	RM_ROMANSH = 'rm',
	RN_RUNDI = 'rn',
	ROA_RUP_AROMANIAN = 'roa-rup',
	ROA_TARA_TARANTINO = 'roa-tara',
	RO_ROMANIAN = 'ro',
	RUE_RUSYN = 'rue',
	RU_RUSSIAN = 'ru',
	RW_KINYARWANDA = 'rw',
	SAH_SAKHA = 'sah',
	SAT_SANTALI = 'sat',
	SA_SANSKRIT = 'sa',
	SCN_SICILIAN = 'scn',
	SCO_SCOTS = 'sco',
	SC_SARDINIAN = 'sc',
	SD_SINDHI = 'sd',
	SE_NORTHERN_SAMI = 'se',
	SG_SANGO = 'sg',
	SHI_TACHELHIT = 'shi',
	SHN_SHAN = 'shn',
	SHY_SHAWIYA = 'shy',
	SH_SERBO_CROATIAN = 'sh',
	SIMPLE_SIMPLE_ENGLISH = 'simple',
	SI_SINHALA = 'si',
	SKR_SARAIKI = 'skr',
	SK_SLOVAK = 'sk',
	SL_SLOVENIAN = 'sl',
	SMN_INARI_SAMI = 'smn',
	SM_SAMOAN = 'sm',
	SN_SHONA = 'sn',
	SO_SOMALI = 'so',
	SQ_ALBANIAN = 'sq',
	SRN_SRANAN_TONGO = 'srn',
	SR_SERBIAN = 'sr',
	SS_SWATI = 'ss',
	STQ_SATERLAND_FRISIAN = 'stq',
	ST_SOUTHERN_SOTHO = 'st',
	SU_SUNDANESE = 'su',
	SV_SWEDISH = 'sv',
	SW_SWAHILI = 'sw',
	SZL_SILESIAN = 'szl',
	SZY_SAKIZAYA = 'szy',
	TAY_TAYAL = 'tay',
	TA_TAMIL = 'ta',
	TCY_TULU = 'tcy',
	TET_TETUM = 'tet',
	TE_TELUGU = 'te',
	TG_TAJIK = 'tg',
	TH_THAI = 'th',
	TI_TIGRINYA = 'ti',
	TK_TURKMEN = 'tk',
	TL_TAGALOG = 'tl',
	TN_TSWANA = 'tn',
	TO_TONGAN = 'to',
	TPI_TOK_PISIN = 'tpi',
	TRV_TAROKO = 'trv',
	TR_TURKISH = 'tr',
	TS_TSONGA = 'ts',
	TT_TATAR = 'tt',
	TUM_TUMBUKA = 'tum',
	TW_TWI = 'tw',
	TYV_TUVINIAN = 'tyv',
	TY_TAHITIAN = 'ty',
	UDM_UDMURT = 'udm',
	UG_UYGHUR = 'ug',
	UK_UKRAINIAN = 'uk',
	UR_URDU = 'ur',
	UZ_UZBEK = 'uz',
	VEC_VENETIAN = 'vec',
	VEP_VEPS = 'vep',
	VE_VENDA = 've',
	VI_VIETNAMESE = 'vi',
	VLS_WEST_FLEMISH = 'vls',
	VO_VOLAPUK = 'vo',
	WAR_WARAY = 'war',
	WA_WALLOON = 'wa',
	WO_WOLOF = 'wo',
	WUU_WU_CHINESE = 'wuu',
	XAL_KALMYK = 'xal',
	XH_XHOSA = 'xh',
	XMF_MINGRELIAN = 'xmf',
	YI_YIDDISH = 'yi',
	YO_YORUBA = 'yo',
	YUE_CANTONESE = 'yue',
	ZA_ZHUANG = 'za',
	ZEA_ZEELANDIC = 'zea',
	ZH_CHINESE = 'zh',
	ZH_CLASSICAL_CLASSICAL_CHINESE = 'zh-classical',
	ZH_MIN_NAN_CHINESE_MIN_NAN = 'zh-min-nan',
	ZH_YUE_CANTONESE = 'zh-yue',
	ZU_ZULU = 'zu',
}
