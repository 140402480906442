import { DOCUMENT } from '@angular/common';
import { Directive, ElementRef, inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';

@Directive({
	selector: '[moveToRoot]',
	standalone: true,
})
export class MoveToRootDirective<T = HTMLElement> implements OnInit, OnDestroy {
	protected readonly elementRef = inject<ElementRef<T>>(ElementRef);
	protected readonly document = inject(DOCUMENT);
	protected readonly renderer = inject(Renderer2);

	ngOnInit(): void {
		this.renderer.appendChild(this.document.body, this.elementRef.nativeElement);
	}

	ngOnDestroy(): void {
		this.renderer.removeChild(this.document.body, this.elementRef.nativeElement);
	}
}
