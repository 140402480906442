import { OverlayModule } from '@angular/cdk/overlay';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatNativeDateModule } from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideHotToastConfig } from '@ngneat/hot-toast';
import { UntilDestroy } from '@ngneat/until-destroy';
import { MarkdownModule } from 'ngx-markdown';
import { REACH_API_COMMON_HEADERS } from '~app-client-item/api/tokens/common-headers';
import { AnalyticsService, FirebaseDeviceService } from '~app-client/core/services';
import {
	CLIENT_ENVIRONMENT,
	CommitHash,
	REACH_COMMIT_HASH,
	REACH_VERSION,
	ReachVersion,
} from '~app-client/core/tokens';
import {
	ApiDownInterceptor,
	ApiNotReachableInterceptor,
	HandleHeadersInterceptor,
	MetadataInterceptor,
	QuotaInterceptor,
	ReplaceBackendUrlInterceptor,
	VersionErrorInterceptor,
} from '~app-client/root/interceptors';
import { AppPageComponent, AppPageModule } from '~app-client/root/page';
import { RootRouterModule } from '~app-client/root/router';
import { environment } from '~app-environment';
import { ReachPanModule } from '~app-ui/cdk/reach-pan';
import { REACH_API_COMMON_HEADERS as NEW_REACH_API_COMMON_HEADERS } from '~ng-common-lib/core/tokens/api-common-headers';
import { COMMON_ENVIRONMENT } from '~ng-common-lib/core/tokens/environment';
import { TranslocoRootModule } from './transloco.module';
import { CHAT_DATA_RETRIEVAL_BASE_ROUTER_PATH } from '~app-client-item/chat-data-retrieval/tokens/base-router-path';

declare const PACKAGE_VERSION: ReachVersion;
declare const COMMIT_HASH: CommitHash;

@UntilDestroy()
@NgModule({
	declarations: [],
	bootstrap: [AppPageComponent],
	imports: [
		AppPageModule,
		BrowserModule,
		BrowserAnimationsModule,
		ReachPanModule, // this is needed or hammer won't work.
		RootRouterModule,
		TranslocoRootModule,
		OverlayModule, // without this, the `onboarding-target` component doesn't work
		MatNativeDateModule, // without this imported in the root module, the material datepicker component doesn't work
		MarkdownModule.forRoot(),
	],
	providers: [
		provideHotToastConfig(),
		{ provide: REACH_VERSION, useValue: PACKAGE_VERSION },
		// required when tunneling through ngrok
		{ provide: REACH_API_COMMON_HEADERS, useValue: { 'ngrok-skip-browser-warning': 'true' } },
		{ provide: REACH_COMMIT_HASH, useValue: COMMIT_HASH },
		{ provide: CLIENT_ENVIRONMENT, useValue: environment },
		{ provide: NEW_REACH_API_COMMON_HEADERS, useValue: {} },
		{
			provide: COMMON_ENVIRONMENT,
			useValue: {
				environmentName: environment.environmentName,
				server: {
					url: environment.apiBaseUrl,
				},
				mainClient: {
					url: environment.frontendUrl,
				},
				handbook: {
					url: environment.handbookUrl,
				},
			},
		},
		{
			provide: CHAT_DATA_RETRIEVAL_BASE_ROUTER_PATH,
			useValue: ['/', 'home', 'chat'],
		},
		{ provide: HTTP_INTERCEPTORS, useExisting: HandleHeadersInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useExisting: MetadataInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: ReplaceBackendUrlInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: VersionErrorInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: ApiDownInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: QuotaInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: ApiNotReachableInterceptor, multi: true },
		provideHttpClient(withInterceptorsFromDi()),
	],
})
export class AppModule {
	constructor(
		private readonly analytics: AnalyticsService,
		private readonly firebaseDeviceService: FirebaseDeviceService
	) {
		this.analytics.init();
		this.firebaseDeviceService.init();
	}
}
