import { Injectable } from '@angular/core';
import {
	EmailSubscriptionCreateBody,
	EmailSubscriptionCreateResponse,
	EmailSubscriptionGetResponse,
} from '@reach/interfaces';
import { BaseApiService } from '~app-client/api/_internal';

@Injectable()
export class EmailSubscriptionsApiService extends BaseApiService {
	protected commonPath = ['email-subscriptions'];

	public getEmailsSubscriptions(): Promise<EmailSubscriptionGetResponse> {
		return this.http.get<EmailSubscriptionGetResponse>(this.url(''));
	}

	public getEmailSubscriptionsUnfiltered(): Promise<EmailSubscriptionGetResponse> {
		return this.http.get<EmailSubscriptionGetResponse>(this.url('unfiltered'));
	}

	public createEmailSubscription(
		dto: EmailSubscriptionCreateBody
	): Promise<EmailSubscriptionCreateResponse> {
		return this.http.post<EmailSubscriptionCreateResponse>(this.url(''), dto);
	}

	public deleteAllEmailSubscriptions(): Promise<void> {
		return this.http.delete<void>(this.url('all'));
	}

	public deleteEmailSubscription(email: string): Promise<void> {
		return this.http.delete<void>(this.url('all', email));
	}
}
