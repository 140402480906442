import { ChangeDetectionStrategy, Component, NgZone, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
	selector: 'app-url-open-reactor',
	template: ``,
	styles: [':host { display: none; } '],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [],
})
export class AppUrlOpenReactorComponent implements OnInit {
	private readonly ngZone = inject(NgZone);
	private readonly router = inject(Router);

	ngOnInit(): void {
		App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
			this.ngZone.run(() => {
				// Example url: https://beerswift.app/tabs/tab2
				// slug = /tabs/tab2
				const url = event.url.split('.ch').slice(1).join();
				const [cleanPath, ...paramsStrArr] = url.split('?');
				const urlSegments = cleanPath.split('/').filter((x) => `${x || ''}` !== '');

				const paramsStr = paramsStrArr.join();
				const paramsObj = new URLSearchParams(paramsStr);
				const params = {};

				paramsObj.forEach((value, key) => {
					params[key] = [...(params[key] || []), value];
				});

				console.debug('appUrlOpen', { url, urlSegments, params });

				if (url) {
					this.router.navigate(['/', ...urlSegments], {
						queryParams: params,
					});
				}
			});
		});
	}
}
