import { CommonModule, DOCUMENT } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	inject,
	Renderer2,
	RendererStyleFlags2,
} from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import {
	distinctUntilChanged,
	filter,
	fromEvent,
	map,
	of,
	startWith,
	switchMap,
	tap,
	timer,
} from 'rxjs';
import { MoveToRootDirective } from '~app-client/core/directives';
import { CLIENT_ENVIRONMENT } from '~app-client/core/tokens';
import { ReachStorage } from '~app-client/core/utils';
import { DEBUG_SAFE_AREA_KEY } from '~app-client/root/constants';

@UntilDestroy()
@Component({
	selector: 'safe-area-reactor',
	templateUrl: './safe-area-reactor.component.html',
	styles: [':host { display: none; } '],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [CommonModule, MoveToRootDirective],
})
export class SafeAreaReactorComponent {
	private readonly environment = inject(CLIENT_ENVIRONMENT);
	private readonly renderer = inject(Renderer2);
	private readonly document = inject(DOCUMENT);

	public readonly mockSafeArea$ = of(!this.environment.production).pipe(
		switchMap((listen) => {
			if (listen) {
				return fromEvent<StorageEvent>(window, 'storage').pipe(
					startWith({
						storageArea: localStorage,
						key: DEBUG_SAFE_AREA_KEY,
						newValue: ReachStorage.getItem(DEBUG_SAFE_AREA_KEY),
					}),
					filter((event) => event.key === DEBUG_SAFE_AREA_KEY),
					map((event) => event.newValue === `${true}`),
					distinctUntilChanged()
				);
			}

			return of(false);
		}),
		tap((mock) => {
			if (mock) {
				this.renderer.setStyle(
					this.document.body,
					'--safe-area-inset-top',
					'44px',
					RendererStyleFlags2.DashCase
				);
				this.renderer.setStyle(
					this.document.body,
					'--safe-area-inset-bottom',
					'34px',
					RendererStyleFlags2.DashCase
				);
			} else {
				this.renderer.removeStyle(
					this.document.body,
					'--safe-area-inset-top',
					RendererStyleFlags2.DashCase
				);
				this.renderer.removeStyle(
					this.document.body,
					'--safe-area-inset-bottom',
					RendererStyleFlags2.DashCase
				);
			}
		})
	);

	public readonly time$ = this.mockSafeArea$.pipe(
		switchMap((mock) => {
			if (!mock) {
				return '';
			}

			// refresh every 30s
			return timer(0, 1000 * 30).pipe(
				map(() => {
					const date = new Date();
					const hours = `${date.getHours()}`.padStart(2, '0');
					const minutes = `${date.getMinutes()}`.padStart(2, '0');
					return `${hours}:${minutes}`;
				})
			);
		}),
		distinctUntilChanged()
	);
}
