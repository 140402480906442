import { AdminFeatureFlags, UserTimeInterval } from '../user';
import { ApiChart, MixpanelChart } from './chart.type';
import {
	DashboardsEnum,
	InsightGroupConfiguration,
	PositioningOptions,
} from './insight-group-configuration.type';
import { CommonChartOptions, InsightGroup, InsightGroupApiMetadata } from './insight-group.type';

// ------------------------------------------------------------------------------
//  Request to get all the charts in a dashboard (the ones you have access to)
// ------------------------------------------------------------------------------

// Query params
export type GetChartsFromDashboardQuery = {
	dashboardId: DashboardsEnum;
};

export enum TimeWindow {
	ALL,
	MONTH, // 30 days
	WEEK, // 7 days
	DAY, // 1 day
	YESTERDAY, // 1 day
	QUARTER, // 90 days
	YEAR, // 365 days
}

export const userTimeIntervalToTimeWindow: { [key in UserTimeInterval]: TimeWindow } = {
	[UserTimeInterval.ALL_TIME]: TimeWindow.ALL,
	[UserTimeInterval.MONTHLY]: TimeWindow.MONTH,
	[UserTimeInterval.WEEKLY]: TimeWindow.WEEK,
	[UserTimeInterval.DAILY]: TimeWindow.DAY,
	[UserTimeInterval.QUARTERLY]: TimeWindow.QUARTER,
	[UserTimeInterval.YEARLY]: TimeWindow.YEAR,
};

export const timeWindowToUserTimeInterval: {
	[key in Exclude<TimeWindow, TimeWindow.YESTERDAY>]?: UserTimeInterval;
} = {
	[TimeWindow.ALL]: UserTimeInterval.ALL_TIME,
	[TimeWindow.MONTH]: UserTimeInterval.MONTHLY,
	[TimeWindow.WEEK]: UserTimeInterval.WEEKLY,
	[TimeWindow.DAY]: UserTimeInterval.DAILY,
	[TimeWindow.QUARTER]: UserTimeInterval.QUARTERLY,
	[TimeWindow.YEAR]: UserTimeInterval.YEARLY,
};

export const timeToDaysMap: {
	[key in Exclude<UserTimeInterval, UserTimeInterval.ALL_TIME>]: number;
} = {
	[UserTimeInterval.WEEKLY]: 7,
	[UserTimeInterval.DAILY]: 1,
	[UserTimeInterval.MONTHLY]: 30,
	[UserTimeInterval.QUARTERLY]: 90,
	[UserTimeInterval.YEARLY]: 365,
};

// Response
export type InsightMap<T> = {
	[timeFrameId in TimeWindow]?: T;
};

export type GetChartsByDashboardResponse<T extends CommonChartOptions> = {
	[insightGroupId: number]: {
		data: InsightMap<ApiChart | MixpanelChart> | ApiChart;
		configurations: InsightGroupConfiguration[];
		insightGroupData: Omit<InsightGroup<T>, 'configurations'>;
	};
};

// ------------------------------------------------------------------------------
//  Request to get all the insight groups
// ------------------------------------------------------------------------------

// Response
export type GetInsightGroupsResponse<T extends CommonChartOptions> = {
	[insightGroupId: string]: InsightGroup<T>;
};

// ------------------------------------------------------------------------------
//  Request to patch an insight group
// ------------------------------------------------------------------------------

// Params *
export type InsightGroupIdQuery = {
	insightGroupId: number;
};

// Body
export type UpdateInsightGroupBody = {
	minimumFeatureFlag?: AdminFeatureFlags;
	goal?: number;
	bonus?: number;
	showAnimation?: boolean;
};

// ------------------------------------------------------------------------------
//  Request to patch an insight group positioning
// ------------------------------------------------------------------------------

// Query
export type InsightGroupPositioningQuery = {
	dashboardId: DashboardsEnum;
	adminFeatureFlag: AdminFeatureFlags;
};

// BODY
export type UpdateInsightGroupPositioning = Partial<PositioningOptions> & {
	insightGroupId: number;
};
export type UpdateInsightGroupPositioningBody = { configs: UpdateInsightGroupPositioning[] };

// ------------------------------------------------------------------------------
//  Request to create an API insight group
// ------------------------------------------------------------------------------
export type CreateApiInsightGroupBody<T extends CommonChartOptions> = Pick<
	InsightGroup<T>,
	'name' | 'options' | 'type'
> &
	Omit<InsightGroupApiMetadata, 'apiStatisticType'>;

// ------------------------------------------------------------------------------
//  Request to create configurations
// ------------------------------------------------------------------------------

export type CreateInsightGroupConfiguration = InsightGroupConfiguration & {
	insightGroupId: number;
};
export type CreateInsightGroupConfigurationsBody = { configs: CreateInsightGroupConfiguration[] };

// ------------------------------------------------------------------------------
//  Request to delete configurations
// ------------------------------------------------------------------------------

export type DeleteInsightGroupConfiguration = Omit<
	InsightGroupConfiguration,
	'positioningOptions'
> & {
	insightGroupId: number;
};
export type DeleteInsightGroupConfigurationsBody = { configs: DeleteInsightGroupConfiguration[] };

// ------------------------------------------------------------------------------
// Sync response
// ------------------------------------------------------------------------------

export type SyncResponseData = {
	ids: number[];
	count: number;
};
export type SyncResponse = {
	insightGroupsCreated: SyncResponseData;
	insightGroupsDeleted: SyncResponseData;
	insightGroupsUpdated: SyncResponseData;
	bookmarksCreated: SyncResponseData;
	bookmarksDeleted: SyncResponseData;
	seriesUpdated: SyncResponseData;
};
