import { Injectable } from '@angular/core';
import { DeepPartial, SettingsResponse, UserPreferences } from '@reach/interfaces';
import { BaseApiService } from '~app-client/api/_internal';

@Injectable({ providedIn: 'root' })
export class SettingsApiService extends BaseApiService {
	protected commonPath = ['settings'];

	public get(): Promise<SettingsResponse> {
		const params = { t: this.paramTime };
		return this.http.get<SettingsResponse>(this.url(), params);
	}

	public update(settings: DeepPartial<UserPreferences>): Promise<SettingsResponse> {
		return this.http.patch<SettingsResponse>(this.url(), settings);
	}
}
