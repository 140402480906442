export enum Interest {
	ANIMALS = 'animals',
	ARCHITECTURE = 'architecture',
	ART = 'art',
	DESIGN = 'design',
	DRINKS = 'drinks',
	FAMILY = 'family',
	FASHION = 'fashion',
	FINANCE = 'finance',
	FITNESS = 'fitness',
	FOOD = 'food',
	GAMING = 'gaming',
	MARKETING = 'marketing',
	MOVIES = 'movies',
	MUSIC = 'music',
	NATURE = 'nature',
	NEWS = 'news',
	PHOTOGRAPHY = 'photography',
	POLITICS = 'politics',
	READING = 'reading',
	SCIENCE = 'science',
	SPORTS = 'sports',
	STARTUPS = 'startups',
	TECHNOLOGY = 'technology',
	TRAVEL = 'travel',
}
