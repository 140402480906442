import {
	ImpersonateUserAccessInterface,
	ImpersonateUserTicketInterface,
} from './impersonate-user.interface';

export type CreateImpersonateUserTicketBody = {
	impersoneeId: string;
	reason: string;
	source?: string;
	comments?: string;
};

export type ImpersonateUserTicketIdBody = {
	ticketId: string;
};

export type ImpersonateUserSearchBody = {
	search?: string;
};
export type ImpersonateUserModifyTicketBody = {
	reason?: string;
	source?: string;
	comments?: string;
};

export type ImpersonateUserTicketResponse = ImpersonateUserTicketInterface;
export type ImpersonateUserAccessResponse = ImpersonateUserAccessInterface;
