import { ReachSlateElementType } from '@reach/interfaces';

export const INLINE_ELEMENTS = [
	ReachSlateElementType.URL,
	ReachSlateElementType.TEXT,
	ReachSlateElementType.EMOJI,
	ReachSlateElementType.MENTION_TAG,
	ReachSlateElementType.MENTION_ITEM,
	ReachSlateElementType.MENTION_CARD,
];
