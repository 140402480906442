import { Require } from '@reach/interfaces';

export function ensureAssert<T>(argument: T): argument is Exclude<T, undefined | null> {
	return argument !== undefined && argument !== null;
}

export function ensureAssertPaths<T, K extends keyof T>(
	argument: T,
	key: K[]
): argument is Require<T, K> {
	for (const k of key) {
		if (!ensureAssert(argument[k])) {
			return false;
		}
	}
	return true;
}
