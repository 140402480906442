import { NodeSubtypeEnum, NodeSubtypeId } from '../node-subtypes';
import { NodeType } from '../nodes';

export enum ExtractedContentEnum {
	AUTHOR = 'AUTHOR',
	EMBEDDED_URLS = 'EMBEDDED_URLS',
	EMBEDDED_PHONES = 'EMBEDDED_PHONES',
	READING_TIME = 'READING_TIME',
	FILE_TYPE = 'FILE_TYPE',
	DURATION = 'DURATION',
	MODEL = 'MODEL',
	FILE_LOCATION = 'IMAGE_LOCATION',
}

export enum ExtractedContentOptionEnum {
	PROPERTY_VISIBLE = 'PROPERTY_VISIBLE',
	PROPERTY_HIDDEN = 'PROPERTY_HIDDEN',
	NODE = 'NODE',
	EXTRACTED_NODE_CANDIDATE = 'EXTRACTED_NODE_CANDIDATE',
}
export interface NodeSubtypesSettingsNodeMinimalInfo {
	nodeType: NodeType;
	subtypeId: NodeSubtypeId;
	key: string;
}

export interface ExtractedContentInterface {
	content: ExtractedContentEnum;
	selectedOption: ExtractedContentOptionEnum;
}

export const extractedContentOptionsMap: {
	[key in ExtractedContentEnum]: {
		availableOptions: ExtractedContentOptionEnum[];
		nodesToCreate?: Omit<NodeSubtypesSettingsNodeMinimalInfo, 'key'>[];
		extractedNodeCandidates?: Omit<NodeSubtypesSettingsNodeMinimalInfo, 'key'>[];
	};
} = {
	[ExtractedContentEnum.AUTHOR]: {
		availableOptions: [
			ExtractedContentOptionEnum.PROPERTY_VISIBLE,
			ExtractedContentOptionEnum.PROPERTY_HIDDEN,
			// ExtractedContentOptionEnum.NODE,
		],
		nodesToCreate: [
			{
				nodeType: NodeType.TAG,
				subtypeId: NodeSubtypeEnum.TAG_CONTACT,
			},
		],
	},
	[ExtractedContentEnum.EMBEDDED_URLS]: {
		availableOptions: [
			ExtractedContentOptionEnum.PROPERTY_VISIBLE,
			ExtractedContentOptionEnum.PROPERTY_HIDDEN,
			// ExtractedContentOptionEnum.EXTRACTED_NODE_CANDIDATE,
		],
		extractedNodeCandidates: [
			{
				nodeType: NodeType.WEBSITE,
				subtypeId: NodeSubtypeEnum.WEBSITE_BASE,
			},
		],
	},
	[ExtractedContentEnum.EMBEDDED_PHONES]: {
		availableOptions: [
			ExtractedContentOptionEnum.PROPERTY_VISIBLE,
			ExtractedContentOptionEnum.PROPERTY_HIDDEN,
		],
	},
	[ExtractedContentEnum.READING_TIME]: {
		availableOptions: [
			ExtractedContentOptionEnum.PROPERTY_VISIBLE,
			ExtractedContentOptionEnum.PROPERTY_HIDDEN,
		],
	},
	[ExtractedContentEnum.FILE_LOCATION]: {
		availableOptions: [
			ExtractedContentOptionEnum.PROPERTY_VISIBLE,
			ExtractedContentOptionEnum.PROPERTY_HIDDEN,
		],
	},
	[ExtractedContentEnum.FILE_TYPE]: {
		availableOptions: [
			ExtractedContentOptionEnum.PROPERTY_VISIBLE,
			ExtractedContentOptionEnum.PROPERTY_HIDDEN,
		],
	},
	[ExtractedContentEnum.DURATION]: {
		availableOptions: [
			ExtractedContentOptionEnum.PROPERTY_VISIBLE,
			ExtractedContentOptionEnum.PROPERTY_HIDDEN,
		],
	},
	[ExtractedContentEnum.MODEL]: {
		availableOptions: [
			ExtractedContentOptionEnum.PROPERTY_VISIBLE,
			ExtractedContentOptionEnum.PROPERTY_HIDDEN,
		],
	},
};
