import { NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'loadingIconIfTrue',
	pure: true,
})
export class LoadingIconIfTruePipe implements PipeTransform {
	transform(value: boolean, fallback: string): string {
		return value ? 'icon-loading-bubbles' : fallback;
	}
}

@NgModule({
	declarations: [LoadingIconIfTruePipe],
	exports: [LoadingIconIfTruePipe],
})
export class LoadingIconIfTrueModule {}
