import { ReachNodeId } from '~app-client/core/types';
import { ExtraWorkspaceType, ReachWorkspace } from '~app-client/workspaces/types';

export const unassignedWorkspaceId: ReachNodeId = '-1';

export function workspaceUnasignedNodesTransformer(count: number): ReachWorkspace {
	const now = Date.now();

	return {
		id: unassignedWorkspaceId,
		type: ExtraWorkspaceType.UNASSIGNED_NODES,
		name: 'Unassigned Nodes',
		createdAt: now,
		updatedAt: now,
		count: count,
		customProperties: {},
		lastSearch: Number.NEGATIVE_INFINITY,
	};
}
