import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { ReachPanEvent, ReachPanEventPoint } from './types';

interface RegularPanEvent {
	center: { x: number; y: number };
}

@Directive({
	selector: '[reachPan]',
	host: {
		class: '__pan-handler',
	},
})
export class ReachPanDirective {
	@Input()
	public reachPan = true;

	@Output()
	public reachPanMove = new EventEmitter<ReachPanEvent>();

	@Output()
	public reachPanCancel = new EventEmitter<void>();

	@Output()
	public reachPanFinish = new EventEmitter<ReachPanEvent>();

	private initialPoint: ReachPanEventPoint = { x: 0, y: 0 };

	private readonly ID_KEY = '__reachPanIdArr';

	private get randomStr(): string {
		return `${Math.random()}`.substr(2);
	}

	private uniqueId = `${performance.now()}_${this.randomStr}_${this.randomStr}`;

	private previousHandlers: string[] = [];

	constructor() {}

	@HostListener('mousedown', ['$event'])
	@HostListener('touchstart', ['$event'])
	public touchStart(event: TouchEvent | MouseEvent): void {
		this.onlyWhenListening(() => {
			const touchEvent = event as TouchEvent;
			const mouseEvent = event as MouseEvent;

			const pointX = touchEvent.touches ? touchEvent.touches[0].pageX : mouseEvent.pageX;

			const pointY = touchEvent.touches ? touchEvent.touches[0].pageY : mouseEvent.pageY;

			this.handleStart(event);
			const x = pointX;
			const y = pointY;
			this.initialPoint = { x, y };
		});
	}

	@HostListener('panmove', ['$event'])
	public panMove(event: RegularPanEvent): void {
		this.onlyWhenListening(() => {
			this.emit(this.reachPanMove, event);
		});
	}

	@HostListener('panend', ['$event'])
	public panEnd(event: RegularPanEvent): void {
		this.onlyWhenListening(() => {
			this.emit(this.reachPanFinish, event);
		});
	}

	@HostListener('pancancel')
	public panCancel(): void {
		this.onlyWhenListening(() => {
			this.reachPanCancel.emit();
		});
	}

	private onlyWhenListening(cb: () => void): void {
		if (this.reachPan !== false) {
			cb();
		}
	}

	private cleanPoint(point: ReachPanEventPoint): ReachPanEventPoint {
		return {
			x: point.x - this.initialPoint.x,
			y: point.y - this.initialPoint.y,
		};
	}

	private emit(emitter: EventEmitter<ReachPanEvent>, panEvent: RegularPanEvent): void {
		const x = panEvent.center.x;
		const y = panEvent.center.y;
		const currentPoint: ReachPanEventPoint = { x, y };
		const point = this.cleanPoint(currentPoint);

		emitter.emit({
			startingPoint: this.initialPoint,
			rawPoint: currentPoint,
			point,
			previousHandlers: this.previousHandlers.length > 0,
		});
	}

	private handleStart(event: TouchEvent | MouseEvent): void {
		this.previousHandlers = (event[this.ID_KEY] as string[]) || [];
		event[this.ID_KEY] = [...this.previousHandlers, this.uniqueId];
	}
}
