export interface AnalyticsEventIntegrationExecuted {
	integrationExecutedType: string;
}

export interface AnalyticsEventIntegrationDeleted {
	integrationDeletedType: string;
}

export interface AnalyticsEventIntegrationPhoneContactsSuccess {
	phoneContactsAllCount: number;
	phoneContactsSelectedCount: number;

	phoneContactsConflictsCount: number;
	phoneContactsConflictsSkippedCount: number;

	phoneContactsPhoneLabelsCount: number;
	phoneContactsEmailLabelsCount: number;

	phoneContactsWithPhoneCount: number;
	phoneContactsWithEmailCount: number;
	phoneContactsWithBirthdayCount: number;
	phoneContactsWithCompanyNameCount: number;
	phoneContactsWithCompanyRoleCount: number;
	phoneContactsWithImageCount: number;

	phoneContactsPhonePropertiesCount: number;
	phoneContactsEmailPropertiesCount: number;
	phoneContactsBirthdayAsProperty: boolean;
	phoneContactsCompanyNameAsTag: boolean;
	phoneContactsCompanyNameAsProperty: boolean;
	phoneContactsCompanyRoleAsTag: boolean;
	phoneContactsCompanyRoleAsProperty: boolean;
}
