export enum OccurrenceStatisticsSortByEnum {
	KEY = 'KEY',
	OCCURRENCES_COUNT = 'OCCURRENCES_COUNT',
	OCCURRENCES_PERCENTAGE = 'OCCURRENCES_PERCENTAGE',
	USERS_WITH_OCCURRENCES_COUNT = 'USERS_WITH_OCCURRENCES_COUNT',
	USERS_WITH_OCCURRENCES_PERCENTAGE = 'USERS_WITH_OCCURRENCES_PERCENTAGE',
}

export interface OccurrenceStatisticItemInterface {
	name: string;
	occurrencesCount: number;
	occurrencesPercentage: number;
	usersWithOccurrencesCount: number;
	usersWithOccurrencesPercentage: number;
}

export interface OccurrenceStatisticResponse {
	usersCount: number;
	differentValuesCount: number;
	// filteredPercentage: number;
	statsPerValue: OccurrenceStatisticItemInterface[];
}
