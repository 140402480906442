export enum Profession {
	ACCOUNTANT = 'ACCOUNTANT',
	AGRICULTURE = 'AGRICULTURE',
	ANALYST = 'ANALYST',
	ARCHITECT = 'ARCHITECT',
	ARTIST = 'ARTIST',
	ASSISTANT = 'ASSISTANT',
	CHEF = 'CHEF',
	CONSTRUCTION = 'CONSTRUCTION',
	CONSULTANT = 'CONSULTANT',
	DESIGNER = 'DESIGNER',
	DEVELOPER = 'DEVELOPER',
	DOCTOR = 'DOCTOR',
	ENGINEER = 'ENGINEER',
	ENTREPRENEUR = 'ENTREPRENEUR',
	EXECUTIVE = 'EXECUTIVE',
	GAMER = 'GAMER',
	HOMEMAKER = 'HOMEMAKER',
	INVESTOR = 'INVESTOR',
	LAWYER = 'LAWYER',
	MARKETING = 'MARKETING',
	MILITARY = 'MILITARY',
	MUSICIAN = 'MUSICIAN',
	NURSE = 'NURSE',
	OPERATIONS = 'OPERATIONS',
	OTHER = 'OTHER',
	PHARMACIST = 'PHARMACIST',
	PHOTOGRAPHER = 'PHOTOGRAPHER',
	PR = 'PR',
	PRODUCT_MANAGER = 'PRODUCT_MANAGER',
	RELIGIOUS_WORKER = 'RELIGIOUS_WORKER',
	RESEARCHER = 'RESEARCHER',
	SALES = 'SALES',
	SOCIAL_WORKER = 'SOCIAL_WORKER',
	STUDENT = 'STUDENT',
	TEACHER = 'TEACHER',
	TRANSPORTATION = 'TRANSPORTATION',
	WRITER = 'WRITER',
}
