import { ensureAssert } from '../assertions';

export function concatNotNull(parts: (string | null | undefined)[], separator = ' '): string {
	let concatenatedString = '';

	const filteredStrings: string[] = parts.filter((s) => ensureAssert(s)) as string[];

	if (filteredStrings.length === 0) {
		return '';
	}

	concatenatedString = filteredStrings[0];
	for (let i = 1; i < filteredStrings.length; ++i) {
		concatenatedString = concatenatedString.concat(separator, filteredStrings[i]);
	}

	return concatenatedString;
}
