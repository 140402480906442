type NoNullField<T> = {
	[P in keyof T]: null extends T[P] ? Exclude<T[P], null> | undefined : T[P];
};

export const removeNullProps = <T>(obj: T): [NoNullField<T>, (keyof T)[]] => {
	const removed: (keyof T)[] = [];
	Object.keys(obj as Record<string, unknown>).forEach((key) => {
		if (obj[key] === null) {
			delete obj[key];
			removed.push(key as keyof T);
		}
		/*if (typeof obj[key] === 'string' && obj[key].length === 0) {
			delete obj[key];
		}*/
	});
	return [obj as NoNullField<T>, removed];
};
