export enum NodeSubtypeIconEnum {
	DOCUMENT = 'DOCUMENT',
	IMAGE = 'IMAGE',
	PLAY_CIRCLE = 'PLAY_CIRCLE',
	SPEAKER = 'SPEAKER',
	WEBSITE = 'WEBSITE',
	NOTE = 'NOTE',
	ALBUM = 'ALBUM',
	WORKSPACE = 'WORKSPACE',
	CONTACT = 'CONTACT',
	EMOJI = 'EMOJI',
	TEAM = 'TEAM',
	COMPANY = 'COMPANY',
	HOME = 'HOME',
	WORLD_MAP = 'WORLD_MAP',
	GLOBE = 'GLOBE',
	TRANSLATE = 'TRANSLATE',
	LOCATION = 'LOCATION',
	PIN = 'PIN',
	FLAG = 'FLAG',
	KEYWORD = 'KEYWORD',
	TASK = 'TASK',
	DATE = 'DATE',
	BACK_IN_TIME = 'BACK_IN_TIME',
	TICK = 'TICK',
	TOPIC = 'TOPIC',
	PHONE = 'PHONE',
	COMPUTER = 'COMPUTER',
	INVENTORY = 'INVENTORY',
	EMAIL = 'EMAIL',
	COLOR = 'COLOR',
	CAMERA = 'CAMERA',
	BELL_RING = 'BELL_RING',
	RESTAURANT = 'RESTAURANT',
	THOUGHT = 'THOUGHT',
	MYREACH = 'MYREACH',
	TARGET = 'TARGET',
	STAR = 'STAR',
	CASH = 'CASH',
	DIAMOND = 'DIAMOND',
	INTERESTS = 'INTERESTS',
	PUZZLE = 'PUZZLE',
	MAGIC_WAND = 'MAGIC_WAND',
	LINK = 'LINK',
	LOCK = 'LOCK',
	KEY = 'KEY',
	LOADING_TIME = 'LOADING_TIME',
	DASHBOARD = 'DASHBOARD',
	MERGE = 'MERGE',
	STOCK = 'STOCK',
	UPDATE = 'UPDATE',
	BUG = 'BUG',
	WARNING = 'WARNING',
	INTEGRATION = 'INTEGRATION',
	ENVELOPE = 'ENVELOPE',
}
