import { DOCUMENT } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	HostBinding,
	HostListener,
	Inject,
	Input,
	OnInit,
	Output,
} from '@angular/core';
import { ClientScreenDirective } from '~app-client/core/directives';
import {
	cleanOpenModalOptions,
	defaultOpenModalOptions,
	OpenModalOptions,
} from '~app-client/modals/types';
import { blurActiveElement } from '~app-ui/cdk/utils';

@Component({
	selector: 'reach-modal',
	templateUrl: './modal.component.html',
	styleUrls: ['./modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [ClientScreenDirective],
	host: {
		class: 'reach-modal-host',
	},
})
export class ReachModalComponent implements OnInit {
	@Input()
	public set options(options: Partial<OpenModalOptions>) {
		this._options = cleanOpenModalOptions(options || {});
	}

	public get options(): Partial<OpenModalOptions> {
		return this._options;
	}

	private _options = defaultOpenModalOptions;

	@HostBinding('style.z-index')
	public get hostZIndex(): number {
		return this._options.hostZIndex;
	}

	@HostBinding('class.default-host-style')
	public get hostDefaultStyle(): boolean {
		return this._options.defaultHostStyle;
	}

	@HostBinding('class.default-container-style')
	public get containerDefaultStyle(): boolean {
		return this._options.defaultContainerStyle;
	}

	@HostBinding('class.fullscreen')
	public get fullScreenClass(): boolean {
		return this._options.fullScreen;
	}

	@Output()
	public close = new EventEmitter<void>();

	constructor(@Inject(DOCUMENT) private document: Document) {}

	ngOnInit(): void {
		blurActiveElement(this.document);
	}

	public stopEvent(event?: Event): void {
		event?.stopPropagation();
	}

	@HostListener('click')
	public onBackgroundClick(): void {
		if (this._options.closeOnOutsideClick) {
			this.close.emit();
		}
	}
}
