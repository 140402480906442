import { Injectable } from '@angular/core';
import {
	TableViewCreateBody,
	TableViewCreateResponse,
	TableViewGetResponse,
	TableViewUpdateBody,
	TableViewUpdateResponse,
} from '@reach/interfaces';
import { BaseApiService } from '~app-client/api/_internal';

@Injectable()
export class TableViewsApiService extends BaseApiService {
	protected commonPath = ['users', 'me', 'table-views'];

	public getTableViews(): Promise<TableViewGetResponse> {
		return this.http.get<TableViewGetResponse>(this.url(''));
	}

	public createTableView(dto: TableViewCreateBody): Promise<TableViewCreateResponse> {
		return this.http.post<TableViewCreateResponse>(this.url(''), dto);
	}

	public deleteTableView(tableViewId: string): Promise<void> {
		return this.http.delete<void>(this.url(tableViewId));
	}

	public updateTableView(
		tableViewId: string,
		dto: TableViewUpdateBody
	): Promise<TableViewUpdateResponse> {
		return this.http.put<TableViewUpdateResponse>(this.url(tableViewId), dto);
	}
}
