import { QuotaStatusItem, StandardQuotaTypeEnum, ReachIntegration } from '@reach/interfaces';

interface QuotaItemsToOverride {
	used: number;
	total: number;
	available: number;
}
export type ReachQuotaStatusItem = Omit<QuotaStatusItem, keyof QuotaItemsToOverride> & {
	percentage: number;
	showWarning: boolean;
} & QuotaItemsToOverride;

export type ReachQuotaStatus = {
	standardQuotas: Record<StandardQuotaTypeEnum, ReachQuotaStatusItem>;
	integrationQuotas: Record<ReachIntegration, ReachQuotaStatusItem>;
};
