import { RankKeyEnum } from '../ranks';

export interface HeaderReachBeforeAfterValue<T> {
	before: T;
	after: T;
}

export type HeaderReachExpInterface = HeaderReachBeforeAfterValue<number>;
export type HeaderReachLevelInterface = HeaderReachBeforeAfterValue<number>;
export type HeaderReachRankInterface = HeaderReachBeforeAfterValue<RankKeyEnum>;
export type HeaderReachCreditsInterface = HeaderReachBeforeAfterValue<number>;
export type HeaderReachAchievementsLeftToClaimInterface = HeaderReachBeforeAfterValue<number>;
