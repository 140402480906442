import { CustomPropertyType, ProcessPropertyStatusEnum, UnitOptionType } from '../custom-property';
import { LocationLatLon } from '../location';
import {
	ComparisonOperatorGroup,
	ContainOperatorGroup,
	ContainSomeOperatorGroup,
	EqualOperatorGroup,
	HasPropertyOperatorGroup,
	SearchOperator,
} from './search-operator.type';

export const typeToOperator: { [type in CustomPropertyType]: SearchOperator[] } = {
	[CustomPropertyType.PLAIN_TEXT]: [...ContainOperatorGroup, ...HasPropertyOperatorGroup],
	[CustomPropertyType.PLAIN_NUMBER]: [
		...EqualOperatorGroup,
		...ComparisonOperatorGroup,
		...HasPropertyOperatorGroup,
	],
	[CustomPropertyType.SELECT_TAG]: [
		...EqualOperatorGroup,
		...HasPropertyOperatorGroup,
		...ContainSomeOperatorGroup,
	],
	[CustomPropertyType.DATE]: [
		...EqualOperatorGroup,
		...ComparisonOperatorGroup,
		...HasPropertyOperatorGroup,
	],
	[CustomPropertyType.TIME]: [
		...EqualOperatorGroup,
		...ComparisonOperatorGroup,
		...HasPropertyOperatorGroup,
	],
	[CustomPropertyType.MULTI_SELECT_TAG]: [
		...EqualOperatorGroup,
		...ContainOperatorGroup,
		...HasPropertyOperatorGroup,
	],
	[CustomPropertyType.MULTI_SELECT_SOURCE]: [
		...EqualOperatorGroup,
		...ContainOperatorGroup,
		...HasPropertyOperatorGroup,
	],
	[CustomPropertyType.SELECT_STAR]: [
		...EqualOperatorGroup,
		...ComparisonOperatorGroup,
		...HasPropertyOperatorGroup,
	],
	[CustomPropertyType.PHONE]: [
		...EqualOperatorGroup,
		...ContainOperatorGroup,
		...HasPropertyOperatorGroup,
	],
	[CustomPropertyType.EMAIL]: [
		...EqualOperatorGroup,
		...ContainOperatorGroup,
		...HasPropertyOperatorGroup,
	],
	[CustomPropertyType.URL]: [
		...EqualOperatorGroup,
		...ContainOperatorGroup,
		...HasPropertyOperatorGroup,
	],
	[CustomPropertyType.NAME]: [
		...EqualOperatorGroup,
		...ContainOperatorGroup,
		...HasPropertyOperatorGroup,
	],
	[CustomPropertyType.CURRENCY]: [
		...EqualOperatorGroup,
		...ComparisonOperatorGroup,
		...HasPropertyOperatorGroup,
	],
	[CustomPropertyType.BOOLEAN]: [...EqualOperatorGroup, ...HasPropertyOperatorGroup],
	[CustomPropertyType.MEASURE_WEIGHT]: [
		...EqualOperatorGroup,
		...ComparisonOperatorGroup,
		...HasPropertyOperatorGroup,
	],
	[CustomPropertyType.MEASURE_DISTANCE]: [
		...EqualOperatorGroup,
		...ComparisonOperatorGroup,
		...HasPropertyOperatorGroup,
	],
	[CustomPropertyType.SELECT_IMPORTANCE]: [
		...EqualOperatorGroup,
		...ComparisonOperatorGroup,
		...HasPropertyOperatorGroup,
	],
	[CustomPropertyType.PERCENTAGE]: [
		...EqualOperatorGroup,
		...ComparisonOperatorGroup,
		...HasPropertyOperatorGroup,
	],
	[CustomPropertyType.LOCATION]: [SearchOperator.LE, ...HasPropertyOperatorGroup],
	[CustomPropertyType.USER]: [
		...EqualOperatorGroup,
		...ContainOperatorGroup,
		...HasPropertyOperatorGroup,
	],
	[CustomPropertyType.PROCESS]: [...EqualOperatorGroup, ...HasPropertyOperatorGroup],
};

export interface SearchByLocationInterface {
	location: LocationLatLon;
	radius: number;
}

export type SearchPropertyFilterValue =
	| boolean
	| string
	| number
	| string[]
	| SearchByLocationInterface
	| ProcessPropertyStatusEnum;

export interface SearchPropertyFilter {
	id: string;
	operator: SearchOperator;
	unit?: UnitOptionType;
	unitSensitive?: boolean;
	value?: SearchPropertyFilterValue;
}
