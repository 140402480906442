// myReacher account - workspace TEMPLATES
export enum NoteTemplate {
	BLANK_TEMPLATE = 'BLANK_TEMPLATE',
	CLASS_NOTES = 'CLASS_NOTES',
	FAMILY_TO_DOS = 'FAMILY_TO_DOS',
	GROCERY_LIST = 'GROCERY_LIST',
	INTERVIEW_NOTES = 'INTERVIEW_NOTES',
	JOB_OFFER = 'JOB_OFFER',
	LIST_OF_RESTAURANTS = 'LIST_OF_RESTAURANTS',
	MEETING_NOTES = 'MEETING_NOTES',
	MY_BUCKET_LIST = 'MY_BUCKET_LIST',
	PACKING_LIST = 'PACKING_LIST',
	PATIENT_VISIT = 'PATIENT_VISIT',
	QUICK_MEETING = 'QUICK_MEETING',
	RECIPE = 'RECIPE',
	TRIP_ITINERARY = 'TRIP_ITINERARY',
	WEEKS_MENU = 'WEEKS_MENU',
}
