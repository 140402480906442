import { CssUnit } from './types';

export function getValueUnitAndValue(value: string): { value: number; unit: CssUnit } | null {
	const units = Object.values(CssUnit);

	let ret: { value: number; unit: CssUnit } | null = null;

	for (let idx = 0; idx < units.length && !ret; idx++) {
		const unit = units[idx];
		if (value.includes(unit)) {
			const num = Number(value.replace(unit, '').trim());
			if (!Number.isNaN(num)) {
				ret = { value: num, unit };
			}
		}
	}

	return ret;
}
