export type GetObjectPath<
	T,
	S extends string,
	O extends string = S
> = S extends `${infer first}.${infer rest}`
	? first extends keyof T
		? T[first] extends Array<unknown>
			? GetObjectPath<Unwrap<T[first]>, rest, O>
			: GetObjectPath<T[first], rest, O>
		: never
	: S extends keyof T
	? O
	: never;

export function checkPath<S extends string, T>(path: S): GetObjectPath<T, S> {
	return path as unknown as GetObjectPath<T, S>;
}

type Unwrap<T> = T extends Array<infer U> ? U : never;
