import { Injectable } from '@angular/core';
import { BaseApiService } from '~app-client/api/_internal';

@Injectable({ providedIn: 'root' })
export class DiscourseApiService extends BaseApiService {
	protected commonPath = ['users', 'me', 'discourse'];

	public getLoginLink(): Promise<{ redirectUrl: string }> {
		return this.http.post<{ redirectUrl: string }>(this.url('logged-in-link'));
	}

	public getRedirectUrl(ssoKey: string, signatureKey: string): Promise<{ redirectUrl: string }> {
		const body = {
			sso: ssoKey,
			sig: signatureKey,
		};
		return this.http.post<{ redirectUrl: string }>(this.url('login-link-from-forum'), body);
	}

	public get loginRedirectEndpoint(): string {
		return `${this.url('redirect')}?t=${this.paramTime}`;
	}
}
