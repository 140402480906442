import { inject, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ApiDownGuard } from '~app-client/api-down/guards';
import { AppInitializationPageComponent } from '~app-client/app-initialization/page';
import { AppInitializationService } from '~app-client/app-initialization/services';
import { ClientErrorScreenService } from '~app-client/client-error-screen/services';
import { LogOutGuard } from '~app-client/core/guards';
import { EmptyPageComponent, EmptyPageWithOutletComponent } from '~app-client/core/pages';
import { CanAccessHomeGuard, RootRedirectorGuard } from '~app-client/root/guards';

const routes: Routes = [
	{
		path: '',
		component: AppInitializationPageComponent,
		providers: [RootRedirectorGuard, CanAccessHomeGuard],
		children: [
			{
				/**
				 * I did this in order to boost the initial page load.
				 * If we don't have this, we need to wait until the path is resolved.
				 * By doing this, we gain some time of initial loading.
				 */
				path: '',
				pathMatch: 'prefix',
				canMatch: [
					() => {
						return inject(AppInitializationService).initialized === false;
					},
				],
				component: EmptyPageWithOutletComponent,
				children: [
					{
						path: '**',
						component: EmptyPageComponent,
					},
				],
			},
			{
				// once the app is loaded
				path: '',
				children: [
					{
						path: 'client-error',
						loadComponent: async () => {
							const component = await import('~app-client/client-error-screen/pages');
							return component.ClientErrorScreenPageComponent;
						},
						canMatch: [
							() => {
								const service = inject(ClientErrorScreenService);
								console.log('DEBUG SERVICE', service, service.somethingFailed);
								return service.somethingFailed;
							},
						],
						canDeactivate: [() => false],
					},
					{
						path: 'auth',
						loadChildren: async () => {
							const module = await import('~app-client/auth/router');
							return module.AuthRoutingModule;
						},
					},
					{
						path: 'oauth',
						data: { redirectUrl: 'auth/sign-in' },
						canActivate: [LogOutGuard],
						component: EmptyPageComponent,
					},
					{
						path: 'change-password',
						data: { redirectUrl: 'auth/change-password' },
						canActivate: [LogOutGuard],
						component: EmptyPageComponent,
					},
					{
						path: 'confirm-email',
						data: { redirectUrl: 'auth/confirm-email' },
						canActivate: [LogOutGuard],
						component: EmptyPageComponent,
					},
					{
						path: 'home',
						canMatch: [CanAccessHomeGuard],
						loadChildren: async () => {
							const module = await import('~app-client/home/router');
							return module.HomeRoutingModule;
						},
					},
					{
						path: 'legal',
						loadChildren: async () => {
							const module = await import('~app-client/legal/router');
							return module.LegalRouterModule;
						},
					},
					{
						path: 'version-error',
						loadChildren: async () => {
							const module = await import('~app-client/version-error/router');
							return module.VersionErrorRouterModule;
						},
					},
					{
						path: 'api-down',
						loadChildren: async () => {
							const module = await import('~app-client/api-down/router');
							return module.ApiDownRouterModule;
						},
						canActivate: [ApiDownGuard],
					},
					{
						path: 'api-not-reachable',
						loadChildren: async () => {
							const module = await import('~app-client/api-not-reachable/router');
							return module.ApiNotReachableRouterModule;
						},
					},
					{
						path: 'delete-account',
						loadChildren: async () => {
							const module = await import('~app-client/delete-account/router');
							return module.DeleteAccountRouterModule;
						},
					},
					{
						// redirector
						path: '**',
						component: EmptyPageComponent,
						canActivate: [RootRedirectorGuard],
					},
				],
			},
		],
	},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			paramsInheritanceStrategy: 'always',
		}),
	],
	exports: [RouterModule],
})
export class RootRouterModule {}
