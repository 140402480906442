import { CustomPropertyType } from '@reach/interfaces';

export interface AnalyticsEventPropertyAdded {
	propertyType: CustomPropertyType;
}

export interface AnalyticsEventPropertyCreated {
	propertyType: CustomPropertyType;
}

export interface AnalyticsEventPropertyRenamed {
	propertyType: CustomPropertyType;
}

export interface AnalyticsEventPropertyOptionsUpdated {
	propertyType: CustomPropertyType;
}

export interface AnalyticsEventPropertyValueUpdated {
	propertyType: CustomPropertyType;
}

export interface AnalyticsEventPropertyRemovedFromNode {
	propertyType: CustomPropertyType;
}

export interface AnalyticsEventPropertyDeleted {
	propertyType: CustomPropertyType;
}
