import { Injectable } from '@angular/core';
import { BaseApiService } from '~app-client/api/_internal';

@Injectable({ providedIn: 'root' })
export class BulkNodesApiService extends BaseApiService {
	protected commonPath = ['bulk', 'nodes'];

	public deleteNodesPermanently(ids: number[], workspaceId: number): Promise<void> {
		const body = { nodes: ids, workspaceID: workspaceId };
		return this.http.patch<void>(this.url('delete'), body);
	}
}
