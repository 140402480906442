import { Injectable } from '@angular/core';
import { BaseApiService } from '~app-client/api/_internal';

@Injectable({ providedIn: 'root' })
export class SubscriptionsApiService extends BaseApiService {
	protected commonPath = ['subscriptions'];

	public addEmailToSubscriptions(email: string): Promise<void> {
		const body = { email };
		return this.http.post(this.url(), body);
	}
}
