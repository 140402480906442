import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class VersionErrorService {
	private _shouldNavigateToError = false;

	public get shouldNavigateToError(): boolean {
		return this._shouldNavigateToError;
	}

	constructor(private readonly router: Router) {}

	public navigateToPage(): void {
		this._shouldNavigateToError = true;

		this.router.navigate(['/', 'version-error'], {});
	}
}
