import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiAdminInterface, ErrorCodes } from '@reach/interfaces';
import { BehaviorSubject } from 'rxjs';
import {
	ApiReachabilityStatus,
	apiReachabilityStatusParser,
} from '~app-client/api-not-reachable/types';
import { AuthApiService } from '~app-client/api/services';
import { asApiError } from '~app-client/api/utils';

@Injectable({ providedIn: 'root' })
export class ApiNotReachableService {
	private readonly status$$ = new BehaviorSubject<ApiReachabilityStatus | null>(null);
	public readonly status$ = this.status$$.asObservable();
	public get currentStatus(): ApiReachabilityStatus | null {
		return this.status$$.value;
	}

	private redirectUrl: string | null = null;

	constructor(private authApi: AuthApiService, private router: Router) {}

	public setRedirectUrl(path: string): void {
		this.redirectUrl = path;
	}

	public navigate(data: ApiAdminInterface): void {
		const status = apiReachabilityStatusParser(data);

		this.status$$.next(status);

		if (!data.allowRequests) {
			this.router.navigate(['/', 'api-not-reachable'], {});
		}
	}

	public async updateStatus(): Promise<void> {
		try {
			await this.authApi.ping();

			this.status$$.next(null);

			this.router.navigateByUrl(this.redirectUrl || '/');
		} catch (_error) {
			const error = asApiError(_error);
			if (
				error.isReachError &&
				error.error.code === ErrorCodes.MIDDLEWARE_REQUESTS_NOT_ALLOWED
			) {
				const status = apiReachabilityStatusParser(
					error.error.meta?.status as ApiAdminInterface
				);
				this.status$$.next(status);
			} else {
				this.status$$.next(null);
				this.router.navigateByUrl(this.redirectUrl);
			}
		}
	}
}
