import { WorkspaceType } from '@reach/interfaces';

export interface AnalyticsEventNodeUpdated {
	nodeUpdatedProperty: string;
	nodeUpdatedExists: boolean;
	workspaceType: WorkspaceType;
}

export interface AnalyticsEventNodeSentToTrash {
	count: number;
}

export interface AnalyticsEventNodeRestored {
	count: number;
}

export interface AnalyticsEventNodeWorkspaceChanged {
	fromWorkspaceType: WorkspaceType;
	toWorkspaceType: WorkspaceType;
}
