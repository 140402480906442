import { Injectable } from '@angular/core';
import { BaseApiService } from '~app-client/api/_internal';

@Injectable({ providedIn: 'root' })
export class AvatarApiService extends BaseApiService {
	protected commonPath = ['avatar'];

	public changeAvatar(image: File): Promise<{ imageUrl: string; thumbnailUrl: string }> {
		const formData = new FormData();
		formData.append('image', image);

		return this.http.post<{ imageUrl: string; thumbnailUrl: string }>(this.url(), formData);
	}

	public delete(): Promise<void> {
		return this.http.delete<void>(this.url());
	}

	public addUrl(url: string): Promise<{ imageUrl: string; thumbnailUrl: string }> {
		const body = { url };
		return this.http.post(this.url('url'), body);
	}
}
