import { EnigmaAnswerInterface } from './enigma-answer.interface';
import { EnigmaQuestionCategoryEnum } from './enigma-question-category.enum';
import { EnigmaQuestionSubcategoriesEnum } from './enigma-question-subcategories.enum';

export interface EnigmaQuestionInterface {
	id: string;
	category: EnigmaQuestionCategoryEnum;
	subcategory: EnigmaQuestionSubcategoriesEnum;
	title: string;
	timeLimitMs: number;
	answers: Array<EnigmaAnswerInterface>;
}
