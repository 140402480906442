import { inject, Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
	CustomPropertyKeys,
	CustomPropertyLastModifiedBy,
	WorkspaceCreateBody,
	WorkspaceType,
} from '@reach/interfaces';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { TrashApiService, WorkspacesApiService } from '~app-client/api/services';
import { ReachNodeId, SemiPartial } from '~app-client/core/types';
import { isValidNodeId, ReachStorage } from '~app-client/core/utils';
import {
	ExtraWorkspaceType,
	ReachWorkspace,
	ReachWorkspaceType,
} from '~app-client/workspaces/types';
import {
	unassignedWorkspaceId,
	workspaceApiToFrontTransformer,
	workspaceUnasignedNodesTransformer,
} from '~app-client/workspaces/utils';
import { AnalyticsEvent, AnalyticsService } from './analytics';
import { SessionService } from './session';

export enum OrderOptions {
	ALPHABETICAL = 'ALPHABETICAL',
	CREATION_DATE = 'CREATION_DATE',
	LAST_USED = 'LAST_USED',
	SIZE = 'SIZE',
}

/**
 * Service that handles all the logic regarding Workspaces.
 */

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class WorkspacesService {
	private static lastActiveWsIdsStorageKey = 'WorkspaceService-last-active-workspaces-ids';
	private static orderWssStorageKey = 'WorkspaceService-order-key-workspaces';

	private static get orderWorkspacesStorage(): OrderOptions {
		const value = ReachStorage.getItem<OrderOptions>(WorkspacesService.orderWssStorageKey);
		return value || OrderOptions.CREATION_DATE;
	}

	private static readonly typesToTranslate: Partial<Record<ReachWorkspaceType, string>> = {
		[ExtraWorkspaceType.UNASSIGNED_NODES]: 'general.workspaces_names.unassigned',
	};

	public get className(): string {
		return 'WorkspacesService';
	}

	private typesToNameTranslationMap: Partial<Record<ReachWorkspaceType, string>> = {};

	private _loadId = 0;

	private readonly loading$$ = new BehaviorSubject<number>(0);
	private readonly workspaces$$ = new BehaviorSubject<ReachWorkspace[]>([]);

	private readonly activeWorkspaces$$ = new BehaviorSubject<ReachWorkspace[]>([]);

	/**
	 * Whether this service is performing an async action.
	 */
	public readonly loading$ = this.loading$$.asObservable().pipe(map((count) => count > 0));

	/**
	 * List of workspaces.
	 */
	public readonly workspaces$ = this.workspaces$$.asObservable();
	public get workspaces(): ReachWorkspace[] {
		return this.workspaces$$.value;
	}

	public unassignedWorkspace$ = this.workspaces$.pipe(
		map((arr) => {
			return arr.find(({ id }) => id === unassignedWorkspaceId);
		})
	);

	/**
	 * List of the active workspaces.
	 */
	public readonly activeWorkspaces$ = this.activeWorkspaces$$.asObservable().pipe(
		distinctUntilChanged((prev, curr) => {
			const prevArr = prev
				.map(({ id }) => id)
				.sort()
				.join('_');
			const currArr = curr
				.map(({ id }) => id)
				.sort()
				.join('_');
			return prevArr === currArr;
		})
	);
	public get activeWorkspaces(): ReachWorkspace[] {
		return this.activeWorkspaces$$.value;
	}

	private orderKeyWorkspaces$$ = new BehaviorSubject<OrderOptions>(
		WorkspacesService.orderWorkspacesStorage
	);

	public get orderKeyWorkspaces(): OrderOptions {
		return this.orderKeyWorkspaces$$.value;
	}

	public orderKeyWorkspaces$ = this.orderKeyWorkspaces$$.asObservable();

	private readonly transloco = inject(TranslocoService);
	private readonly session = inject(SessionService);
	private readonly analytics = inject(AnalyticsService);
	private readonly workspacesApi = inject(WorkspacesApiService);
	private readonly trashApi = inject(TrashApiService);

	constructor() {
		this.session.loggedOut$.pipe(untilDestroyed(this)).subscribe(() => this.clear());

		Object.keys(WorkspacesService.typesToTranslate)
			.map((type) => type as ReachWorkspaceType)
			.forEach((type) => {
				const i18n = WorkspacesService.typesToTranslate[type];
				if (!!i18n) {
					this.transloco
						.selectTranslate(i18n)
						.pipe(
							filter((name) => name !== i18n),
							untilDestroyed(this)
						)
						.subscribe((name) => {
							this.typesToNameTranslationMap[type] = name;
							this.updateWorkspacesByType(type, { name });
						});
				}
			});
	}

	public getById(id: ReachNodeId): ReachWorkspace | undefined {
		return this.workspaces.find((ws) => ws.id === id);
	}

	public getTrashWorkspace(): ReachWorkspace {
		return this.workspaces.find((ws) => {
			return ws.type === WorkspaceType.TRASH;
		}) as ReachWorkspace;
	}

	/**
	 * Loads the workspaces.
	 * @param force If true, it reloads the data even if it was previously loaded.
	 */
	public async load(force = false): Promise<void> {
		const emptyArr = this.workspaces$$.value.length === 0;
		const performLoad = force || emptyArr;

		if (performLoad) {
			try {
				this._loadId += 1;
				const id = this._loadId;
				await this.increaseLoading(async () => {
					const getAllWorkspacesResponse = await this.workspacesApi.getAll();

					if (id !== this._loadId) {
						return;
					}

					const wsResponse = [
						...getAllWorkspacesResponse.all,
						getAllWorkspacesResponse.trash,
					].map((ws) => workspaceApiToFrontTransformer(ws));

					this.setWorkspaces(
						...wsResponse,
						workspaceUnasignedNodesTransformer(
							getAllWorkspacesResponse.unassigned.count
						)
					);
				});
			} catch (error) {
				console.error(error);
			}
		}
	}

	/**
	 * If the given id is selected, is is deselected.
	 *
	 * Otherwise it is selected.
	 */
	public toggleWorkspaceSelection(id: ReachNodeId): void {
		if (this.isSelected(id)) {
			return this.removeFromSelection(id);
		}

		return this.addToSelection(id);
	}

	/**
	 * @returns Whether the given id is a selected workspace.
	 */
	public isSelected(id: ReachNodeId): boolean {
		return this.activeWorkspaces$$.value.map(({ id }) => id).includes(id);
	}

	/**
	 * Removes the given workspace from the active array.
	 */
	public removeFromSelection(id: ReachNodeId): void {
		const nextWs = this.activeWorkspaces$$.value
			.filter((ws) => ws.id !== id)
			.map((ws) => ws.id);

		this.setActiveWorkspaces(nextWs);
	}

	/**
	 * Adds the given workspace to the active array.
	 */
	public addToSelection(id: ReachNodeId): void {
		const ws = this.workspaces.find(({ id: wsId }) => wsId === id);
		if (!!ws) {
			this.setActiveWorkspaces([...this.activeWorkspaces$$.value, ws].map((ws) => ws.id));
		}
	}

	/**
	 * Sets the active workspaces to the ones given.
	 */
	public setActiveWorkspaces(ids: ReachNodeId[]): void {
		const arr = this.workspaces.filter(({ id }) => ids.includes(id));
		if (arr.length === 0) {
			return;
		}
		const analyticsCount = arr.length;
		const analyticsTypes = [...new Set(arr.map(({ type }) => type))];
		this.analytics.addEvent(AnalyticsEvent.WORKSPACES_SELECTION_CHANGED, {
			workspacesSelectionCount: analyticsCount,
			workspacesSelectionTypes: analyticsTypes,
		});
		this.activeWorkspaces$$.next(arr);
		this.storeActiveWorkspaces(ids);

		const filteredIds = ids.filter((id) => id !== unassignedWorkspaceId);
		if (filteredIds.length > 0) {
			this.workspacesApi.setLastUsedWorkspaces(filteredIds);
		}

		this.workspaces$$.next(
			this.workspaces$$.value.map((ws) => {
				if (ids.includes(ws.id)) {
					return {
						...ws,
						customProperties: {
							...ws.customProperties,
							[CustomPropertyKeys.LAST_SEARCHED]: {
								id: CustomPropertyKeys.LAST_SEARCHED,
								value: Date.now(),
								visible: false,
								lastModifiedBy: CustomPropertyLastModifiedBy.REACH,
								createdAt: 0,
								updatedAt: 0,
							},
						},
					};
				}

				return ws;
			})
		);
	}

	/**
	 * Return a list of workspaces excluding some types.
	 */
	public getWorkspacesList$(excludeTypes?: ReachWorkspaceType[]): Observable<ReachWorkspace[]> {
		return this.workspaces$.pipe(
			map((workspaces) => {
				return workspaces.filter(({ type }) => !(excludeTypes || []).includes(type));
			})
		);
	}

	/**
	 * Substracts 1 from the count property of the given workspaces ids.
	 */
	public decreaseCount(workspacesIds: ReachNodeId[], update = true): void {
		this.workspaces$$.next(
			this.workspaces$$.value.map((workspace) => {
				const currCount = workspace.count;
				const count = workspacesIds.includes(workspace.id) ? currCount - 1 : currCount;

				return {
					...workspace,
					count: Math.max(0, count),
				};
			})
		);

		if (update) {
			this.computeActiveWorkspaces();
		}
	}

	/**
	 * Adds 1 to the count property of the given workspaces ids.
	 */
	public increaseCount(workspacesIds: ReachNodeId[], increase = 1, update = true): void {
		this.workspaces$$.next(
			this.workspaces$$.value.map((workspace) => {
				const currCount = workspace.count;
				const count = workspacesIds.includes(workspace.id)
					? currCount + increase
					: currCount;

				return {
					...workspace,
					count: Math.max(0, count),
				};
			})
		);

		if (update) {
			this.computeActiveWorkspaces();
		}
	}

	public async sendNodesToTrash(nodeIds: ReachNodeId[]): Promise<void> {
		await this.trashApi.moveToTrash(nodeIds);
		await this.load(true);
	}

	/**
	 * Creates a workspace given its data.
	 */
	public async createWorkspace(data: WorkspaceCreateBody): Promise<ReachWorkspace> {
		try {
			const workspace = await this.increaseLoading(async () => {
				const response = await this.workspacesApi.create(data);
				await this.load(true);
				this.setActiveWorkspaces([response.id]);
				return this.getById(response.id);
			});
			return workspace;
		} catch (error) {
			console.error(error);
		}
	}

	/**
	 * Removes a workspace given its id.
	 * @param id The id of the workspace to remove.
	 * @returns The ids of the nodes that will be moved to unassigned.
	 */
	public async removeWorkspace(id: ReachNodeId): Promise<ReachNodeId[]> {
		try {
			return await this.increaseLoading(async () => {
				const ids = await this.workspacesApi.deleteWorkspace(id);
				const unassignedWs = this.workspaces.find(({ id }) => id === unassignedWorkspaceId);
				this.updateData(
					[
						{
							id: unassignedWs.id,
							count: unassignedWs.count + ids.length,
						},
					],
					false
				);
				this.removeFromWorkspaces(id);
				this.load(true);
				return ids;
			});
		} catch (error) {
			console.error(error);
		}
	}

	public async addNodesToWorkspaces(
		workspaceId: ReachNodeId,
		nodeIds: ReachNodeId[]
	): Promise<void> {
		await this.workspacesApi.addNodesToWorkspace(workspaceId, nodeIds);
		await this.load(true);
	}

	public async removeNodesFromWorkspaces(
		workspaceId: ReachNodeId,
		nodeIds: ReachNodeId[]
	): Promise<void> {
		await this.workspacesApi.removeNodesFromWorkspace(workspaceId, nodeIds);
		await this.load(true);
	}

	/**
	 * Internally updates the data of the given workspaces.
	 */
	public updateData(
		workspaces: SemiPartial<ReachWorkspace, 'id'>[],
		computeActiveWorkspaces = true
	): void {
		this.workspaces$$.next(
			this.workspaces$$.value.map((ws) => {
				const dataToOverride = workspaces.find(({ id }) => id === ws.id) || {};
				return {
					...ws,
					...dataToOverride,
				};
			})
		);

		if (computeActiveWorkspaces) {
			this.computeActiveWorkspaces();
		}
	}

	/**
	 * Adds workspaces to the array.
	 *
	 * @param workspaces The workspaces to add.
	 */
	private addToWorkspaces(...workspaces: ReachWorkspace[]): void {
		const newIds = workspaces.map((ws) => ws.id);
		const oldWs = this.workspaces$$.value.filter((oldWs) => !newIds.includes(oldWs.id));
		this.workspaces$$.next([...oldWs, ...workspaces]);
		this.computeActiveWorkspaces();
	}

	/**
	 * Sets the given workspaces to the array.
	 *
	 * @param workspaces The workspaces to add.
	 */
	private setWorkspaces(...workspaces: ReachWorkspace[]): void {
		const cleanData = workspaces.map((ws) => {
			const name = this.typesToNameTranslationMap[ws.type] || ws.name;
			return {
				...ws,
				name,
			};
		});
		this.workspaces$$.next(cleanData);
		this.computeActiveWorkspaces();
	}

	/**
	 * Removes a workspace from the array.
	 *
	 * @param id The id of the workspace to remove.
	 */
	private removeFromWorkspaces(...ids: ReachNodeId[]): void {
		this.workspaces$$.next(
			this.workspaces$$.value.filter(({ id: wsId }) => !ids.includes(wsId))
		);
		this.computeActiveWorkspaces();
	}

	private updateWorkspacesByType(
		type: ReachWorkspaceType,
		data: Partial<Omit<ReachWorkspace, 'id' | 'type'>>
	): void {
		const ws = this.workspaces
			.filter(({ type: wsType }) => wsType === type)
			.map((ws) => {
				return {
					id: ws.id,
					...data,
				};
			});

		this.updateData(ws, false);
	}

	/**
	 * Increases the loading count while an async function is being performed.
	 */
	private async increaseLoading<T>(cb: () => Promise<T>): Promise<T> {
		try {
			this.loading$$.next(this.loading$$.value + 1);
			const res = await cb();
			return res;
		} catch (error) {
			throw error;
		} finally {
			this.loading$$.next(Math.max(0, this.loading$$.value - 1));
		}
	}

	/**
	 * Clears the data (usually due to a log-out).
	 */
	private clear(): void {
		this._loadId = 0;
		this.workspaces$$.next([]);
		this.activeWorkspaces$$.next([]);
		ReachStorage.deleteItem(WorkspacesService.lastActiveWsIdsStorageKey);
		this.loading$$.next(0);
	}

	/**
	 * Computes the next active workspaces.
	 */
	private computeActiveWorkspaces(): void {
		const currentWs = [...this.workspaces$$.value.map((ws) => ({ ...ws }))];
		const currentWsIds = currentWs.map(({ id }) => id);

		// Set the active workspaces to the last used (if any)
		const validCurrentActiveWorkspaces = this.activeWorkspaces$$.value
			.filter((ws) => {
				return currentWsIds.includes(ws.id);
			})
			.map(({ id }) => {
				return currentWs.find((ws) => ws.id === id);
			});

		if (validCurrentActiveWorkspaces.length > 0) {
			this.activeWorkspaces$$.next(validCurrentActiveWorkspaces);
			return;
		} else {
			// get last selected or first in array
			const lastActiveWorkspacesSaved = this.getLastActiveWorkspace();
			if (lastActiveWorkspacesSaved.length > 0) {
				this.activeWorkspaces$$.next(lastActiveWorkspacesSaved);
				return;
			}
		}
	}

	private getLastActiveWorkspace(): ReachWorkspace[] {
		const currentWorkspaces = [...this.workspaces];
		try {
			const stored = ReachStorage.getItem(WorkspacesService.lastActiveWsIdsStorageKey);
			if (Array.isArray(stored)) {
				const currentIds = currentWorkspaces.map(({ id }) => id);
				const storedArray: ReachNodeId[] = stored
					.filter((id) => isValidNodeId(id, [unassignedWorkspaceId]))
					.filter((id) => {
						return currentIds.includes(id);
					});
				if (storedArray.length === 0) {
					throw 'no last stored workspaces';
				}

				return storedArray.map((id) => {
					return currentWorkspaces.find((ws) => ws.id === id);
				});
			} else {
				throw 'Stored not an array';
			}
		} catch (error) {
			console.error(error);
			const ret = currentWorkspaces[0] ? [currentWorkspaces[0]] : [];
			this.storeActiveWorkspaces(ret.map(({ id }) => id));
			return ret;
		}
	}

	private storeActiveWorkspaces(ids: ReachNodeId[]): void {
		ReachStorage.setItem(WorkspacesService.lastActiveWsIdsStorageKey, ids);
	}

	public setOrderWorkspaces(order: OrderOptions): void {
		this.orderKeyWorkspaces$$.next(order);
		ReachStorage.setItem(WorkspacesService.orderWssStorageKey, order);

		if (order === OrderOptions.LAST_USED) {
			this.workspaces$$.next(
				this.workspaces$$.value.map((ws) => {
					return {
						...ws,
						lastSearch:
							(ws.customProperties?.[CustomPropertyKeys.LAST_SEARCHED]
								?.value as number) ?? Number.NEGATIVE_INFINITY,
					};
				})
			);
		}
	}
}
