export enum Hearing {
	APP_STORE = 'APP_STORE',
	BETA_LIST = 'BETA_LIST',
	BLOG = 'BLOG',
	DISCORD = 'DISCORD',
	FACEBOOK = 'FACEBOOK',
	FUCKING_HOMEPAGE = 'FUCKING_HOMEPAGE',
	GOOGLE = 'GOOGLE',
	HACKER_NEWS = 'HACKER_NEWS',
	INSTAGRAM = 'INSTAGRAM',
	/** Invalid value: comes from a non-meaningful input */
	INVALID_VALUE = 'INVALID_VALUE',
	LINKEDIN = 'LINKEDIN',
	OTHER = 'OTHER',
	PERSON = 'PERSON',
	PRODUCT_HUNT = 'PRODUCT_HUNT',
	REDDIT = 'REDDIT',
	SLACK = 'SLACK',
	TWITTER = 'TWITTER',
	USEFUL_INTERWEB = 'USEFUL_INTERWEB',
	YOUTUBE = 'YOUTUBE',
}
