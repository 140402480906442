export interface ReachPanEventPoint {
	x: number;
	y: number;
}

export interface ReachPanEvent {
	startingPoint: ReachPanEventPoint;
	rawPoint: ReachPanEventPoint;
	point: ReachPanEventPoint;
	previousHandlers: boolean;
}
