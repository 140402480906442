export interface BatchIdQuery {
	id: string;
}

export interface SyncBatchResponse {
	id: string;
}

export type BatchStatus = 'pending' | 'preprocessing' | 'started' | 'finalizing' | 'finished';

export interface BatchStatusResponse {
	id: string;
	status: BatchStatus;
	total_operations: number;
	finished_operations: number;
	errored_operations: number;
	submitted_at: Date;
	completed_at: Date;
}
