import type * as cheerio from 'cheerio';

export enum HtmlTag {
	// MISC
	DIV = 'div',

	// PARAGRAPH
	PARAGRAPH = 'p',

	// IMAGES
	IMG = 'img',

	// TABLES
	TABLE = 'table',
	TABLE_ROW = 'tr',
	TABLE_CELL = 'td',
	TABLE_CELL_2 = 'th',

	// ANCHORS
	ANCHOR = 'a',
	ANCHOR_2 = 'reach-anchor',

	// HEADERS
	HEADER_1 = 'h1',
	HEADER_2 = 'h2',
	HEADER_3 = 'h3',
	HEADER_4 = 'h4',
	HEADER_5 = 'h5',
	HEADER_6 = 'h6',

	// LISTS
	ORDERED_LIST = 'ol',
	UNORDERED_LIST = 'ul',
	LIST_ITEM = 'li',

	// DIVIDERS
	HORIZONTAL_RULE = 'hr',

	// CODE
	PREFORMATTED = 'pre',

	// QUOTES
	BLOCK_QUOTE = 'blockquote',

	// FORMATTING
	UNDERLINE = 'u',
	EMPHASIS = 'em',
	STRIKE = 's',
	BOLD = 'b',
	STRONG = 'strong',
}

export type ReachHtmlNodeBase = cheerio.NodeWithChildren & {
	data: string;
	name?: HtmlTag;
	attribs: {
		class?: string;
		style?: string;
		href?: string;
		['data-checked']?: string;
		['data-attachment-id']?: string;
	};
};

export type ReachHtmlNode = ReachHtmlNodeBase;
