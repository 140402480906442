import { CustomPropertyDateRepeatsEnum } from '../custom-property';
import { Nullable } from '../typescript';
import { CreateReminderItem } from './requests.interface';

export interface ReminderInterface {
	id: string;
	reminderTime: Date;
	type: ReminderTypeEnum;
	nodeId: string;
	customPropertyId: string;
	timezone: string;
	active: boolean;
	allDay: boolean;
	repeat: CustomPropertyDateRepeatsEnum;
	lastSent: Nullable<Date>;
}

export enum ReminderTypeEnum {
	ON_THE_DAY = 'ON_THE_DAY',
	FIVE_MINUTES_BEFORE = 'FIVE_MINUTES_BEFORE',
	TEN_MINUTES_BEFORE = 'TEN_MINUTES_BEFORE',
	FIFTEEN_MINUTES_BEFORE = 'FIFTEEN_MINUTES_BEFORE',
	THIRTY_MINUTES_BEFORE = 'THIRTY_MINUTES_BEFORE',
	ONE_HOUR_BEFORE = 'ONE_HOUR_BEFORE',
	TWO_HOURS_BEFORE = 'TWO_HOURS_BEFORE',
	DAY_BEFORE = 'DAY_BEFORE',
	TWO_DAYS_BEFORE = 'TWO_DAYS_BEFORE',
	ONE_WEEK_BEFORE = 'ONE_WEEK_BEFORE',
	TWO_WEEKS_BEFORE = 'TWO_WEEKS_BEFORE',
	ONE_MONTH_BEFORE = 'ONE_MONTH_BEFORE',
}

export interface RemindersToUpdate {
	toAdd: CreateReminderItem[];
	toDelete: ReminderInterface['id'][];
}
