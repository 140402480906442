import { CustomPropertyCreateRequest } from '../../custom-property';
import { NodeSubtypeId } from '../../node-subtypes';

export class ZapierCreateNoteActionInterface {
	name!: string;
	body!: string;
	attachmentBundleUrl?: string;
	workspaceIds!: string[];
	nodeSubtypeId?: NodeSubtypeId;
	customProperties!: CustomPropertyCreateRequest[];
	comments!: string[];
	callbackUrl!: string;
}
