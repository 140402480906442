/**
 * Removes undefinedElements
 * @param obj Object to remove undefined properties
 * ```
 * 	test('remove undefined from obj', () => {
 * 		expect(removeUndefinedProps({a: 2, b: undefined})).toEqual({a:2});
 * 	});
 * 	```
 */
export const removeUndefinedProps = <T>(obj: T): T => {
	Object.keys(obj as Record<string, unknown>).forEach((key) => {
		if (obj[key] === undefined) {
			delete obj[key];
		}
		/*if (typeof obj[key] === 'string' && obj[key].length === 0) {
			delete obj[key];
		}*/
	});
	return obj;
};
