export interface AnalyticsEventTutorialTagSelected {
	tutorialTag: string;
}

export interface AnalyticsEventTutorialVideoOpened {
	tutorialVideoId: string;
}

export interface AnalyticsEventTutorialVideoClosed {
	tutorialVideoId: string;
	tutorialSeen: boolean;
}
