export enum SettingsKey {
	GENERAL = 'GENERAL',
	PROFILE_ACCOUNT = 'PROFILE_ACCOUNT',
	VISUALIZER = 'VISUALIZER',
	PLAN = 'PLAN',
	PRIVACY_SECURITY = 'PRIVACY_SECURITY',
	CONNECTED_APPS = 'CONNECTED_APPS',
	NOTIFICATIONS = 'NOTIFICATIONS',
	DASHBOARD = 'DASHBOARD',
	ACCESSIBILITY = 'ACCESSIBILITY',
	INVITATIONS = 'INVITATIONS',
}
