import { inject, NgModule, Pipe, PipeTransform } from '@angular/core';
import { CLIENT_ENVIRONMENT } from '~app-client/core/tokens';

@Pipe({ name: 'cdn' })
export class CdnPipe implements PipeTransform {
	private readonly environment = inject(CLIENT_ENVIRONMENT);
	private readonly cdnUrl = this.environment.imageCdn?.trim();
	private readonly useCdn = !!this.cdnUrl;

	transform(targetUrl: string, element?: HTMLElement, quality?: number): string {
		if (this.useCdn && !!targetUrl && !targetUrl.startsWith(this.environment.apiBaseUrl)) {
			const width = element?.clientWidth;
			const height = element?.clientHeight;
			const density = window.devicePixelRatio;

			const cleanTargetUrl = targetUrl;
			const url = new URL(this.cdnUrl);
			url.searchParams.append('url', cleanTargetUrl);

			if (width && height) {
				url.searchParams.append('w', `${width * density}`);
				url.searchParams.append('h', `${height * density}`);
			}

			if (Number.isInteger(quality)) {
				url.searchParams.append('q', `${Math.max(0, Math.min(100, quality))}`);
			}

			return url.href;
		}

		return targetUrl;
	}
}

@NgModule({
	declarations: [CdnPipe],
	providers: [CdnPipe],
	exports: [CdnPipe],
})
export class CdnModule {}
