import * as cheerio from 'cheerio';
import * as chroma from 'chroma-js';

export const XML_ELEMENT_2_HTML_ELEMENT_MAP: Record<string, string> = {
	div: 'p',
};

let nFilesAttached: number;
export const TEXT_DECORATION_NAME_2_HTML_NAME_MAP: Record<string, string> = {
	underline: 'u',
	'line-through': 's',
	'text-align:left;': 'ql-align-left',
	'text-align:center;': 'ql-align-center',
	'text-align:right;': 'ql-align-right',
	'text-align:justify;': 'ql-align-justify',
};
export const COLOR_VECTOR: string[] = [
	'#2D70C0',
	'#02A2CC',
	'#00BFD3',
	'#57C042',
	'#9CC810',
	'#D1CA00',
	'#FCC600',
	'#F58F00',
	'#F1592A',
	'#E72437',
];
export const COLOR_MAP: Record<string, string> = {
	'#2D70C0': 'ql-color-reach-imp-1',
	'#02A2CC': 'ql-color-reach-imp-2',
	'#00BFD3': 'ql-color-reach-imp-3',
	'#57C042': 'ql-color-reach-imp-4',
	'#9CC810': 'ql-color-reach-imp-5',
	'#D1CA00': 'ql-color-reach-imp-6',
	'#FCC600': 'ql-color-reach-imp-7',
	'#F58F00': 'ql-color-reach-imp-8',
	'#F1592A': 'ql-color-reach-imp-9',
	'#E72437': 'ql-color-reach-imp-10',
};

export const BACKGROUND_COLOR_MAP: Record<string, string> = {
	'#2D70C0': 'ql-bg-reach-imp-1',
	'#02A2CC': 'ql-bg-reach-imp-2',
	'#00BFD3': 'ql-bg-reach-imp-3',
	'#57C042': 'ql-bg-reach-imp-4',
	'#9CC810': 'ql-bg-reach-imp-5',
	'#D1CA00': 'ql-bg-reach-imp-6',
	'#FCC600': 'ql-bg-reach-imp-7',
	'#F58F00': 'ql-bg-reach-imp-8',
	'#F1592A': 'ql-bg-reach-imp-9',
	'#E72437': 'ql-bg-reach-imp-10',
};

const getMostSimilarColor = (color: string, highlited: boolean): string => {
	let min = 100;
	let min_color = color;
	COLOR_VECTOR.forEach((i) => {
		const deltaE = chroma.deltaE(color, i);
		if (deltaE < min) {
			min_color = i;
			min = deltaE;
		}
	});
	const res = highlited
		? BACKGROUND_COLOR_MAP[min_color] || color
		: COLOR_MAP[min_color] || color;
	return res;
};

const parseTextSize = (size: string): string => {
	//Mides: del 8 al 96 [8,9,10,	12,18,20,	24,30,36,	48,64,72,96]
	const num = parseInt(size);
	if (num <= 10) {
		return 'ql-size-reach-s';
	} else if (num <= 20) {
		return `ql-size-reach-m`;
	} else if (num <= 36) {
		return `ql-size-reach-l`;
	} else {
		return `ql-size-reach-xl`;
	}
};
const parsePaddingSize = (size: number): string => {
	const tab = size / 40;
	if (tab > 8) return `ql-indent-8`;
	return `ql-indent-${tab}`;
};

const parseFontType = (font: string): string => {
	if (font.search('roman') !== -1) {
		return 'ql-font-reach-serif';
	} else {
		return 'ql-font-reach-monospace';
	}
};

export const TAGS_TO_REMOVE: string[] = ['colgroup'];

const tagShouldBeRemoved = (tag: string): boolean => {
	return TAGS_TO_REMOVE.includes(tag);
};

const correctElementTag = (element: cheerio.Node): void => {
	const tag = element['name'] as string;
	const newTag = XML_ELEMENT_2_HTML_ELEMENT_MAP[tag] || tag;
	element['name'] = newTag;
};

const correctElementStyle = (element: cheerio.Node): void => {
	if (element && element['attribs'] && element['attribs']['style']) {
		element['attribs'] = Object.assign({}, element['attribs']);
		const info = element['attribs']['style'];
		element['attribs'] = Object.assign({}, element['attribs']);
		const og = element['attribs'];
		let edited = false;
		element['attribs'] = {};
		if (info.search('text-decoration') !== -1) {
			const txt = info.substring(info.indexOf('text-decoration: ') + 17, info.indexOf(';'));
			element['name'] = TEXT_DECORATION_NAME_2_HTML_NAME_MAP[txt] || txt;
			element['attribs'] = {};
			edited = true;
		} else {
			let typeClass = '';
			if (info.search('font-size') !== -1) {
				const size = info.substring(info.indexOf('font-size: ') + 11, info.indexOf('px;'));
				typeClass += ' ' + parseTextSize(size);
				edited = true;
			}
			if (info.search('--en-highlight') !== -1) {
				const color = info.substring(info.indexOf('#'), info.length - 1);
				if (chroma.valid(color)) {
					typeClass += ' ' + getMostSimilarColor(color, true);
					edited = true;
				}
			}
			if (info.search(`color`) !== -1 && info.search('--en-highlight') === -1) {
				const color: string = info.substring(info.indexOf('color: ') + 7, info.length);
				let finCol;
				if (color.search('#') !== -1) {
					finCol = color.substring(color.indexOf('#'), color.indexOf('#') + 7);
				} else {
					finCol = color.substring(0, color.indexOf(')') + 1);
				}
				if (chroma.valid(finCol)) {
					typeClass += ' ' + getMostSimilarColor(finCol, false);
					edited = true;
				}
			}
			if (info.search('font-family') !== -1) {
				const font = info.substring(info.indexOf('font-family: ') + 13, info.indexOf(';'));
				typeClass += ' ' + parseFontType(font);
				edited = true;
			}
			if (info.search('padding-left') !== -1) {
				const size = parseInt(
					info.substring(info.indexOf('padding-left: ') + 14, info.indexOf('px;'))
				);
				typeClass += ' ' + parsePaddingSize(size);
				edited = true;
			}
			if (info.search('text-align') !== -1) {
				typeClass += ' ' + TEXT_DECORATION_NAME_2_HTML_NAME_MAP[info] || info;
				edited = true;
			}

			edited ? (element['attribs'] = { class: typeClass }) : [];
		}
		!edited ? (element['attribs'] = og) : [];
	}
};

const correctBreakLine = (element: cheerio.Node): void => {
	const tag = element['name'] as string;
	if (tag && tag === 'br') {
		element['attribs'] = Object.assign({}, element['attribs']);
		element['attribs'] = {};
	}
};

const correctTickBox = (element: cheerio.Node | cheerio.Element): void => {
	const tag = element['name'] as string;
	if (tag && tag.search('ul') !== -1) {
		element['attribs'] = Object.assign({}, element['attribs']);
		const attr = element['attribs'];
		element['attribs'] = {};
		if (attr['style'] && attr['style'].search('--en-todo') != -1)
			element['attribs'] = { 'data-checked': 'true' };
	} else if (tag && tag.search('li') !== -1) {
		const info = element['attribs']['style'];
		if (info) {
			const bool = info.substring(info.indexOf('--en-checked:') + 13, info.indexOf(';'));
			element['attribs'] = Object.assign({}, element['attribs']);
			element['attribs'] = {};
			if (bool === 'true') {
				element['attribs'] = { 'data-checked': 'true' };
			} else if (bool === 'false') {
				element['attribs'] = { 'data-checked': 'false' };
			}
		}
	} else if (tag && tag.search('en-media') !== -1) {
		const filename = 'reachFile-' + String(nFilesAttached);
		const $ = cheerio.load(`<p>${filename}</p>`, null, false);
		const firstQuery = $('p');
		firstQuery.append(...(element as cheerio.NodeWithChildren).childNodes);
		firstQuery['attribs'] = Object.assign({}, firstQuery['attribs']);
		const span = $.parseHTML($.html());
		(element as cheerio.Element).children = span;
		element['attribs'] = {};
		element['name'] = 'p';
		nFilesAttached++;
	}
};
export const parseElement = (
	element: cheerio.Node | cheerio.Element
): cheerio.Node | cheerio.Element | null => {
	const elementAsElement = element as cheerio.Element;
	const hasChildren = elementAsElement?.children?.length > 0;
	if (hasChildren) {
		elementAsElement.children = parseChildren(elementAsElement.children);
	}

	correctElementTag(element);
	correctElementStyle(element);
	correctBreakLine(element);
	correctTickBox(element);

	// TODO: remove some tags
	return element;
};

const parseChildren = (
	elements: (cheerio.Node | cheerio.Element)[]
): (cheerio.Node | cheerio.Element)[] => {
	return elements
		.filter((element) => {
			return !tagShouldBeRemoved(element['name']);
		})
		.map((element) => {
			return parseElement(element);
		})
		.filter((element) => {
			return !!element; // remove null elements
		}) as (cheerio.Node | cheerio.Element)[];
};

export const xhml2html = (xhml: string): string => {
	//console.log(xhml);
	const $ = cheerio.load(xhml, { xmlMode: true }, false);
	const cleanXhml = $('en-note').html() || '';
	const xhmlElements = $.parseHTML(cleanXhml);
	nFilesAttached = 0;
	const parsedChilds = parseChildren(xhmlElements);
	const loadedSlate = cheerio.load(parsedChilds);
	const parsed = loadedSlate.html();
	//console.log(parsed);
	return parsed;
};

/**
 * this is just to easily test the script with:
 * `npx nodemon --exec npx ts-node ./src/parsers/xhml-to-html.ts`
 */
(() => {})();
