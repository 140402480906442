export type AnalyticsEventRegisterStart = {
	withReferal: boolean;
};

export type AnalyticsEventRegisterSuccessProvider = 'REACH' | 'GOOGLE' | 'APPLE';

export interface AnalyticsEventRegisterSuccess {
	provider: AnalyticsEventRegisterSuccessProvider;
	withReferal: boolean;
}

export type AnalyticsEventRegisterCancel = {
	withReferal: boolean;
};
