/**
 * Parse environment value
 * @param key Environment name to parse with `process.env[key]`
 */
export const parseEnv = (key: string): string | undefined => {
	if (process.env[key]) {
		return process.env[key] as string;
	}
	// console.error(`Key ${key} not found in enviroment. Using default.`);
	return undefined;
};
