import { CommonModule, DOCUMENT } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	EventEmitter,
	inject,
	OnInit,
	Output,
} from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as FontFaceObserver from 'fontfaceobserver';
import { BehaviorSubject, combineLatest, Observable, of, timer, firstValueFrom } from 'rxjs';
import { debounceTime, filter, map, switchMap, take, tap, timeout } from 'rxjs/operators';
import { CodepushService, SessionService, SettingsService } from '~app-client/core/services';
import {
	CapacitorSecurityProvider,
	SecurityProviderStatus,
} from '@capacitor-community/security-provider';
import { HotToastService } from '@ngneat/hot-toast';

export enum ItemsToLoad {
	FONT = 'FONT',
	ICONS = 'ICONS',
	SESSION = 'SESSION',
	SETTINGS = 'SETTINGS',
	CODE_PUSH = 'CODE_PUSH',
	ANDROID_SECURITY = 'ANDROID_SECURITY',
}

enum ItemsToLoadState {
	LOADING = 'loading',
	FAILED = 'failed',
	FINISHED = 'finished',
}

@UntilDestroy()
@Component({
	selector: 'app-initialization',
	templateUrl: './app-initialization.component.html',
	styleUrls: ['./app-initialization.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [CommonModule],
})
export class AppInitializationComponent implements OnInit {
	private readonly stateMap: Record<ItemsToLoad, BehaviorSubject<ItemsToLoadState>> = {
		[ItemsToLoad.FONT]: new BehaviorSubject<ItemsToLoadState>(ItemsToLoadState.LOADING),
		[ItemsToLoad.ICONS]: new BehaviorSubject<ItemsToLoadState>(ItemsToLoadState.LOADING),
		[ItemsToLoad.SESSION]: new BehaviorSubject<ItemsToLoadState>(ItemsToLoadState.LOADING),
		[ItemsToLoad.SETTINGS]: new BehaviorSubject<ItemsToLoadState>(ItemsToLoadState.LOADING),
		[ItemsToLoad.CODE_PUSH]: new BehaviorSubject<ItemsToLoadState>(ItemsToLoadState.LOADING),
		[ItemsToLoad.ANDROID_SECURITY]: new BehaviorSubject<ItemsToLoadState>(
			ItemsToLoadState.LOADING
		),
	};

	private getState$(key: ItemsToLoad): Observable<ItemsToLoadState> {
		return this.stateMap[key].asObservable();
	}

	private readonly logoLoaded$$ = new BehaviorSubject<boolean>(false);
	public readonly logoLoaded$ = this.logoLoaded$$.asObservable();

	public readonly loaderVisible$ = this.logoLoaded$.pipe(
		filter((logoLoaded) => logoLoaded),
		debounceTime(1000)
	);

	public readonly itemsFinished$ = combineLatest(
		Object.values(ItemsToLoad).map((key) => {
			return this.getState$(key).pipe(map((state) => ({ key, state })));
		})
	).pipe(
		map((states) => {
			return states.filter((item) => item.state !== ItemsToLoadState.LOADING);
		}),
		tap((finishedStates) => {
			console.debug(
				'finished items:',
				finishedStates
					.filter((item) => item.state === ItemsToLoadState.FINISHED)
					.map((item) => item.key)
					.join(', ')
			);
		}),
		filter((finishedStates) => {
			return finishedStates.length === Object.values(ItemsToLoad).length;
		}),
		map((finishedStates) => {
			return finishedStates
				.filter((item) => item.state === ItemsToLoadState.FAILED)
				.map((items) => items.key);
		})
	);

	public readonly animationFinished$ = this.loaderVisible$.pipe(
		filter((visible) => visible),
		switchMap(() => {
			return timer(1000);
		}),
		switchMap(() => this.itemsFinished$)
	);

	public showProgress$ = this.getState$(ItemsToLoad.CODE_PUSH).pipe(
		map((state) => {
			return state !== ItemsToLoadState.LOADING;
		})
	);

	private needsRestart$$ = new BehaviorSubject<boolean>(false);
	public needsRestart$ = this.loaderVisible$.pipe(
		switchMap((visible) => {
			if (!visible) {
				return of(false);
			}

			return timer(500).pipe(
				switchMap(() => {
					return this.needsRestart$$.asObservable();
				})
			);
		})
	);

	public showUpdatingLabel$ = this.loaderVisible$.pipe(
		switchMap((visible) => {
			if (!visible) {
				return of(false);
			}

			return this.getState$(ItemsToLoad.FONT).pipe(
				filter((state) => state === ItemsToLoadState.FINISHED),
				switchMap(() => {
					return this.getState$(ItemsToLoad.CODE_PUSH).pipe(
						map((state) => {
							return state === ItemsToLoadState.LOADING;
						})
					);
				}),
				switchMap((showingLabel) => {
					if (!showingLabel) {
						return of(false);
					}

					return this.prepareRestart$.pipe(
						map((restart) => {
							return !restart;
						})
					);
				})
			);
		})
	);

	public prepareRestart$ = this.needsRestart$.pipe(
		switchMap((needsRestart) => {
			if (!needsRestart) {
				return of(false);
			}

			return this.loaderVisible$;
		})
	);

	private readonly hotToast = inject(HotToastService);
	private readonly codepush = inject(CodepushService);
	private readonly sessionService = inject(SessionService);
	private readonly settingsService = inject(SettingsService);
	private readonly document = inject(DOCUMENT);
	private readonly elementRef = inject<ElementRef<HTMLElement>>(ElementRef);

	@Output('close')
	protected readonly close$$ = new EventEmitter<ItemsToLoad[]>();

	constructor() {
		this.codepush.autoRedirect = false;
	}

	async ngOnInit(): Promise<void> {
		this.handleFontsCheck();
		this.checkCodePush();
		this.checkSession();

		if (Capacitor.isNativePlatform() && Capacitor.getPlatform() === 'android') {
			const result = await CapacitorSecurityProvider.installIfNeeded();
			if (
				result.status !== SecurityProviderStatus.Success &&
				result.status != SecurityProviderStatus.NotImplemented
			) {
				this.stateMap[ItemsToLoad.ANDROID_SECURITY].next(ItemsToLoadState.FAILED);
			} else {
				this.stateMap[ItemsToLoad.ANDROID_SECURITY].next(ItemsToLoadState.FINISHED);
			}
		} else {
			this.stateMap[ItemsToLoad.ANDROID_SECURITY].next(ItemsToLoadState.FINISHED);
		}

		this.prepareRestart$
			.pipe(
				filter((restart) => restart),
				switchMap(() => {
					return timer(2000);
				}),
				take(1),
				untilDestroyed(this)
			)
			.subscribe(async () => {
				try {
					await this.codepush.restartApplication();
					setTimeout(() => {
						this.stateMap[ItemsToLoad.CODE_PUSH].next(ItemsToLoadState.FAILED);
					}, 1000);
				} catch (error) {
					this.stateMap[ItemsToLoad.CODE_PUSH].next(ItemsToLoadState.FAILED);
				}
			});

		this.animationFinished$
			.pipe(
				switchMap((failedKeys) => {
					return timer(1000).pipe(map(() => failedKeys));
				}),
				take(1),
				untilDestroyed(this)
			)
			.subscribe(() => {
				this.finish();
			});
	}

	public async logoLoaded(): Promise<void> {
		try {
			await SplashScreen.hide({ fadeOutDuration: 100 });
		} catch (error) {}
		this.logoLoaded$$.next(true);

		const loaderWrapper = this.document.querySelector('#indexLoaderWrapper');
		loaderWrapper?.remove();
	}

	private async checkCodePush(): Promise<void> {
		try {
			const needsToUpdate = await firstValueFrom(
				this.codepush.newVersionDetected$.pipe(
					filter((newV) => !!newV),
					timeout(200)
				)
			);

			if (needsToUpdate) {
				this.needsRestart$$.next(true);
			} else {
				this.stateMap[ItemsToLoad.CODE_PUSH].next(ItemsToLoadState.FINISHED);
			}
		} catch (error) {
			this.stateMap[ItemsToLoad.CODE_PUSH].next(ItemsToLoadState.FAILED);
		}
	}

	private async checkSession(): Promise<void> {
		try {
			await this.sessionService.ping();
			this.stateMap.SESSION.next(ItemsToLoadState.FINISHED);
			this.checkSettings();
		} catch (error) {
			this.stateMap.SESSION.next(ItemsToLoadState.FAILED);
			this.stateMap.SETTINGS.next(ItemsToLoadState.FAILED);
		}
	}

	private async checkSettings(): Promise<void> {
		try {
			await this.settingsService.load();
			this.stateMap.SETTINGS.next(ItemsToLoadState.FINISHED);
		} catch (error) {
			this.stateMap.SETTINGS.next(ItemsToLoadState.FAILED);
		}
	}

	private async handleFontsCheck(): Promise<void> {
		const icomoonFont = new FontFaceObserver('icomoon');

		this.testFont(ItemsToLoad.ICONS, icomoonFont);

		try {
			// https://developer.mozilla.org/en-US/docs/Web/API/Document/fonts
			await this.document['fonts'].ready;
			this.stateMap[ItemsToLoad.FONT].next(ItemsToLoadState.FINISHED);
		} catch (error) {
			console.error('DEBUG FONTS', error);
			// Fallback
			const helveticaFont = new FontFaceObserver('Helvetica Neue');
			const robotoFont = new FontFaceObserver('Roboto');
			this.testFont(ItemsToLoad.FONT, helveticaFont);
			this.testFont(ItemsToLoad.FONT, robotoFont);
		}
	}

	private testFont(key: ItemsToLoad, font: FontFaceObserver, count = 0): void {
		const MAX_TIME_MS = 30000; // 30s
		const TIMEOUT_MS = 300;
		const MAX_COUNT = Math.ceil(MAX_TIME_MS / TIMEOUT_MS);
		font.load('test', TIMEOUT_MS)
			.then(() => {
				this.stateMap[key].next(ItemsToLoadState.FINISHED);
			})
			.catch(() => {
				if (this.stateMap[key].value === ItemsToLoadState.LOADING) {
					if (count < MAX_COUNT) {
						this.testFont(key, font, count + 1);
					} else {
						this.stateMap[key].next(ItemsToLoadState.FAILED);
					}
				}
			})
			.finally(() => {});
	}

	private finish(): void {
		const failingItems = Object.values(ItemsToLoad).filter((key) => {
			return this.stateMap[key]?.value === ItemsToLoadState.FAILED;
		});
		this.codepush.autoRedirect = true;
		this.close$$.emit(failingItems);
		this.elementRef.nativeElement.remove();
	}
}
