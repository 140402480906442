export class DebugLine {
	constructor(public readonly id: number, public readonly logs: string[]) {}

	public addToLine(...logs: string[]): void {
		this.logs.push(...logs);
	}

	public print(): void {
		console.log(...this.logs);
	}
}

export class DebugUtils {
	private lastId = 0;

	private readonly lines: DebugLine[] = [];

	public printAll(): void {
		console.log();
		this.lines.forEach((line) => {
			line.print();
		});
		console.log();
	}

	public newLine = (...logs: string[]): DebugLine => {
		const line = new DebugLine(++this.lastId, logs);
		this.lines.push(line);
		return line;
	};
}
