export enum NodeFilterOperation {
	/** All nodes related to the specified nodes */
	ALL = 'ALL',
	/** Intersection of the nodes related to the specified nodes */
	IN_COMMON = 'IN_COMMON',
	/** All the nodes not related to the specified nodes */
	EXCLUDE = 'EXCLUDE',
	/** Only the given nodes */
	ONLY = 'ONLY',
}
