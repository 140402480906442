import { inject, Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { UserType } from '@reach/interfaces';
import { SessionService } from '~app-client/core/services';

@Injectable()
export class RootRedirectorGuard implements CanActivate {
	private readonly session = inject(SessionService);
	private readonly router = inject(Router);

	async canActivate(): Promise<UrlTree> {
		await this.session.untilLoadingFinished();

		if (!this.session.triedToLogin) {
			try {
				await this.session.ping();
			} catch (e) {}
		}

		const user = this.session.user;

		if (user?.type === UserType.ALPHA) {
			return this.createUrlTree(['/', 'home']);
		}

		return this.createUrlTree(['/', 'auth']);
	}

	private createUrlTree(urls: string[]): UrlTree {
		return this.router.createUrlTree(urls, {
			queryParamsHandling: 'preserve',
		});
	}
}
