import { PipelineStatusEnum } from './pipeline-status.enum';
import { PipelineTypeEnum } from './pipeline-type.enum';

export interface PipelineInterface {
	id: string;
	status: PipelineStatusEnum;
	type: PipelineTypeEnum;
	createdAt: Date;
	finishedAt: Date | null;
	userId: string;
}
