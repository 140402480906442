export enum ChatNodesStrategyEnum {
	CHAIN = 'CHAIN',
	AGENT = 'AGENT',
	AGENT_3_5 = 'AGENT_3_5',
	SEMANTIC_3_5 = 'SEMANTIC_3_5',
	SEMANTIC_4_0 = 'SEMANTIC_4_0',
}

export enum ChatNodesMessageTypeEnum {
	AI = 'AI',
	USER = 'USER',
	SYSTEM = 'SYSTEM',
}

export enum ChatNodesMessageStatusEnum {
	SENDING = 'SENDING',
	WRITING = 'WRITING',
	SUCCESS = 'SUCCESS',
	ERROR = 'ERROR',
}

export enum ChatNodesMessageAIAnswerTypeEnum {
	REASONED = 'REASONED',
}
