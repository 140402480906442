import { inject, NgModule, Pipe, PipeTransform } from '@angular/core';
import { CLIENT_ENVIRONMENT } from '~app-client/core/tokens';
import { ClientEnvironment, ClientEnvironmentName } from '~app-client/core/types';

export function http2Https(url: string, environment: ClientEnvironment): string {
	const parsedUrl = url || '';
	const convert = environment.environmentName !== ClientEnvironmentName.LOCAL;

	if (typeof parsedUrl === typeof 'string') {
		const trimmedUrl = parsedUrl.trim();

		const isHttp = trimmedUrl.substring(0, 7) === 'http://';

		const isLocalhost = !convert;

		if (isHttp && !isLocalhost) {
			const withoutProtocol = trimmedUrl.substr(7);
			return `https://${withoutProtocol}`;
		}

		return parsedUrl;
	}

	return url;
}

@Pipe({
	pure: true,
	name: 'https',
})
export class Http2HttpsPipe implements PipeTransform {
	private readonly environment = inject(CLIENT_ENVIRONMENT);

	transform(url: string): string {
		return http2Https(url, this.environment);
	}
}

@NgModule({
	declarations: [Http2HttpsPipe],
	providers: [Http2HttpsPipe],
	exports: [Http2HttpsPipe],
})
export class Http2HttpsModule {}
