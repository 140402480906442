export function nameWithNumberIfRepeated(baseName: string, otherNames: string[]): string {
	const indexes = new Set<number>();

	const escapedBaseName = baseName.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
	const r = new RegExp(`^(${escapedBaseName})( \\(([0-9]+)\\))?\$`);
	otherNames.forEach((name) => {
		const exec = [...(r.exec(name) || [])];

		if (exec[1] === baseName) {
			const index = +exec[3];
			const cleanIndex = Math.max(0, Number.isInteger(index) ? index : 0);
			indexes.add(cleanIndex);
		}
	});

	let currentIdx = 0;
	let foundIndex: number | undefined = undefined;

	while (!Number.isInteger(foundIndex)) {
		const found = !indexes.has(currentIdx);
		if (found) {
			foundIndex = currentIdx;
		}
		currentIdx++;
	}

	return foundIndex === 0 ? baseName : `${baseName} (${foundIndex})`;
}
