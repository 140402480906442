import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, OnInit, Renderer2 } from '@angular/core';
import { TextZoom } from '@capacitor/text-zoom';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ClientService } from '~app-client/core/services';

@UntilDestroy()
@Component({
	selector: 'fontsize-reactor',
	template: ``,
	styles: [':host { display: none; } '],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [],
})
export class FontSizeReactorComponent implements OnInit {
	private readonly clientService = inject(ClientService);
	private readonly renderer = inject(Renderer2);
	private readonly document = inject(DOCUMENT);

	ngOnInit(): void {
		this.changePreferredFontSize();

		this.clientService.appFocused$.pipe(untilDestroyed(this)).subscribe((focused) => {
			if (focused) {
				this.changePreferredFontSize();
			}
		});
	}

	private async changePreferredFontSize(): Promise<void> {
		try {
			const preferred = await TextZoom.getPreferred();
			this.setPreferredFontSize(Math.max(1, Math.min(1.353, preferred?.value || 1)));
		} catch (error) {}
	}

	private setPreferredFontSize(preferred: number): void {
		try {
			TextZoom.set({ value: 1 });
			this.renderer.setStyle(
				this.document.documentElement,
				'fontSize',
				`${16 * preferred}px`
			);
		} catch (error) {}
	}
}
