import { NodeSubtypeEnum, NodeSubtypeIconEnum, SmartFeatureEnum } from '@reach/interfaces';

export type AnalyticsEventNodeSubtype = NodeSubtypeEnum | 'CUSTOM';

export interface AnalyticsEventTagTypePageInfoViewed {
	subtypeId: AnalyticsEventNodeSubtype;
}

export interface AnalyticsEventNodeSubtypeCreated {
	subtypeNameLength: number;
	subtypeIcon: NodeSubtypeIconEnum;
}

export interface AnalyticsEventNodeSubtypeNameChanged {
	subtypeId: AnalyticsEventNodeSubtype;
	subtypeNameLength: number;
}

export interface AnalyticsEventsNodeSubtypeIconChanged {
	subtypeId: AnalyticsEventNodeSubtype;
	subtypeIcon: NodeSubtypeIconEnum;
}

export interface AnalyticsEventNodeSubtypePropertyChanged {
	subtypeId: AnalyticsEventNodeSubtype;
	subtypeSuggestedPropertiesLength: number;
}

export interface AnalyticsEventNodeSubtypeSmartFeatureChanged {
	subtypeId: AnalyticsEventNodeSubtype;
	smartFeature: SmartFeatureEnum;
	enabled: boolean;
}
