import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, OnInit, Renderer2 } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ClientScreen, ClientService } from '~app-client/core/services';

@UntilDestroy()
@Component({
	selector: 'screen-reactor',
	template: ``,
	styles: [':host { display: none; } '],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [],
})
export class ScreenReactorComponent implements OnInit {
	private static screenToClass(screen: ClientScreen): string {
		return `screen-${screen}`.toLowerCase();
	}

	private readonly screen$ = inject(ClientService).screen$;
	private readonly renderer = inject(Renderer2);
	private readonly hostElement = inject(DOCUMENT).documentElement as HTMLElement;

	ngOnInit(): void {
		this.screen$.pipe(untilDestroyed(this)).subscribe((screen) => {
			this.removeAllClasses();
			this.addClass(screen);
		});
	}

	private removeAllClasses(): void {
		Object.values(ClientScreen).map((screen) => {
			this.renderer.removeClass(
				this.hostElement,
				ScreenReactorComponent.screenToClass(screen)
			);
		});
	}

	private addClass(screen: ClientScreen): void {
		this.renderer.addClass(this.hostElement, ScreenReactorComponent.screenToClass(screen));
	}
}
