import { NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'percentage',
	pure: true,
})
export class PercentagePipe implements PipeTransform {
	transform(used = 0, total = 100, decimals = 0): string {
		return ((used / total) * 100).toFixed(decimals);
	}
}

@NgModule({
	declarations: [PercentagePipe],
	providers: [PercentagePipe],
	exports: [PercentagePipe],
})
export class PercentageModule {}
