export enum RankKeyEnum {
	ADVENTURER = 'ADVENTURER', //3
	DREAMER = 'DREAMER', // 2
	EXPERT = 'EXPERT', // 8
	EXPLORER = 'EXPLORER', // 4
	MASTER = 'MASTER', // 7
	PIONEER = 'PIONEER', // 5
	ROOKIE = 'ROOKIE', // 1
	VISIONARY = 'VISIONARY', // 6
}
