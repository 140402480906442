import { ReachColor, RgbColor } from './types';

export const REACH_COLORS_LIST: Record<string, RgbColor> = {
	[ReachColor.IMP_1]: [45, 112, 192],
	[ReachColor.IMP_2]: [2, 162, 204],
	[ReachColor.IMP_3]: [0, 191, 211],
	[ReachColor.IMP_4]: [87, 192, 66],
	[ReachColor.IMP_5]: [156, 200, 16],
	[ReachColor.IMP_6]: [209, 202, 0],
	[ReachColor.IMP_7]: [252, 198, 0],
	[ReachColor.IMP_8]: [245, 143, 0],
	[ReachColor.IMP_9]: [241, 89, 42],
	[ReachColor.IMP_10]: [231, 36, 55],
};
