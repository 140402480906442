export interface TemplateOptions {
	firstName?: string;
	fullName?: string;
	country?: string;
	changeDate?: string;
	email?: string;
	activationToken?: string;
	recoveryToken?: string;
	BASE_URL?: string;
	subscriptionToken: string;
	deletionToken?: string;
	numJobsSuccessful?: number;
	numJobsFailed?: number;
}
