import { ErrorCodes, Require } from '@reach/interfaces';
import { StatusCodes } from 'http-status-codes';
import { ensureAssert, ensureAssertPaths } from './ensure-assert';
import { AppError } from '../error';

export class UnexpectedNullOrUndefined extends AppError {
	constructor() {
		super({
			status: StatusCodes.INTERNAL_SERVER_ERROR,
			code: ErrorCodes.UTILS_UNEXPECTED_NULL_OR_UNDEFINED,
			title: 'Unexpected null or undefined value.',
		});
	}
}

export function ensure<T, A>(
	argument: T,
	errorFn?: (...args: A[]) => never,
	...errorArgs: A[]
): Exclude<T, undefined | null> {
	if (ensureAssert(argument)) {
		return argument;
	}
	if (ensureAssert(errorFn)) {
		errorFn(...errorArgs);
	}

	throw new UnexpectedNullOrUndefined();
}

export function ensurePath<T, K extends keyof T, A>(
	argument: T,
	keys: K[],
	errorFn?: (...args: A[]) => never,
	...errorArgs: A[]
): Require<T, K> {
	if (ensureAssertPaths(argument, keys)) {
		return argument;
	}
	if (ensureAssert(errorFn)) {
		errorFn(...errorArgs);
	}

	throw new UnexpectedNullOrUndefined();
}
