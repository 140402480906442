import { AppError } from './app-error';
import { AppErrorParams } from './app-error-params.type';
import { StatusCodes } from 'http-status-codes';
import { ErrorCodes } from '@reach/interfaces';

export class NotFoundError extends AppError {
	constructor({
		code,
		title,
		detail,
		resourceId,
		meta,
	}: Partial<AppErrorParams> & { resourceId?: string }) {
		super({
			status: StatusCodes.NOT_FOUND,
			code: code ?? ErrorCodes.MISC_NOT_FOUND,
			title: title ?? 'The resource could not be found.',
			detail,
			meta: {
				...(meta ?? {}),
				resourceId: resourceId ?? undefined,
			},
		});
	}
}
