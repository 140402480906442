import { NoteTemplateTypeEnum } from './note-template-type.enum';
import { NoteTemplateCategoryEnum } from './note-template-category.enum';

export interface NoteTemplateInterface {
	id: string;
	type: NoteTemplateTypeEnum;
	templateName: string;
	templateDescription: string | null;
	templateEmoji: string | null;
	templateCategory: NoteTemplateCategoryEnum;
	templateCategoryName: string;
}
