import { IntegrationBaseInterface } from '../integration-base.interface';
import { GoogleDriveIntegrationFileImportInterface } from './google-drive-integration-files-import.interface';

export interface GoogleDriveIntegrationAccountInterface extends IntegrationBaseInterface {
	email: string;
	accessToken: string;
	refreshToken: string; // THIS SHOULD NOT BE SENT TO THE FRONTEND
	isSync: boolean;
	syncIds: GoogleDriveIntegrationFileImportInterface[];
	rootId: string;
}

export type GoogleDriveIntegrationAccountWithoutSensitive = Omit<
	GoogleDriveIntegrationAccountInterface,
	'accessToken' | 'refreshToken'
>;
