import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import {
	ClientMetadataIsNative,
	ClientMetadataSource,
	CLIENT_METADATA_IS_NATIVE_HEADER,
	CLIENT_METADATA_OS_HEADER,
	CLIENT_METADATA_SOURCE_HEADER,
} from '@reach/interfaces';
import { Observable } from 'rxjs';
import { ClientService } from '~app-client/core/services';
import { CLIENT_ENVIRONMENT } from '~app-client/core/tokens';

@Injectable({ providedIn: 'root' })
export class MetadataInterceptor implements HttpInterceptor {
	private readonly environment = inject(CLIENT_ENVIRONMENT);
	private readonly clientService = inject(ClientService);

	private os = this.clientService.os;

	private isNative = this.clientService.isNative
		? ClientMetadataIsNative.TRUE
		: ClientMetadataIsNative.FALSE;

	intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		if (!req.url.startsWith(this.environment.apiBaseUrl)) {
			return next.handle(req);
		}

		const reqWithClientHeaders = req.clone({
			headers: req.headers
				.set(CLIENT_METADATA_SOURCE_HEADER, ClientMetadataSource.MOBILE)
				.set(CLIENT_METADATA_IS_NATIVE_HEADER, this.isNative)
				.set(CLIENT_METADATA_OS_HEADER, this.os),
		});

		return next.handle(reqWithClientHeaders);
	}
}
