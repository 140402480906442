export enum AnalyticsEvent {
	// REGISTRATION

	/**
	 * The registration process begins as soon as the user
	 * enters the registration page.
	 */
	REGISTER_START = 'REGISTER_START',

	/**
	 * The registration is successful if the user was created.
	 */
	REGISTER_SUCCESS = 'REGISTER_SUCCESS',

	/**
	 * The registration is considered cancelled if the user leaves the
	 * registration page.
	 */
	REGISTER_CANCEL = 'REGISTER_CANCEL',

	// COMPLETE REGISTRATION

	/**
	 * The complete registration process starts as soon as the user
	 * enters the complete-registration page.
	 */
	COMPLETE_REGISTRATION_START = 'COMPLETE_REGISTRATION_START',

	/**
	 * The complete registration process is successful if the API response is 200.
	 */
	COMPLETE_REGISTRATION_SUCCESS = 'COMPLETE_REGISTRATION_SUCCESS',

	/**
	 * The complete registration is considered cancelled if the user leaves the
	 * complete-registration page.
	 */
	COMPLETE_REGISTRATION_CANCEL = 'COMPLETE_REGISTRATION_CANCEL',

	// SESSION

	/** The user logged in (with or without credentials). */
	LOGIN = 'LOGIN',

	/** The user is logged out. */
	LOGOUT = 'LOGOUT',

	// NODES

	/** A node was opened. */
	NODE_OPENED = 'NODE_OPENED',

	/**
	 * The node creation process starts as soon as the user
	 * enters the node-creation page.
	 */
	NODE_CREATION_START = 'NODE_CREATION_START',

	/**
	 * The node creation process is considered a success if a node is created.
	 */
	NODE_CREATION_SUCCESS = 'NODE_CREATION_SUCCESS',

	/**
	 * The node creation of files (or albums) with stats about timing and size.
	 */
	NODE_CREATION_WITH_SIZE = 'NODE_CREATION_WITH_SIZE',

	/** The nodes that are created while searching. */
	NODE_CREATION_ON_THE_FLY = 'NODE_CREATION_ON_THE_FLY',

	/**
	 * The node creation process is considered cancel
	 * when the user navigates away from the node-creation page.
	 */
	NODE_CREATION_CANCEL = 'NODE_CREATION_CANCEL',

	/**
	 * This event is called when the node the user is trying to create already exists.
	 */
	NODE_CREATION_ALREADY_EXISTS = 'NODE_CREATION_ALREADY_EXISTS',

	/** This is called when the user tried to upload a large file. */
	NODE_CREATION_FILE_TOO_LARGE = 'NODE_CREATION_FILE_TOO_LARGE',

	/** A node was updated (name, description or thumbnail) */
	NODE_UPDATED = 'NODE_UPDATED',

	/** A node was sent to the trash. */
	NODE_SENT_TO_TRASH = 'NODE_SENT_TO_TRASH',

	/** A node was restored from the trash. */
	NODE_RESTORED = 'NODE_RESTORED',

	/** A node was restored from the trash. */
	NODE_PERMANENTLY_DELETED = 'NODE_PERMANENTLY_DELETED',

	/** A node was moved from one workspace to another. */
	NODE_WORKSPACE_CHANGED = 'NODE_WORKSPACE_CHANGED',

	/** A node (file) was downloaded */
	NODE_DOWNLOADED = 'NODE_DOWNLOADED',

	/** A website was opened in a new tab */
	NODE_WEBSITE_OPENED = 'NODE_WEBSITE_OPENED',

	/** A file was previewed (as a PDF). */
	NODE_FILE_PREVIEWED = 'NODE_FILE_PREVIEWED',

	// TRASH

	/** The trash was emptied. */
	TRASH_EMPTIED = 'TRASH_EMPTIED',

	/** The trash was restored. */
	TRASH_RESTORED = 'TRASH_RESTORED',

	// SEARCH

	/** A search was launched. */
	SEARCH = 'SEARCH',

	/** The search was cleared */
	SEARCH_CLEARED = 'SEARCH_CLEARED',

	/** The current workspace menu was shown (workspace info, delete workspace, etc). */
	SEARCH_CURRENT_WORKSPACE_MENU_SHOWN = 'SEARCH_CURRENT_WORKSPACE_MENU_SHOWN',

	// CARD VIEWS

	/** The card view that is currently selected. */
	CARD_VIEW_START = 'CARD_VIEW_START',

	/** The card view that was selected before (if any). */
	CARD_VIEW_FINISH = 'CARD_VIEW_FINISH',

	// SWIPE ACTIONS

	/** Any swipe actions were shown. */
	SWIPE_ACTION_SHOWN = 'SWIPE_ACTION_SHOWN',

	/** Some swipe action was executed. */
	SWIPE_ACTION_EXECUTED = 'SWIPE_ACTION_EXECUTED',

	// WORKSPACES

	/** The workspaces were loaded. */
	WORKSPACES_LOADED = 'WORKSPACES_LOADED',

	/** The workspaces menu selection was shown. */
	WORKSPACE_MENU_SHOWN = 'WORKSPACE_MENU_SHOWN',

	/** The current workspace was changed. */
	WORKSPACE_OPENED = 'WORKSPACE_OPENED',

	/** The current workspaces selection changed */
	WORKSPACES_SELECTION_CHANGED = 'WORKSPACES_SELECTION_CHANGED',

	// PROPERTIES

	/** The properties menu was opened. */
	PROPERTIES_MENU_OPENED = 'PROPERTIES_MENU_OPENED',

	/** The properties menu was closed. */
	PROPERTIES_MENU_CLOSED = 'PROPERTIES_MENU_CLOSED',

	/** An existing property was added to a node. */
	PROPERTY_ADDED = 'PROPERTY_ADDED',

	/** A new property was created. */
	PROPERTY_CREATED = 'PROPERTY_CREATED',

	/** A property was renamed. */
	PROPERTY_RENAMED = 'PROPERTY_RENAMED',

	/** A property options' field was updated */
	PROPERTY_OPTIONS_UPDATED = 'PROPERTY_OPTIONS_UPDATED',

	/** A property value was updated. */
	PROPERTY_VALUE_UPDATED = 'PROPERTY_VALUE_UPDATED',

	/** A property was removed from a node. */
	PROPERTY_REMOVED_FROM_NODE = 'PROPERTY_REMOVED_FROM_NODE',

	/** A property was deleted (from everywhere). */
	PROPERTY_DELETED = 'PROPERTY_DELETED',

	// RELATIONSHIPS

	/** The relationships menu was opened. */
	RELATIONSHIPS_MENU_OPENED = 'RELATIONSHIPS_MENU_OPENED',

	/** The relationships menu was closed. */
	RELATIONSHIPS_MENU_CLOSED = 'RELATIONSHIPS_MENU_CLOSED',

	// NODE RELATIONSHIPS

	/**
	 * The node relationships process starts as soon as the add
	 * new relationship menu is shown.
	 */
	NODE_RELATIONSHIP_CREATION_START = 'NODE_RELATIONSHIP_CREATION_START',

	/**
	 * The node relationships process is considered a success
	 * when a relationship is created.
	 *
	 * This can be called many times between
	 * {@link AnalyticsEvent.NODE_RELATIONSHIP_CREATION_START NODE_RELATIONSHIP_CREATION_START }
	 * and
	 * {@link AnalyticsEvent.NODE_RELATIONSHIP_CREATION_CANCEL NODE_RELATIONSHIP_CREATION_CANCEL }.
	 */
	NODE_RELATIONSHIP_CREATION_SUCCESS = 'NODE_RELATIONSHIP_CREATION_SUCCESS',

	/**
	 * The node relationships process is considered cancel as soon as
	 * the add new relationship menu is closed.
	 */
	NODE_RELATIONSHIP_CREATION_CANCEL = 'NODE_RELATIONSHIP_CREATION_CANCEL',

	// SETTINGS

	/** Called when a setting changes. */
	SETTINGS_CHANGED = 'SETTINGS_CHANGED',

	// USER PROFILE

	/** Called when a user profile value changes. */
	USER_PROFILE_EDITED = 'USER_PROFILE_EDITED',

	// FRIEND INVITE

	/**
	 * The friend invite process starts as soon as the share is shown.
	 */
	FRIEND_INVITE_START = 'FRIEND_INVITE_START',

	/**
	 * The friend invite process is considered a success if the share
	 * finished successfully.
	 */
	FRIEND_INVITE_SUCCESS = 'FRIEND_INVITE_SUCCESS',

	/**
	 * The friend invite process is considered cancel if the share is closed
	 * without using it.
	 */
	FRIEND_INVITE_CANCEL = 'FRIEND_INVITE_CANCEL',

	/// NODE SHARE

	/**
	 * The node share process starts as soon as the share is shown.
	 */
	NODE_SHARE_START = 'NODE_SHARE_START',

	/**
	 * The node share process is considered a success if the share
	 * finished successfully.
	 */
	NODE_SHARE_SUCCESS = 'NODE_SHARE_SUCCESS',

	/**
	 * The node share process is considered cancel if the share is closed
	 * without using it.
	 */
	NODE_SHARE_CANCEL = 'NODE_SHARE_CANCEL',

	// FEEDBACK

	/**
	 * The user opened the discourse page.
	 */
	DISCOURSE_FEEDBACK_PAGE_OPENED = 'DISCOURSE_FEEDBACK_PAGE_OPENED',

	// TUTORIAL

	/** When the user searches for a tutorial. */
	TUTORIAL_SEARCHED = 'TUTORIAL_SEARCHED',

	/** When the user selects a tag. */
	TUTORIAL_TAG_SELECTED = 'TUTORIAL_TAG_SELECTED',

	/** When a user opens a tutorial. */
	TUTORIAL_VIDEO_OPENED = 'TUTORIAL_VIDEO_OPENED',

	/** When a user closes a tutorial. */
	TUTORIAL_VIDEO_CLOSED = 'TUTORIAL_VIDEO_CLOSED',

	// APP STATE

	/** When the app is active. */
	APP_FOREGROUND = 'APP_FOREGROUND',

	/** When the app goes to the background (not active). */
	APP_BACKGROUND = 'APP_BACKGROUND',

	/** When the app is fully closed. */
	APP_KILLED = 'APP_KILLED',

	// SHARE RECEIVED

	/**
	 * The share received process starts as soon as the app detects a new share.
	 */
	SHARE_RECEIVED_START = 'SHARE_RECEIVED_START',

	/**
	 * The share received process is considered a success when
	 * the node creation before-data process finishes successfully.
	 */
	SHARE_RECEIVED_SUCCESS = 'SHARE_RECEIVED_SUCCESS',

	/**
	 * The share received process is considered a failure when
	 * the items received can not be processed.
	 */
	SHARE_RECEIVED_FAILS = 'SHARE_RECEIVED_FAILS',

	// NODE_PREVIEW

	/** The node preview page is shown. */
	NODE_PREVIEW_OPENED = 'NODE_PREVIEW_OPENED',

	/** The node preview page is closed. */
	NODE_PREVIEW_CLOSED = 'NODE_PREVIEW_CLOSED',

	// NODE COMMENT

	/**
	 * The node comment process starts as soon as
	 * the create new comment button is clicked.
	 */
	NODE_COMMENT_START = 'NODE_COMMENT_START',

	/**
	 * The node comment process is considered a success when the user
	 * creates a new comment.
	 */
	NODE_COMMENT_SUCCESS = 'NODE_COMMENT_SUCCESS',

	/**
	 * The node comment process is considered cancelled when the user
	 * closes the new comment menu without creating one.
	 */
	NODE_COMMENT_CANCEL = 'NODE_COMMENT_CANCEL',

	/**
	 * This event is fired when a comment is edited.
	 */
	NODE_COMMENT_EDITED = 'NODE_COMMENT_EDITED',

	/**
	 * This event is fired when the user deletes a comment.
	 */
	NODE_COMMENT_DELETED = 'NODE_COMMENT_DELETED',

	ONBOARDING_START = 'ONBOARDING_START',
	ONBOARDING_SUCCESS = 'ONBOARDING_SUCCESS',
	ONBOARDING_CANCEL = 'ONBOARDING_CANCEL',

	/**
	 * This event is fired when the user requests a password recovery.
	 */
	PASSWORD_CHANGE_REQUEST = 'PASSWORD_CHANGE_REQUEST',

	/**
	 * This event is fired when the user lands on the route `/change-passwrd`
	 * (the one on the email).
	 */
	PASSWORD_CHANGE_START = 'PASSWORD_CHANGE_START',

	/**
	 * This event is fired when the user changes the password.
	 */
	PASSWORD_CHANGE_SUCCESS = 'PASSWORD_CHANGE_SUCCESS',

	/**
	 * This event is fired when the user moves away from the route `/change-passwrd`.
	 */

	PASSWORD_CHANGE_CANCEL = 'PASSWORD_CHANGE_CANCEL',

	// NODE IMAGE

	NODE_IMAGE_START = 'NODE_IMAGE_START',
	NODE_IMAGE_SHARED = 'NODE_IMAGE_SHARED',
	NODE_IMAGE_VIEW_PICTURE = 'NODE_IMAGE_VIEW_PICTURE',
	NODE_IMAGE_REMOVE_PICTURE = 'NODE_IMAGE_REMOVE_PICTURE',
	NODE_IMAGE_SHARE_PICTURE = 'NODE_IMAGE_SHARE_PICTURE',
	NODE_IMAGE_CHANGE_PICTURE_UPLOAD = 'NODE_IMAGE_CHANGE_PICTURE_UPLOAD',
	NODE_IMAGE_CHANGE_PICTURE_LINK = 'NODE_IMAGE_CHANGE_PICTURE_LINK',
	NODE_IMAGE_CHANGE_PICTURE_EMOJI = 'NODE_IMAGE_CHANGE_PICTURE_EMOJI',
	NODE_IMAGE_CHANGE_PICTURE_UNSPLASH = 'NODE_IMAGE_CHANGE_PICTURE_UNSPLASH',
	NODE_IMAGE_CANCEL = 'NODE_IMAGE_CANCEL',

	// RECOMMENDATIONS

	RECOMMENDATIONS_START = 'RECOMMENDATIONS_START',
	RECOMMENDATIONS_SUCCESS = 'RECOMMENDATIONS_SUCCESS',
	RECOMMENDATIONS_REJECTED = 'RECOMMENDATIONS_REJECTED',
	RECOMMENDATIONS_CANCEL = 'RECOMMENDATIONS_CANCEL',

	// NOTES

	NOTE_CONTENT_EDITED = 'NOTE_CONTENT_EDITED',

	// EMAIL_SUBSCRIPTION

	EMAIL_SUBSCRIPTION_CHANGE = 'EMAIL_SUBSCRIPTION_CHANGE',

	// SPOTLIGHTS
	SPOTLIGHT_FINISHED = 'SPOTLIGHT_FINISHED',

	// INFO MODALS
	INFO_MODAL_OPENED = 'INFO_MODAL_OPENED',
	INFO_MODAL_CLOSED = 'INFO_MODAL_CLOSED',

	// NODE SUB TYPES
	NODE_SUB_TYPE_PAGE_LIST_VIEWED = 'NODE_SUB_TYPE_PAGE_LIST_VIEWED',
	NODE_SUB_TYPE_PAGE_INFO_VIEWED = 'NODE_SUB_TYPE_PAGE_INFO_VIEWED',
	NODE_SUB_TYPE_CREATED = 'NODE_SUB_TYPE_CREATED',
	NODE_SUB_TYPE_DELETED = 'NODE_SUB_TYPE_DELETED',
	NODE_SUB_TYPE_NAME_CHANGED = 'NODE_SUB_TYPE_NAME_CHANGED',
	NODE_SUB_TYPE_ICON_CHANGED = 'NODE_SUB_TYPE_ICON_CHANGED',
	NODE_SUB_TYPE_PROPERTY_CHANGED = 'NODE_SUB_TYPE_PROPERTY_CHANGED',
	NODE_SUB_TYPE_SMART_FEATURE_CHANGED = 'NODE_SUB_TYPE_SMART_FEATURE_CHANGED',

	// INTEGRATIONS
	INTEGRATION_EXECUTED = 'INTEGRATION_EXECUTED',
	INTEGRATION_DELETED = 'INTEGRATION_DELETED',
	INTEGRATION_PHONE_CONTACTS_SUCCESS = 'INTEGRATION_PHONE_CONTACTS_SUCCESS',

	// NEW_RELEASES
	NEW_RELEASES_OPENED = 'NEW_RELEASES_OPENED',
	NEW_RELEASES_CLOSED = 'NEW_RELEASES_CLOSED',
	NEW_RELEASES_NAVIGATED_TO_BLOG = 'NEW_RELEASES_NAVIGATED_TO_BLOG',

	// ALBUM
	ALBUM_NODE_SET_COVER = 'ALBUM_NODE_SET_COVER',
	ALBUM_NODE_DELETE_ITEMS = 'ALBUM_NODE_DELETE_ITEMS',
	ALBUM_NODE_ADD_ITEMS = 'ALBUM_NODE_ADD_ITEMS',
	ALBUM_NODE_SHARE_ITEM = 'ALBUM_NODE_SHARE_ITEM',
	ALBUM_NODE_DOWNLOAD_ITEM = 'ALBUM_NODE_DOWNLOAD_ITEM',
	ALBUM_NODE_CREATE_ITEM_AS_NODE = 'ALBUM_NODE_CREATE_ITEM_AS_NODE',

	// DELETE_ACCOUNT
	DELETE_ACCOUNT_REQUESTED = 'DELETE_ACCOUNT_REQUESTED',
	DELETE_ACCOUNT_FINISHED = 'DELETE_ACCOUNT_FINISHED',
	DELETE_ACCOUNT_FEEDBACK = 'DELETE_ACCOUNT_FEEDBACK',

	// EXTRA

	CODEPUSH_UPDATE_SUCCESS = 'CODEPUSH_UPDATE_SUCCESS',
	CODEPUSH_UPDATE_FAILED = 'CODEPUSH_UPDATE_FAILED',

	OPEN_MOBILE_MENU_SHOWN = 'OPEN_MOBILE_MENU_SHOWN',
	OPEN_MOBILE_MENU_SUCCESS = 'OPEN_MOBILE_MENU_SUCCESS',
	OPEN_MOBILE_MENU_CANCELED = 'OPEN_MOBILE_MENU_CANCELED',

	/** Any API request */
	API_REQUEST = 'API_REQUEST',

	// REMINDERS

	/** The relationships menu was opened. */
	REMINDER_ADDED = 'REMINDER_ADDED',

	/** The relationships menu was closed. */
	REMINDER_DELETED = 'REMINDER_DELETED',
}
