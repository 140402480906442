export interface RequestPaginationInterface<T, K extends keyof T = keyof T> {
	page?: number;
	size?: number;
	sortBy?: K;
	orderBy?: OrderByEnum;
	cursor?: T[K]; // Value for the sort by key, if it is set the page is ignored ( so far only for messages )
}

export enum OrderByEnum {
	ASC = 'ASC',
	DESC = 'DESC',
}
