import { inject, Injectable } from '@angular/core';
import { CanMatch } from '@angular/router';
import { UserType } from '@reach/interfaces';
import { SessionService } from '~app-client/core/services';

@Injectable()
export class CanAccessHomeGuard implements CanMatch {
	private readonly session = inject(SessionService);

	private async guard(): Promise<boolean> {
		await this.session.untilLoadingFinished();

		if (!this.session.triedToLogin) {
			try {
				await this.session.ping();
			} catch (e) {}
		}

		const user = this.session.user;

		if (user?.type === UserType.ALPHA) {
			return true;
		}

		return false;
	}

	async canMatch(): Promise<boolean> {
		const ret = await this.guard();
		return ret;
	}
}
