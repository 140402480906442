import { Injectable } from '@angular/core';
import { NoteTemplateInterface } from '@reach/interfaces';
import { BaseApiService } from '~app-client/api/_internal';

@Injectable({ providedIn: 'root' })
export class NoteTemplatesApiService extends BaseApiService {
	protected commonPath = ['note-templates'];

	public getAll(): Promise<NoteTemplateInterface[]> {
		return this.http.get<NoteTemplateInterface[]>(this.url(''));
	}
}
