export enum ChatIntegrationEnum {
	SHOPIFY = 'SHOPIFY',
}

export interface ChatIntegrationShopifyInterface {
	accessToken: string;
	shopName: string;
}

export interface ChatIntegrationToBodyInterfaceMap {
	[ChatIntegrationEnum.SHOPIFY]: ChatIntegrationShopifyInterface;
}

export type ChatIntegrationBodyInterface<T extends ChatIntegrationEnum> =
	T extends keyof ChatIntegrationToBodyInterfaceMap
		? ChatIntegrationToBodyInterfaceMap[T]
		: never;

export interface ChatIntegrationInterface<T extends ChatIntegrationEnum = ChatIntegrationEnum> {
	id: string;
	type: T;
	createdAt: number;
	updatedAt: number;
	body: ChatIntegrationBodyInterface<T>;
}
