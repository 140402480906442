import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslocoModule, TRANSLOCO_SCOPE } from '@ngneat/transloco';
import {
	AppUrlOpenReactorComponent,
	FontSizeReactorComponent,
	KeyboardReactorComponent,
	SafeAreaReactorComponent,
	ScreenReactorComponent,
	SettingsReactorComponent,
	UserReactorComponent,
} from '~app-client/root/_internal/components';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{ provide: TRANSLOCO_SCOPE, useValue: { scope: 'general' }, multi: true },
		{ provide: TRANSLOCO_SCOPE, useValue: { scope: 'node-type' }, multi: true },
	],
})
export class AppPageComponent {}

@NgModule({
	declarations: [AppPageComponent],
	imports: [
		CommonModule,
		TranslocoModule,
		RouterModule,

		AppUrlOpenReactorComponent,
		SettingsReactorComponent,
		SafeAreaReactorComponent,
		UserReactorComponent,
		ScreenReactorComponent,
		KeyboardReactorComponent,
		FontSizeReactorComponent,
	],
	exports: [AppPageComponent],
})
export class AppPageModule {}
