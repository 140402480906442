export type UptimeMonitorsData = {
	monitorId: number;
	monitorName: string;
	dailyRatios: MonitorDailyRatio[];
};
export type MonitorDailyRatio = {
	ratio: string; // float
	label: MonitorStateLabel;
	day: string;
};
export enum MonitorStateLabel {
	SUCCESS = 'success',
	WARNING = 'warning',
}
