import { ClientEnvironment } from '~app-client/core/types';

export enum KnownScript {
	GOOGLE_IDENTITY_SERVICES = 'GOOGLE_IDENTITY_SERVICES',
}

export interface KnownScriptData {
	elementId: string;
	url: (environment: ClientEnvironment) => string;
}

export enum KnownScriptState {
	NOT_ADDED = 'NOT_ADDED',
	LOADING = 'LOADING',
	LOADED = 'LOADED',
	FAILED = 'FAILED',
}
