import { NodeType } from '../nodes';
import { SearchNodeResponse } from '../search';
import { Nullable } from '../typescript';

export enum ChatNodesToolEnum {
	SIMILARITY_SEARCH = 'SIMILARITY_SEARCH',
	NLP_SEARCH = 'NLP_SEARCH',
	CUSTOM_API_CALL = 'CUSTOM_API_CALL',
}

export type ChatNodesSimilaritySearchToolInputType = {
	query: string;
	nodeIds: Nullable<Array<string>>;
};

export type ChatNodesCustomAPICallToolInputType = {
	params?: Record<string, unknown>;
	body?: Record<string, unknown>;
};

export type ChatNodesCustomAPICallToolOutputItemType = ChatNodesToolOutputItemBaseInterface & {
	item: unknown;
};

export type ChatNodesCustomAPICallToolOutputType =
	ChatNodesItemizedToolOutputBaseInterface<ChatNodesCustomAPICallToolOutputItemType> & {
		itemCount: number;
	};

export interface ChatNodesToolOutputItemBaseInterface {
	isUsed: boolean;
}

export interface ChatNodesItemizedToolOutputBaseInterface<
	T extends ChatNodesToolOutputItemBaseInterface
> {
	items: Array<T>;
}

export type ChatNodesSimilaritySearchToolOutputItemType = ChatNodesToolOutputItemBaseInterface & {
	embeddingId: string;
	text: string;
	num_page: number;
	superblock_id: string;
	position: number;
	bbox_x0: number;
	bbox_y0: number;
	bbox_x1: number;
	bbox_y1: number;
	node: SearchNodeResponse;
};

export type ChatNodesSimilaritySearchToolOutputType =
	ChatNodesItemizedToolOutputBaseInterface<ChatNodesSimilaritySearchToolOutputItemType>;

export type ChatNodesNLPSearchToolOutputItemType = ChatNodesToolOutputItemBaseInterface & {
	node: SearchNodeResponse;
};
export type ChatNodesNLPSearchToolOutputType =
	ChatNodesItemizedToolOutputBaseInterface<ChatNodesNLPSearchToolOutputItemType> & {
		nodeCount: number;
	};

export interface ChatNodesNLPSearchToolInputType {
	query?: string;
	nodeTypes?: Array<Exclude<NodeType, NodeType.WORKSPACE>>;
	textualPropertyFilters?: Array<{
		textualProperty: string;
		operator:
			| 'EQUALS'
			| 'NOT_EQUALS'
			| 'CONTAINS'
			| 'NOT_CONTAINS'
			| 'HAS_PROPERTY'
			| 'NOT_HAS_PROPERTY';
		value?: string;
	}>;
	datePropertyFilters?: Array<{
		dateProperty: string;
		dateRange: {
			start?: string;
			end?: string;
		};
	}>;
	numericPropertyFilters?: Array<{
		numericProperty: string;
		operator:
			| 'EQUALS'
			| 'NOT_EQUALS'
			| 'GREATER_THAN'
			| 'GREATER_THAN_OR_EQUAL'
			| 'LESS_THAN'
			| 'LESS_THAN_OR_EQUAL'
			| 'HAS_PROPERTY'
			| 'NOT_HAS_PROPERTY';
		value?: number;
	}>;
}

export type ChatNodesToolTypesMap = {
	[ChatNodesToolEnum.SIMILARITY_SEARCH]: {
		input: ChatNodesSimilaritySearchToolInputType;
		output: ChatNodesSimilaritySearchToolOutputType;
	};
	[ChatNodesToolEnum.NLP_SEARCH]: {
		input: ChatNodesNLPSearchToolInputType;
		output: ChatNodesNLPSearchToolOutputType;
	};
	[ChatNodesToolEnum.CUSTOM_API_CALL]: {
		input: ChatNodesCustomAPICallToolInputType;
		output: ChatNodesCustomAPICallToolOutputType;
	};
};

export interface ChatNodesToolUsageInterface<T extends ChatNodesToolEnum> {
	toolType: T;
	input: ChatNodesToolTypesMap[T]['input'];
	output: Nullable<ChatNodesToolTypesMap[T]['output']>;
	customAPICallTool: T extends ChatNodesToolEnum.CUSTOM_API_CALL
		? ChatCustomAPICallToolEnum
		: undefined;
	timestamp: number;
}

export enum ChatCustomAPICallToolEnum {
	SHOPIFY_ORDER_RETRIEVAL = 'SHOPIFY_ORDER_RETRIEVAL',
	SHOPIFY_USER_RETRIEVAL = 'SHOPIFY_USER_RETRIEVAL',
}
