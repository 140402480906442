import { PlatformLocation } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import { CLIENT_ENVIRONMENT } from '../tokens/environment';
import { AnalyticsService } from './analytics';

/**
 * Service that handles the state of the routes.
 *
 * Right now it is not used and it might never be, but it can be useful to check
 * if the last navigation is finished.
 */
@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class RoutesService {
	private readonly router = inject(Router);
	private readonly analytics = inject(AnalyticsService);
	private readonly environment = inject(CLIENT_ENVIRONMENT);
	private readonly location = inject(PlatformLocation);

	private navigationLoadingMap: { [id: string]: number } = {};
	private latestId: string;

	private navigating$$ = new BehaviorSubject<boolean>(false);
	public navigating$ = this.navigating$$.asObservable();

	/**
	 * Map of ended navigations (cleaned - without query params) and the time they took to load.
	 */
	private navigationTimeMap: { [url: string]: number } = {};

	private loadingRoute$$ = new BehaviorSubject<boolean>(false);
	/**
	 * Whether the last navigation has ended or not.
	 */
	public loadingRoute$ = this.loadingRoute$$.asObservable().pipe(untilDestroyed(this));

	private waitingRoutes$$ = new BehaviorSubject<number>(0);
	/**
	 * Number of routes that are pending to load.
	 */
	public waitingRoutes$ = this.waitingRoutes$$.asObservable().pipe(untilDestroyed(this));

	private navigationFinishedErrors = 0;

	private lastNavigationId = 0;

	protected onStart(): void {
		// start of each navigation
		this.router.events
			.pipe(
				filter((event) => event instanceof NavigationStart),
				tap(() => this.navigating$$.next(true)),
				untilDestroyed(this)
			)
			.subscribe((event: NavigationStart) => this.handleNavigationStart(event));

		// end of each navigation
		this.router.events
			.pipe(
				filter((event) => event instanceof NavigationEnd),
				tap(() => this.navigating$$.next(false)),
				untilDestroyed(this)
			)
			.subscribe((event: NavigationEnd) => this.handleNavigationEnd(event));

		this.router.events
			.pipe(
				filter((event) => event instanceof NavigationCancel),
				untilDestroyed(this)
			)
			.subscribe(() => {
				this.navigating$$.next(false);
			});

		this.location.onPopState((event) => {
			const currentNavigationId = event.state?.id || 0;
			const back = currentNavigationId < this.lastNavigationId;

			this.lastNavigationId = currentNavigationId;

			const intermediateState = history.state?.intermediateState === true;
			const isIntermediate = !!intermediateState || typeof intermediateState === typeof 'str';

			if (isIntermediate) {
				console.debug(
					`Intermediate state found while navigating. Expanding navigation to handle it.`
				);

				if (back) {
					history.back();
				} else {
					history.forward();
				}
			}
		});
	}

	public navigationFinished(): Promise<void> {
		return firstValueFrom(
			this.navigating$.pipe(
				filter((navigating) => navigating === false),
				map(() => null),
				take(1),
				untilDestroyed(this)
			)
		);
	}

	public async addIntermediateState(debugMsg?: string): Promise<number> {
		this.lastNavigationId += 1;
		console.debug(`Adding intermediate state "${debugMsg}" [${this.lastNavigationId}]`);
		const state = { id: this.lastNavigationId, intermediateState: true };
		history.pushState(state, null);

		return this.lastNavigationId;
	}

	/**
	 * @param url Url to clean
	 * @returns The url without the query params.
	 */
	private cleanUrl(url: string): string {
		return `${url.split('?')[0]}`.trim() || '/';
	}

	/**
	 * Updates all the Services streams.
	 */
	private update(): void {
		this.loadingRoute$$.next(!!this.navigationLoadingMap[this.latestId]);
		this.waitingRoutes$$.next(Object.keys(this.navigationLoadingMap).length);
	}

	private handleNavigationStart(event: NavigationStart): void {
		if (this.navigationLoadingMap[`${event.id}`]) {
			this.analytics.addException(
				`[RoutesService] navigation started twice with the same id {"url": ${event.url} "id": ${event.id}}`
			);
		}

		this.navigationLoadingMap[`${event.id}`] = performance.now();
		this.latestId = `${event.id}`;
		this.update();
	}

	private async handleNavigationEnd(event: NavigationEnd): Promise<void> {
		this.lastNavigationId += 1;
		history.replaceState({ id: this.lastNavigationId }, null);

		const before = this.navigationLoadingMap[`${event.id}`];
		const time = performance.now() - before;
		const url = this.cleanUrl(event.url);
		this.navigationTimeMap[url] = Math.max(this.navigationTimeMap[url] || 0, time);
		delete this.navigationLoadingMap[`${event.id}`];
		this.update();

		if (!before) {
			this.navigationFinishedErrors++;
			// we let the first one slip because the service might started after the navigation.
			if (this.navigationFinishedErrors > 1) {
				this.analytics.addException(
					`[RoutesService] navigation finished but never started {"url": ${event.url} "id": ${event.id}}`
				);
			}
		}
	}
}
