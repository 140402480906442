import { HttpErrorResponse } from '@angular/common/http';
import { ErrorCodes } from '@reach/interfaces';
import { ClientApiError, ReachError } from '../types/error';

export function isReachError(error: unknown): error is ReachError {
	const rError = error as ReachError;

	const validId = typeof rError?.id === typeof 'string';
	const validStatus = typeof rError?.status === typeof 200;
	const validCode = Object.values(ErrorCodes).includes(rError?.code);
	const validTitle = typeof rError?.title === typeof 'string';
	const validDetail = typeof rError?.detail === typeof 'string';
	const validMeta = typeof rError?.meta === typeof {};

	return validId && validStatus && validCode && validTitle && validDetail && validMeta;
}

export function isApiThrowResponse(response: unknown): response is ClientApiError {
	try {
		const { isReachError, error } = response as ClientApiError;
		return (isReachError === true || isReachError === false) && !!error;
	} catch (error) {
		return false;
	}
}

export function asApiError(response: unknown): ClientApiError {
	try {
		if (isApiThrowResponse(response)) {
			return response;
		}

		if (isReachError(response)) {
			return { isReachError: true, error: response };
		}

		const error = (response as HttpErrorResponse)?.error;
		if (isReachError(error)) {
			return { isReachError: true, error };
		}

		return { isReachError: false, error: response };
	} catch (error) {
		return { isReachError: false, error: response };
	}
}

export const apiToReachError = asApiError;

export function throwAsApiError(error: unknown): never {
	throw asApiError(error);
}
