import { CustomPropertyCreateRequest } from '../../custom-property';
import { NodeSubtypeId } from '../../node-subtypes';

export class ZapierCreateFileActionInterface {
	name?: string;
	fileUrl!: string;
	workspaceIds!: string[];
	nodeSubtypeId?: NodeSubtypeId;
	customProperties!: CustomPropertyCreateRequest[];
	comments!: string[];
	callbackUrl!: string;
}
