import { Optional } from '../typescript';
import { Language, UserInterface, UserPreferencesGeneral } from '../user';

export type LoginBody = Required<Pick<UserInterface, 'username' | 'password'>>;

export interface RenewPasswordBody {
	newPassword: string;
}

export enum RegisterMethod {
	REACH = 'REACH',
	GOOGLE = 'GOOGLE',
	APPLE = 'APPLE',
}

export interface RecoverBody {
	email: string;
}

export interface RegisterBody {
	user: RegisterUserBody;
}

export type RegisterUserBody = Required<Pick<UserInterface, 'username' | 'email' | 'password'>> &
	Optional<Pick<UserPreferencesGeneral, 'language'>, 'language'>;

export interface SocialLoginRegisterBody {
	googleToken: string;
	language?: Language;
}

export type CompleteRegistrationBody = Partial<Pick<RegisterUserBody, 'username'>> &
	Pick<Optional<UserInterface, 'generalUse'>, 'hearing' | 'fullName' | 'generalUse'> &
	Required<Pick<UserPreferencesGeneral, 'language'>> & { subscribed: boolean };
