import { UserWithoutSensitive } from '../user';

export type PingResponse = UserWithoutSensitive;
export type ReferResponse = void;
export type LoginResponse = UserWithoutSensitive;
export type LogoutResponse = void;
export type RegisterResponse = UserWithoutSensitive;
export type RecoverPasswordResponse = void;
export type NewPasswordResponse = void;
export type VerifyEmailResponse = UserWithoutSensitive;
export type ResendVerifyEmailResponse = void;
export type SocialLoginRegisterResponse = UserWithoutSensitive;
export type CompleteRegistrationResponse = UserWithoutSensitive;
