import { Injectable } from '@angular/core';
import { HammerGestureConfig } from '@angular/platform-browser';

@Injectable()
export class HammerConfig extends HammerGestureConfig {
	overrides = {
		pan: { threshold: 16 },
		// These two are to enable scroll
		// https://stackoverflow.com/questions/41017202/vertical-scroll-is-not-working-with-hammerjs-and-angular2
		pinch: { enable: false },
		rotate: { enable: false },
	};
}
