import { Injectable } from '@angular/core';
import {
	CustomPropertyValue,
	FileInterface,
	GetElementType,
	LocationLatLon,
	NoteCreateBody,
	NoteInterface,
	NotePatchInterface,
	NoteTemplateInterface,
	NoteUpdateBody,
} from '@reach/interfaces';
import { BaseApiService } from '~app-client/api/_internal';
import { ReachNodeId } from '~app-client/core/types';

@Injectable({ providedIn: 'root' })
export class NotesApiService extends BaseApiService {
	protected commonPath = ['notes'];

	public create(
		workspaceIds: ReachNodeId[],
		name: string,
		noteTemplate?: NoteTemplateInterface['id'] | undefined,
		location?: LocationLatLon
	): Promise<NoteInterface> {
		const body: NoteCreateBody = {
			name,
			noteContent: '',
			workspaceIds: workspaceIds,
			noteTemplate,
			location: location,
		} as NoteCreateBody;
		return this.http.post<NoteInterface>(this.url(), body);
	}

	public edit(noteId: ReachNodeId, data: NoteUpdateBody): Promise<NotePatchInterface> {
		const body = data;
		return this.http.patch<NotePatchInterface>(this.url(noteId), body);
	}

	public updateCustomProperties(
		noteId: ReachNodeId,
		customProperties: CustomPropertyValue[],
		workspaceId: ReachNodeId
	): Promise<CustomPropertyValue[]> {
		const body = {
			customProperties,
			fatherWorkspaceId: workspaceId,
		};
		return this.http
			.patch<NoteInterface>(this.url(noteId), body)
			.then((node) => node.customProperties || []);
	}

	public createNoteAttachment(
		noteId: ReachNodeId,
		file: File
	): Promise<GetElementType<NoteInterface['attachments']>> {
		const formData = new FormData();
		formData.append('file', file);
		return this.http.post<GetElementType<NoteInterface['attachments']>>(
			this.url(`${noteId}/attachments`),
			formData
		);
	}

	public deleteNoteAttachment(noteId: ReachNodeId, attachmentId: string): Promise<NoteInterface> {
		return this.http.delete<NoteInterface>(this.url(`${noteId}/attachments/${attachmentId}`));
	}

	public attachmentToNode(
		noteId: ReachNodeId,
		attachmentId: string,
		name: string,
		workspaceIds: ReachNodeId[]
	): Promise<{ file: FileInterface; note: NoteInterface }> {
		const body = {
			name,
			workspaceIds,
		};
		return this.http.post<{ file: FileInterface; note: NoteInterface }>(
			this.url(`${noteId}/attachments/${attachmentId}/to-node`),
			body
		);
	}
}
