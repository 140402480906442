// https://stackoverflow.com/questions/67824800/typescript-generic-use-default-value-only
export type NoInferData<
	DataType extends unknown | void,
	InputType,
	DefaultType
> = DataType extends void
	? // If it was void, there was no argument, so the type must be an empty object, or whatever you'd like
	  DefaultType
	: // Otherwise it should match the explcitly added data type
	  InputType;
