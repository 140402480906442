export function flat<T>(arr: T[][]): T[] {
	return arr.reduce((accumulator, value) => accumulator.concat(value), []);
}

/**
 * @param url The url to clean.
 * @returns The same url **without** '/' at the beggining and at the end.
 */
export function cleanUrl(url: string): string {
	let _url = url;

	// removes '/' at the end.
	if (_url.endsWith('/')) {
		_url = _url.substring(0, _url.length - 1);
	}

	// removes '/' at the beggining.
	if (_url.startsWith('/')) {
		_url = _url.substring(1);
	}

	return _url;
}

/**
 * @param path String to split by '/'.
 * @returns The segments of the given path (each section splitted by '/').
 */
export function splitPathSegment(path: string | number): string[] {
	return `${path}`
		.split('/')
		.map((segment) => segment.trim())
		.filter((segment) => segment !== '');
}

export function cleanSegments(segments: string | string[]): string[] {
	return Array.isArray(segments)
		? flat(segments.map(splitPathSegment))
		: splitPathSegment(segments);
}

export function urlFromSegments(segments: string[]): string {
	return segments.join('/');
}
