import { ensure } from '../assertions';

export function createLruCache<T>(maxEntries = 20, store: Map<string, T> = new Map<string, T>()) {
	function put(key: string, value: T) {
		if (store.size >= maxEntries) {
			const keyToDelete = store.keys().next().value;
			store.delete(keyToDelete);
		}
		store.set(key, value);
	}
	function get(key: string): T | undefined {
		const hasKey = store.has(key);
		if (!hasKey) return undefined;

		const entry = ensure(store.get(key));
		store.delete(key);
		store.set(key, entry);
		return entry;
	}

	function invalidate(key: string): T | undefined {
		const hasKey = store.has(key);
		if (!hasKey) return undefined;

		const entry = ensure(store.get(key));
		store.delete(key);

		return entry;
	}

	function updateProperties(key: string, properties: unknown): T | undefined {
		const hasKey = store.has(key);
		if (!hasKey) return undefined;

		const entry = ensure(store.get(key));
		store.delete(key);
		store.set(key, { ...entry, customProperties: properties });
		return entry;
	}

	return {
		put,
		get,
		invalidate,
		updateProperties,
		maxEntries,
		store,
	};
}
