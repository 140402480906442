import { Injectable } from '@angular/core';
import { NodesRecommendationResponse, SimilarityRecommendationsResponse } from '@reach/interfaces';
import { BaseApiService } from '~app-client/api/_internal';
import { ReachNodeId } from '~app-client/core/types';

@Injectable({ providedIn: 'root' })
export class RecommendationsApiService extends BaseApiService {
	protected commonPath = ['recommendations'];

	public getSimilarNodes(nodeId: ReachNodeId): Promise<SimilarityRecommendationsResponse> {
		const params = { t: `${this.cacheTimer}` };
		return this.http.get<SimilarityRecommendationsResponse>(
			this.url('similarity', nodeId),
			params
		);
	}

	public getTags(nodeId: ReachNodeId): Promise<NodesRecommendationResponse> {
		const params = { t: `${this.cacheTimer}` };
		return this.http.get<NodesRecommendationResponse>(this.url('tags', nodeId), params);
	}

	public retryTags(nodeId: ReachNodeId): Promise<NodesRecommendationResponse> {
		const body = { nodeIds: [nodeId] };
		return this.http.post<NodesRecommendationResponse>(this.url('tags'), body);
	}
}
