type FacebookPixelTrackFn = (
	fnName: 'trackCustom',
	eventName: string,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	properties?: Record<string, unknown>
) => void;

type FacebookPixelObj = FacebookPixelTrackFn;

declare const fbq: FacebookPixelObj;

export class FacebookPixel {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	track<T extends Record<string, unknown> = Record<string, never>>(
		eventName: string,
		properties?: T
	): void {
		try {
			fbq?.('trackCustom', eventName, properties);
		} catch (e) {
			console.error(e);
		}
	}
}
