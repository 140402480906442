export function assertArrayContainsString<T extends string, P extends string>(
	array: T[],
	value: P
): P {
	// only for strings so far since we didn't need to use it for other types, feel free to extend it
	if (!(array as string[]).includes(value)) {
		throw new Error(`Array does not contain value: ${value}`);
	}
	return value;
}
