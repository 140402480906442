export enum SuggestionSource {
	/**
	 * Suggested because of the website's keywords.
	 */
	KEYWORDS = 'KEYWORDS',

	/**
	 * Suggested after analyzing the content of the node.
	 */
	CONTENT = 'CONTENT',
}
