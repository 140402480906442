import { Injectable } from '@angular/core';
import {
	AllWorkspacesResponse,
	CustomPropertyValue,
	NodeInterface,
	WorkspaceCreateBody,
	WorkspaceInterface,
	WorkspaceUpdateBody,
} from '@reach/interfaces';
import { BaseApiService } from '~app-client/api/_internal';
import { ReachNodeId } from '~app-client/core/types';

@Injectable({ providedIn: 'root' })
export class WorkspacesApiService extends BaseApiService {
	protected commonPath = ['workspaces'];

	public getAll(): Promise<AllWorkspacesResponse> {
		const params = { t: this.paramTime };
		return this.http.get<AllWorkspacesResponse>(this.url(), params);
	}

	public moveNodes(destinyWorkspaceId: ReachNodeId, ...nodeIds: ReachNodeId[]): Promise<void> {
		const body = { nodeIds: nodeIds || [] };
		return this.http.post<void>(this.url(destinyWorkspaceId, 'move'), body);
	}

	public create(body: WorkspaceCreateBody): Promise<WorkspaceInterface> {
		return this.http.post<WorkspaceInterface>(this.url(), body);
	}

	public updateCustomProperties(
		workspaceId: ReachNodeId,
		customProperties: CustomPropertyValue[]
	): Promise<CustomPropertyValue[]> {
		const body = {
			customProperties,
		};
		return this.http
			.patch<NodeInterface>(this.url(workspaceId), body)
			.then((node) => node?.customProperties || []);
	}

	public addNodesToWorkspace(workspaceId: ReachNodeId, nodeIds: ReachNodeId[]): Promise<void> {
		const body = { nodeIds };
		return this.http.post<void>(this.url(workspaceId, 'add'), body);
	}

	public removeNodesFromWorkspace(
		workspaceId: ReachNodeId,
		nodeIds: ReachNodeId[]
	): Promise<void> {
		const body = { nodeIds };
		return this.http.post<void>(this.url(workspaceId, 'remove'), body);
	}

	public deleteWorkspace(workspaceId: ReachNodeId): Promise<ReachNodeId[]> {
		return this.http.delete<ReachNodeId[]>(this.url(workspaceId));
	}

	public edit(workspaceId: ReachNodeId, data: WorkspaceUpdateBody): Promise<WorkspaceInterface> {
		const body = data;
		return this.http.patch<WorkspaceInterface>(this.url(workspaceId), body);
	}

	public setLastUsedWorkspaces(ids: ReachNodeId[]): Promise<void> {
		const body = { workspaceIds: ids };
		return this.http.patch<void>(this.url('update-last-searched'), body);
	}
}
