import { ReachSlateElementType } from '@reach/interfaces';
import { HtmlTag } from '../../html-types';
import { ConfigAccumulator } from '../types';

export const FORMATS_FROM_ELEMENT_TAG: Partial<Record<HtmlTag, ConfigAccumulator>> = {
	[HtmlTag.UNDERLINE]: {
		[ReachSlateElementType.TEXT]: {
			underlined: true,
		},
	},
	[HtmlTag.EMPHASIS]: {
		[ReachSlateElementType.TEXT]: {
			italic: true,
		},
	},
	[HtmlTag.STRIKE]: {
		[ReachSlateElementType.TEXT]: {
			strikethrough: true,
		},
	},
	[HtmlTag.BOLD]: {
		[ReachSlateElementType.TEXT]: {
			bold: true,
		},
	},
	[HtmlTag.STRONG]: {
		[ReachSlateElementType.TEXT]: {
			bold: true,
		},
	},
};
