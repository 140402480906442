import { WikimediaLanguageCodeEnum } from './wikimedia-language-code.enum';

export const WikimediaLanguages: {
	[code in WikimediaLanguageCodeEnum]: { english: string; original: string };
} = {
	[WikimediaLanguageCodeEnum.AB_ABKHAZIAN]: { english: 'Abkhazian', original: 'аԥсшәа' },
	[WikimediaLanguageCodeEnum.ACE_ACHINESE]: { english: 'Achinese', original: 'Acèh' },
	[WikimediaLanguageCodeEnum.ADY_ADYGHE]: { english: 'Adyghe', original: 'адыгабзэ' },
	[WikimediaLanguageCodeEnum.AF_AFRIKAANS]: { english: 'Afrikaans', original: 'Afrikaans' },
	[WikimediaLanguageCodeEnum.AK_AKAN]: { english: 'Akan', original: 'Akan' },
	[WikimediaLanguageCodeEnum.ALS_ALEMANNISCH]: {
		english: 'Alemannisch',
		original: 'Alemannisch',
	},
	[WikimediaLanguageCodeEnum.ALT_SOUTHERN_ALTAI]: {
		english: 'Southern Altai',
		original: 'алтай тил',
	},
	[WikimediaLanguageCodeEnum.AM_AMHARIC]: { english: 'Amharic', original: 'አማርኛ' },
	[WikimediaLanguageCodeEnum.AMI_AMIS]: { english: 'Amis', original: 'Pangcah' },
	[WikimediaLanguageCodeEnum.AN_ARAGONESE]: { english: 'Aragonese', original: 'aragonés' },
	[WikimediaLanguageCodeEnum.ANG_OLD_ENGLISH]: { english: 'Old English', original: 'Ænglisc' },
	[WikimediaLanguageCodeEnum.AR_ARABIC]: { english: 'Arabic', original: 'العربية' },
	[WikimediaLanguageCodeEnum.ARC_ARAMAIC]: { english: 'Aramaic', original: 'ܐܪܡܝܐ' },
	[WikimediaLanguageCodeEnum.ARY_MOROCCAN_ARABIC]: {
		english: 'Moroccan Arabic',
		original: 'الدارجة',
	},
	[WikimediaLanguageCodeEnum.ARZ_EGYPTIAN_ARABIC]: {
		english: 'Egyptian Arabic',
		original: 'مصرى',
	},
	[WikimediaLanguageCodeEnum.AS_ASSAMESE]: { english: 'Assamese', original: 'অসমীয়া' },
	[WikimediaLanguageCodeEnum.AST_ASTURIAN]: { english: 'Asturian', original: 'asturianu' },
	[WikimediaLanguageCodeEnum.ATJ_ATIKAMEKW]: { english: 'Atikamekw', original: 'Atikamekw' },
	[WikimediaLanguageCodeEnum.AV_AVARIC]: { english: 'Avaric', original: 'авар' },
	[WikimediaLanguageCodeEnum.AVK_KOTAVA]: { english: 'Kotava', original: 'Kotava' },
	[WikimediaLanguageCodeEnum.AWA_AWADHI]: { english: 'Awadhi', original: 'अवधी' },
	[WikimediaLanguageCodeEnum.AY_AYMARA]: { english: 'Aymara', original: 'Aymar aru' },
	[WikimediaLanguageCodeEnum.AZ_AZERBAIJANI]: {
		english: 'Azerbaijani',
		original: 'azərbaycanca',
	},
	[WikimediaLanguageCodeEnum.AZB_SOUTH_AZERBAIJANI]: {
		english: 'South Azerbaijani',
		original: 'تۆرکجه',
	},
	[WikimediaLanguageCodeEnum.BA_BASHKIR]: { english: 'Bashkir', original: 'башҡортса' },
	[WikimediaLanguageCodeEnum.BAN_BALINESE]: { english: 'Balinese', original: 'Basa Bali' },
	[WikimediaLanguageCodeEnum.BAR_BAVARIAN]: { english: 'Bavarian', original: 'Boarisch' },
	[WikimediaLanguageCodeEnum.BAT_SMG_SAMOGITIAN]: {
		english: 'Samogitian',
		original: 'žemaitėška',
	},
	[WikimediaLanguageCodeEnum.BCL_CENTRAL_BIKOL]: {
		english: 'Central Bikol',
		original: 'Bikol Central',
	},
	[WikimediaLanguageCodeEnum.BE_BELARUSIAN]: { english: 'Belarusian', original: 'беларуская' },
	[WikimediaLanguageCodeEnum.BE_TARASK_BELARUSIAN_TARASKIEVICA_ORTHOGRAPHY]: {
		english: 'Belarusian (Taraškievica orthography)',
		original: 'беларуская (тарашкевіца)',
	},
	[WikimediaLanguageCodeEnum.BE_X_OLD_BELARUSIAN_TARASKIEVICA_ORTHOGRAPHY]: {
		english: 'Belarusian (Taraškievica orthography)',
		original: 'беларуская (тарашкевіца)',
	},
	[WikimediaLanguageCodeEnum.BG_BULGARIAN]: { english: 'Bulgarian', original: 'български' },
	[WikimediaLanguageCodeEnum.BH_BHOJPURI]: { english: 'Bhojpuri', original: 'भोजपुरी' },
	[WikimediaLanguageCodeEnum.BI_BISLAMA]: { english: 'Bislama', original: 'Bislama' },
	[WikimediaLanguageCodeEnum.BJN_BANJAR]: { english: 'Banjar', original: 'Banjar' },
	[WikimediaLanguageCodeEnum.BLK_PAO]: { english: "Pa'O", original: 'ပအိုဝ်ႏဘာႏသာႏ' },
	[WikimediaLanguageCodeEnum.BM_BAMBARA]: { english: 'Bambara', original: 'bamanankan' },
	[WikimediaLanguageCodeEnum.BN_BANGLA]: { english: 'Bangla', original: 'বাংলা' },
	[WikimediaLanguageCodeEnum.BO_TIBETAN]: { english: 'Tibetan', original: 'བོད་ཡིག' },
	[WikimediaLanguageCodeEnum.BPY_BISHNUPRIYA]: {
		english: 'Bishnupriya',
		original: 'বিষ্ণুপ্রিয়া মণিপুরী',
	},
	[WikimediaLanguageCodeEnum.BR_BRETON]: { english: 'Breton', original: 'brezhoneg' },
	[WikimediaLanguageCodeEnum.BS_BOSNIAN]: { english: 'Bosnian', original: 'bosanski' },
	[WikimediaLanguageCodeEnum.BUG_BUGINESE]: { english: 'Buginese', original: 'ᨅᨔ ᨕᨘᨁᨗ' },
	[WikimediaLanguageCodeEnum.BXR_RUSSIA_BURIAT]: { english: 'Russia Buriat', original: 'буряад' },
	[WikimediaLanguageCodeEnum.CA_CATALAN]: { english: 'Catalan', original: 'català' },
	[WikimediaLanguageCodeEnum.CBK_ZAM_CHAVACANO]: {
		english: 'Chavacano',
		original: 'Chavacano de Zamboanga',
	},
	[WikimediaLanguageCodeEnum.CDO_MIN_DONG_CHINESE]: {
		english: 'Min Dong Chinese',
		original: 'Mìng-dĕ̤ng-ngṳ̄',
	},
	[WikimediaLanguageCodeEnum.CE_CHECHEN]: { english: 'Chechen', original: 'нохчийн' },
	[WikimediaLanguageCodeEnum.CEB_CEBUANO]: { english: 'Cebuano', original: 'Cebuano' },
	[WikimediaLanguageCodeEnum.CH_CHAMORRO]: { english: 'Chamorro', original: 'Chamoru' },
	[WikimediaLanguageCodeEnum.CHR_CHEROKEE]: { english: 'Cherokee', original: 'ᏣᎳᎩ' },
	[WikimediaLanguageCodeEnum.CHY_CHEYENNE]: { english: 'Cheyenne', original: 'Tsetsêhestâhese' },
	[WikimediaLanguageCodeEnum.CKB_CENTRAL_KURDISH]: {
		english: 'Central Kurdish',
		original: 'کوردی',
	},
	[WikimediaLanguageCodeEnum.CO_CORSICAN]: { english: 'Corsican', original: 'corsu' },
	[WikimediaLanguageCodeEnum.CR_CREE]: { english: 'Cree', original: 'Nēhiyawēwin / ᓀᐦᐃᔭᐍᐏᐣ' },
	[WikimediaLanguageCodeEnum.CRH_CRIMEAN_TATAR]: {
		english: 'Crimean Tatar',
		original: 'qırımtatarca',
	},
	[WikimediaLanguageCodeEnum.CS_CZECH]: { english: 'Czech', original: 'čeština' },
	[WikimediaLanguageCodeEnum.CSB_KASHUBIAN]: { english: 'Kashubian', original: 'kaszëbsczi' },
	[WikimediaLanguageCodeEnum.CU_CHURCH_SLAVIC]: {
		english: 'Church Slavic',
		original: 'словѣньскъ / ⰔⰎⰑⰂⰡⰐⰠⰔⰍⰟ',
	},
	[WikimediaLanguageCodeEnum.CV_CHUVASH]: { english: 'Chuvash', original: 'чӑвашла' },
	[WikimediaLanguageCodeEnum.CY_WELSH]: { english: 'Welsh', original: 'Cymraeg' },
	[WikimediaLanguageCodeEnum.DA_DANISH]: { english: 'Danish', original: 'dansk' },
	[WikimediaLanguageCodeEnum.DAG_DAGBANI]: { english: 'Dagbani', original: 'dagbanli' },
	[WikimediaLanguageCodeEnum.DE_GERMAN]: { english: 'German', original: 'Deutsch' },
	[WikimediaLanguageCodeEnum.DIN_DINKA]: { english: 'Dinka', original: 'Thuɔŋjäŋ' },
	[WikimediaLanguageCodeEnum.DIQ_ZAZAKI]: { english: 'Zazaki', original: 'Zazaki' },
	[WikimediaLanguageCodeEnum.DSB_LOWER_SORBIAN]: {
		english: 'Lower Sorbian',
		original: 'dolnoserbski',
	},
	[WikimediaLanguageCodeEnum.DTY_DOTELI]: { english: 'Doteli', original: 'डोटेली' },
	[WikimediaLanguageCodeEnum.DV_DIVEHI]: { english: 'Divehi', original: 'ދިވެހިބަސް' },
	[WikimediaLanguageCodeEnum.DZ_DZONGKHA]: { english: 'Dzongkha', original: 'ཇོང་ཁ' },
	[WikimediaLanguageCodeEnum.EE_EWE]: { english: 'Ewe', original: 'eʋegbe' },
	[WikimediaLanguageCodeEnum.EL_GREEK]: { english: 'Greek', original: 'Ελληνικά' },
	[WikimediaLanguageCodeEnum.EML_EMILIANO_ROMAGNOLO]: {
		english: 'Emiliano-Romagnolo',
		original: 'emiliàn e rumagnòl',
	},
	[WikimediaLanguageCodeEnum.EN_ENGLISH]: { english: 'English', original: 'English' },
	[WikimediaLanguageCodeEnum.EO_ESPERANTO]: { english: 'Esperanto', original: 'Esperanto' },
	[WikimediaLanguageCodeEnum.ES_SPANISH]: { english: 'Spanish', original: 'español' },
	[WikimediaLanguageCodeEnum.ET_ESTONIAN]: { english: 'Estonian', original: 'eesti' },
	[WikimediaLanguageCodeEnum.EU_BASQUE]: { english: 'Basque', original: 'euskara' },
	[WikimediaLanguageCodeEnum.EXT_EXTREMADURAN]: {
		english: 'Extremaduran',
		original: 'estremeñu',
	},
	[WikimediaLanguageCodeEnum.FA_PERSIAN]: { english: 'Persian', original: 'فارسی' },
	[WikimediaLanguageCodeEnum.FF_FULAH]: { english: 'Fulah', original: 'Fulfulde' },
	[WikimediaLanguageCodeEnum.FI_FINNISH]: { english: 'Finnish', original: 'suomi' },
	[WikimediaLanguageCodeEnum.FIU_VRO_VORO]: { english: 'võro', original: 'võro' },
	[WikimediaLanguageCodeEnum.FJ_FIJIAN]: { english: 'Fijian', original: 'Na Vosa Vakaviti' },
	[WikimediaLanguageCodeEnum.FO_FAROESE]: { english: 'Faroese', original: 'føroyskt' },
	[WikimediaLanguageCodeEnum.FR_FRENCH]: { english: 'French', original: 'français' },
	[WikimediaLanguageCodeEnum.FRP_ARPITAN]: { english: 'Arpitan', original: 'arpetan' },
	[WikimediaLanguageCodeEnum.FRR_NORTHERN_FRISIAN]: {
		english: 'Northern Frisian',
		original: 'Nordfriisk',
	},
	[WikimediaLanguageCodeEnum.FUR_FRIULIAN]: { english: 'Friulian', original: 'furlan' },
	[WikimediaLanguageCodeEnum.FY_WESTERN_FRISIAN]: {
		english: 'Western Frisian',
		original: 'Frysk',
	},
	[WikimediaLanguageCodeEnum.GA_IRISH]: { english: 'Irish', original: 'Gaeilge' },
	[WikimediaLanguageCodeEnum.GAG_GAGAUZ]: { english: 'Gagauz', original: 'Gagauz' },
	[WikimediaLanguageCodeEnum.GAN_GAN_CHINESE]: { english: 'Gan Chinese', original: '贛語' },
	[WikimediaLanguageCodeEnum.GCR_GUIANAN_CREOLE]: {
		english: 'Guianan Creole',
		original: 'kriyòl gwiyannen',
	},
	[WikimediaLanguageCodeEnum.GD_SCOTTISH_GAELIC]: {
		english: 'Scottish Gaelic',
		original: 'Gàidhlig',
	},
	[WikimediaLanguageCodeEnum.GL_GALICIAN]: { english: 'Galician', original: 'galego' },
	[WikimediaLanguageCodeEnum.GLK_GILAKI]: { english: 'Gilaki', original: 'گیلکی' },
	[WikimediaLanguageCodeEnum.GN_GUARANI]: { english: 'Guarani', original: "Avañe'ẽ" },
	[WikimediaLanguageCodeEnum.GOM_GOAN_KONKANI]: {
		english: 'Goan Konkani',
		original: 'गोंयची कोंकणी / Gõychi Konknni',
	},
	[WikimediaLanguageCodeEnum.GOR_GORONTALO]: {
		english: 'Gorontalo',
		original: 'Bahasa Hulontalo',
	},
	[WikimediaLanguageCodeEnum.GOT_GOTHIC]: { english: 'Gothic', original: '𐌲𐌿𐍄𐌹𐍃𐌺' },
	[WikimediaLanguageCodeEnum.GU_GUJARATI]: { english: 'Gujarati', original: 'ગુજરાતી' },
	[WikimediaLanguageCodeEnum.GUW_GUN]: { english: 'Gun', original: 'gungbe' },
	[WikimediaLanguageCodeEnum.GV_MANX]: { english: 'Manx', original: 'Gaelg' },
	[WikimediaLanguageCodeEnum.HA_HAUSA]: { english: 'Hausa', original: 'Hausa' },
	[WikimediaLanguageCodeEnum.HAK_HAKKA_CHINESE]: {
		english: 'Hakka Chinese',
		original: '客家語/Hak-kâ-ngî',
	},
	[WikimediaLanguageCodeEnum.HAW_HAWAIIAN]: { english: 'Hawaiian', original: 'Hawaiʻi' },
	[WikimediaLanguageCodeEnum.HE_HEBREW]: { english: 'Hebrew', original: 'עברית' },
	[WikimediaLanguageCodeEnum.HI_HINDI]: { english: 'Hindi', original: 'हिन्दी' },
	[WikimediaLanguageCodeEnum.HIF_FIJI_HINDI]: { english: 'Fiji Hindi', original: 'Fiji Hindi' },
	[WikimediaLanguageCodeEnum.HR_CROATIAN]: { english: 'Croatian', original: 'hrvatski' },
	[WikimediaLanguageCodeEnum.HSB_UPPER_SORBIAN]: {
		english: 'Upper Sorbian',
		original: 'hornjoserbsce',
	},
	[WikimediaLanguageCodeEnum.HT_HAITIAN_CREOLE]: {
		english: 'Haitian Creole',
		original: 'Kreyòl ayisyen',
	},
	[WikimediaLanguageCodeEnum.HU_HUNGARIAN]: { english: 'Hungarian', original: 'magyar' },
	[WikimediaLanguageCodeEnum.HY_ARMENIAN]: { english: 'Armenian', original: 'հայերեն' },
	[WikimediaLanguageCodeEnum.HYW_WESTERN_ARMENIAN]: {
		english: 'Western Armenian',
		original: 'Արեւմտահայերէն',
	},
	[WikimediaLanguageCodeEnum.IA_INTERLINGUA]: { english: 'Interlingua', original: 'interlingua' },
	[WikimediaLanguageCodeEnum.ID_INDONESIAN]: {
		english: 'Indonesian',
		original: 'Bahasa Indonesia',
	},
	[WikimediaLanguageCodeEnum.IE_INTERLINGUE]: { english: 'Interlingue', original: 'Interlingue' },
	[WikimediaLanguageCodeEnum.IG_IGBO]: { english: 'Igbo', original: 'Igbo' },
	[WikimediaLanguageCodeEnum.IK_INUPIAQ]: { english: 'Inupiaq', original: 'Iñupiatun' },
	[WikimediaLanguageCodeEnum.ILO_ILOKO]: { english: 'Iloko', original: 'Ilokano' },
	[WikimediaLanguageCodeEnum.INH_INGUSH]: { english: 'Ingush', original: 'гӀалгӀай' },
	[WikimediaLanguageCodeEnum.IO_IDO]: { english: 'Ido', original: 'Ido' },
	[WikimediaLanguageCodeEnum.IS_ICELANDIC]: { english: 'Icelandic', original: 'íslenska' },
	[WikimediaLanguageCodeEnum.IT_ITALIAN]: { english: 'Italian', original: 'italiano' },
	[WikimediaLanguageCodeEnum.IU_INUKTITUT]: {
		english: 'Inuktitut',
		original: 'ᐃᓄᒃᑎᑐᑦ/inuktitut',
	},
	[WikimediaLanguageCodeEnum.JA_JAPANESE]: { english: 'Japanese', original: '日本語' },
	[WikimediaLanguageCodeEnum.JAM_JAMAICAN_CREOLE_ENGLISH]: {
		english: 'Jamaican Creole English',
		original: 'Patois',
	},
	[WikimediaLanguageCodeEnum.JBO_LOJBAN]: { english: 'Lojban', original: 'la .lojban.' },
	[WikimediaLanguageCodeEnum.JV_JAVANESE]: { english: 'Javanese', original: 'Jawa' },
	[WikimediaLanguageCodeEnum.KA_GEORGIAN]: { english: 'Georgian', original: 'ქართული' },
	[WikimediaLanguageCodeEnum.KAA_KARA_KALPAK]: {
		english: 'Kara-Kalpak',
		original: 'Qaraqalpaqsha',
	},
	[WikimediaLanguageCodeEnum.KAB_KABYLE]: { english: 'Kabyle', original: 'Taqbaylit' },
	[WikimediaLanguageCodeEnum.KBD_KABARDIAN]: { english: 'Kabardian', original: 'адыгэбзэ' },
	[WikimediaLanguageCodeEnum.KBP_KABIYE]: { english: 'Kabiye', original: 'Kabɩyɛ' },
	[WikimediaLanguageCodeEnum.KCG_TYAP]: { english: 'Tyap', original: 'Tyap' },
	[WikimediaLanguageCodeEnum.KG_KONGO]: { english: 'Kongo', original: 'Kongo' },
	[WikimediaLanguageCodeEnum.KI_KIKUYU]: { english: 'Kikuyu', original: 'Gĩkũyũ' },
	[WikimediaLanguageCodeEnum.KK_KAZAKH]: { english: 'Kazakh', original: 'қазақша' },
	[WikimediaLanguageCodeEnum.KL_KALAALLISUT]: { english: 'Kalaallisut', original: 'kalaallisut' },
	[WikimediaLanguageCodeEnum.KM_KHMER]: { english: 'Khmer', original: 'ភាសាខ្មែរ' },
	[WikimediaLanguageCodeEnum.KN_KANNADA]: { english: 'Kannada', original: 'ಕನ್ನಡ' },
	[WikimediaLanguageCodeEnum.KO_KOREAN]: { english: 'Korean', original: '한국어' },
	[WikimediaLanguageCodeEnum.KOI_KOMI_PERMYAK]: {
		english: 'Komi-Permyak',
		original: 'перем коми',
	},
	[WikimediaLanguageCodeEnum.KRC_KARACHAY_BALKAR]: {
		english: 'Karachay-Balkar',
		original: 'къарачай-малкъар',
	},
	[WikimediaLanguageCodeEnum.KS_KASHMIRI]: { english: 'Kashmiri', original: 'कॉशुर / کٲشُر' },
	[WikimediaLanguageCodeEnum.KSH_COLOGNIAN]: { english: 'Colognian', original: 'Ripoarisch' },
	[WikimediaLanguageCodeEnum.KU_KURDISH]: { english: 'Kurdish', original: 'kurdî' },
	[WikimediaLanguageCodeEnum.KV_KOMI]: { english: 'Komi', original: 'коми' },
	[WikimediaLanguageCodeEnum.KW_CORNISH]: { english: 'Cornish', original: 'kernowek' },
	[WikimediaLanguageCodeEnum.KY_KYRGYZ]: { english: 'Kyrgyz', original: 'кыргызча' },
	[WikimediaLanguageCodeEnum.LA_LATIN]: { english: 'Latin', original: 'Latina' },
	[WikimediaLanguageCodeEnum.LAD_LADINO]: { english: 'Ladino', original: 'Ladino' },
	[WikimediaLanguageCodeEnum.LB_LUXEMBOURGISH]: {
		english: 'Luxembourgish',
		original: 'Lëtzebuergesch',
	},
	[WikimediaLanguageCodeEnum.LBE_LAK]: { english: 'Lak', original: 'лакку' },
	[WikimediaLanguageCodeEnum.LEZ_LEZGHIAN]: { english: 'Lezghian', original: 'лезги' },
	[WikimediaLanguageCodeEnum.LFN_LINGUA_FRANCA_NOVA]: {
		english: 'Lingua Franca Nova',
		original: 'Lingua Franca Nova',
	},
	[WikimediaLanguageCodeEnum.LG_GANDA]: { english: 'Ganda', original: 'Luganda' },
	[WikimediaLanguageCodeEnum.LI_LIMBURGISH]: { english: 'Limburgish', original: 'Limburgs' },
	[WikimediaLanguageCodeEnum.LIJ_LIGURIAN]: { english: 'Ligurian', original: 'Ligure' },
	[WikimediaLanguageCodeEnum.LLD_LADIN]: { english: 'Ladin', original: 'Ladin' },
	[WikimediaLanguageCodeEnum.LMO_LOMBARD]: { english: 'Lombard', original: 'lombard' },
	[WikimediaLanguageCodeEnum.LN_LINGALA]: { english: 'Lingala', original: 'lingála' },
	[WikimediaLanguageCodeEnum.LO_LAO]: { english: 'Lao', original: 'ລາວ' },
	[WikimediaLanguageCodeEnum.LT_LITHUANIAN]: { english: 'Lithuanian', original: 'lietuvių' },
	[WikimediaLanguageCodeEnum.LTG_LATGALIAN]: { english: 'Latgalian', original: 'latgaļu' },
	[WikimediaLanguageCodeEnum.LV_LATVIAN]: { english: 'Latvian', original: 'latviešu' },
	[WikimediaLanguageCodeEnum.MAD_MADURESE]: { english: 'Madurese', original: 'Madhurâ' },
	[WikimediaLanguageCodeEnum.MAI_MAITHILI]: { english: 'Maithili', original: 'मैथिली' },
	[WikimediaLanguageCodeEnum.MAP_BMS_BASA_BANYUMASAN]: {
		english: 'Basa Banyumasan',
		original: 'Basa Banyumasan',
	},
	[WikimediaLanguageCodeEnum.MDF_MOKSHA]: { english: 'Moksha', original: 'мокшень' },
	[WikimediaLanguageCodeEnum.MG_MALAGASY]: { english: 'Malagasy', original: 'Malagasy' },
	[WikimediaLanguageCodeEnum.MHR_EASTERN_MARI]: {
		english: 'Eastern Mari',
		original: 'олык марий',
	},
	[WikimediaLanguageCodeEnum.MI_MAORI]: { english: 'Maori', original: 'Māori' },
	[WikimediaLanguageCodeEnum.MIN_MINANGKABAU]: {
		english: 'Minangkabau',
		original: 'Minangkabau',
	},
	[WikimediaLanguageCodeEnum.MK_MACEDONIAN]: { english: 'Macedonian', original: 'македонски' },
	[WikimediaLanguageCodeEnum.ML_MALAYALAM]: { english: 'Malayalam', original: 'മലയാളം' },
	[WikimediaLanguageCodeEnum.MN_MONGOLIAN]: { english: 'Mongolian', original: 'монгол' },
	[WikimediaLanguageCodeEnum.MNI_MANIPURI]: { english: 'Manipuri', original: 'ꯃꯤꯇꯩ ꯂꯣꯟ' },
	[WikimediaLanguageCodeEnum.MNW_MON]: { english: 'Mon', original: 'ဘာသာ မန်' },
	[WikimediaLanguageCodeEnum.MO_MOLDOVAN]: { english: 'Moldovan', original: 'молдовеняскэ' },
	[WikimediaLanguageCodeEnum.MR_MARATHI]: { english: 'Marathi', original: 'मराठी' },
	[WikimediaLanguageCodeEnum.MRJ_WESTERN_MARI]: {
		english: 'Western Mari',
		original: 'кырык мары',
	},
	[WikimediaLanguageCodeEnum.MS_MALAY]: { english: 'Malay', original: 'Bahasa Melayu' },
	[WikimediaLanguageCodeEnum.MT_MALTESE]: { english: 'Maltese', original: 'Malti' },
	[WikimediaLanguageCodeEnum.MWL_MIRANDESE]: { english: 'Mirandese', original: 'Mirandés' },
	[WikimediaLanguageCodeEnum.MY_BURMESE]: { english: 'Burmese', original: 'မြန်မာဘာသာ' },
	[WikimediaLanguageCodeEnum.MYV_ERZYA]: { english: 'Erzya', original: 'эрзянь' },
	[WikimediaLanguageCodeEnum.MZN_MAZANDERANI]: { english: 'Mazanderani', original: 'مازِرونی' },
	[WikimediaLanguageCodeEnum.NA_NAURU]: { english: 'Nauru', original: 'Dorerin Naoero' },
	[WikimediaLanguageCodeEnum.NAH_NAHUATL]: { english: 'Nāhuatl', original: 'Nāhuatl' },
	[WikimediaLanguageCodeEnum.NAP_NEAPOLITAN]: { english: 'Neapolitan', original: 'Napulitano' },
	[WikimediaLanguageCodeEnum.NDS_LOW_GERMAN]: { english: 'Low German', original: 'Plattdüütsch' },
	[WikimediaLanguageCodeEnum.NDS_NL_LOW_SAXON]: {
		english: 'Low Saxon',
		original: 'Nedersaksies',
	},
	[WikimediaLanguageCodeEnum.NE_NEPALI]: { english: 'Nepali', original: 'नेपाली' },
	[WikimediaLanguageCodeEnum.NEW_NEWARI]: { english: 'Newari', original: 'नेपाल भाषा' },
	[WikimediaLanguageCodeEnum.NIA_NIAS]: { english: 'Nias', original: 'Li Niha' },
	[WikimediaLanguageCodeEnum.NL_DUTCH]: { english: 'Dutch', original: 'Nederlands' },
	[WikimediaLanguageCodeEnum.NN_NORWEGIAN_NYNORSK]: {
		english: 'Norwegian Nynorsk',
		original: 'norsk nynorsk',
	},
	[WikimediaLanguageCodeEnum.NO_NORWEGIAN]: { english: 'Norwegian', original: 'norsk' },
	[WikimediaLanguageCodeEnum.NOV_NOVIAL]: { english: 'Novial', original: 'Novial' },
	[WikimediaLanguageCodeEnum.NQO_NKO]: { english: 'N’Ko', original: 'ߒߞߏ' },
	[WikimediaLanguageCodeEnum.NRM_NORMAN]: { english: 'Norman', original: 'Nouormand' },
	[WikimediaLanguageCodeEnum.NSO_NORTHERN_SOTHO]: {
		english: 'Northern Sotho',
		original: 'Sesotho sa Leboa',
	},
	[WikimediaLanguageCodeEnum.NV_NAVAJO]: { english: 'Navajo', original: 'Diné bizaad' },
	[WikimediaLanguageCodeEnum.NY_NYANJA]: { english: 'Nyanja', original: 'Chi-Chewa' },
	[WikimediaLanguageCodeEnum.OC_OCCITAN]: { english: 'Occitan', original: 'occitan' },
	[WikimediaLanguageCodeEnum.OLO_LIVVI_KARELIAN]: {
		english: 'Livvi-Karelian',
		original: 'livvinkarjala',
	},
	[WikimediaLanguageCodeEnum.OM_OROMO]: { english: 'Oromo', original: 'Oromoo' },
	[WikimediaLanguageCodeEnum.OR_ODIA]: { english: 'Odia', original: 'ଓଡ଼ିଆ' },
	[WikimediaLanguageCodeEnum.OS_OSSETIC]: { english: 'Ossetic', original: 'ирон' },
	[WikimediaLanguageCodeEnum.PA_PUNJABI]: { english: 'Punjabi', original: 'ਪੰਜਾਬੀ' },
	[WikimediaLanguageCodeEnum.PAG_PANGASINAN]: { english: 'Pangasinan', original: 'Pangasinan' },
	[WikimediaLanguageCodeEnum.PAM_PAMPANGA]: { english: 'Pampanga', original: 'Kapampangan' },
	[WikimediaLanguageCodeEnum.PAP_PAPIAMENTO]: { english: 'Papiamento', original: 'Papiamentu' },
	[WikimediaLanguageCodeEnum.PCD_PICARD]: { english: 'Picard', original: 'Picard' },
	[WikimediaLanguageCodeEnum.PCM_NIGERIAN_PIDGIN]: {
		english: 'Nigerian Pidgin',
		original: 'Naijá',
	},
	[WikimediaLanguageCodeEnum.PDC_PENNSYLVANIA_GERMAN]: {
		english: 'Pennsylvania German',
		original: 'Deitsch',
	},
	[WikimediaLanguageCodeEnum.PFL_PALATINE_GERMAN]: {
		english: 'Palatine German',
		original: 'Pälzisch',
	},
	[WikimediaLanguageCodeEnum.PI_PALI]: { english: 'Pali', original: 'पालि' },
	[WikimediaLanguageCodeEnum.PIH_NORFUK_PITKERN]: {
		english: 'Norfuk / Pitkern',
		original: 'Norfuk / Pitkern',
	},
	[WikimediaLanguageCodeEnum.PL_POLISH]: { english: 'Polish', original: 'polski' },
	[WikimediaLanguageCodeEnum.PMS_PIEDMONTESE]: { english: 'Piedmontese', original: 'Piemontèis' },
	[WikimediaLanguageCodeEnum.PNB_WESTERN_PUNJABI]: {
		english: 'Western Punjabi',
		original: 'پنجابی',
	},
	[WikimediaLanguageCodeEnum.PNT_PONTIC]: { english: 'Pontic', original: 'Ποντιακά' },
	[WikimediaLanguageCodeEnum.PS_PASHTO]: { english: 'Pashto', original: 'پښتو' },
	[WikimediaLanguageCodeEnum.PT_PORTUGUESE]: { english: 'Portuguese', original: 'português' },
	[WikimediaLanguageCodeEnum.PWN_PAIWAN]: { english: 'Paiwan', original: 'pinayuanan' },
	[WikimediaLanguageCodeEnum.QU_QUECHUA]: { english: 'Quechua', original: 'Runa Simi' },
	[WikimediaLanguageCodeEnum.RM_ROMANSH]: { english: 'Romansh', original: 'rumantsch' },
	[WikimediaLanguageCodeEnum.RMY_VLAX_ROMANI]: {
		english: 'Vlax Romani',
		original: 'romani čhib',
	},
	[WikimediaLanguageCodeEnum.RN_RUNDI]: { english: 'Rundi', original: 'ikirundi' },
	[WikimediaLanguageCodeEnum.RO_ROMANIAN]: { english: 'Romanian', original: 'română' },
	[WikimediaLanguageCodeEnum.ROA_RUP_AROMANIAN]: {
		english: 'Aromanian',
		original: 'armãneashti',
	},
	[WikimediaLanguageCodeEnum.ROA_TARA_TARANTINO]: { english: 'Tarantino', original: 'tarandíne' },
	[WikimediaLanguageCodeEnum.RU_RUSSIAN]: { english: 'Russian', original: 'русский' },
	[WikimediaLanguageCodeEnum.RUE_RUSYN]: { english: 'Rusyn', original: 'русиньскый' },
	[WikimediaLanguageCodeEnum.RW_KINYARWANDA]: {
		english: 'Kinyarwanda',
		original: 'Ikinyarwanda',
	},
	[WikimediaLanguageCodeEnum.SA_SANSKRIT]: { english: 'Sanskrit', original: 'संस्कृतम्' },
	[WikimediaLanguageCodeEnum.SAH_SAKHA]: { english: 'Sakha', original: 'саха тыла' },
	[WikimediaLanguageCodeEnum.SAT_SANTALI]: { english: 'Santali', original: 'ᱥᱟᱱᱛᱟᱲᱤ' },
	[WikimediaLanguageCodeEnum.SC_SARDINIAN]: { english: 'Sardinian', original: 'sardu' },
	[WikimediaLanguageCodeEnum.SCN_SICILIAN]: { english: 'Sicilian', original: 'sicilianu' },
	[WikimediaLanguageCodeEnum.SCO_SCOTS]: { english: 'Scots', original: 'Scots' },
	[WikimediaLanguageCodeEnum.SD_SINDHI]: { english: 'Sindhi', original: 'سنڌي' },
	[WikimediaLanguageCodeEnum.SE_NORTHERN_SAMI]: {
		english: 'Northern Sami',
		original: 'davvisámegiella',
	},
	[WikimediaLanguageCodeEnum.SG_SANGO]: { english: 'Sango', original: 'Sängö' },
	[WikimediaLanguageCodeEnum.SH_SERBO_CROATIAN]: {
		english: 'Serbo-Croatian',
		original: 'srpskohrvatski / српскохрватски',
	},
	[WikimediaLanguageCodeEnum.SHI_TACHELHIT]: { english: 'Tachelhit', original: 'Taclḥit' },
	[WikimediaLanguageCodeEnum.SHN_SHAN]: { english: 'Shan', original: 'ၽႃႇသႃႇတႆး ' },
	[WikimediaLanguageCodeEnum.SHY_SHAWIYA]: { english: 'Shawiya', original: 'tacawit' },
	[WikimediaLanguageCodeEnum.SI_SINHALA]: { english: 'Sinhala', original: 'සිංහල' },
	[WikimediaLanguageCodeEnum.SIMPLE_SIMPLE_ENGLISH]: {
		english: 'Simple English',
		original: 'Simple English',
	},
	[WikimediaLanguageCodeEnum.SK_SLOVAK]: { english: 'Slovak', original: 'slovenčina' },
	[WikimediaLanguageCodeEnum.SKR_SARAIKI]: { english: 'Saraiki', original: 'سرائیکی' },
	[WikimediaLanguageCodeEnum.SL_SLOVENIAN]: { english: 'Slovenian', original: 'slovenščina' },
	[WikimediaLanguageCodeEnum.SM_SAMOAN]: { english: 'Samoan', original: 'Gagana Samoa' },
	[WikimediaLanguageCodeEnum.SMN_INARI_SAMI]: { english: 'Inari Sami', original: 'anarâškielâ' },
	[WikimediaLanguageCodeEnum.SN_SHONA]: { english: 'Shona', original: 'chiShona' },
	[WikimediaLanguageCodeEnum.SO_SOMALI]: { english: 'Somali', original: 'Soomaaliga' },
	[WikimediaLanguageCodeEnum.SQ_ALBANIAN]: { english: 'Albanian', original: 'shqip' },
	[WikimediaLanguageCodeEnum.SR_SERBIAN]: { english: 'Serbian', original: 'српски / srpski' },
	[WikimediaLanguageCodeEnum.SRN_SRANAN_TONGO]: {
		english: 'Sranan Tongo',
		original: 'Sranantongo',
	},
	[WikimediaLanguageCodeEnum.SS_SWATI]: { english: 'Swati', original: 'SiSwati' },
	[WikimediaLanguageCodeEnum.ST_SOUTHERN_SOTHO]: {
		english: 'Southern Sotho',
		original: 'Sesotho',
	},
	[WikimediaLanguageCodeEnum.STQ_SATERLAND_FRISIAN]: {
		english: 'Saterland Frisian',
		original: 'Seeltersk',
	},
	[WikimediaLanguageCodeEnum.SU_SUNDANESE]: { english: 'Sundanese', original: 'Sunda' },
	[WikimediaLanguageCodeEnum.SV_SWEDISH]: { english: 'Swedish', original: 'svenska' },
	[WikimediaLanguageCodeEnum.SW_SWAHILI]: { english: 'Swahili', original: 'Kiswahili' },
	[WikimediaLanguageCodeEnum.SZL_SILESIAN]: { english: 'Silesian', original: 'ślůnski' },
	[WikimediaLanguageCodeEnum.SZY_SAKIZAYA]: { english: 'Sakizaya', original: 'Sakizaya' },
	[WikimediaLanguageCodeEnum.TA_TAMIL]: { english: 'Tamil', original: 'தமிழ்' },
	[WikimediaLanguageCodeEnum.TAY_TAYAL]: { english: 'Tayal', original: 'Tayal' },
	[WikimediaLanguageCodeEnum.TCY_TULU]: { english: 'Tulu', original: 'ತುಳು' },
	[WikimediaLanguageCodeEnum.TE_TELUGU]: { english: 'Telugu', original: 'తెలుగు' },
	[WikimediaLanguageCodeEnum.TET_TETUM]: { english: 'Tetum', original: 'tetun' },
	[WikimediaLanguageCodeEnum.TG_TAJIK]: { english: 'Tajik', original: 'тоҷикӣ' },
	[WikimediaLanguageCodeEnum.TH_THAI]: { english: 'Thai', original: 'ไทย' },
	[WikimediaLanguageCodeEnum.TI_TIGRINYA]: { english: 'Tigrinya', original: 'ትግርኛ' },
	[WikimediaLanguageCodeEnum.TK_TURKMEN]: { english: 'Turkmen', original: 'Türkmençe' },
	[WikimediaLanguageCodeEnum.TL_TAGALOG]: { english: 'Tagalog', original: 'Tagalog' },
	[WikimediaLanguageCodeEnum.TN_TSWANA]: { english: 'Tswana', original: 'Setswana' },
	[WikimediaLanguageCodeEnum.TO_TONGAN]: { english: 'Tongan', original: 'lea faka-Tonga' },
	[WikimediaLanguageCodeEnum.TPI_TOK_PISIN]: { english: 'Tok Pisin', original: 'Tok Pisin' },
	[WikimediaLanguageCodeEnum.TR_TURKISH]: { english: 'Turkish', original: 'Türkçe' },
	[WikimediaLanguageCodeEnum.TRV_TAROKO]: { english: 'Taroko', original: 'Seediq' },
	[WikimediaLanguageCodeEnum.TS_TSONGA]: { english: 'Tsonga', original: 'Xitsonga' },
	[WikimediaLanguageCodeEnum.TT_TATAR]: { english: 'Tatar', original: 'татарча/tatarça' },
	[WikimediaLanguageCodeEnum.TUM_TUMBUKA]: { english: 'Tumbuka', original: 'chiTumbuka' },
	[WikimediaLanguageCodeEnum.TW_TWI]: { english: 'Twi', original: 'Twi' },
	[WikimediaLanguageCodeEnum.TY_TAHITIAN]: { english: 'Tahitian', original: 'reo tahiti' },
	[WikimediaLanguageCodeEnum.TYV_TUVINIAN]: { english: 'Tuvinian', original: 'тыва дыл' },
	[WikimediaLanguageCodeEnum.UDM_UDMURT]: { english: 'Udmurt', original: 'удмурт' },
	[WikimediaLanguageCodeEnum.UG_UYGHUR]: { english: 'Uyghur', original: 'ئۇيغۇرچە / Uyghurche' },
	[WikimediaLanguageCodeEnum.UK_UKRAINIAN]: { english: 'Ukrainian', original: 'українська' },
	[WikimediaLanguageCodeEnum.UR_URDU]: { english: 'Urdu', original: 'اردو' },
	[WikimediaLanguageCodeEnum.UZ_UZBEK]: { english: 'Uzbek', original: 'oʻzbekcha/ўзбекча' },
	[WikimediaLanguageCodeEnum.VE_VENDA]: { english: 'Venda', original: 'Tshivenda' },
	[WikimediaLanguageCodeEnum.VEC_VENETIAN]: { english: 'Venetian', original: 'vèneto' },
	[WikimediaLanguageCodeEnum.VEP_VEPS]: { english: 'Veps', original: 'vepsän kel’' },
	[WikimediaLanguageCodeEnum.VI_VIETNAMESE]: { english: 'Vietnamese', original: 'Tiếng Việt' },
	[WikimediaLanguageCodeEnum.VLS_WEST_FLEMISH]: {
		english: 'West Flemish',
		original: 'West-Vlams',
	},
	[WikimediaLanguageCodeEnum.VO_VOLAPUK]: { english: 'Volapük', original: 'Volapük' },
	[WikimediaLanguageCodeEnum.WA_WALLOON]: { english: 'Walloon', original: 'walon' },
	[WikimediaLanguageCodeEnum.WAR_WARAY]: { english: 'Waray', original: 'Winaray' },
	[WikimediaLanguageCodeEnum.WO_WOLOF]: { english: 'Wolof', original: 'Wolof' },
	[WikimediaLanguageCodeEnum.WUU_WU_CHINESE]: { english: 'Wu Chinese', original: '吴语' },
	[WikimediaLanguageCodeEnum.XAL_KALMYK]: { english: 'Kalmyk', original: 'хальмг' },
	[WikimediaLanguageCodeEnum.XH_XHOSA]: { english: 'Xhosa', original: 'isiXhosa' },
	[WikimediaLanguageCodeEnum.XMF_MINGRELIAN]: { english: 'Mingrelian', original: 'მარგალური' },
	[WikimediaLanguageCodeEnum.YI_YIDDISH]: { english: 'Yiddish', original: 'ייִדיש' },
	[WikimediaLanguageCodeEnum.YO_YORUBA]: { english: 'Yoruba', original: 'Yorùbá' },
	[WikimediaLanguageCodeEnum.YUE_CANTONESE]: { english: 'Cantonese', original: '粵語' },
	[WikimediaLanguageCodeEnum.ZA_ZHUANG]: { english: 'Zhuang', original: 'Vahcuengh' },
	[WikimediaLanguageCodeEnum.ZEA_ZEELANDIC]: { english: 'Zeelandic', original: 'Zeêuws' },
	[WikimediaLanguageCodeEnum.ZH_CHINESE]: { english: 'Chinese', original: '中文' },
	[WikimediaLanguageCodeEnum.ZH_CLASSICAL_CLASSICAL_CHINESE]: {
		english: 'Classical Chinese',
		original: '文言',
	},
	[WikimediaLanguageCodeEnum.ZH_MIN_NAN_CHINESE_MIN_NAN]: {
		english: 'Chinese (Min Nan)',
		original: 'Bân-lâm-gú',
	},
	[WikimediaLanguageCodeEnum.ZH_YUE_CANTONESE]: { english: 'Cantonese', original: '粵語' },
	[WikimediaLanguageCodeEnum.ZU_ZULU]: { english: 'Zulu', original: 'isiZulu' },
};
