import { Optional } from '@reach/interfaces';

/**
 * Remove the given properties from the object
 */
export function removeTypedProperties<T, K extends keyof T>(obj: T, keys: K[]): Optional<T, K> {
	const partialObj: Optional<T, K> = { ...obj };

	for (const key of keys) {
		delete partialObj[key];
	}

	return partialObj;
}
