import { EnigmaQuestionInterface } from './enigma-question.interface';
import { EnigmaAnswerInterface } from './enigma-answer.interface';
import { Nullable } from '../typescript/nullable';

export interface EnigmaUserChallengeInterface {
	id: string;
	question: EnigmaQuestionInterface;
	hasBeenGuessed: boolean;
	submittedAnswer: Nullable<EnigmaAnswerInterface>;
	correctAnswer: Nullable<EnigmaAnswerInterface>;
	startedAt: Date;
	finishesAt: Date;
}
