import { NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({
	pure: true,
	name: 'videoDuration',
})
export class VideoDurationPipe implements PipeTransform {
	transform(durationInSeconds: number): string {
		const minutes = Math.floor(durationInSeconds / 60);
		const seconds = Math.floor(durationInSeconds - minutes * 60);

		return `${`${minutes}`.padStart(2, '0')}:${`${seconds}`.padStart(2, '0')}`;
	}
}

@NgModule({
	declarations: [VideoDurationPipe],
	providers: [VideoDurationPipe],
	exports: [VideoDurationPipe],
})
export class VideoDurationModule {}
