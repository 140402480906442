export enum UnsplashRequestOrderBy {
	RELEVANT = 'relevant',
	LATEST = 'latest',
}

export enum UnsplashRequestContentFilter {
	LOW = 'low',
	HIGH = 'high',
}

export enum UnsplashRequestColor {
	BLACK_AND_WHITE = 'black_and_white',
	BLACK = 'black',
	WHITE = 'white',
	YELLOW = 'yellow',
	ORANGE = 'orange',
	RED = 'red',
	PURPLE = 'purple',
	MAGENTA = 'magenta',
	GREEN = 'green',
	TEAL = 'teal',
	BLUE = 'blue',
}

export enum UnsplashRequestOrientation {
	LANDSCAPE = 'landscape',
	PORTRAIT = 'portrait',
	SQUARISH = 'squarish',
}

export interface UnsplashApiResponseResultUrls {
	raw: string;
	full: string;
	regular: string;
	small: string;
	thumb: string;
}

export interface UnsplashApiResponseResultLinks {
	self: string;
	html: string;
	download: string;
	download_location: string;
}

export interface UnsplashApiResponseResultUser {
	id: string;
	username: string;
	name: string;
	first_name: string;
	last_name: string;
	instagram_username: string;
	twitter_username: string;
	portfolio_url: string;
	profile_image: {
		small: string;
		medium: string;
		large: string;
	};
	links: {
		self: string;
		html: string;
		photos: string;
		likes: string;
	};
}

export interface UnsplashApiResponseResult {
	id: string;
	created_at: string;
	width: number;
	height: number;
	color: string;
	blur_hash: string;
	likes: number;
	liked_by_user: boolean;
	description: string;
	user: UnsplashApiResponseResultUser;
	current_user_collections: string[];
	urls: UnsplashApiResponseResultUrls;
	links: UnsplashApiResponseResultLinks;
}

export interface UnsplashApiResponse {
	total: number;
	total_pages: number;
	results: UnsplashApiResponseResult[];
}
