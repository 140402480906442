import { Renderer2 } from '@angular/core';

/**
 * This function changes the attribute `inputmode`, which changes the keyboard on mobile devices.
 *
 * This function looks a little bit weird because firefox has a bug which I reported here
 * { @link https://github.com/mozilla-mobile/fenix/issues/23724 }
 */
export function changeInputMode(
	renderer: Renderer2,
	element: Element | undefined,
	inputmode: string
): void {
	const fn = () => {
		if (!!element) {
			renderer.setAttribute(element, 'inputmode', inputmode);
		}
	};

	const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
	if (isFirefox) {
		requestAnimationFrame(() => {
			if (!!element) {
				fn();
			}
		});
	} else {
		if (!!element) {
			fn();
		}
	}
}
