export enum ToolbarAddBasicOptions {
	BULLET_LIST = 'BULLET_LIST',
	NUMBERED_LIST = 'NUMBERED_LIST',
	CHECK_LIST = 'CHECK_LIST',
	EMOJI = 'EMOJI',
	CODE = 'CODE',
	DIVIDER = 'DIVIDER',
	CALL_OUT = 'CALL_OUT',
	QUOTE = 'QUOTE',
}

export enum ToolbarFormatOptions {
	BOLD = 'BOLD',
	ITALIC = 'ITALIC',
	UNDERLINED = 'UNDERLINED',
	STRIKETHROUGH = 'STRIKETHROUGH',
	FONT_COLOR = 'FONT_COLOR',
	BACKGROUND = 'BACKGROUND',
	TEXT_SIZE = 'TEXT_SIZE',
	HEADING = 'HEADING',
	ALIGN = 'ALIGN',
	RIGHT_TAB = 'RIGHT_TAB',
	LEFT_TAB = 'LEFT_TAB',
}

export enum ToolbarAddMediaOptions {
	URL = 'URL',
	ATTACHMENT = 'ATTACHMENT'
}
