import { NoteSlateItemInterface, ReachSlateElementType } from '@reach/interfaces';
import { HtmlTag, ReachHtmlNode } from '../html-types';
import { ConfigAccumulator } from './types';

export const ELEMENTS_THAT_CAN_NOT_HAVE_TEXT = [
	ReachSlateElementType.TABLE,
	ReachSlateElementType.TABLE_ROW,
	ReachSlateElementType.TABLE_CELL, // text must be inside a paragraph
];

function getAnchorConfig(element: ReachHtmlNode): NoteSlateItemInterface['baseConfig'] {
	return {
		href: element.attribs.href,
	};
}

const HEADER_SUBTYPE_MAP = {
	[HtmlTag.HEADER_1]: 'H1',
	[HtmlTag.HEADER_2]: 'H2',
	[HtmlTag.HEADER_3]: 'H3',
	[HtmlTag.HEADER_4]: 'H3',
	[HtmlTag.HEADER_5]: 'H3',
	[HtmlTag.HEADER_6]: 'H3',
};

function getHeaderConfig(element: ReachHtmlNode): NoteSlateItemInterface['baseConfig'] {
	const subtype = HEADER_SUBTYPE_MAP[element.name || '(no element name)'] || 'H3';
	return { subtype };
}

function getListTypeAndConfig(
	element: ReachHtmlNode
): Pick<NoteSlateItemInterface, 'type' | 'baseConfig'> {
	const isCheckbox = element?.attribs?.['data-checked'] !== undefined;
	const baseType =
		element.name === HtmlTag.ORDERED_LIST
			? ReachSlateElementType.LIST_ORDERED
			: ReachSlateElementType.LIST_UNORDERED;
	const type = isCheckbox ? ReachSlateElementType.LIST_CHECKBOX : baseType;
	return {
		type,
		baseConfig: {},
	};
}

function getListItemTypeAndConfig(
	element: ReachHtmlNode,
	parent: NoteSlateItemInterface | null
): Pick<NoteSlateItemInterface, 'type' | 'baseConfig'> {
	const isCheckbox = parent?.type === ReachSlateElementType.LIST_CHECKBOX;
	const type = isCheckbox ? ReachSlateElementType.CHECKBOX_ITEM : ReachSlateElementType.LIST_ITEM;

	const checked = `${element?.attribs['data-checked'] || ''}` !== 'false';

	const baseConfig = isCheckbox ? { active: checked } : {};

	return {
		type,
		baseConfig,
	};
}

export function element2Candidates(
	element: ReachHtmlNode,
	configAccumulator: ConfigAccumulator
): NoteSlateItemInterface | null {
	const htmlTag = element?.name || "(element doesn't have a tag)";

	switch (htmlTag) {
		case HtmlTag.DIV: {
			return {
				type: ReachSlateElementType.PARAGRAPH,
				children: [],
				baseConfig: configAccumulator[ReachSlateElementType.PARAGRAPH] || {},
			};
		}

		default: {
			return null;
		}
	}
}

export function element2Slate(
	element: ReachHtmlNode,
	parent: NoteSlateItemInterface | null,
	configAccumulator: ConfigAccumulator,
	attachmentSet: Set<string>
): NoteSlateItemInterface | null {
	const htmlTag = element?.name || "(element doesn't have a tag)";

	switch (htmlTag) {
		// PARAGRAPHS

		case HtmlTag.PARAGRAPH: {
			return {
				type: ReachSlateElementType.PARAGRAPH,
				children: [],
				baseConfig: configAccumulator[ReachSlateElementType.PARAGRAPH] || {},
			};
		}

		// IMAGES

		case HtmlTag.IMG: {
			const attachmentId = element?.attribs?.['data-attachment-id'] || '';

			if (!attachmentSet.has(attachmentId)) {
				return null;
			}

			return {
				type: ReachSlateElementType.ATTACHMENT,
				children: [],
				baseConfig: { attachment: { attachmentId } },
			};
		}

		// TABLES

		case HtmlTag.TABLE: {
			return {
				type: ReachSlateElementType.TABLE,
				children: [],
				baseConfig: {},
			};
		}

		case HtmlTag.TABLE_ROW: {
			return {
				type: ReachSlateElementType.TABLE_ROW,
				children: [],
				baseConfig: {},
			};
		}

		case HtmlTag.TABLE_CELL:
		case HtmlTag.TABLE_CELL_2: {
			return {
				type: ReachSlateElementType.TABLE_CELL,
				children: [],
				baseConfig: {},
			};
		}

		// ANCHORS

		case HtmlTag.ANCHOR:
		case HtmlTag.ANCHOR_2: {
			return {
				type: ReachSlateElementType.URL,
				children: [],
				baseConfig: getAnchorConfig(element),
			};
		}

		// HEADERS

		case HtmlTag.HEADER_1:
		case HtmlTag.HEADER_2:
		case HtmlTag.HEADER_3:
		case HtmlTag.HEADER_4:
		case HtmlTag.HEADER_5:
		case HtmlTag.HEADER_6: {
			return {
				type: ReachSlateElementType.HEADER,
				children: [],
				baseConfig: getHeaderConfig(element),
			};
		}

		// LISTS

		case HtmlTag.ORDERED_LIST:
		case HtmlTag.UNORDERED_LIST: {
			const { type, baseConfig } = getListTypeAndConfig(element);
			return {
				type,
				baseConfig,
				children: [],
			};
		}

		case HtmlTag.LIST_ITEM: {
			const { type, baseConfig } = getListItemTypeAndConfig(element, parent);
			return {
				type,
				baseConfig,
				children: [],
			};
		}

		// DIVIDERS

		case HtmlTag.HORIZONTAL_RULE: {
			return {
				type: ReachSlateElementType.DIVIDER,
				children: [],
				baseConfig: {},
			};
		}

		// CODE

		case HtmlTag.PREFORMATTED: {
			return {
				type: ReachSlateElementType.CODE,
				children: [],
				baseConfig: {},
			};
		}

		// BLOCK

		case HtmlTag.BLOCK_QUOTE: {
			return {
				type: ReachSlateElementType.QUOTE,
				children: [],
				baseConfig: {},
			};
		}

		default: {
			return null;
		}
	}
}
