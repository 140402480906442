import { CustomPropertyKeys, CustomPropertyValue, WorkspaceInterface } from '@reach/interfaces';
import { ReachWorkspace } from '~app-client/workspaces/types';

export function workspaceApiToFrontTransformer(workspace: WorkspaceInterface): ReachWorkspace {
	const propertiesMap: {
		[id in CustomPropertyKeys]?: CustomPropertyValue;
	} = workspace.customProperties.reduce((acc, cur) => {
		return {
			...acc,
			[cur.id]: cur,
		};
	}, {});

	const createdAt = propertiesMap[CustomPropertyKeys.CREATION_DATE].value as number;
	const updatedAt = propertiesMap[CustomPropertyKeys.LAST_MODIFIED].value as number;
	const integrationId = propertiesMap[CustomPropertyKeys.INTEGRATION_ID]?.value as string;
	const lastSearch = propertiesMap[CustomPropertyKeys.LAST_SEARCHED]?.value as number;

	return {
		id: workspace.id,
		type: workspace.type,
		name: workspace.name,
		createdAt: new Date(createdAt).valueOf(),
		updatedAt: new Date(updatedAt).valueOf(),
		count: workspace.count || 0,
		emoji: `${workspace.emoji || ''}`.trim() || undefined,
		thumbnailUrl: `${workspace.thumbnailUrl || ''}`.trim() || undefined,
		integrationId,
		customProperties: propertiesMap,
		lastSearch: lastSearch ?? Number.NEGATIVE_INFINITY,
	};
}
