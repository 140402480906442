import { InjectionToken } from '@angular/core';

export type ReachVersion = `${number}.${number}.${number}`;
export const REACH_VERSION = new InjectionToken<ReachVersion>('REACH_VERSION', {
	factory: () => '0.0.0',
});

export type CommitHash = string;
export const REACH_COMMIT_HASH = new InjectionToken<CommitHash>('REACH_COMMIT_HASH', {
	factory: () => 'unknown',
});
