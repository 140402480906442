import { CustomPropertyKeys, CustomPropertyValue } from '@reach/interfaces';
import { ReachNodeId } from '~app-client/core/types';
import { ReachWorkspaceType } from './workspaces-types.type';

export interface ReachWorkspace {
	id: ReachNodeId;

	type: ReachWorkspaceType;

	name: string;
	createdAt: number;
	updatedAt: number;
	count: number;

	integrationId?: string;
	customProperties: {
		[id in CustomPropertyKeys]?: CustomPropertyValue;
	};

	emoji?: string;
	thumbnailUrl?: string;

	lastSearch: number;
}
