import { Injectable } from '@angular/core';
import {
	CustomPropertyValue,
	FileInterface,
	FileUpdateBody,
	LocationLatLonWithPrecision,
	NodeCreationSource,
	NodeCreationSourceBody,
	NodeInterface,
	NoteInterface,
} from '@reach/interfaces';
import { BaseApiService } from '~app-client/api/_internal';
import { ReachNodeId } from '~app-client/core/types';

const sanitize = require('sanitize-filename');

@Injectable({ providedIn: 'root' })
export class FilesApiService extends BaseApiService {
	protected commonPath = ['files'];

	public createFiles(
		files: File[],
		workspaceIds: ReachNodeId[],
		source: NodeCreationSource,
		location?: LocationLatLonWithPrecision
	): Promise<FileInterface[]> {
		const body = new FormData();
		for (const file of files) {
			body.append('files', file);
		}

		const sourceItem: NodeCreationSourceBody = { source };

		body.append('source', JSON.stringify(sourceItem));
		body.append('fileCreate', JSON.stringify({ location }));
		body.append('workspaceIds', JSON.stringify(workspaceIds));

		return this.http.post<FileInterface[]>(this.url(), body);
	}

	public edit(fileId: ReachNodeId, data: FileUpdateBody): Promise<FileInterface> {
		const body = data;
		return this.http.patch<FileInterface>(this.url(fileId), body);
	}

	public replace(fileId: NodeInterface['id'], file: File): Promise<FileInterface> {
		const body = new FormData();
		body.append('file', file);

		return this.http.post<FileInterface>(this.url(fileId, 'replace'), body);
	}

	public noteFromDocNode(nodeId: NodeInterface['id']): Promise<NoteInterface> {
		return this.http.post<NoteInterface>(this.url(nodeId, 'docx-to-note'), {});
	}

	public updateCustomProperties(
		fileId: ReachNodeId,
		customProperties: CustomPropertyValue[],
		workspaceId: ReachNodeId
	): Promise<CustomPropertyValue[]> {
		const body = {
			customProperties,
			fatherWorkspaceId: workspaceId,
		};
		return this.http
			.patch<NodeInterface>(this.url(fileId), body)
			.then((node) => node?.customProperties || []);
	}
}

@Injectable({ providedIn: 'root' })
export class UserFilesApiService extends BaseApiService {
	protected commonPath = [];

	public downloadFile(filePath: string): Promise<{ blob: Blob; extension: string | null }> {
		return this.http.getBlobWithHeaders(filePath, {}, {}).then((response) => {
			const blob = new Blob([response.body as Blob]);
			return {
				blob,
				extension: getExtensionFromContentDisposition(
					response.headers.get('Content-Disposition') || ''
				),
			};
		});
	}

	public getFilename(name: string, extension): string {
		return sanitize(this.getFilenameWithoutSanitization(name, extension), {
			replacement: '_',
		}).replace(/[/\\?%*:|"<>; ]/g, '_');
	}

	private getFilenameWithoutSanitization(name: string, extension): string {
		if (name.endsWith(`.${extension}`)) {
			return name;
		}

		if (!extension) {
			return name;
		}

		return `${name}.${extension}`;
	}
}

function getExtensionFromContentDisposition(headerValue: string): string {
	const rawItemValue =
		headerValue
			.split(';')
			.find((part) => part.includes('filename='))
			?.trim()
			?.split('=')?.[1] || '';
	const itemValueWithSpaces = rawItemValue?.replaceAll('"', '') || '';

	const lastItemAux = itemValueWithSpaces.split(' ');
	const lastItem = lastItemAux[lastItemAux.length - 1];
	const extensionsAux = lastItem?.split('.') || [];
	return extensionsAux[extensionsAux.length - 1];
}
