import { DeepPick } from 'ts-essentials';
import { NodeSubtypeIconEnum, NodeSubtypeId } from '../node-subtypes';
import { NodeType } from '../nodes';
import {
	NodeAdditionalSettingInterface,
	NodeSubtypeAdditionalSettingEnum,
} from './additional-settings';
import { ExtractedContentInterface } from './extracted-content.types';
import { SmartFeatureInterface } from './smart-features.types';

export interface NodeSubtypeSettingsInterface {
	id: string;
	subtypeId: NodeSubtypeId;
	nodeType: NodeType;
	name: string;
	icon: NodeSubtypeIconEnum;
	createdAt: Date;
	updatedAt: Date;
	smartFeatures: SmartFeatureInterface[];
	extractedContent: ExtractedContentInterface[];
	additionalSettings: NodeAdditionalSettingInterface<NodeSubtypeAdditionalSettingEnum>[];
	suggestedPropertyIds: string[];
}

export type UpdateNodeSubtypeSettingsType = DeepPick<
	Partial<NodeSubtypeSettingsInterface>,
	{
		name: never;
		icon: never;
		suggestedPropertyIds: never;
		smartFeatures: Array<{
			feature: never;
			selectedOption: never;
		}>;
		extractedContent: Array<{
			content: never;
			selectedOption: never;
		}>;
		additionalSettings: Array<{
			additionalSetting: never;
			selectedOption: never;
		}>;
	}
>;
