import { NoteSlateItemInterface, ReachSlateElementType } from '@reach/interfaces';
import * as cheerio from 'cheerio';
import { ElementType } from 'domelementtype';
import { HtmlTag, ReachHtmlNode } from '../html-types';
import { getAccConfig, getElementConfig } from './config/base';
import { DebugUtils } from './debug';
import { normalizeNote } from './normalize';
import { element2Candidates, element2Slate, ELEMENTS_THAT_CAN_NOT_HAVE_TEXT } from './slate';
import { ConfigAccumulator } from './types';
import { INLINE_ELEMENTS } from './utils';

const ELEMENT_TO_JUST_PICK_CHILDREN = ['tbody', 'thead', 'tfoot', 'span'];
const ElEMENTS_TO_SKIP = ['colgroup'];

const debugUtils = new DebugUtils();

function elementChildrenParser(
	childNodes: ReachHtmlNode[],
	configAccumulator: ConfigAccumulator,
	parent: NoteSlateItemInterface | null,
	auxiliaryParent: NoteSlateItemInterface | null,
	attachmentSet: Set<string>,
	_debugAcc: string
): NoteSlateItemInterface[] {
	return (childNodes || []).reduce((acc, child, _idx) => {
		elementParser(
			child,
			configAccumulator,
			parent,
			auxiliaryParent,
			attachmentSet,
			_idx,
			childNodes.length,
			_debugAcc
		).forEach((parsedChild) => {
			acc.push(parsedChild);
		});

		return acc;
	}, [] as NoteSlateItemInterface[]);
}

/**
 * Transforms an HTML element to an Slate element (if posible)
 * @param element The HTML element to parse.
 * @param textFormats The previous values that were accumulated to generate the object.
 * @param parent The parent element.
 * @param auxiliaryParent An aux component in case there is no parent.
 * @param _childIdx (DEBUG)
 * @param _totalChilds (DEBUG)
 * @param _debugAcc (DEBUG)
 * @returns an Slate object.
 */
function elementParser(
	element: ReachHtmlNode,
	configAccumulator: ConfigAccumulator,
	parent: NoteSlateItemInterface | null,
	auxiliaryParent: NoteSlateItemInterface | null,
	attachmentSet: Set<string>,
	_childIdx: number,
	_totalChilds: number,
	_debugAcc: string
): NoteSlateItemInterface[] {
	const elementTag = element.name as HtmlTag;
	const childNodes = (element?.childNodes || []) as ReachHtmlNode[];

	// #region Nasty debug code zone
	let _nextDebugAcc = _debugAcc;
	let _debugFirstLine;
	{
		const _hasSiblingsBelow = _totalChilds > _childIdx + 1;
		const _firstLineFirstChar = _hasSiblingsBelow ? '┣' : '┗';
		const _secondLineFirstChar = _hasSiblingsBelow ? '┃' : ' ';

		const _hasChildren = childNodes.length > 0;
		const _firstLineSecondChar = _hasChildren ? '┳' : '━';
		const _secondLineSecondChar = _hasChildren ? '┃' : ' ';

		const _firstLinePrefix = `${_debugAcc}${_firstLineFirstChar}━${_firstLineSecondChar}━`;
		const _secondLinePrefix = `${_debugAcc}${_secondLineFirstChar} ${_secondLineSecondChar} `;

		_nextDebugAcc = `${_secondLinePrefix}`.substring(0, _secondLinePrefix.length - 2);

		_debugFirstLine = debugUtils.newLine(
			`${_firstLinePrefix}╾ ${element.name || element.type}`
		);
		debugUtils.newLine(`${_secondLinePrefix}`);
	}
	// #endregion

	// if text, end of recursion
	if (element.type === ElementType.Text) {
		const text = {
			type: ReachSlateElementType.TEXT,
			baseConfig: {
				...(getAccConfig(ReachSlateElementType.TEXT, configAccumulator) as Record<
					string,
					string
				>),
			},
			text: element.data,
		};

		// #region Nasty debug code
		const _previewCountStart = 8;
		const _previewCountEnd = 8;
		const _previewStart = `${text.text.substring(0, _previewCountStart)}`
			.replace(/\n/g, ' ')
			.trim();
		const _previewEndIndex = Math.max(text.text.length - _previewCountEnd, _previewCountStart);
		const _previewEnd = `${text.text.substring(_previewEndIndex, text.text.length)}`;
		const _previewUseEllipsis = text.text.length > _previewCountStart + _previewCountEnd;
		const _previeEllipsis = _previewUseEllipsis ? '<...>' : '';

		const _debugText = `${_previewStart}${_previeEllipsis}${_previewEnd}`;

		_debugFirstLine.addToLine(`("${_debugText}")`);

		// #endregion

		if (!parent || ELEMENTS_THAT_CAN_NOT_HAVE_TEXT.includes(parent.type)) {
			if (
				!!auxiliaryParent &&
				!ELEMENTS_THAT_CAN_NOT_HAVE_TEXT.includes(auxiliaryParent.type)
			) {
				_debugFirstLine.addToLine(`(using aux parent)`);
				return [text];
			}
			_debugFirstLine.addToLine('[invalid]');
			return [];
		}

		return [text];
	}

	// skip this element
	if (ElEMENTS_TO_SKIP.includes(elementTag)) {
		_debugFirstLine.addToLine(`(skip)`);
		return [];
	}

	// Add formats to the configuration acc.
	const currentFormats = getElementConfig(element, configAccumulator, _debugFirstLine);

	// if we want to omit the element, parse the children and flatten the result.
	// (this is also the reason that we need to return an array)
	if (ELEMENT_TO_JUST_PICK_CHILDREN.includes(elementTag)) {
		_debugFirstLine.addToLine(`(parse children)`);
		return elementChildrenParser(
			childNodes,
			currentFormats,
			parent,
			auxiliaryParent,
			attachmentSet,
			_nextDebugAcc
		);
	}

	const slateElement = element2Slate(element, parent, currentFormats, attachmentSet);

	// if it is a known slate element, parse children and return it.
	if (!!slateElement) {
		_debugFirstLine.addToLine(`[${slateElement.type}]`);

		slateElement.children = elementChildrenParser(
			childNodes,
			currentFormats,
			slateElement,
			auxiliaryParent,
			attachmentSet,
			_nextDebugAcc
		);
		return [slateElement];
	}

	_debugFirstLine.addToLine(`(unknown)`);

	const currentPossibleParent = element2Candidates(element, currentFormats);

	if (currentPossibleParent) {
		_debugFirstLine.addToLine(`(possible ${currentPossibleParent.type})`);
	}

	const possibleParent = currentPossibleParent || auxiliaryParent;

	// at this point, the element is not a known html element
	// so we try to parse the children
	const children = elementChildrenParser(
		childNodes,
		currentFormats,
		parent,
		possibleParent,
		attachmentSet,
		_nextDebugAcc
	);

	if (children.length > 0) {
		const includesInline = children.reduce((acc, curr) => {
			return acc || INLINE_ELEMENTS.includes(curr.type);
		}, false);

		// set as possible parent
		if (includesInline && !parent && !!possibleParent) {
			_debugFirstLine.addToLine('(using the possible parent)');
			return [
				{
					...possibleParent,
					children,
				},
			];
		}
	}

	// if there are no children, just add an empty text if possible
	if (children.length === 0) {
		_debugFirstLine.addToLine(`(no children)`);

		const text = {
			type: ReachSlateElementType.TEXT,
			baseConfig: getAccConfig(ReachSlateElementType.TEXT, currentFormats),
			text: '',
		};

		if (!parent || ELEMENTS_THAT_CAN_NOT_HAVE_TEXT.includes(parent?.type)) {
			if (
				!!auxiliaryParent &&
				!ELEMENTS_THAT_CAN_NOT_HAVE_TEXT.includes(auxiliaryParent.type)
			) {
				_debugFirstLine.addToLine(`(using aux parent)`);
				return [text];
			}
			_debugFirstLine.addToLine('(skip)');
			return [];
		}

		_debugFirstLine.addToLine('(add empty text)');

		return [text];
	}

	return children;
}

export const html2Slate = (
	str: string,
	attachmentSet: Set<string> = new Set(),
	debug = false
): NoteSlateItemInterface[] => {
	try {
		const $ = cheerio.load(str, null, false);
		const parsedHTML = $.parseHTML(str) as ReachHtmlNode[];

		const _debugInit = '━┓';
		debugUtils.newLine(_debugInit);

		const rawSlate = elementChildrenParser(
			parsedHTML,
			{},
			null,
			null,
			attachmentSet,
			' '.repeat(_debugInit.length - 1)
		);

		if (debug) {
			debugUtils.printAll();
		}

		const slate = normalizeNote(rawSlate);

		return slate;
	} catch (error) {
		console.error(error);
		return [];
	}
};

export const htmlToSlateJSON = (
	str: string,
	attachmentSet: Set<string> = new Set(),
	debug = false
): string => {
	try {
		return JSON.stringify(html2Slate(str, attachmentSet, debug));
	} catch (error) {
		console.error(error);
		return JSON.stringify([]);
	}
};

/**
 * this is just to easily test the script with:
 * `npx nodemon --exec npx ts-node ./src/parsers/html-to-json.ts`
 */
(() => {})();
