export enum SearchOperator {
	EQ = 'EQ', // equals
	NEQ = 'NEQ', // not equals
	CA = 'CA', // contains all
	CS = 'CS', // contains some
	NCA = 'NCA', // not contains all
	NCS = 'NCS', // not contains some
	G = 'G', // greater
	GE = 'GE', // greater or equals
	L = 'L', // less
	LE = 'LE', // less or equals
	P = 'P', // present
	NP = 'NP', // not present
}

export const EqualOperatorGroup = [SearchOperator.EQ, SearchOperator.NEQ] as const;

export type EqualOperatorGroupType = (typeof EqualOperatorGroup)[number];

export const ContainAllOperatorGroup = [SearchOperator.CA, SearchOperator.NCA] as const;

export const ContainSomeOperatorGroup = [SearchOperator.CS, SearchOperator.NCS] as const;

export const ContainOperatorGroup = [
	...ContainAllOperatorGroup,
	...ContainSomeOperatorGroup,
] as const;

export type ContainOperatorGroupType = (typeof ContainOperatorGroup)[number];
export const ComparisonOperatorGroup = [
	SearchOperator.G,
	SearchOperator.GE,
	SearchOperator.L,
	SearchOperator.LE,
] as const;
export type ComparisonOperatorGroupType = (typeof ComparisonOperatorGroup)[number];

export const HasPropertyOperatorGroup = [SearchOperator.P, SearchOperator.NP] as const;
export type HasPropertyOperatorGroupType = (typeof HasPropertyOperatorGroup)[number];

export const searchOperatorWithValues = [
	...EqualOperatorGroup,
	...ContainOperatorGroup,
	...ComparisonOperatorGroup,
] as const;

export type SearchOperatorWithValueType = (typeof searchOperatorWithValues)[number];

export const searchOperatorWithoutValues = [...HasPropertyOperatorGroup] as const;

export type SearchOperatorWithoutValueType = (typeof searchOperatorWithoutValues)[number];
