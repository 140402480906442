import { Injectable } from '@angular/core';
import { firstValueFrom, fromEvent } from 'rxjs';
import { filter, timeout, first } from 'rxjs/operators';
import {
	BrowserExtensionRequestMessageKey,
	BrowserExtensionRequestMessageDataMap,
	BrowserExtensionRequestToResponseKeyMap,
	BrowserExtensionRequestToResponseKeyMapObj,
	BrowserExtensionResponseMessageDataMap,
} from './browser-extension.types';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class BrowserExtensionService {
	public get className(): string {
		return 'BrowserExtensionService';
	}

	public async sendMessage<
		REQ_KEY extends BrowserExtensionRequestMessageKey,
		REQ_DATA extends REQ_KEY extends keyof BrowserExtensionRequestMessageDataMap
			? BrowserExtensionRequestMessageDataMap[REQ_KEY]
			: Record<string, never>,
		RES_KEY extends BrowserExtensionRequestToResponseKeyMap[REQ_KEY],
		RES_DATA extends RES_KEY extends keyof BrowserExtensionResponseMessageDataMap
			? BrowserExtensionResponseMessageDataMap[RES_KEY]
			: Record<string, never>
	>(key: REQ_KEY, data: REQ_DATA, timeoutMs = 2_000): Promise<RES_DATA> {
		return new Promise<RES_DATA>(async (res, rej) => {
			try {
				const resKey = BrowserExtensionRequestToResponseKeyMapObj[key];

				const obs$ = fromEvent<MessageEvent<{ key: RES_KEY; data: RES_DATA }>>(
					window,
					'message'
				).pipe(
					filter((event) => {
						try {
							const { key } = event.data as {
								key: RES_KEY;
							};

							return key === resKey;
						} catch (error) {
							return false;
						}
					}),
					timeout(timeoutMs),
					first(),
					untilDestroyed(this)
				);

				firstValueFrom(obs$)
					.then((event) => {
						res(event.data.data);
					})
					.catch((error) => {
						rej(error);
					});

				window.postMessage({ key, data }, window.location.href);
			} catch (err) {
				rej(err);
			}
		});
	}
}
