import { EnigmaQuestionCategoryEnum } from './enigma-question-category.enum';

export enum EnigmaQuestionSubcategoriesEnum {
	INSIGHTS = 'INSIGHTS',
	TUTORIAL = 'TUTORIAL',
	ANIMALS = 'ANIMALS',
	CULTURE = 'CULTURE',
	ENTERTAINMENT = 'ENTERTAINMENT',
	FOOD_AND_DRINKS = 'FOOD_AND_DRINKS',
	GEOGRAPHY = 'GEOGRAPHY',
	HISTORY = 'HISTORY',
	MOVIES_AND_BOOKS = 'MOVIES_AND_BOOKS',
	SCIENCE = 'SCIENCE',
	SPORTS = 'SPORTS',
	TECHNOLOGY = 'TECHNOLOGY',
}

export const enigmaQuestionSubcategoriesPerCategory: {
	[c in EnigmaQuestionCategoryEnum]: Array<EnigmaQuestionSubcategoriesEnum>;
} = {
	[EnigmaQuestionCategoryEnum.MYREACH]: [
		EnigmaQuestionSubcategoriesEnum.TUTORIAL,
		EnigmaQuestionSubcategoriesEnum.INSIGHTS,
	],
	[EnigmaQuestionCategoryEnum.TRIVIA]: [
		EnigmaQuestionSubcategoriesEnum.ANIMALS,
		EnigmaQuestionSubcategoriesEnum.CULTURE,
		EnigmaQuestionSubcategoriesEnum.ENTERTAINMENT,
		EnigmaQuestionSubcategoriesEnum.FOOD_AND_DRINKS,
		EnigmaQuestionSubcategoriesEnum.GEOGRAPHY,
		EnigmaQuestionSubcategoriesEnum.HISTORY,
		EnigmaQuestionSubcategoriesEnum.MOVIES_AND_BOOKS,
		EnigmaQuestionSubcategoriesEnum.SCIENCE,
		EnigmaQuestionSubcategoriesEnum.SPORTS,
		EnigmaQuestionSubcategoriesEnum.TECHNOLOGY,
	],
};
