import { ReachTeam } from './team';

export interface GetTeamsResponse {
	teams: ReachTeam[];
}

export interface CreateTeamRequest {
	name: string;
	leaderId: string;
	memberIds: string[];
}

export interface CreateTeamResponse {
	team: ReachTeam;
}

export type UpdateTeamRequest = CreateTeamRequest;

export interface UpdateTeamResponse {
	team: ReachTeam;
}

export type DeleteTeamResponse = Record<string, never>;
