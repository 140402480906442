import { DOCUMENT } from '@angular/common';
import {
	ApplicationRef,
	ComponentRef,
	createComponent,
	Inject,
	Injectable,
	Injector,
	Type,
} from '@angular/core';

export interface CreateComponentOptions {
	// Defaults to `true`.
	appendComponent?: boolean;

	// Defaults to `undefined`.
	injector?: Injector;

	// Defaults to `<body>`.
	hostElement?: HTMLElement;
}

@Injectable({ providedIn: 'root' })
export class AsyncLoaderService {
	constructor(
		@Inject(DOCUMENT) private readonly document: Document,
		private appRef: ApplicationRef
	) {}

	/**
	 * Creates a component and attach it's view to the app.
	 */
	public createComponent<C>(
		component: Type<C>,
		options?: CreateComponentOptions
	): ComponentRef<C> {
		const ref = createComponent(component, {
			environmentInjector: this.appRef.injector,
			elementInjector: options?.injector,
		});
		this.appRef.attachView(ref.hostView);

		if (options?.appendComponent ?? true) {
			const parent = options?.hostElement ?? this.document.body;
			parent.appendChild(ref.location.nativeElement);
		}

		return ref;
	}
}
