import { Injectable } from '@angular/core';
import { SubscriptionPreferences, UserPreferencesGeneral } from '@reach/interfaces';
import { BaseApiService } from '~app-client/api/_internal';

@Injectable({ providedIn: 'root' })
export class EmailSubscriptionApiService extends BaseApiService {
	protected commonPath = ['settings', 'subscription'];

	public getSubscriptionState(
		token: string
	): Promise<Pick<UserPreferencesGeneral, 'emailSubscriptions' | 'pushSubscriptions'>> {
		return this.http.get<
			Pick<UserPreferencesGeneral, 'emailSubscriptions' | 'pushSubscriptions'>
		>(this.url(''), {}, { 'x-access-token': token });
	}

	public editSubscriptionState(
		token: string,
		newState: Partial<SubscriptionPreferences>
	): Promise<void> {
		const body = { emailSubscriptions: newState };
		return this.http.post<void>(this.url(''), body, {}, { 'x-access-token': token });
	}
}
