import { Edge } from '../edges';
import { NodeType } from '../nodes';

export interface GraphNodeFromSearch {
	id: string;
	name: string;
	nodeType: NodeType;
}

export type GraphLinkFromSearch = Partial<Edge>;

export interface GraphDataFromSearch {
	nodes: GraphNodeFromSearch[];
	links: GraphLinkFromSearch[];
}
