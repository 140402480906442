import { NodeType } from '../nodes';

export interface NameSuggestion {
	/**
	 * Emphasized name
	 */
	value: string;

	/**
	 * Node type
	 */
	type: NodeType;

	name: string;
	id: string;
}
