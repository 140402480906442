import { SuggestionSource } from './suggestion-source.enum';
import { NodeType } from '../nodes/node-type.enum';

export interface NodeSuggestion {
	/**
	 * The ID of the node. Only mandatory if the node already exists.
	 */
	id?: number;

	name: string;

	nodeType: NodeType;

	source: SuggestionSource;

	/**
	 * This should be a number between 0 and 1.
	 * It is needed to only show to the user the ones with the higher scores.
	 * If it is not set, it will be treated as a score of 0.
	 */
	score?: number;
}
