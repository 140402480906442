import { ReachSlateElementType } from '@reach/interfaces';
import { DebugLine } from '../../debug';
import { ConfigAccumulator } from '../../types';
import { ColorDistance, getColorDistance } from './colors/distance';
import { REACH_COLORS_LIST } from './colors/list';
import { ReachColor, RgbColor } from './colors/types';
import { REACH_COLOR_TO_VALUE } from './colors/utils';
import * as chroma from 'chroma-js';

function getClosestColor(value: string): ReachColor | null {
	let bestDistance: {
		delta: number;
		distance: ColorDistance;
		rgb: RgbColor | null;
		name: ReachColor | null;
	} = {
		delta: Number.POSITIVE_INFINITY,
		distance: ColorDistance.NOPE,
		rgb: null,
		name: null,
	};
	if (!chroma.valid(value)) {
		// We added this since the css color attribute can have non-valid color values
		// e.g. color: inherit
		// This was throwing an error, because inherit is not a valid color for chromajs
		return null;
	}
	const reachColors = Object.values(ReachColor);
	for (let idx = 0; idx < reachColors.length; idx++) {
		const currentColor = reachColors[idx];
		const currentRgb = REACH_COLORS_LIST[currentColor];
		const currentChroma = `rgb(${currentRgb.join(', ')})`;
		const delta = chroma.deltaE(currentChroma, value);

		if (delta < bestDistance.delta) {
			bestDistance = {
				delta,
				distance: getColorDistance(delta),
				rgb: currentRgb,
				name: currentColor,
			};
		}
	}

	if (bestDistance.distance <= ColorDistance.VERY_SIMILAR) {
		return bestDistance.name;
	}

	return null;
}

export function fontColorFn(value: string, _debugLine?: DebugLine): ConfigAccumulator {
	const reachColor = getClosestColor(value);

	if (!!reachColor) {
		const reachColorValue = REACH_COLOR_TO_VALUE[reachColor];
		_debugLine?.addToLine(`(color: ${reachColorValue})`);

		return {
			[ReachSlateElementType.TEXT]: {
				fontColor: reachColorValue,
			},
		};
	}

	return {};
}

export function backgroundColorFn(value: string, _debugLine?: DebugLine): ConfigAccumulator {
	const reachColor = getClosestColor(value);

	if (!!reachColor) {
		const reachColorValue = REACH_COLOR_TO_VALUE[reachColor];
		_debugLine?.addToLine(`(backgroundColor: ${reachColorValue})`);

		return {
			[ReachSlateElementType.TEXT]: {
				backgroundColor: reachColorValue,
			},
		};
	}

	return {};
}
