import { NodeType } from '../nodes';
import { NodeActionEnum } from '../integrations';
import { NodeSubtypeEnum } from '../node-subtypes';

export type NodeGroupActionsSetMap = {
	[key in NodeSubtypeEnum | NodeType]?: { [key in NodeActionEnum]?: Set<string> };
};

export type NodeGroupActionsArrayMap = {
	[key in NodeSubtypeEnum | NodeType]?: { [key in NodeActionEnum]?: Array<string> };
};

export type NodeGroupActionsCountMap = {
	[key in NodeSubtypeEnum | NodeType]?: { [key in NodeActionEnum]?: number };
};
