import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { NodeType } from '@reach/interfaces';

@Pipe({
	pure: true,
	name: 'nodeTypeColorClass',
})
export class NodeTypeColorClassPipe implements PipeTransform {
	transform(type: NodeType): string {
		return `${type}-color`.toLowerCase();
	}
}

@NgModule({
	declarations: [NodeTypeColorClassPipe],
	providers: [NodeTypeColorClassPipe],
	exports: [NodeTypeColorClassPipe],
})
export class NodeTypeColorClassModule {}
