import { NodeType } from '@reach/interfaces';

export type AnalyticsEventShareReceivedStart = {
	shareLength: number;
};

export interface AnalyticsEventShareReceivedSuccess {
	nodeType: NodeType;
}

export interface AnalyticsEventShareReceivedFails {
	shareFailureReason: 'empty' | 'unknown';
}
