import { ReachHtmlNode } from '../../../html-types';
import { DebugLine } from '../../debug';
import { ConfigAccumulator } from '../../types';
import { mergeConfigs } from '../common';
import { backgroundColorFn, fontColorFn } from './color';
import { fontFamilyFn, fontSizeFn, textAlignFn, textDecorationFn } from './fonts';
import { paddingFn, paddingLeftFn } from './padding';
import { StyleFn } from './types';

export const FORMATS_FROM_STYLES: {
	[styleKey: string]: StyleFn;
} = {
	'font-size': fontSizeFn,
	color: fontColorFn,
	backgroundColor: backgroundColorFn,
	'text-decoration': textDecorationFn,
	'text-align': textAlignFn,
	'font-family': fontFamilyFn,
	padding: paddingFn,
	'padding-left': paddingLeftFn, // this needs to be placed AFTER padding, so it overwrittes it
};

export function getFormatFromStyles(
	element: ReachHtmlNode,
	_debugLine?: DebugLine
): ConfigAccumulator {
	const styleStr = element?.attribs?.style || '';

	const styles = styleStr
		.trim()
		.split(';')
		.map((style) => {
			const [key, value] = style.trim().split(':');
			return {
				key: key?.trim() || '',
				value: value?.trim() || '',
			};
		})
		.filter((item) => {
			return !!item.key && !!item.value;
		});

	return styles.reduce((acc, curr) => {
		try {
			const config = FORMATS_FROM_STYLES[curr.key]?.(curr.value, _debugLine) || {};
			return mergeConfigs(acc, config);
		} catch (e) {
			console.error(e);
			return acc;
		}
	}, {} as ConfigAccumulator);
}
