import { Injectable } from '@angular/core';
import {
	AlbumCreateBody,
	AlbumInterface,
	AlbumUpdateBody,
	FileInterface,
	LocationLatLonWithPrecision,
	NodeCreationSource,
	NodeCreationSourceBody,
} from '@reach/interfaces';
import { BaseApiService } from '~app-client/api/_internal';
import { ReachNodeId } from '~app-client/core/types';

@Injectable({ providedIn: 'root' })
export class AlbumsApiService extends BaseApiService {
	protected commonPath = ['albums'];

	public createAlbum(
		files: File[],
		workspaceIds: ReachNodeId[],
		source: NodeCreationSource,
		location?: LocationLatLonWithPrecision
	): Promise<AlbumInterface> {
		const body = new FormData();
		for (const file of files) {
			body.append('files', file);
		}

		const albumCreate: AlbumCreateBody = {
			location,
		};
		const sourceItem: NodeCreationSourceBody = {
			source,
		};

		body.append('albumCreate', JSON.stringify(albumCreate));
		body.append('workspaceIds', JSON.stringify(workspaceIds));
		body.append('source', JSON.stringify(sourceItem));

		return this.http.post<AlbumInterface>(this.url(), body);
	}

	public getAlbumItem(albumId: ReachNodeId, fileId: ReachNodeId): Promise<FileInterface> {
		return this.http.get<FileInterface>(this.url(albumId, 'getFile', fileId));
	}

	public createAlbumItemAsFile(
		albumId: ReachNodeId,
		albumItemId: string
	): Promise<FileInterface> {
		return this.http.post<FileInterface>(this.url(albumId, 'toFile', albumItemId));
	}

	public edit(albumId: ReachNodeId, data: AlbumUpdateBody): Promise<AlbumInterface> {
		const body = data;
		return this.http.patch<AlbumInterface>(this.url(albumId), body);
	}

	public addItems(
		albumId: ReachNodeId,
		files: File[],
		location?: LocationLatLonWithPrecision
	): Promise<AlbumInterface> {
		const body = new FormData();
		for (const file of files) {
			body.append('files', file);
		}

		const params = {
			source: NodeCreationSource.DEFAULT,
			location: JSON.stringify(location),
		} as { source: string; location?: string };

		if (!location) {
			delete params.location;
		}

		return this.http.post<AlbumInterface>(this.url(albumId, 'add'), body, params);
	}

	public removeItems(albumId: ReachNodeId, itemsIds: ReachNodeId[]): Promise<void> {
		const body = { fileIds: itemsIds };
		return this.http.delete<void>(this.url(albumId), body);
	}

	public downloadAsZip(albumId: ReachNodeId): Promise<Blob> {
		return this.http.get<Blob>(this.url(albumId, 'download'), {}, {}, 'blob');
	}

	public itemAsNode(albumId: ReachNodeId, itemId: ReachNodeId): Promise<FileInterface> {
		return this.http.post<FileInterface>(this.url(albumId, 'toFile', itemId));
	}
}
