import { NodeType } from '../nodes';

export interface BookmarkItem {
	id: string;
	name: string;
}

export type BookmarkTag = BookmarkItem;
export interface BookmarkWebsite extends BookmarkItem {
	url: string;
}

export interface BookmarkEdge {
	// Id of source
	source: string;
	// Id of target
	target: string;
	jumps: number;
}

export interface ImportBookmarks {
	websites: BookmarkWebsite[];
	tags: BookmarkTag[];
	edges: BookmarkEdge[];
}

export interface BookmarkIntegrationItem {
	url: string;
	name: string;
	folderPath: string[];
}

export interface FrontendBookmarkItem {
	id: string;
	nodeType: NodeType.WEBSITE | NodeType.TAG;
	name: string;
	children: FrontendBookmarkItem[];
	href: string;
}

export interface ReachBookmark {
	url: string;

	name: string;

	folderPath: string[];
}
