import { UserInterface } from '../user';

export interface ImpersonateUserTicketInterface {
	ticketId: string;
	impersonator: Partial<UserInterface> | string;
	impersonee: Partial<UserInterface> | string;
	reason: string;
	source: string | null;
	comments: string | null;
	isResolved: boolean;
	createdAt: Date;
	resolvedAt: Date | null;
}

export interface ImpersonateUserAccessInterface {
	accessId: string;
	user: Partial<UserInterface> | string;
	startedAt: Date;
}
