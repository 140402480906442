/**
 * This could just be moved somewhere in the frontend as the backend will never use it.
 */
export const Country: { [index in CountryCode]: string } = {
	AF: 'Afghanistan',
	AL: 'Albania',
	DZ: 'Algeria',
	AS: 'American Samoa',
	AD: 'Andorra',
	AO: 'Angola',
	AI: 'Anguilla',
	AQ: 'Antarctica',
	AG: 'Antigua and Barbuda',
	AR: 'Argentina',
	AM: 'Armenia',
	AW: 'Aruba',
	AU: 'Australia',
	AT: 'Austria',
	AZ: 'Azerbaijan',
	BS: 'Bahamas (the)',
	BH: 'Bahrain',
	BD: 'Bangladesh',
	BB: 'Barbados',
	BY: 'Belarus',
	BE: 'Belgium',
	BZ: 'Belize',
	BJ: 'Benin',
	BM: 'Bermuda',
	BT: 'Bhutan',
	BO: 'Bolivia (Plurinational State of)',
	BQ: 'Bonaire, Sint Eustatius and Saba',
	BA: 'Bosnia and Herzegovina',
	BW: 'Botswana',
	BV: 'Bouvet Island',
	BR: 'Brazil',
	IO: 'British Indian Ocean Territory (the)',
	BN: 'Brunei Darussalam',
	BG: 'Bulgaria',
	BF: 'Burkina Faso',
	BI: 'Burundi',
	CV: 'Cabo Verde',
	KH: 'Cambodia',
	CM: 'Cameroon',
	CA: 'Canada',
	KY: 'Cayman Islands (the)',
	CF: 'Central African Republic (the)',
	TD: 'Chad',
	CL: 'Chile',
	CN: 'China',
	CX: 'Christmas Island',
	CC: 'Cocos (Keeling) Islands (the)',
	CO: 'Colombia',
	KM: 'Comoros (the)',
	CD: 'Congo (the Democratic Republic of the)',
	CG: 'Congo (the)',
	CK: 'Cook Islands (the)',
	CR: 'Costa Rica',
	HR: 'Croatia',
	CU: 'Cuba',
	CW: 'Curaçao',
	CY: 'Cyprus',
	CZ: 'Czechia',
	CI: "Côte d'Ivoire",
	DK: 'Denmark',
	DJ: 'Djibouti',
	DM: 'Dominica',
	DO: 'Dominican Republic (the)',
	EC: 'Ecuador',
	EG: 'Egypt',
	SV: 'El Salvador',
	GQ: 'Equatorial Guinea',
	ER: 'Eritrea',
	EE: 'Estonia',
	SZ: 'Eswatini',
	ET: 'Ethiopia',
	FK: 'Falkland Islands (the) [Malvinas]',
	FO: 'Faroe Islands (the)',
	FJ: 'Fiji',
	FI: 'Finland',
	FR: 'France',
	GF: 'French Guiana',
	PF: 'French Polynesia',
	TF: 'French Southern Territories (the)',
	GA: 'Gabon',
	GM: 'Gambia (the)',
	GE: 'Georgia',
	DE: 'Germany',
	GH: 'Ghana',
	GI: 'Gibraltar',
	GR: 'Greece',
	GL: 'Greenland',
	GD: 'Grenada',
	GP: 'Guadeloupe',
	GU: 'Guam',
	GT: 'Guatemala',
	GG: 'Guernsey',
	GN: 'Guinea',
	GW: 'Guinea-Bissau',
	GY: 'Guyana',
	HT: 'Haiti',
	HM: 'Heard Island and McDonald Islands',
	VA: 'Holy See (the)',
	HN: 'Honduras',
	HK: 'Hong Kong',
	HU: 'Hungary',
	IS: 'Iceland',
	IN: 'India',
	ID: 'Indonesia',
	IR: 'Iran (Islamic Republic of)',
	IQ: 'Iraq',
	IE: 'Ireland',
	IM: 'Isle of Man',
	IL: 'Israel',
	IT: 'Italy',
	JM: 'Jamaica',
	JP: 'Japan',
	JE: 'Jersey',
	JO: 'Jordan',
	KZ: 'Kazakhstan',
	KE: 'Kenya',
	KI: 'Kiribati',
	KP: "Korea (the Democratic People's Republic of)",
	KR: 'Korea (the Republic of)',
	KW: 'Kuwait',
	KG: 'Kyrgyzstan',
	LA: "Lao People's Democratic Republic (the)",
	LV: 'Latvia',
	LB: 'Lebanon',
	LS: 'Lesotho',
	LR: 'Liberia',
	LY: 'Libya',
	LI: 'Liechtenstein',
	LT: 'Lithuania',
	LU: 'Luxembourg',
	MO: 'Macao',
	MG: 'Madagascar',
	MW: 'Malawi',
	MY: 'Malaysia',
	MV: 'Maldives',
	ML: 'Mali',
	MT: 'Malta',
	MH: 'Marshall Islands (the)',
	MQ: 'Martinique',
	MR: 'Mauritania',
	MU: 'Mauritius',
	YT: 'Mayotte',
	MX: 'Mexico',
	FM: 'Micronesia (Federated States of)',
	MD: 'Moldova (the Republic of)',
	MC: 'Monaco',
	MN: 'Mongolia',
	ME: 'Montenegro',
	MS: 'Montserrat',
	MA: 'Morocco',
	MZ: 'Mozambique',
	MM: 'Myanmar',
	NA: 'Namibia',
	NR: 'Nauru',
	NP: 'Nepal',
	NL: 'Netherlands (the)',
	NC: 'New Caledonia',
	NZ: 'New Zealand',
	NI: 'Nicaragua',
	NE: 'Niger (the)',
	NG: 'Nigeria',
	NU: 'Niue',
	NF: 'Norfolk Island',
	MP: 'Northern Mariana Islands (the)',
	NO: 'Norway',
	OM: 'Oman',
	PK: 'Pakistan',
	PW: 'Palau',
	PS: 'Palestine, State of',
	PA: 'Panama',
	PG: 'Papua New Guinea',
	PY: 'Paraguay',
	PE: 'Peru',
	PH: 'Philippines (the)',
	PN: 'Pitcairn',
	PL: 'Poland',
	PT: 'Portugal',
	PR: 'Puerto Rico',
	QA: 'Qatar',
	MK: 'Republic of North Macedonia',
	RO: 'Romania',
	RU: 'Russian Federation (the)',
	RW: 'Rwanda',
	RE: 'Réunion',
	BL: 'Saint Barthélemy',
	SH: 'Saint Helena, Ascension and Tristan da Cunha',
	KN: 'Saint Kitts and Nevis',
	LC: 'Saint Lucia',
	MF: 'Saint Martin (French part)',
	PM: 'Saint Pierre and Miquelon',
	VC: 'Saint Vincent and the Grenadines',
	WS: 'Samoa',
	SM: 'San Marino',
	ST: 'Sao Tome and Principe',
	SA: 'Saudi Arabia',
	SN: 'Senegal',
	RS: 'Serbia',
	SC: 'Seychelles',
	SL: 'Sierra Leone',
	SG: 'Singapore',
	SX: 'Sint Maarten (Dutch part)',
	SK: 'Slovakia',
	SI: 'Slovenia',
	SB: 'Solomon Islands',
	SO: 'Somalia',
	ZA: 'South Africa',
	GS: 'South Georgia and the South Sandwich Islands',
	SS: 'South Sudan',
	ES: 'Spain',
	LK: 'Sri Lanka',
	SD: 'Sudan (the)',
	SR: 'Suriname',
	SJ: 'Svalbard and Jan Mayen',
	SE: 'Sweden',
	CH: 'Switzerland',
	SY: 'Syrian Arab Republic',
	TW: 'Taiwan (Province of China)',
	TJ: 'Tajikistan',
	TZ: 'Tanzania, United Republic of',
	TH: 'Thailand',
	TL: 'Timor-Leste',
	TG: 'Togo',
	TK: 'Tokelau',
	TO: 'Tonga',
	TT: 'Trinidad and Tobago',
	TN: 'Tunisia',
	TR: 'Turkey',
	TM: 'Turkmenistan',
	TC: 'Turks and Caicos Islands (the)',
	TV: 'Tuvalu',
	UG: 'Uganda',
	UA: 'Ukraine',
	AE: 'United Arab Emirates (the)',
	GB: 'United Kingdom of Great Britain and Northern Ireland (the)',
	UM: 'United States Minor Outlying Islands (the)',
	US: 'United States of America (the)',
	UY: 'Uruguay',
	UZ: 'Uzbekistan',
	VU: 'Vanuatu',
	VE: 'Venezuela (Bolivarian Republic of)',
	VN: 'Viet Nam',
	VG: 'Virgin Islands (British)',
	VI: 'Virgin Islands (U.S.)',
	WF: 'Wallis and Futuna',
	EH: 'Western Sahara',
	YE: 'Yemen',
	ZM: 'Zambia',
	ZW: 'Zimbabwe',
	AX: 'Åland Islands',
};

export enum CountryCode {
	AF = 'AF',
	AL = 'AL',
	DZ = 'DZ',
	AS = 'AS',
	AD = 'AD',
	AO = 'AO',
	AI = 'AI',
	AQ = 'AQ',
	AG = 'AG',
	AR = 'AR',
	AM = 'AM',
	AW = 'AW',
	AU = 'AU',
	AT = 'AT',
	AZ = 'AZ',
	BS = 'BS',
	BH = 'BH',
	BD = 'BD',
	BB = 'BB',
	BY = 'BY',
	BE = 'BE',
	BZ = 'BZ',
	BJ = 'BJ',
	BM = 'BM',
	BT = 'BT',
	BO = 'BO',
	BQ = 'BQ',
	BA = 'BA',
	BW = 'BW',
	BV = 'BV',
	BR = 'BR',
	IO = 'IO',
	BN = 'BN',
	BG = 'BG',
	BF = 'BF',
	BI = 'BI',
	CV = 'CV',
	KH = 'KH',
	CM = 'CM',
	CA = 'CA',
	KY = 'KY',
	CF = 'CF',
	TD = 'TD',
	CL = 'CL',
	CN = 'CN',
	CX = 'CX',
	CC = 'CC',
	CO = 'CO',
	KM = 'KM',
	CD = 'CD',
	CG = 'CG',
	CK = 'CK',
	CR = 'CR',
	HR = 'HR',
	CU = 'CU',
	CW = 'CW',
	CY = 'CY',
	CZ = 'CZ',
	CI = 'CI',
	DK = 'DK',
	DJ = 'DJ',
	DM = 'DM',
	DO = 'DO',
	EC = 'EC',
	EG = 'EG',
	SV = 'SV',
	GQ = 'GQ',
	ER = 'ER',
	EE = 'EE',
	SZ = 'SZ',
	ET = 'ET',
	FK = 'FK',
	FO = 'FO',
	FJ = 'FJ',
	FI = 'FI',
	FR = 'FR',
	GF = 'GF',
	PF = 'PF',
	TF = 'TF',
	GA = 'GA',
	GM = 'GM',
	GE = 'GE',
	DE = 'DE',
	GH = 'GH',
	GI = 'GI',
	GR = 'GR',
	GL = 'GL',
	GD = 'GD',
	GP = 'GP',
	GU = 'GU',
	GT = 'GT',
	GG = 'GG',
	GN = 'GN',
	GW = 'GW',
	GY = 'GY',
	HT = 'HT',
	HM = 'HM',
	VA = 'VA',
	HN = 'HN',
	HK = 'HK',
	HU = 'HU',
	IS = 'IS',
	IN = 'IN',
	ID = 'ID',
	IR = 'IR',
	IQ = 'IQ',
	IE = 'IE',
	IM = 'IM',
	IL = 'IL',
	IT = 'IT',
	JM = 'JM',
	JP = 'JP',
	JE = 'JE',
	JO = 'JO',
	KZ = 'KZ',
	KE = 'KE',
	KI = 'KI',
	KP = 'KP',
	KR = 'KR',
	KW = 'KW',
	KG = 'KG',
	LA = 'LA',
	LV = 'LV',
	LB = 'LB',
	LS = 'LS',
	LR = 'LR',
	LY = 'LY',
	LI = 'LI',
	LT = 'LT',
	LU = 'LU',
	MO = 'MO',
	MG = 'MG',
	MW = 'MW',
	MY = 'MY',
	MV = 'MV',
	ML = 'ML',
	MT = 'MT',
	MH = 'MH',
	MQ = 'MQ',
	MR = 'MR',
	MU = 'MU',
	YT = 'YT',
	MX = 'MX',
	FM = 'FM',
	MD = 'MD',
	MC = 'MC',
	MN = 'MN',
	ME = 'ME',
	MS = 'MS',
	MA = 'MA',
	MZ = 'MZ',
	MM = 'MM',
	NA = 'NA',
	NR = 'NR',
	NP = 'NP',
	NL = 'NL',
	NC = 'NC',
	NZ = 'NZ',
	NI = 'NI',
	NE = 'NE',
	NG = 'NG',
	NU = 'NU',
	NF = 'NF',
	MP = 'MP',
	NO = 'NO',
	OM = 'OM',
	PK = 'PK',
	PW = 'PW',
	PS = 'PS',
	PA = 'PA',
	PG = 'PG',
	PY = 'PY',
	PE = 'PE',
	PH = 'PH',
	PN = 'PN',
	PL = 'PL',
	PT = 'PT',
	PR = 'PR',
	QA = 'QA',
	MK = 'MK',
	RO = 'RO',
	RU = 'RU',
	RW = 'RW',
	RE = 'RE',
	BL = 'BL',
	SH = 'SH',
	KN = 'KN',
	LC = 'LC',
	MF = 'MF',
	PM = 'PM',
	VC = 'VC',
	WS = 'WS',
	SM = 'SM',
	ST = 'ST',
	SA = 'SA',
	SN = 'SN',
	RS = 'RS',
	SC = 'SC',
	SL = 'SL',
	SG = 'SG',
	SX = 'SX',
	SK = 'SK',
	SI = 'SI',
	SB = 'SB',
	SO = 'SO',
	ZA = 'ZA',
	GS = 'GS',
	SS = 'SS',
	ES = 'ES',
	LK = 'LK',
	SD = 'SD',
	SR = 'SR',
	SJ = 'SJ',
	SE = 'SE',
	CH = 'CH',
	SY = 'SY',
	TW = 'TW',
	TJ = 'TJ',
	TZ = 'TZ',
	TH = 'TH',
	TL = 'TL',
	TG = 'TG',
	TK = 'TK',
	TO = 'TO',
	TT = 'TT',
	TN = 'TN',
	TR = 'TR',
	TM = 'TM',
	TC = 'TC',
	TV = 'TV',
	UG = 'UG',
	UA = 'UA',
	AE = 'AE',
	GB = 'GB',
	UM = 'UM',
	US = 'US',
	UY = 'UY',
	UZ = 'UZ',
	VU = 'VU',
	VE = 'VE',
	VN = 'VN',
	VG = 'VG',
	VI = 'VI',
	WF = 'WF',
	EH = 'EH',
	YE = 'YE',
	ZM = 'ZM',
	ZW = 'ZW',
	AX = 'AX',
}
