import { Injectable } from '@angular/core';
import { EnigmaChallengeStatusInterface, EnigmaUserChallengeInterface } from '@reach/interfaces';
import { Observable } from 'rxjs';
import { BaseApiService } from '~app-client/api/_internal';

@Injectable({ providedIn: 'root' })
export class EnigmaApiService extends BaseApiService {
	protected commonPath = ['enigma'];

	public getChallengeStatus(): Observable<EnigmaChallengeStatusInterface> {
		const params = {
			t: `${this.paramTime}`,
		};
		return this.http.getObs<EnigmaChallengeStatusInterface>(
			this.url('challenge', 'status'),
			params
		);
	}

	public generateChallenge(): Promise<EnigmaUserChallengeInterface> {
		return this.http.post<EnigmaUserChallengeInterface>(this.url('challenge', 'generate'));
	}

	public guessChallenge(
		enigmaUserChallengeId: string,
		answerId: string
	): Promise<EnigmaUserChallengeInterface> {
		return this.http.post<EnigmaUserChallengeInterface>(
			this.url('challenge', enigmaUserChallengeId, 'guess'),
			{
				answerId,
			}
		);
	}

	public timeOutChallenge(enigmaUserChallengeId: string): Promise<EnigmaUserChallengeInterface> {
		return this.http.post<EnigmaUserChallengeInterface>(
			this.url('challenge', enigmaUserChallengeId, 'timeout')
		);
	}
}
