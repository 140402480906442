import { Injectable } from '@angular/core';
import { JobInterface } from '@reach/interfaces';
import { splitInChunks } from '@reach/utils';
import { BaseApiService } from '~app-client/api/_internal';
@Injectable({ providedIn: 'root' })
export class JobsApiService extends BaseApiService {
	protected commonPath = ['jobs'];

	public async getJobs(ids: string[]): Promise<JobInterface[]> {
		if (ids.length === 0) {
			return new Promise((res) => res([]));
		}

		const chunks = [...splitInChunks(ids, 35)];

		const t = this.paramTime;

		const promises = await Promise.allSettled(
			chunks.map((chunk) => {
				const params = {
					jobIds: chunk,
					t,
				};
				return this.http.get<JobInterface[]>(this.url(), params);
			})
		);

		const jobs: JobInterface[] = [];

		promises.forEach((result) => {
			if (result.status === 'fulfilled') {
				jobs.push(...result.value);
			}
		});

		return jobs;
	}
}
