export enum NodeCreationSource {
	DEFAULT = 'DEFAULT',
	FAST = 'FAST',
	RECOMMENDATION = 'RECOMMENDATION',
	EXTRACTED_CANDIDATE = 'EXTRACTED_CANDIDATE',
	SHARE = 'SHARE',
	PROPERTY_VALUE = 'PROPERTY_VALUE',
}

export interface NodeCreationSourceBody {
	source?: NodeCreationSource;
}
