import { Injectable } from '@angular/core';
import { BaseApiService } from '~app-client/api/_internal';
import { ReachNodeId } from '~app-client/core/types';

@Injectable({ providedIn: 'root' })
export class ImportApiService extends BaseApiService {
	protected commonPath = [''];

	public import(file: File): Promise<void> {
		const formData = new FormData();
		formData.append('file', file);

		return this.http.post<void>(this.url('import'), formData, { i: JSON.stringify({}) });
	}

	public exportWorkspaces(workspacesIds: ReachNodeId[], unassigned: boolean): Promise<void> {
		const body = {
			workspaceIds: workspacesIds,
			unassigned,
		};
		return this.http.post<void>(this.url('export', 'workspaces'), body);
	}
}
