import { LocationLatLon } from '../../location';

export interface ImportPinMapsListInterface {
	coords: LocationLatLon;
	placeName: string | null;
	description: string | null;
	address: string | null;
	urlImage: string | null;
	website: string | null;
	pinType: string | null;
	phone: string | null;
}

export interface ImportMapsPinsListGeneralInfoInterface {
	title: string;
	subtitle: string;
	size: number;
	listId: string;
}

export type MapsPinsListRequestType = {
	url: string;
};
