import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { AppInitializationService } from '~app-client/app-initialization/services';
import { ClientErrorScreenService } from '~app-client/client-error-screen/services';
import { AppInitializationComponent, ItemsToLoad } from '../component/app-initialization.component';

@UntilDestroy()
@Component({
	selector: 'app-initializer',
	templateUrl: './app-initialization.page.component.html',
	styleUrls: ['./app-initialization.page.component.scss'],
	standalone: true,
	imports: [CommonModule, RouterModule, AppInitializationComponent],
})
export class AppInitializationPageComponent {
	private readonly clientErrorService = inject(ClientErrorScreenService);
	private readonly service = inject(AppInitializationService);

	private readonly router = inject(Router);

	public get initialized(): boolean {
		return this.service.initialized;
	}

	public async _onAppInitialized(failingItems: ItemsToLoad[]): Promise<void> {
		this.service.setState(true);

		if (failingItems.includes(ItemsToLoad.ANDROID_SECURITY)) {
			await this.clientErrorService.show({
				title: 'Android App Not Secure',
				description: "We can't ensure that your device is running on a secure context",
			});
		} else {
			// This redirects the app to the same url that was called.
			// This is a hack used to gain initial load time.
			this.router.navigateByUrl(this.router.url, {
				skipLocationChange: false,
				replaceUrl: true,
				onSameUrlNavigation: 'reload',
			});
		}
	}
}
