import { CustomPropertyInterface } from '../custom-property';
import { NodeSubtypeInterface } from '../node-subtypes';
import { NodeSubtypeSettingsInterface } from '../node-subtypes-settings';
import { Nullable } from '../typescript';
import { ShareNodesActivitySourceEnum } from './share-nodes-activity-source.enum';
import { ShareNodesActivityStatusEnum } from './share-nodes-activity-status.enum';
import { ShareNodesPrivacyEnum } from './share-nodes-privacy.enum';
import { ShareNodesTypeEnum } from './share-nodes-type.enum';

export interface ShareNodesInterface {
	id: string;
	name: string;
	username: string;
    link: Nullable<string>;
	downloadCount: number;
	createdAt: Date;
	validFrom: Date;
	validUntil: Date;
	isExpired: boolean;
	expiredAt: Nullable<Date>;
	hasAlreadyExtendedExpiration: boolean;
	type: ShareNodesTypeEnum;
	privacy: ShareNodesPrivacyEnum;
	stats: ShareNodesStats;
	activities: ShareNodesActivityInterface[];
}

export interface ShareNodesStats {
	nodeCount: number;
	workspaceCount: number;
	sizeInBytes: number;
}

export interface ShareNodesActivityInterface {
	id: string;
	email: string;
	createdAt: Date;
	finishedAt: Nullable<Date>;
	status: ShareNodesActivityStatusEnum;
	source: ShareNodesActivitySourceEnum;
}

export interface ShareNodesPrepareImportInterface {
	shareNodes: ShareNodesInterface;
	usedData: ShareNodesUserUsedDataInterface;
}

export interface ShareNodesUserUsedDataInterface {
	customProperties: CustomPropertyInterface[];
	nodeSubtypes: NodeSubtypeInterface[];
	nodeSubtyesSettings: NodeSubtypeSettingsInterface[];
}

export interface UpdateShareNodesInterface {
	privacy: ShareNodesPrivacyEnum;
}
