import { NoteSlateItemInterface } from '@reach/interfaces';
import { convert, HtmlToTextOptions } from 'html-to-text';

export const parseHTMLToText = (html: string, options?: HtmlToTextOptions): string => {
	return convert(html, { preserveNewlines: true, ...(!!options ? options : {}) });
};

export const parseSlateToText = (
	slate: NoteSlateItemInterface[] | string | null | undefined
): string => {
	if (typeof slate === 'string') {
		return parseSlateToText(JSON.parse(slate));
	}
	return (slate ?? []).reduce((acc, curr) => {
		if (curr.text !== undefined) {
			acc = acc.concat(` ${curr.text}`);
		} else {
			if (curr.children && curr.children.length !== 0) {
				acc = acc.concat(parseSlateToText(curr.children));
			}
		}
		return acc;
	}, '');
};
